<template>
  <div class="wrapper" style="min-height: 100vh">
    <navbar></navbar>
    <sidebar></sidebar>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/includes/Navbar";
import Sidebar from "../components/includes/Sidebar";
export default {
  name: "Main",
  components: {Sidebar, Navbar}
}
</script>

<style>
p {
  font-size: 14px;
}

.required-field::after {
  content: "*";
  color: #ff4949;
  margin-inline-start: 2px;
}
</style>
