<template>
  <div v-if="hasPermission(permissions.userTasksCreate)">
    <content-header>{{ $t('createUserTask') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="title" :name="$t('title')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-area-input id="description" :name="$t('description')" :validate="$v" :is-loading="isLoading" />
            <div class="form-group row">
              <label for="taskType" class="col-sm-2 col-form-label text-right">{{ $t('taskType') }}</label>
              <div class="col-sm-10">
                <div class="input-group">
                  <b-form-select v-model="form.taskType" :options="taskTypes"></b-form-select>
                </div>
              </div>
            </div>
            <div v-if="form.taskType !== 'Daily'">
              <date-time-input v-if="form.taskType !== 'Weekly'" id="date" :name="$t('date')" :default-value="getCurrentDate()" :validate="$v" :is-loading="isLoading" />
              <div v-if="form.taskType === 'Weekly'" class="form-group row">
                <label for="day" class="col-sm-2 col-form-label text-right">{{ $t('day') }}</label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <b-form-select v-model="form.dayName" :options="days"></b-form-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="time" class="col-sm-2 col-form-label text-right">{{ $t('time') }}</label>
              <div class="col-sm-10">
                <div class="input-group">
                  <b-form-timepicker v-model="form.time" locale="en" />
                </div>
              </div>
            </div>
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "UserTaskCreate",
  components: {TextAreaInput, DateTimeInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: {
        title: '',
        description: '',
        taskType: 'Daily',
        dayName: 'Saturday',
        time: '00:00',
        isDone: false,
        staffNote: null,
        doneAt: null,
        userId: null
      },
      isLoading: false,
      permissions: permissions,
      days: [
        { text: 'Saturday', value: 'Saturday' },
        { text: 'Sunday', value: 'Sunday' },
        { text: 'Monday', value: 'Monday' },
        { text: 'Tuesday', value: 'Tuesday' },
        { text: 'Wednesday', value: 'Wednesday' },
        { text: 'Thursday', value: 'Thursday' },
        { text: 'Friday', value: 'Friday' },
      ],
      taskTypes: [
        { text: 'OneTime', value: 'OneTime' },
        { text: 'Daily', value: 'Daily' },
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Monthly', value: 'Monthly' },
      ],
    }
  },
  validations: {
    form: {
      title: {required, maxLength: maxLength(255)},
      description: {},
      date: {},
      time: {required},
      dayName: {},
      taskType: {},
    }
  },
  methods: {
    onSubmit() {
      delete this.form.mcTotalPrice;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('userTasks/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "userTasks"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
