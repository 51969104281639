<template>
  <div v-if="hasPermission(permissions.upcomingPaymentsList)">
    <content-header>{{ $t("upcomingPayments") }}</content-header>
    <v-content>
      <div class="m-4">
        <div class="row">
          <div class="col-6">
            <label for="fromDate">{{ $t("fromDate") }}</label>
            <flat-pickr
              id="fromDate"
              v-model="fromDate"
              class="form-control"
              :placeholder="$t('selectDate')"
              name="date"
            />
          </div>
          <div class="col-6">
            <label for="toDate">{{ $t("toDate") }}</label>
            <flat-pickr
              id="toDate"
              v-model="toDate"
              class="form-control"
              :placeholder="$t('selectDate')"
              name="date"
            />
          </div>
        </div>
      </div>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="detail" slot-scope="props">
          {{ props.row.detail.match(/Name: (.*)Property/i)[1] }}
        </template>
        <template slot="date" slot-scope="props">
          {{ formatDate(props.row.date) }}
        </template>
        <template slot="phone" slot-scope="props">
          {{ replacePhone(props.row.phone) }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { UpcomingPayment } from "@/models/UpcomingPayment";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "UpcomingPaymentList",
  components: {
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      model: new UpcomingPayment(),
      columns: [],
      rows: [],
      actions: tableActions(),
      totalRecords: 0,
      fromDate: new Date(),
      toDate: new Date(),
      config: tableConfigure(this.$t("upcomingPaymentsList"), true, false),
      permissions: permissions,
    };
  },
  watch: {
    fromDate: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    toDate: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      let fromDate = moment(this.fromDate).format("YYYY-MM-DD");
      let toDate = moment(this.toDate).format("YYYY-MM-DD");
      this.$store
        .dispatch("dashboard/GET_UPCOMING_PAYMENTS", {
          fromDate,
          toDate,
          serverParams,
        })
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
  },
};
</script>
