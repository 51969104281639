<template>
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th>{{ $t('contractNumber') }}</th>
      <th>{{ projectTenant() === 'Candle' ? $t('apartment') : $t('propertyName') }}</th>
      <th>{{ $t('buyingPrice') }}</th>
      <th>{{ $t('buyingDate') }}</th>
      <th>{{ $t('transferred') }}</th>
      <th>{{ $t('cancelled') }}</th>
      <th>{{ $t('note') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="contract in allContracts" :key="contract.id" :style="contract.isTransfered ? {'background-color': '#ffc1c1'} : contract.isCancelled ? {'background-color': '#ff8e8e'} : {'background-color': '#ffffff'}">
      <td>
        <router-link :to="{name: 'buyContracts.show', params: {id: contract.id }}">
          {{ contract.contractNumber }}
        </router-link>
      </td>
      <td>
        <router-link :to="{name: 'properties.edit', params: {id: contract.propertyId }}">
          {{ contract.property.name }}
        </router-link>
      </td>
      <td>{{ contract.buyingPrice.toLocaleString() }}</td>
      <td>{{ formatDate(contract.buyingDate, 'YYYY-MM-DD') }}</td>
      <td>{{ contract.isTransfered ? $t('yes') : $t('no') }}</td>
      <td>{{ contract.isCancelled ? $t('yes') : $t('no') }}</td>
      <td>{{ contract.note }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: "CustomerContractsList",
  props: {
    contracts: {
      type: Array
    }
  },
  watch: {
    contracts: function (value) {
      this.allContracts = value
    }
  },
  data() {
    return {
      allContracts: this.contracts,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false
    }
  },
}
</script>
