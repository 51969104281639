export default [
  {
    name: 'Cash',
    id: 1,
  },
  {
    name: 'BankSlip',
    id: 2,
  },
]
