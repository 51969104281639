<template>
  <div v-if="hasPermission(permissions.reportsDeposits)">
    <content-header>{{ $t("deposits") }}</content-header>
    <v-content>
      <h5>
        {{ $t("totalDeposits") }}:
        {{
          projectCurrencySymbol() +
          totalDeposits.toLocaleString() +
          projectCurrencySymbol(true)
        }}
      </h5>
      <b-row class="mb-4 justify-content-center align-items-end">
        <b-col sm="12" md="6" lg="3">
          <label for="fromDate">{{ $t("fromDate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="fromDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="toDate">{{ $t("toDate") }}</label>
          <flat-pickr
            id="toDate"
            v-model="toDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="safeBox">{{ $t("safeBox") }}</label>
          <select id="safeBox" v-model="selectedSafeBoxId" class="form-control">
            <option value="0">{{ $t("all") }}</option>
            <option
              v-for="safeBox in safeBoxes"
              :value="safeBox.id"
              :key="safeBox.id"
            >
              {{ safeBox.name }}
            </option>
          </select>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="total">{{ $t("total") }}</label>
          <input
            :value="
              projectCurrencySymbol() +
              total.toLocaleString() +
              projectCurrencySymbol(true)
            "
            id="total"
            class="form-control"
            disabled="disabled"
          />
        </b-col>
      </b-row>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.mcPrice.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format("YYYY-MM-DD") }}
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment
            :attachment="props.row.attachment"
            :title="props.row.attachment"
          />
        </template>
        <template slot="controls" slot-scope="props">
          <b-button @click="prePrintVoucher(props.row.id)" variant="success"
            ><i class="fas fa-print"></i
          ></b-button>
          <div
            :id="'depositVoucherReceipt_' + props.row.id"
            style="display: none"
          >
            <withdraw-voucher-candle
              :voucherNo="props.row.id"
              :voucherDate="props.row.date"
              :paidTo="props.row.title"
              :amount="props.row.mcTotalPrice"
              :reason="props.row.depositType.name"
              :note="props.row.note"
            />
          </div>
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { Deposit } from "@/models/Deposit";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";
import WithdrawVoucherCandle from "@/views/voucherDesigns/WithdrawVoucherCandle";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "DepositList",
  components: {
    WithdrawVoucherCandle,
    ShowAttachment,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      model: new Deposit(),
      columns: [],
      rows: [],
      actions: tableActions(),
      totalRecords: 0,
      config: tableConfigure(this.$t("depositList")),
      serverParams: serverParams,
      selectedRows: [],
      fromDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      selectedSafeBoxId: 1,
      safeBoxes: [],
      total: "",
      permissions: permissions,
      totalDeposits: 0,
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  mounted() {
    this.loadSafeBoxes();
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    selectedSafeBoxId: function () {
      this.resetServerParams();
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store
        .dispatch("deposits/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.totalDeposits = parseInt(response.headers["mctotal"]);
          this.isLoading = false;
          this.total = parseInt(response.headers["mctotal"]);
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subLevelId: this.selectedSafeBoxId,
        enableSubLevel: true,
      };
      this.loadData();
    },
    prePrintVoucher(index) {
      let printContents = document.getElementById(
        `depositVoucherReceipt_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    loadSafeBoxes() {
      this.$store
        .dispatch("safeBoxes/GET_ALL", serverParams)
        .then((response) => {
          this.safeBoxes = response.data;
        });
    },
    resetServerParams() {
      serverParams._start = 0;
      serverParams._end = 10;
      serverParams._sort = "";
      serverParams._order = "";
      serverParams._search = "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subLevelId: this.selectedSafeBoxId,
        enableSubLevel: true,
      };
    },
  },
};
</script>
