var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission(_vm.permissions.customersList))?_c('div',[_c('content-header',[_vm._v(_vm._s(_vm.$t("customers")))]),_c('v-content',[_c('BootstrapTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"config":_vm.config,"total-rows":_vm.totalRecords,"actions":_vm.actions},on:{"on-change-query":_vm.onChangeQuery,"on-create":function($event){return _vm.$router.push({ name: 'customers.create' })}},scopedSlots:_vm._u([{key:"pagination-info",fn:function(props){return [(props.currentPageRowsLength !== 0)?[_vm._v(_vm._s(_vm.totalRecords)+" "+_vm._s(_vm.$t("totalRecords")))]:[_vm._v(_vm._s(_vm.$t("noResultsFound")))]]}},{key:"firstName",fn:function(props){return [_vm._v(" "+_vm._s(props.row.firstName + " " + props.row.middleName + " " + props.row.lastName)+" ")]}},{key:"phone",fn:function(props){return [_vm._v(" "+_vm._s(props.row.phone.replace("+964", "0"))+" "),(props.row.phone2 !== null)?_c('span',[_vm._v(" - "+_vm._s(props.row.phone2))]):_vm._e()]}},{key:"male",fn:function(props){return [_vm._v(" "+_vm._s(props.row.male ? "M" : "F")+" ")]}},{key:"hasAccount",fn:function(props){return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":function (e) { return _vm.changeHasAccount(e, props.row.id); }},model:{value:(props.row.hasAccount),callback:function ($$v) {_vm.$set(props.row, "hasAccount", $$v)},expression:"props.row.hasAccount"}})]}},{key:"controls",fn:function(props){return (
          _vm.hasPermission(_vm.permissions.customersEdit) ||
          _vm.hasPermission(_vm.permissions.customersShow) ||
          _vm.hasPermission(_vm.permissions.customersShowAttachments) ||
          _vm.hasPermission(_vm.permissions.customersDelete)
        )?[_c('control-buttons',{attrs:{"props":props,"edit-link":(_vm.hasPermission(_vm.permissions.customersEdit) ||
              _vm.hasPermission(_vm.permissions.customersShowAttachments)) &&
            'customers.edit',"delete-link":_vm.hasPermission(_vm.permissions.customersDelete) && 'customers/DELETE'},on:{"successAction":function($event){return _vm.loadData(true)}}})]:undefined}}],null,true)},[_c('template',{slot:"empty-results"},[_vm._v(_vm._s(_vm.$t("noResultsFound")))])],2),(_vm.isLoading)?_c('is-loading'):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }