<template>
  <div class="contract text-right px-5" dir="rtl">
    <!-- Header -->
    <div>
      <h4 class="bold center">عقد بيع شقة بالأقساط</h4>
      <p>
        رقم العقد/
        <span class="bold">
          {{ buyContract.contractNumber }}
        </span>
      </p>
      <p>
        إنه في يوم الموافق ( {{ formatDate(buyContract.buyingDate) }} ) وفي
        محافظة اربيل / اقليم كوردستان / العراق تم الاتفاق بين كل من:
      </p>
      <p>
        الطرف الأول/ البائع/ شركة <span class="bold"> (Candle) </span>، و يمثله
        المدير المفوض السيد
        <span class="bold"> (رزگار عمر قادر) </span>
        إضافة لوظيفته ، و المسجل برقم
        <span class="bold"> (3103) </span>
        لدى دائرة تسجيل الشركات أربيل بتاريخ
        <span class="bold"> (8/2006/30) </span>
      </p>
      <p>
        الطرف الثاني/ المشتري / السيد/السيدة
        <span class="bold"> ({{ customer.fullName }}) </span>
        تولد /{{ customer.dob && formatDate(customer.dob) }} يحمل
        <span class="bold"> ({{ customer.idcard }}) </span>
        العنوان الدائم
        <span class="bold"> ({{ customer.address }}) </span>
        رقم الموبايل:
        <span class="bold"> {{ replacePhone(customer.phone) }} </span>
      </p>
    </div>
    <!-- detail -->
    <div class="flex-class-detail">
      <h4>
        بعد أن أقر الطرفان بأھليتھما للتعاقد والتصرفات القانونية،اتفقا على
        البنود المدرجة أدناه :-
      </h4>
      <!-- 1 -->
      <div>
        <p>
          <span class="bold"> البند الأول: </span>
          باع الطرف الأول إلى الطرف الثاني في مشروع
          <span class="bold"> (Candle) </span>
          في البناية
          <span class="bold"> ({{ property.zone }}) </span>
          في الطابق
          <span class="bold"> ({{ property.block }}) </span>
          الشقة رقم
          <span class="bold"> ({{ property.name.split("-")[1] }}) </span>
          والمشيدة على جزء من القطعة المرقمة
          <span class="bold"> (32/8853 ك 44 وارش) </span>
          والبالغ مساحتھا
          <span class="bold"> ({{ property.buildUpArea }}) </span>
          متراً مربعا وحسب الخريطة المرفقة بھذا العقد وھي جزء لا يتجزأ من ھذا
          العقد بمبلغ اجمالي وقدره
          <span class="bold">
            (${{ (buyContract.buyingPrice / 1320).toLocaleString() }})
          </span>
          دولار أمريكي أي ما يعادل
          <span class="bold">
            ({{ buyContract.buyingPrice.toLocaleString() }} د.ع)
          </span>
          دينار عراقي لاغيربالتقسيط ويتم تسديدھا كما مذكور في البند الثاني.
        </p>
      </div>
      <!-- 2 -->
      <div>
        <p v-if="!customer.isSharedCustomer">
          <span class="bold"> البند الثاني: </span>
          أتفق الطرفان على أن يسدد الطرف الثاني المبلغ المذكور اعلاة إلى الطرف
          الأول موزعة على
          <span class="bold"> (3) </span>
          ثلاث أقساط و يتم دفعه حسب المواعيد المذكورة أدناه وتدفع اعلى الشكل
          الأتی: -
        </p>
        <p v-else>
          <span class="bold">البند الثاني:</span>
          أتفق الطرفان على ان الطرف الثاني يشكلون طرفاَ واحداَ ( تكافلاَ
          وتضامناَ ) في الالتزامات والحقوق على أن يسدد الطرف الثاني المبلغ
          المذكور اعلاه إلى الطرف الأول على رقم الحساب المصرفي الذي يحدده الطرف
          الأول موزعة على المقدمة و (2) قسطا اضافة الى القسط الأخير الذي يتم
          دفعه حسب المواعيد المذكورة أدناه عند تسليم المفتاح وعلى الطرف الثاني
          تسلم قسط المفتاح بعد (30) يوما من تاريخ تبليغه بتسلم الشقة
        </p>
        <ol>
          <li>
            المقدمة نسبة
            <span class="bold"> ( %18) </span>
            ثمانية عشرة بالمائة والبالغ
            <span class="bold">
              ( ${{
                buyContract &&
                ((buyContract.buyingPrice * 0.18) / 1320).toLocaleString()
              }})
            </span>
            دولار أمريكي أي ما يعادل
            <span class="bold">
              (
              {{
                (buyContract && buyContract.buyingPrice * 0.18).toLocaleString()
              }}
              د.ع )
            </span>
            دينار عراقي لا غير يدفع عند التوقيع العقد علی ھذا العقد.
          </li>
          <li>
            القسط الأول بنسبة
            <span class="bold"> ( %22) </span>
            اثنان وعشرون بالمائة من المبلغ البيع والبالغ
            <span class="bold">
              ( ${{
                buyContract &&
                ((buyContract.buyingPrice * 0.22) / 1320).toLocaleString()
              }})
            </span>
            دولار أمريكي أي ما يعادل
            <span class="bold">
              (
              {{
                (buyContract && buyContract.buyingPrice * 0.22).toLocaleString()
              }}
              د.ع )
            </span>
            دينار عراقي عند اكمال الھيكل .
          </li>
          <li>
            القسط الثاني بنسبة
            <span class="bold"> ( %30 ) </span>
            ثلاثون بالمائة من المبلغ البيع والبالغ
            <span class="bold">
              (${{
                buyContract &&
                ((buyContract.buyingPrice * 0.3) / 1320).toLocaleString()
              }})
            </span>
            دولار أمريكي أي ما يعادل
            <span class="bold">
              (
              {{
                (buyContract && buyContract.buyingPrice * 0.3).toLocaleString()
              }}
              د.ع )
            </span>
            دينار عراقي عند استلام مفتاح الوحدة .
          </li>
          <li>
            القسط الثالث
            <span class="bold"> ( %30 ) </span>
            ثلاثون بالمائة من المبلغ المتبقي تسدد على شكل أقساط شھرية موزعة
            <span class="bold"> ( 96 ) </span>
            ستة وسبعون شھرا والبالغ
            <span class="bold">
              ( ${{
                buyContract &&
                ((buyContract.buyingPrice * 0.3) / 96 / 1320).toLocaleString()
              }}
              ) دولار
            </span>

            أمريكي أي ما يعادل
            <span class="bold">
              (
              {{
                buyContract &&
                ((buyContract.buyingPrice * 0.3) / 96).toLocaleString()
              }}
              د.ع )
            </span>
            دينارعراقي وتبدا من تاريخ استالم الشقة .
          </li>
        </ol>
      </div>
      <!-- 3 -->
      <div>
        <p>
          <span class="bold"> البند الثالث: </span>
          يلتزم الطرف الأول بتسيلم الوحدة السكنية الي الطرف الثاني مجھزة
          <span class="bold">
            ( بتبرید و غاز مرکزی و کاونتر مطبخ و طباخ و فرن و مرشحة مطبخ مع
            تجھیزات الحمامات و الصحیات اللازمة ).
          </span>
        </p>
      </div>
      <!-- 4 -->
      <div>
        <span class="bold"> البند الرابع: </span>

        على الطرف الثاني دفع الأقساط المترتبة بذمته حسب مواعيد استحقاقھا لدى
        المصرف او لدى مركز مبيعات الطرف الاول وفي حالة رغبة الطرف الأول بتغيير
        المصرف فعلى الطرف الثانيالالتزام بذلك وايداع الأ قساط لدى المصرف الذي
        يحدده الطرف الأول.
      </div>
      <!-- 5 -->
      <div>
        <p>
          <span class="bold"> البند الخامس: </span>
          على الطرف الثاني تسديد و تسليم قسط المفتاح بعد (30) ثلاثون يوما من
          تأريخ تبلغه باستلام الشقة وإذا امتنع عن تسديد ھذا القسط خلال ھذه المدة
          فيحق للطرف الأول المطالبةبحقوقه المذكورة في البند الثاني من ھذا العقد.
        </p>
      </div>
      <!-- 6 -->
      <div>
        <p v-if="!customer.isSharedCustomer">
          <span class="bold"> البند السادس: </span>

          إذا دفع الطرف الثاني المقدمة وجزء من الأقساط وأمتنع عن دفع الباقي لأي
          سبب كان خلال (30) ثلاثون يوما من تاريخ استحقاقه حسب جدول الأقساط
          المذكورة في البند الثاني، فللطرف الأول الخيار بين فسخ العقد والاكتفاء
          بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف الثاني بباقي الأقساط
          أو مطالبة الطرف الثاني بالغرامة التأخيرية وبمبلغ (10$) عشرة دولار
          أمريكي لاغير عن كل يوم تأخير.
        </p>
        <p v-else>
          <span class="bold"> البند السادس: </span>

          إذا دفع الطرف الثاني المقدمة وجزء من الأقساط وأمتنع عن دفع الباقي لأي
          سبب كان خلال (30) ثلاثون يوما من تاريخ استحقاقه حسب جدول الأقساط
          المذكورة في البند الثاني، فللطرف الأول الخيار بين فسخ العقد والاكتفاء
          بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف الثاني بباقي الأقساط (
          تكافلا وتضامنا ) أو مطالبة الطرف الثاني ( تكافلا وتضامنا ) بالغرامة
          التأخيرية وبمبلغ (10$) عشرة دولار أمريكي لاغير عن كل يوم تأخير.
        </p>
      </div>
      <!-- 7 -->
      <div>
        <p>
          <span class="bold"> البند السابع: </span>
          يلتزم الطرف الأول بتسليم الشقة بعد (36) ستة و ثلاثون شهراً من تاريخ
          توقيع العقد، ويحق للطرف الثاني ان يطالب الطرف الأول بالغرامات
          التأخيرية وبمبلغ (10$) عشرة دولار أمريكي لاغير عن كل يوم تأخير بعد
          مرور (6) أشهر من الموعد المتفق عليه للتسليم على أن لا تزيد عن نسبة
          (2%) إثنان بالمئة من المبلغ الاجمالي للشقة أن ينفذ الطرف الثاني كامل
          التزاماته ولم يتأخر في دفع الأقساط ولو ليوم واحد.
        </p>
      </div>
      <!-- 8 -->
      <div>
        <p>
          <span class="bold"> البند الثامن: </span>يقر الطرف الثاني بأنه عاين
          الشقة موضوع البيع المعاينة التامة على الخريطة النافية للجهالة وأنه وضع
          يده عليها بحالتها على الخريطة دون أن يحق له الرجوع على الطرف الأول بأي
          شيء بسبب ذلك.
        </p>
      </div>
      <!-- 9 -->
      <div>
        <p>
          <span class="bold"> البند التاسع: </span>
          يتعهد الطرف الثاني باحترام جميع القواعد والتعليمات التي يحددها الطرف
          الأول وبالأخص المتعلقة باللوحات التعريفية للشقق والمحلات التجارية
          ولوحات الأعلانات والنشرات في الممرات والطوابق والطرق الخارجية والحدائق
          داخل المشروع وعدم إحداث أية تعديلات في الأجزاء والممرات المشتركة بغير
          موافقة الطرف الأول وكذلك عدم اتخاد أي إجراء يؤثر على الجمالية والمظهر
          العام للبناية والمدخل والسلالم وكذلك مراعاة الحرص على مظهر المبنى
          الخارجي مع المحافظة التامة على سلامة المرافق والحدائق العامة،كما لا
          يحق للطرف الثاني تغيير التصميم الداخلي للشقة و تعميرها دون إستحصال
          موافقة تحريرية من الطرف الأول و عند موافقته فتتم تحت إشرافه و مراقبته
          وبخلافه يكون للطرف الأول الحق في اتخاذ الإجراءات القانونية كافة ضد
          الطرف الثاني مع تحمله كافة المصروفات لإعادة الوضع إلى ما كان عليه
          ويسري هذا البند حتى بعد تسجيل الشقة لدى مديرية التسجيل العقـاري،ولا
          يجوز للطرف الثاني نصب أية أجهزة تؤثر على عمل وخدمات منظومة (IBS)
          الموجودة لتغطية البناية بشبكة الهاتف النقال ويجب عليه عدم المساس
          بأجهزة ال (Pico Cell) الموجودة لنفس الغرض في الواجهة الداخلية من سقف
          البناية، ويجب عليه وبعد إعلامه تحريريا بتمكين الفنيين التابعين لشركات
          الهاتف النقال (كورك و اسياسيل) من دخول المكتب بصورة دورية أو في
          الحالات الضرورية والسماح لهم بنصب اجهزة اخرى اضافية تتعلق بالموقع متى
          ما اقتضى الأمر ذلك في المستقبل دون معارضة شرط أن يكون تنصيب هذه
          الأجهزة من أجل تقوية أو تطوير أو إصلاح خلل في الشبكة.
        </p>
      </div>
      <!-- <br /><br /><br /><br /><br /> -->
      <!-- 10 -->
      <div>
        <p>
          <span class="bold"> البند العاشر: </span>
          على الطرف الثاني استلام الشقة من تأريخ ارسال الطرف الأول بريد الكتروني
          الى الطرف الثاني باستلام الشقة ووضع اشارة (تم الأرسال) أو من تاريخ
          توجية انذار الى الطرف الثاني بواسطةكاتب العدل باستلام الشقة أو تاريخ
          نشر استلام الشقق بواسطة صحيفتين يوميتين محليتين.
        </p>
      </div>
      <!-- 11 -->
      <div>
        <p>
          <span class="bold"> البند الحادي عشر: </span>
          يتعهد الطرف الأول بتسجيل (الشقة) المباعة موضوع العقد باسم الطرف الثاني
          في مديرية التسجيل العقاري المختصة بعد اكمال الأجراءات الرسمية بتمليك
          القطعة في دوائر الدولة وذلك بعد اكمال (الشقة) واستلام الطرف الأول كامل
          بدل البيع المتفق عليه وتكون كافة رسوم التسجيل والأتعاب على الطرف
          الثاني.
        </p>
      </div>
      <!-- 12 -->
      <div>
        <p>
          <span class="bold">
            البند الثاني عشر: (خدمات ما بعد تسليم الشقة قبل وبعد تسجيلھا باسم
            الطرف الثاني لدى مديرية التسجيل العقاري) :-
          </span>
          يقوم الطرف الأول بتقديم خدمات الصيانة الى الطرف الثاني لمدة ستة
          أشھراعتباراً من تأريخ تبليغه باستلام الشقة وھي تشمل أي خلل يطرأ على
          البناية ما لم يكن ناجماً عن سوء استخدام الطرف الثاني. كما يقوم الطرف
          الأول بالأشراف والأتفاق مع الشركات الخدمية لتقديمالخدمات والأدارة الى
          الطرف الثاني مقابل مبلغ شھري يدفعه الطرف الثاني سلفاً الى الطرف الأول
          في بداية كل شھر وتشمل الفقرات التالية عدا اجور الماء والكھرباء:
        </p>
        <ol>
          <li>
            التنظيف الخارجي للشقة ومنھا تنظيف الممرات والسلام المشتركة والشوارع
            العامة والسرداب وكراج السيارات وتنظيف الحدائق العامة للمجمع وادامتھا
            وتنظيف المجاري و جمع النفايات ورميھا خارجالمجمع.
          </li>
          <li>
            الحراسة الأمنية للبوابات الرئيسية لمشروع
            <span class="bold"> (Candle) </span>
            والأماكن العامة داخل المجمع.
          </li>
          <li>
            صيانة المصاعد الكھربائية وتنظيفھا وصيانة الانارة الخارجية للشقة و
            داخل الممرات وكراج وقوف السيارات وتنظيم السير في شوارع المجمع وفي
            كراج وقوف السيارات الداخلي وادامة الھياكلالخارجية لبنايات الشقق
            وادامة منظومة اطفاء الحرائق وادامة ملاعب الأطفال.
          </li>
          <li>
            على الطرف الأول تقديم الخدمات المذكورة في ھذا العقد الى الطرف الثاني
            بانتظام الا في حالة الظروف القاھرة كالكوارث الطبيعية أو حدوث تقلبات
            سياسية أو اقتصادية أو عسكرية.
          </li>
          <li>
            على الطرف الثاني الألتزام بكل ما جاء في ھذا البند حتى بعد تسجيل
            الشقة بأسمه رسميا،وأن ھذا الالتزام يبقى على الطرف الثاني الى أن يقرر
            الطرف الأول عدم الحاجة اليه.
          </li>
          <li>
            لا يحق للطرف الثاني التنصل من تنفيذ ھذا البند وذلك بأبرام عقود
            الخدمات أو الأتفاق مع الشركات أو الأشخاص لتأمين تلك الخدمات.
          </li>
        </ol>
      </div>
      <!-- 13 -->
      <div>
        <p>
          <span class="bold"> البند الثالث عشر (أحكام عامة): </span>
          تشكل ھذه الأتفاقية الألتزامات الصحيحة والملزمة النافذة من قبل الطرفين
          وفقا لشروطھا، ويمتثل الطرفان من كافة النواحي المتعلقة بالعقد لأحكام
          ھذه الأتفاقيةوالقواعد وكافة القوانين والتعليمات والمراسيم والنظم
          والموافقات والرخص السارية حاليا في إقليم كوردستان/ العراق.
        </p>
      </div>
      <!-- 14 -->
      <div>
        <p class="bold">البند الرابع عشر (حل الخلافات والنزاعات) :</p>
        <ol>
          <li>
            في حال نشوء أي نزاع خلاف بين الطرفين فيما يتعلق بتفسير اية مادة أو
            بند من مواد وبنود ھذا العقد فيتم تسويته بالطرق الودية و بعكسه تكون
            محاكم بداءة أربيل ھي المختصة بفض النزاع.
          </li>
          <li>
            يتحمل الطرف الثاني كافة النفقات التي يتكبدھا الطرف الأول مثل أجور
            المحاماة والمعقبين نتيجة عدم التزامه ببنود ھذا العقد.
          </li>
        </ol>
      </div>
      <!-- 15 -->
      <div>
        <p>
          <span class="bold"> البند الخامس العشر (المراسلات) : </span>
          وافق الطرف الثاني أن يتم توجيه أي إشعار أو مراسلة من قبل الطرف ألأول
          بموجب ھذا العقد بشكل خطي و باللغة العربية أو الأنكليزية أو الكردية
          وتسلم شخصياً أوترسل من خلال خدمة إرسال الطرود أو
          <span class="bold"> (SMS) </span>
          له عند الحاجة ويعد الأشعار أو الأنذار الصادر من الكاتب العدل من قبل
          الطرف الأول إلى الطرف الثاني قد تم استلامة إذا:
        </p>
        <ol>
          <li>تم تسليمه بشكل شخصي.</li>
          <li>
            بعد ثلاثة أيام من إرساله من خلال خدمة أرسال الطرود أو البريد المسجل.
          </li>
          <li>
            عند إرساله
            <span class="bold"> SMS </span>
            حسب ما يظھر في تقرير الأرسال (تم الأرسال).
          </li>
          <li>
            عند إرساله بالبريد الألكتروني حسب ما يظھر في تقرير الأرسال (تم
            الأرسال).
          </li>
        </ol>
        <p>
          ويقر ويوافق الطرف الثاني بأنه اتخذ من العناوين المذكورة أدناه في ھذا
          العقد محلا له واية مراسلات أو إنذارات أو إخطارات ترسل عليھا تعتبر
          صحيحه و نافذه ومنتجة لاثارھا القانونية.
        </p>
      </div>
      <!-- 16 -->
      <div>
        <p>
          <span class="bold"> البند السادس العشر: </span>
          يقر كل طرف بأنه أطلع على بنود ھذا الأتفاقية إطلاعا نافيا للجھالة
          قانونا وأنه ملتزم بتنفيذ ما جاء فيھا من بنود و عليه قام الطرفان بتوقيع
          ھذا العقد بعد الأطلاع على كافةبنود وقد حرر بثلاث نسخ بيد كل طرف نسخة
          للعمل بموجبھا عند الأقتضاء ونسخة لدى المستشار القانوني وكيل الطرف
          الأول.
        </p>
      </div>
    </div>
    <!-- table -->
    <table class="table table-bordered">
      <tr>
        <td v-if="!customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري/
          <strong>{{
            customer.firstName +
            " " +
            customer.middleName +
            " " +
            customer.lastName
          }}</strong>
          <br />
          <br />
          العنوان الدائم/ <strong>{{ customer.address }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/ <strong>{{ customer.idcard }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          <!-- رقم الموبايل عند الحاجة/
          <strong>{{ replacePhone(customer.phone_2) }}</strong> -->
          <!-- <br />
          <br />
          <br />
          <br />
          <br /> -->
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 1 /
          <strong>{{ customer.firstName }}</strong>
          <br />
          <br />
          العنوان الدائم 1 /
          <strong>{{ separatorString(customer.address)[0] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[0] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 2 /
          <strong>{{ customer.lastName }}</strong>
          <br />
          <br />
          العنوان الدائم 2 /
          <strong>{{ separatorString(customer.address)[1] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[1] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone2) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td style="text-align: right">
          شركة <strong>(Candle)</strong>
          <br />
          <br />
          المدير المفوض السيد <strong>(رزگار عمر قادر)</strong>
          <br />
          <br />
          إضافة لوظيفته.
          <br />
          <br />
          <br />
          <br />
          <!-- <br />
          <br />
          <br /> -->
          التوقيع
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ArabicContractCandleInstallment",
  props: {
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    buyContract: {},
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Edwardian Script ITC";
  src: url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.eot");
  src: url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.svg#Edwardian Script ITC")
      format("svg");
}
/* .customFont {
  font-family: "Edwardian Script ITC";
}
.GoldColor {
  color: #d5a000;
}
.BlueColor {
  color: #274a85;
}
.logo {
  width: 100px;
}

.hr {
  border: 1px solid black;
}
.vl {
  border-left: 2px solid black;
  height: 35px;
}
.footer p {
  font-size: 0.8rem;
  font-weight: 400;
} */
/* .bold {
  font-weight: bold;
}
.center {
  text-align: center;
} */
.padding {
  padding: 50px !important;
}
.contract {
  font-family: "Edwardian Script ITC";
  font-size: 0.8rem !important;
  font-weight: 300 !important;
}
.bold {
  font-weight: bold !important;
}
.center {
  text-align: center !important;
}

.flex-class-detail {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: start;
  margin: 3rem auto;
}
* {
  /* font-size: large; */
  text-align: justify !important;
}
</style>
