import Vue from 'vue'
import Vuex from 'vuex'
import expenseTypes from "./modules/expenseTypes";
import auth from "./modules/auth";
import properties from "./modules/properties";
import customers from "./modules/customers";
import customerAttachments from "./modules/customerAttachments";
import expenses from "./modules/expenses";
import propertyAttachments from "./modules/propertyAttachments";
import propertyExpenses from "./modules/propertyExpenses";
import propertyFollowUp from "./modules/propertyFollowUp";
import leadSources from "./modules/leadSources";
import leadSubSources from "./modules/leadSubSources";
import leadCustomers from "./modules/leadCustomers";
import leadVisits from "./modules/leadVisits";
import leadReports from "./modules/leadReports";
import dashboard from "./modules/dashboard";
import buyContracts from "./modules/buyContracts";
import paymentProcesses from "./modules/paymentProcesses";
import features from "./modules/features";
import propertyTypes from "./modules/propertyTypes";
import reports from "./modules/reports";
import propertyHolds from "./modules/propertyHolds";
import language from "./modules/language";
import transferContracts from "./modules/transferContracts";
import cancelContracts from "./modules/cancelContracts";
import propertyExpenseTypes from "./modules/propertyExpenseTypes";
import users from "@/store/modules/users";
import projects from "@/store/modules/projects";
import mainDashboard from "@/store/modules/mainDashboard";
import permissions from "@/store/modules/permissions";
import propertyFeatures from "@/store/modules/propertyFeatures";
import depositTypes from "@/store/modules/depositTypes";
import withdrawTypes from "@/store/modules/withdrawTypes";
import deposits from "@/store/modules/deposits";
import withdraws from "@/store/modules/withdraws";
import smsMessages from "@/store/modules/smsMessages";
import safeBoxes from "@/store/modules/safeBoxes";
import transfers from "@/store/modules/transfers";
import userTasks from "@/store/modules/userTasks";
import financialDashboard from "@/store/modules/financialDashboard";
import investors from "@/store/modules/investors";
import buyContractsAttachments from "@/store/modules/buyContractsAttachments";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    language,
    expenseTypes,
    properties,
    customers,
    customerAttachments,
    expenses,
    propertyAttachments,
    propertyExpenses,
    propertyFollowUp,
    leadSources,
    leadSubSources,
    leadCustomers,
    leadVisits,
    leadReports,
    dashboard,
    buyContracts,
    paymentProcesses,
    features,
    propertyTypes,
    propertyHolds,
    transferContracts,
    cancelContracts,
    propertyExpenseTypes,
    reports,
    users,
    projects,
    mainDashboard,
    permissions,
    propertyFeatures,
    depositTypes,
    withdrawTypes,
    deposits,
    withdraws,
    smsMessages,
    safeBoxes,
    transfers,
    userTasks,
    financialDashboard,
    investors,
    buyContractsAttachments
  }
})
