import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const investors = {
    namespaced: true,
    state: {
        investors: {},
        createdInvestor: {},
        getOneInvestor: {},
        updatedInvestor: {},
    },
    mutations: {
        SET_INVESTORS (state, value) { state.investors = value },
        SET_CREATED_INVESTOR (state, value) {state.createdInvestor = value},
        SET_GET_ONE_INVESTOR (state, value) {state.getOneInvestor = value},
        SET_UPDATED_INVESTOR (state, value) {state.updatedInvestor = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Investors` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_INVESTORS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_INVESTORS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Investors/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_INVESTOR', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_INVESTOR', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Investors`, form)
                    .then((response) => {
                        commit('SET_CREATED_INVESTOR', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_INVESTOR', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Investors/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_INVESTOR', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_INVESTOR', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Investors/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        investors: state => state.investors,
        createdInvestor: state => state.createdInvestor,
        getOneInvestor: state => state.getOneInvestor,
        updatedInvestor: state => state.updatedInvestor,
    }
}

export default investors;
