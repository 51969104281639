<template>
  <div class="contract text-right" dir="rtl">
    <div class="row text-center m-2 p-4 rounded" style="border: 2px solid #000000">
      <div class="col-4">
        <h5 style="height: 64px">کۆمپانیای <span style="color: #FF1818">هێنلی</span></h5>
        <h5>بۆ وەبەرهێنانی خانووبەرەو کشتوکال</h5>
      </div>
      <div class="col-4">
        <img :src="image" alt="Logo" width="128" height="128" />
      </div>
      <div class="col-4">
        <h5 style="height: 64px"><span style="color: #FF1818">Henley</span> Company</h5>
        <h5>Construction And Investing</h5>
      </div>
    </div>
    <div class="text-center border w-25" style="margin: 10px auto">
      <h5>وصل حجز</h5>
    </div>
    <div class="row mb-2 p-2" style="border-width: 3px 0;border-color: #0a53be;border-style: solid">
      <div class="col-6">
        <span class="text-right" style="display: block">التاریخ: 20-12-2022</span>
      </div>
      <div class="col-6">
        <span class="text-left" style="display: block">:No </span>
      </div>
    </div>
    <div>
      <p class="lead">اسم المشروع : کابیتل تاوەر (capital towers)</p>
      <p class="lead">تم الحجز باسم: {{ `${customer.firstName} ${customer.middleName} ${customer.lastName}` }}</p>
      <p class="lead">عن طریق: مرکز مبیعات هێنلی</p>
      <p class="lead">موبایل: {{ replacePhone(customer.phone) }}</p>
      <p class="lead">العنوان: {{ customer.address }}</p>
      <p class="lead">الشقة التالیة: {{ property.name }}</p>
      <p class="lead">قیاس الشقة: {{ property.area }}</p>
      <p class="lead">وضع دفع المبلغ: النقدیة</p>
    </div>
    <div class="row text-center mt-5">
      <div class="col-6">
        <h6>مستلم الحجز</h6>
      </div>
      <div class="col-6">
        <h6>الحسابات</h6>
      </div>
    </div>
  </div>
</template>

<script>
import cantal from "@/assets/images/cantal.png";
export default {
  name: "ArabicContractCantal",
  props: {
    accountant: {
      type: String,
      default: 'عمر اسماعیل صالح'
    },
    accountantPhone: {
      type: String,
      default: '07504452330'
    },
    customer: {
      type: Object
    },
    property: {
      type: Object
    },
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {}
  },
  data() {
    return {
      image: cantal,
    }
  }
}
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1.28rem;
  font-weight: 300;
}
</style>
