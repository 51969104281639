import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const features = {
    namespaced: true,
    state: {
        features: {},
        createdFeature: {},
        getOneFeature: {},
        updatedFeature: {},
    },
    mutations: {
        SET_FEATURES (state, value) { state.features = value },
        SET_CREATED_FEATURE (state, value) {state.createdFeature = value},
        SET_GET_ONE_FEATURE (state, value) {state.getOneFeature = value},
        SET_UPDATED_FEATURE (state, value) {state.updatedFeature = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Features` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_FEATURES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_FEATURES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Features/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_FEATURE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Features`, form)
                    .then((response) => {
                        commit('SET_CREATED_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_FEATURE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Features/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_FEATURE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Features/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        features: state => state.features,
        createdFeature: state => state.createdFeature,
        getOneFeature: state => state.getOneFeature,
        updatedFeature: state => state.updatedFeature,
    }
}

export default features;
