<template>
  <div>
    <content-header>{{ $t('profile') }}</content-header>
    <v-content>
      <div class="row gutters text-center">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <div class="card h-100">
            <div class="card-body">
              <div class="account-settings">
                <div class="user-profile">
                  <div class="user-avatar">
                    <img :src="getAuthUserProfileImage()" :alt="getAuthUser().fullName" width="64" height="64" />
                  </div>
                  <h5 class="m-2">{{ getAuthUser() }}</h5>
                </div>
                <div class="about">
                  <div class="mb-3">
                    <input class="form-control" type="file" id="formFile" @change="imagesSelected">
                  </div>
                  <b-button class="m-3" variant="primary" @click="uploadFile">
                    <i class="fas fa-upload"></i> &nbsp; <span>{{ $t('updateAvatar') }}</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
          <div class="card h-100">
            <div class="card-body">
              <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h4 class="mt-3 mb-2">{{ $t('accountInfo') }}</h4>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="firstName">{{ $t('firstName') }}</label>
                    <input type="text" v-model="user.firstName" class="form-control" id="firstName" :placeholder='$t("firstName")' />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="middleName">{{ $t('middleName') }}</label>
                    <input type="text" v-model="user.middleName" class="form-control" id="middleName" :placeholder='$t("middleName")' />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="lastName">{{ $t('lastName') }}</label>
                    <input type="text" v-model="user.lastName" class="form-control" id="lastName" :placeholder='$t("lastName")' />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="username">{{ $t('username') }}</label>
                    <input type="text" v-model="user.username" class="form-control" id="username" :placeholder='$t("username")' />
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <button type="button" id="submit" name="submit" class="btn btn-primary" @click="handleUpdateInfo">{{ $t('updateInfo') }}</button>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h4 class="mt-3 mb-2">{{ $t('updatePassword') }}</h4>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="oldPassword">{{ $t('oldPassword') }}</label>
                    <input type="password" v-model="password.oldPassword" class="form-control" id="oldPassword" :placeholder="$t('oldPassword')" />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="newPassword">{{ $t('newPassword') }}</label>
                    <input type="password" v-model="password.newPassword" class="form-control" id="newPassword" :placeholder='$t("newPassword")' />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <label for="confirmNewPassword">{{ $t('confirmNewPassword') }}</label>
                    <input type="password" v-model="password.confirmNewPassword" class="form-control" id="confirmNewPassword" :placeholder='$t("confirmNewPassword")' />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                    <button type="button" name="submit" class="btn btn-primary" @click="handleUpdatePassword">{{ $t('updatePassword') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {HTTP} from "@/plugins/axios";
import {notify} from "@/plugins/constants";

export default {
  name: "Main",
  components: {'v-content': Content, ContentHeader},
  data() {
    return {
      avatar: null,
      user: {
        firstName: localStorage.getItem('firstName'),
        middleName: localStorage.getItem('middleName'),
        lastName: localStorage.getItem('lastName'),
        username: null
      },
      password: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      }
    }
  },
  methods: {
    imagesSelected(e) {
      this.avatar = e.target.files[0];
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append('file', this.avatar);
      if (!this.avatar) {
        notify('Profile Updated Successfully', "error")
      } else {
        const response = await HTTP.post(`/Authentication/UploadProfileImage`, formData)
            .catch((err) => {
              notify(err.response.data.message, "error")
            });
        localStorage.setItem('image', response.data)
        window.location.reload();
      }
    },
    async handleUpdateInfo() {
      if (!this.user.firstName || !this.user.middleName || !this.user.lastName) {
        notify(this.$t('pleaseWriteFullName'), "error")
      } else if (this.user.firstName.length < 3 || this.user.middleName.length < 3 || this.user.lastName.length < 3) {
        notify(this.$t('namesMustBeMoreThan3Chars'), "error")
      } else {
        await HTTP.post(`/Authentication/ChangeAccountInfo`, {FirstName: this.user.firstName, MiddleName: this.user.middleName, LastName: this.user.lastName })
            .then(() => {
              localStorage.setItem('fullName', `${this.user.firstName} ${this.user.middleName} ${this.user.lastName}`)
              notify(this.$t('updated'), "success")
            }).catch((err) => {
              notify(err.response.data.message, "error")
            });
      }
    },
    async handleUpdatePassword() {
      if (!this.password.oldPassword || !this.password.newPassword || !this.password.confirmNewPassword) {
        notify(this.$t('pleaseWriteAllPasswords'), "error")
      } else if (this.password.newPassword !== this.password.confirmNewPassword) {
        notify(this.$t('passwordsAreNotMatch'), "error")
      } else {
        await HTTP.post(`/Authentication/ChangePassword`, {
          CurrentPassword: this.password.oldPassword,
          Password: this.password.newPassword,
          ConfirmPassword: this.password.confirmNewPassword
        }).catch((err) => {
          notify(err.response.data.message, "error")
        }).finally(() => {
          notify(this.$t('updated'), "success")
        });
      }
    }
  }
}
</script>
