<template>
  <div class="contract text-right" dir="rtl">
    <h1 class="text-center">(( عقد بيع وحدة سكنية ))</h1>
    <p class="lead" style="text-align: right">رقم العقد: {{ buyContract.contractNumber }}</p>
    <p class="lead" style="text-align: right">بتأريخ {{ getCurrentDate() }} تم الاتفاق في مدينة أربيل بين كل من :-</p>
    <p class="lead" style="text-align: right">1- الطرف الاول (البائع ) السادة / شركة ماس العراق للأستثمار العقاري ويمثلها في هذا العقد السيد / عمر إسماعيل صالح المدير المفوض للشركة</p>
    <p class="lead" style="text-align: right">
      2- الطرف الثاني (المشتري ) السيد/ة / {{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }} (  {{ customer.nationality }} الجنسية )، بموجب {{ passportOrIdCard }}
    </p>
    <h2 class="text-center">(( المقدمة  ))</h2>
    <p class="lead" style="text-align: right">
      تقوم شركة ماس العراق للأستثمار العقاري  بموجب إجازة الأستثمار المرقمة (44) والمؤرخة في 25/6/2012 الصادرة من المديرية العامة للأستثمار / اربيل. ببناء مجمع سكني وادارتها والذي يقع في ( أربيل / طريق صلاح الدين) على القطع المرقمات / 68 و 69 و 444 مقاطعة 90 بيرزين و 15 و 7 مقاطعة 91 بيركوت
    </p>
    <p class="lead" style="text-align: right">
      قام الطرف الأول ببيع الوحدة السكنية المرقمة: {{ property.name }}
    </p>
    <p class="lead" style="text-align: right">نوع الوحدة السكنية: {{ property.propertyType.name }}</p>
    <p class="lead" style="text-align: right">مساحة الأرض الفعلية :{{ property.area }}</p>
    <p class="lead" style="text-align: right">اسم القاطع: {{ property.zone }}</p>
    <p class="lead" style="text-align: right">رقم البلوك: {{ property.block }}</p>

    <p class="lead" style="text-align: right">قيمة الوحده سكنيه: {{ buyContract.buyingPrice.toLocaleString() }} دولار أمريكي فقط لا غیر / كتابةً: {{ number2Text(buyContract.buyingPrice, 'ar') }} </p>
    <p class="lead" style="text-align: right">
      وبناءً على رغبة الطرف الثاني فى الشراء ، فقد إتفق الطرفان وهما بكامل الأهلية المعتبرة شرعاً ونظاماً على ما ورد في هذا العقد و يعتبر المقدمة جزء لا يتجز من هذا العقد الذي يتكون من الفقرات التالية:
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>أولا: القيمة وطريقة الدفع</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>ثانيا: إستلام الوحده السكنية</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>ثالثا: تعهدات الطرف الثاني وضماناته للطرف الأول</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>رابعا: التخلف في الألتزامات  وانهاء العقد</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>خامسا: الشروط العامة</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>سادسا: مرفقات العقد</strong>
    </p>
    <p class="lead" style="text-align: right">
      <strong>سابعا: توقيع العقد</strong>
    </p>
    <br />
    <br />
    <br />
    <p class="lead" style="text-align: right">
      <strong>أولا:-  القيمة  وطريقة الدفع</strong>
    </p>
    <p class="lead" style="text-align: right">
      القیمة الاجمالي المتفق عليها هو ( {{ property.finalPrice.toLocaleString() }} دولار أمريكي فقط لا غیر ) ويتم تسديد المبلغ حسب الطريقة الآتية:
    </p>
    <!-- Start Payment Process -->
    <p class="lead" style="text-align: right">
      - الدفعة الاولى 20% من قيمة الوحده السكنية  تدفع عند توقيع العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الثانية 15% من قيمة الوحده السكنية تدفع بعد 6 أشهر من توقيع العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الثالثة 15 % من قيمة الوحده السكنية تدفع بعد 12 شهراً من توقيع العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الرابعة 25% من قيمه الوحده السكنية  وتدفع بعد 18 شهراً من توقيع العقد وعند استلام الوحدة السكنية.
    </p>
    <p class="lead" style="text-align: right">
      - ويسدد المبلغ المتبقي البالغ (%25) من قيمة الوحده السكنية بـ ( {{ contractPayments.length }} ) قسطاً شهرياً متساويا.
    </p>
    <!-- End Payment Process -->
    <br />
    <p class="lead" style="text-align: right">
      <strong>ثانيا:- إستلام الوحده السكنيه</strong>
    </p>
    <p class="lead" style="text-align: right">
      يسلم الطرف الاول الوحدة السكنية الى الطرف الثاني  بعد مرور مدة  (18 شهر عمل ) من توقيع العقد بشرط ان يكون الطرف الثاني قد سدد كامل مبلغ الشراء  و نفذ سائر واجباته التعاقدية المتضمن دفع جميع الدفعات المذكورة اعلاه والذي يبلغ مجموعها (75%) من اجمالي قيمة الوحدة السكنية.
    </p>
    <p class="lead" style="text-align: right">
      نقل الملكية: يتم تسجيل الوحدة السكنية بأسم الطرف الثاني بعد تسديد كامل قيمة الوحدة السكنية الى الطرف الأول وكذلك تنفيذه لجميع الشروط والالتزامات الواردة في هذا العقد ويتحمل الطرف الثاني كافة الاجور والرسوم بموجب التعليمات الصادرة من وزارة البلديات وهيئة الأستثمار ودوائر التسجيل العقاري في إقليم كردستان العراق.
    </p>
    <p class="lead" style="text-align: right">
      متابعة أعمال المشروع - اخذ الطرف الثاني علما ووافق على ان الوحدات السكنية موضوع هذا العقد هو قسم من مشروع متكامل وان اعمال البناء في المشروع قد تتابع وفق برامج وخطط عمل المشروع . وعليه لايحق للطرف الثاني الاعتراض على متابعة اي من تلك الاعمال في المشروع او المطالبة بأي تعويض أو عطل وضرر بهذا الخصوص.
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>ثالثا:- تعهدات الطرف الثاني وضماناته.</strong>
    </p>
    <p class="lead" style="text-align: right">
      يضمن الطرف الثاني ويتعهد تجاه الطرف الاول العمل  بالتعهدات والضمانات التالية:
    </p>
    <p class="lead" style="text-align: right">
      - لا يجوز استعمال الوحدات السكنية لأغراض أخرى غير سكنية.
    </p>
    <p class="lead" style="text-align: right">
      - يكون الطرف الثاني مسؤولا عن تسديد  فواتير الماء والكهرباء والخدمات  وأية كلفة استهلاك اخرى مثل الضرائب والرسوم على الاملاك و اعتباراً من تاريخ استلام الوحدة.
    </p>
    <p class="lead" style="text-align: right">
      - لايحق للطرف الثاني القيام بأية تغييرات اواضافات على الشكل الخارجي للوحدة السكنية ولا يحق له تكسير او هدم اي جدار بعد استلامه للوحدة السكنية حتى بعد تسجيله بأسمه في دائرة التسجيل العقاري وعلى الطرف الثاني الالتزام بالتعليمات والقرارات والتي سوف تصدر من قبل ادارة المشروع وفي حال عدم االالتزام يكون مسؤولاً من الناحية القانونية.
    </p>
    <p class="lead" style="text-align: right">
      - لايمكن للطرف الثاني التنازل عن هذا العقد قبل دفعه جميع استحقاقاته الا بعد الموافقة التحريرية من قبل الطرف الاول ويجب ان يكون المشتري الجديد ملتزماً بهذا العقد بجميع بنوده بما فيه تسديد الأقساط المتبقية لدى الطرف الثاني.
    </p>
    <p class="lead" style="text-align: right">
      - يتعهد الطرف الثاني بعدم القيام بأي عمل من شأنه أن يشكل أو يؤدي الى ازعاج أو أضرار الوحدات السكنية المجاورة له أو الاقسام المشتركة وشاغليها.
    </p>
    <p class="lead" style="text-align: right">
      - يلتزم الطرف الثاني بالتقيد بالتعليمات والشروط المعتمدة في اسكان المشروع فيما يتعلق من الناحية الامنية و البيئية وكذلك الحفاظ على الموجودات العامة للمشروع ( خطوط الكهرباء , الماء , الغاز ..... الخ ) وبقية اجزاء البنية التحتية.
    </p>
    <p class="lead" style="text-align: right">
      - في حالة حدوث اي خلاف بين الطرفين يتم اللجوء الى محاكم اربيل وتحسم وفق القوانين النافذة في اقليم كردستان العراق.
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>رابعا :- التأخر في تسديد الألتزامات المالية  وانهاء العقد:</strong>
    </p>
    <p class="lead" style="text-align: right">
      1- في حال تأخر الطرف الثاني عن تسديد أية دفعة مستحقة في موعدها, يتم تبليغه وفي حالة عدم الاستجابة فأنه يتحمل غرامة تأخيرية مقدارها 300$ (ثلاثمائة دولار امريكي) عن كل اسبوع تأخير من تاريخ أستحقاق, وإذا تأخر الطرف الثاني عن دفع قسطين متتاليين في تاريخ إستحقاقها يحق للطرف الأول إلغاء هذا العقد بصورة حتمية وآلية وجازمة وصريحة دون الحاجة للرجوع الى المحاكم و إنذار الطرف الثاني  ويحق له بيع الوحدة السكنية المشتراة والتصرف بها كتصرف المالك بملكه , على انه بعد بيع الوحدة السكنية الى الغير وقبض كامل ثمنها يلتزم الطرف الأول بأعادة جميع المبالغ المدفوعة من قبل الطرف الثاني اليه بعد حسم قيمة البند الجزائي المحدد ب(10٪ ) من قيمة عقد المشتري الجديد و استقطاع الغرامات التأخيرية منه.
    </p>
    <p class="lead" style="text-align: right">
      2- في حالة تأخر الطرف الاول خلال ( 18 شهر عمل ) بعد توقيع العقد عن تسليم الوحدة السكنية  , يلتزم بدفع غرامة تأخيرية مقدارها 300$ (ثلاثمائة دولار امريكي)  فقط لا غير عن كل اسبوع تأخير، على ان لا تتجاوز ( %5 ) من القيمة الاجمالية للعقد في حال كان الطرف الثاني ملتزماً بدفع كافة الأقساط في موعدها , ويتم تسوية هذا الالتزام مع الدفعة الاخيرة علماً لايحتسب ايام الجمعة والعطل الرسمية والتوقفات بسبب ظروف مناخية اواخرى كأيام عمل فعلية ويضاف على مدة ( 18 شهر )
    </p>
    <p class="lead" style="text-align: right">
      3- في حال طلب الطرف الثاني الغاء العقد بعد توقيعه يتحمل غرامة مالية قدرها (20%) من قيمة المبالغ المدفوعة الى الطرف الاول ولايحق له الرجوع الى المحاكم ويرد اليه باقي المبلغ حسب شروط الطرف الاول.
    </p>
    <p class="lead" style="text-align: right">
      4– في حالة وجود القوة القاهرة التي تؤثر على التزامات المشروع للطرفين يتم تطبيق أحكام القوة القاهرة الواردة في القانون المدني العراقي.
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>خامساً :- الشروط العامة :</strong>
    </p>
    <p class="lead" style="text-align: right">
      1- يلتزم الطرف الاول بصيانة كاملة للوحدة السكنية لمدة سنة واحدة من تاريخ الاسكان وتشمل هذه الصيانة البناء واالمنظومات الكهربائية و الميكانيكية المنفذة من قبل الطرف الأول داخل الوحدة السكنية  ماعدا الخلل الذي ينشأ عن سوء الاستخدام والتشغيل.
    </p>
    <p class="lead" style="text-align: right">
      2- كل المراسلات والتبليغات الواجب توجيهها عملا بهذا العقد تكون بالرسائل النصية أو عبر البريد الالكتروني او عن طريق الاتصال الهاتفي الى عناوين الطرفين المعتمدة و التي وردت في مقدمة هذا العقد مالم يصار الى تغييرها بموجب إشعار مرسل ومبلغ أصولا الى الطرف الاخر.
    </p>
    <p class="lead" style="text-align: right">
      3- يوافق الطرفان على الالتزام بالقوانين والقرارات والتعليمات الصادرة الجارية و كل ما يتم إصدارها مستقبلاً من قبل السلطات الرسمية المختصة في اقليم كردستان العراق.
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>سادسا :- مرفقات العقد:</strong>
    </p>
    <p class="lead" style="text-align: right">
      يرفق بهذا العقد ما يلي ويكون جزاً لا يتجزء منه:
    </p>
    <p class="lead" style="text-align: right">
      •خارطة و مواصفات الوحدة السكنية.
    </p>
    <p class="lead" style="text-align: right">
      •هوية الاحوال المدنية او البطاقة الوطنية او جواز السفر للمشتري.
    </p>
    <p class="lead" style="text-align: right">
      •عنوان سكن المشتري بتأييد من مختار المحلة.
    </p>
    <p class="lead" style="text-align: right">
      •جدول الدفعات المالية.
    </p>
    <br />
    <p class="lead" style="text-align: right">
      <strong>سابعا:- توقيع العقد</strong>
    </p>
    <p class="lead" style="text-align: right">
      حرر هذا العقد بثلاث نسخ اصلية و تحتوي على ( 7 ) بنداً و متكونة من ( 4 صفحة ).
    </p>
    <p class="lead" style="text-align: right">
      و تم التوقيع على هذا العقد من قبل الطرفين في اربيل بتأريخ: {{ getCurrentDate() }}
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <b-row class="text-center" style="margin: 0;padding: 0">
      <b-col cols="6">
        <p class="lead" style="text-align: right">
          الطرف الثاني:-
        </p>
      </b-col>
      <b-col cols="6">
        <p class="lead" style="text-align: right">
          الطرف الاول :-
        </p>
      </b-col>
    </b-row>
    <br />
    <table class="table table-bordered text-left">
      <tbody>
      <tr>
        <td>التوقيع</td>
        <td class="w-32"></td>
        <td>التوقيع</td>
        <td class="w-32"></td>
      </tr>
      <tr>
        <td>الاسم الثلاثي</td>
        <td class="w-32">{{ `${customer.firstName} ${customer.middleName} ${customer.lastName}` }}</td>
        <td>الاسم الثلاثي</td>
        <td class="w-32">عمر إسماعيل صالح</td>
      </tr>
      <tr>
        <td>
          <span v-if="isBoth === 0">رقم الهویة / جواز السفر</span>
          <span v-else-if="isBoth === 1">رقم الهویة</span>
          <span v-else>جواز السفر</span>
        </td>
        <td class="w-32">
          <span v-if="isBoth === 0">{{ `${customer.idcard} / ${customer.foodCard}` }}</span>
          <span v-else-if="isBoth === 1">{{ `${customer.idcard}` }}</span>
          <span v-else>{{ `${customer.foodCard}` }}</span>
        </td>
        <td>رقم الهاتف</td>
        <td class="w-32">0750445233</td>
      </tr>
      <tr>
        <td>عنوان السكن</td>
        <td class="w-32">{{ customer.address }}</td>
        <td>البرید الالكتروني</td>
        <td class="w-32">info@masshills.com</td>
      </tr>
      <tr>
        <td>رقم الهاتف</td>
        <td class="w-32">{{ replacePhone(customer.phone) }}</td>
        <td></td>
        <td class="w-32"></td>
      </tr>
      <tr>
        <td>رقم الهاتف</td>
        <td class="w-32">{{ replacePhone(customer.phone2) }}</td>
        <td></td>
        <td class="w-32"></td>
      </tr>
      <tr>
        <td>البريد الالكتروني</td>
        <td class="w-32">{{ customer.email }}</td>
        <td></td>
        <td class="w-32"></td>
      </tr>
      </tbody>
    </table>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <table v-if="contractPrePayments && contractDeliveryKeyPayments && contractPayments" class="table table-bordered text-left payments">
      <tr class="text-center text-dark">
        <th colspan="4">جدول الدفوعات الوحدة رقم ({{ property.name }})</th>
      </tr>
      <tr>
        <td>الوصف</td>
        <td>نسبة الدفعة ٪</td>
        <td>المبلغ / دولار</td>
        <td>تاریخ الدفعة</td>
      </tr>
      <tr class="text-center text-dark">
        <th colspan="4">دفعات ماقبل إستلام الوحدة السکنیة</th>
      </tr>
      <tr v-for="(prePayment, index) in contractPrePayments" :key="prePayment.id">
        <td v-if="index === 0">الدفعة الأولی (المقدمة) عند توقیع العقد</td>
        <td v-else>الدفعة {{ convertNumbers(index, 'ar') }} {{ index * 6 }} شهر بعد توقیع العقد</td>
        <td>{{ calculatePercentage(prePayment.currentPayPrice, buyContract.buyingPrice).toFixed(3) }} %</td>
        <td>{{ prePayment.currentPayPrice.toLocaleString() }} $</td>
        <td>{{ formatDate(prePayment.currentPayDate) }}</td>
      </tr>
      <tr>
        <td>الدفعة الثالثة 18 شهر بعد توقیع العقد و عند إستلام الوحدة السکنیة</td>
        <td>{{ calculatePercentage(contractDeliveryKeyPayments.currentPayPrice, buyContract.buyingPrice).toFixed(3) }} %</td>
        <td>{{ contractDeliveryKeyPayments.currentPayPrice.toLocaleString() }} $</td>
        <td>{{ formatDate(contractDeliveryKeyPayments.currentPayDate) }}</td>
      </tr>
      <tr class="text-center text-dark">
        <th colspan="4">دفعات مابعد الوحدة السکنیە (٪{{ calculatePercentage(contractPayments[0].currentPayPrice, buyContract.buyingPrice).toFixed(3) }} شهریا لمدة {{ contractPayments.length }} شهرا)</th>
      </tr>
      <tr v-for="(payment, index) in contractPayments" :key="payment.id">
        <td>الدفعة {{ convertNumbers(index + 1, 'ar') }}</td>
        <td>{{ calculatePercentage(payment.currentPayPrice, buyContract.buyingPrice).toFixed(3) }} %</td>
        <td>{{ payment.currentPayPrice.toLocaleString() }} $</td>
        <td>{{ formatDate(payment.currentPayDate) }}</td>
      </tr>
      <tr class="text-center text-dark">
        <td></td>
        <td></td>
        <td>{{ sumPayments.toLocaleString() }} $</td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ArabicContract",
  props: {
    buyContract: {},
    accountant: {
      type: String,
      default: 'عمر اسماعیل صالح'
    },
    accountantPhone: {
      type: String,
      default: '07504452330'
    },
    customer: {
      type: Object
    },
    property: {
      type: Object
    },
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {}
  },
  data() {
    return {
      passportOrIdCard: null,
      isBoth: 0
    }
  },
  mounted() {
    let str = '';
    if (this.customer.idcard && this.customer.foodCard) {
      str = ' البطاقة الوطنية المرقمة ( ' + this.customer.idcard + ' ) ';
      str += 'أو جواز رقم ( ' + this.customer.foodCard + ' ) ';
      this.isBoth = 0;
    } else if (this.customer.idcard) {
      str = 'البطاقة الوطنية المرقمة ( ' + this.customer.idcard + ' ) ';
      this.isBoth = 1;
    } else {
      str = 'جواز رقم ( ' + this.customer.foodCard + ' ) ';
      this.isBoth = 2;
    }
    this.passportOrIdCard = str
  },
  computed: {
    sumPayments() {
      let sum = this.contractPrePayments.reduce((sum, payment) => {
        return sum += payment.currentPayPrice;
      }, 0);
      sum += this.contractDeliveryKeyPayments.currentPayPrice;
      sum += this.contractPayments.reduce((sum, payment) => {
        return sum += payment.currentPayPrice;
      }, 0);
      return sum;
    }
  },
  methods: {
    calculatePercentage(price, max) {
      return (price / max) * 100;
    }
  }
}
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1.28rem;
  font-weight: 300;
}

table:not(.payments) tr td {
  height: 80px !important;
}
</style>
