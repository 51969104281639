var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-header',[_vm._v(_vm._s(_vm.$t("editProperties")))]),_c('v-content',[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","value":_vm.activeTabIndex},on:{"input":_vm.changeRoute}},[(_vm.hasPermission(_vm.permissions.propertiesEdit))?_c('b-tab',{attrs:{"title":_vm.$t('edit')}},[_c('form',{staticClass:"forms-sample",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('text-input',{attrs:{"id":"name","name":_vm.projectTenant() === 'MassHills'
                  ? _vm.$t('villaNumber')
                  : _vm.$t('name'),"validate":_vm.$v,"required":true,"max-length":true,"is-loading":_vm.isLoading}}),(_vm.projectTenant() !== 'MassHills')?_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('label',{staticClass:"col-form-label text-right"},[_vm._v(_vm._s(_vm.$t("additionalInfo")))])]),_c('b-col',[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.additional-info",modifiers:{"additional-info":true}}],attrs:{"block":"","variant":"info"}},[_vm._v(_vm._s(_vm.$t("additionalInfo")))])],1),_c('b-collapse',{attrs:{"id":"additional-info","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-row',[_c('b-col',[_c('text-input',{attrs:{"id":"streetNumber","name":_vm.$t('streetNumber'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1),_c('b-col',[_c('number-input',{attrs:{"id":"streetWidth","name":_vm.$t('streetWidth'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1)],1),_c('b-row',[_c('b-col',[_c('text-input',{attrs:{"id":"numberOfRooms","name":_vm.$t('rooms'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1),_c('b-col',[_c('number-input',{attrs:{"id":"floors","name":_vm.$t('floors'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1)],1)],1)],1)],1)],1)])],1):_vm._e(),_c('select-input',{attrs:{"id":"propertyType","name":_vm.projectTenant() === 'Candle'
                  ? _vm.$t('apartmentType')
                  : _vm.$t('villaType'),"validate":_vm.$v,"required":true,"api-link":"propertyTypes/GET_ALL","label":"name","default-value":_vm.form.propertyType,"is-loaded":_vm.isLoading},on:{"itemSelected":function (e) { return (_vm.form.propertyTypeId = e); }}}),_c('text-input',{attrs:{"id":"zone","name":_vm.projectTenant() === 'Candle' ? _vm.$t('tower') : _vm.$t('zone'),"validate":_vm.$v,"is-loading":_vm.isLoading}}),_c('text-input',{attrs:{"id":"block","name":_vm.projectTenant() === 'Candle' ? _vm.$t('floor') : _vm.$t('block'),"validate":_vm.$v,"is-loading":_vm.isLoading}}),(
                _vm.projectTenant() === 'RoyalTower' ||
                _vm.projectTenant() === 'Candle'
              )?_c('text-input',{attrs:{"id":"extraProperty1","name":_vm.$t('extraProperty1'),"validate":_vm.$v,"is-loading":_vm.isLoading}}):_vm._e(),(
                _vm.projectTenant() === 'RoyalTower' ||
                _vm.projectTenant() === 'Candle'
              )?_c('text-input',{attrs:{"id":"extraProperty2","name":_vm.$t('extraProperty2'),"validate":_vm.$v,"is-loading":_vm.isLoading}}):_vm._e(),(_vm.projectTenant() === 'Candle')?_c('text-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"note","name":_vm.$t('realEstateAgent'),"validate":_vm.$v,"is-loading":_vm.isLoading}}):_vm._e(),(_vm.projectTenant() === 'Candle')?_c('select-input',{attrs:{"id":"note1","name":_vm.$t('realEstateAgent'),"validate":_vm.$v,"api-link":"leadSources/GET_ALL","label":"name","return-object":true},on:{"itemSelected":function (e) { return (_vm.form.note = e.name); }}}):_vm._e(),_c('div',{style:([
                _vm.projectTenant() !== 'Candle'
                  ? { display: 'block' }
                  : { display: 'none' } ])},[_c('number-input',{attrs:{"id":"plotArea","name":_vm.$t('plotArea'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1),(_vm.projectTenant() !== 'MassHills')?_c('div',[_c('number-input',{attrs:{"id":"buildUpArea","name":_vm.projectTenant() !== 'MassHills'
                    ? _vm.projectTenant() === 'Candle'
                      ? _vm.$t('apartment')
                      : _vm.$t('area')
                    : _vm.$t('buildUpArea'),"validate":_vm.$v,"is-loading":_vm.isLoading}}),_c('number-input',{attrs:{"id":"meterPrice","name":_vm.projectTenant() !== 'MassHills'
                    ? _vm.projectTenant() === 'Candle'
                      ? _vm.$t('apartmentPrice')
                      : _vm.$t('priceMeter')
                    : _vm.$t('meterPrice'),"validate":_vm.$v,"is-loading":_vm.isLoading}})],1):_vm._e(),(
                _vm.projectTenant() !== 'DiplomatiSafiran' &&
                _vm.projectTenant() !== 'Candle'
              )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label text-right",attrs:{"for":"amount"}},[_vm._v(_vm._s(_vm.$t("amount")))]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.initialPrice),expression:"form.initialPrice",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"amount","placeholder":"Amount","type":"number","autocomplete":"off","step":"any"},domProps:{"value":(_vm.form.initialPrice)},on:{"focus":function($event){return $event.target.select()},"keypress":function (e) { return _vm.$helpers.isNumeric(e); },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "initialPrice", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]):_vm._e(),(
                _vm.projectTenant() !== 'DiplomatiSafiran' &&
                _vm.projectTenant() !== 'Candle' &&
                _vm.projectTenant() !== 'RoyalTower'
              )?_c('number-input',{attrs:{"id":"area","name":_vm.$t('area'),"validate":_vm.$v,"is-loading":_vm.isLoading}}):_vm._e(),(
                _vm.projectTenant() !== 'DiplomatiSafiran' &&
                _vm.projectTenant() !== 'Candle' &&
                _vm.projectTenant() !== 'RoyalTower'
              )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label text-right"},[_vm._v(_vm._s(_vm.$t("variance")))]),_c('div',{staticClass:"col-sm-10"},[_c('p',{staticClass:"lead pt-1"},[_vm._v(_vm._s(_vm.variance.toLocaleString()))])])]):_vm._e(),(
                _vm.projectTenant() !== 'DiplomatiSafiran' &&
                _vm.projectTenant() !== 'Candle' &&
                _vm.projectTenant() !== 'RoyalTower'
              )?_c('number-input',{attrs:{"id":"extraCost","name":_vm.$t('extraCost'),"validate":_vm.$v,"is-loading":_vm.isLoading,"readonly":true}}):_vm._e(),(_vm.projectTenant() !== 'MassHills')?_c('number-input',{attrs:{"id":"initialPrice","name":_vm.$t('initialPrice'),"validate":_vm.$v,"is-loading":_vm.isLoading}}):_vm._e(),_c('text-area-input',{attrs:{"id":"note","name":_vm.$t('note'),"validate":_vm.$v,"is-loading":_vm.isLoading}}),(_vm.projectTenant() !== 'DiplomatiSafiran')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label text-right"}),_c('div',{staticClass:"col-sm-10"},[_c('p',{staticClass:"lead pt-1"},[_vm._v(" "+_vm._s(_vm.projectCurrencySymbol() + _vm.finalPrice.toLocaleString() + _vm.projectCurrencySymbol(true))+" ")])])]):_vm._e(),(this.hasPermission(this.permissions.propertiesEdit))?_c('submit-input',{attrs:{"icon":"fa-save","name":_vm.$t('save'),"disabled":_vm.isLoading}}):_vm._e(),(_vm.isLoading)?_c('is-loading'):_vm._e()],1)]):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowAttachments))?_c('b-tab',{attrs:{"title":_vm.$t('attachment')}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'property.attachment.create',
                params: { id: _vm.$route.params.id },
              })}}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")]),_c('PropertyAttachmentsList',{attrs:{"attachments":_vm.propertyAttachments},on:{"loadAll":_vm.loadOneData}})],1):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowExpenses))?_c('b-tab',{attrs:{"title":_vm.$t('expenses')}},[_c('PropertyExpensesList',{attrs:{"expenses":_vm.propertyExpenses},on:{"loadAll":_vm.loadOneData}})],1):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowFollowups))?_c('b-tab',{attrs:{"title":_vm.$t('followUp')}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'property.follow.up.create',
                params: { id: _vm.$route.params.id },
              })}}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")]),_c('PropertyFollowUpList',{attrs:{"follow-ups":_vm.propertyFollowUps},on:{"loadAll":_vm.loadOneData}})],1):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowContracts))?_c('b-tab',{attrs:{"title":_vm.$t('contracts')}},[_c('PropertyContractsList',{attrs:{"contracts":_vm.propertyContracts}})],1):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowFeatures))?_c('b-tab',{attrs:{"title":_vm.$t('features')}},[_c('PropertyFeaturesList',{attrs:{"features":_vm.propertyFeatures},on:{"loadAll":_vm.loadOneData}})],1):_vm._e(),(_vm.hasPermission(_vm.permissions.propertiesShowHolds))?_c('b-tab',{attrs:{"title":_vm.$t('holds')}},[_c('PropertyHoldsList',{attrs:{"holds":_vm.propertyHolds}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }