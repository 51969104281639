<template>
  <div class="contract text-right" dir="rtl">
    <div class="text-center">
      <img class="contract-logo" :src="shaqlawaView" alt="logo" width="550" />
    </div>
    <h3 class="text-center">
      عقد بيع شقة قيد الانشاء في مشروع شقلاوە ڤیو
      {{ buyType !== 'Cash' ? '(الاقساط)' : '' }}
    </h3>
    <h3 class="text-center">
      {{ property.name }}
    </h3>
    <b-row class="text-center" style="margin: 0; padding: 0">
      <b-col cols="6">
        <p class="lead">تاريخ تنظيم العقد: {{ formatDate(contract.buyingDate) }}</p>
      </b-col>
      <b-col cols="6">
        <p class="lead">رقم العقد: {{ contract.contractNumber }}</p>
      </b-col>
    </b-row>
    <p class="lead" style="text-align: right">الطرف الاول(البائع): جێگر جلال حمدامین</p>
    <p class="lead" style="text-align: right">العنوان: رقم الهاتف: 07506334000</p>
    <p class="lead" style="text-align: right">
      الطرف الثاني(المشتري):
      {{
        customer.firstName + " " + customer.middleName + " " + customer.lastName
      }}
    </p>
    <p class="lead" style="text-align: right">
      المعرف بالهوية المرقمة الجواز: {{ customer.idcard }}
    </p>
    <p class="lead" style="text-align: right">
      العنوان: {{ customer.address }}  - رقم الهاتف: {{ replacePhone(customer.phone) }}
    </p>
    <h4 style="text-align: right">مقدمة العقد:</h4>
    <p class="lead" style="text-align: right">
      بما ان الطرف الاول (البائع) يقوم ببناء العمارة شقلاوە ڤیو في اربيل –
      شقلاوە علی قطعة الارض المرقمة (1/409 16اراضی- ملک صرف) وبموجب اجازة
      المرقمة (98) بتاریخ (30/11/2021) الصادرة من المدیرة العامة لبلدیات اربیل
      شعبة اجازات البناء وحيث ان لدى الطرف الثاني (المشتري) الرغبة والنية شراء
      الشقة الموصوفة في هذا العقد والتي هي قيد الانشاء وبموجب ما يقدمه الطرف
      الاول من مواصفات فنية وهندسية وبعد ان اقر الطرفان على اهليتهم للتعاقد فقد
      تم الاتفاق على بيع الطرف الاول شقة قيد الانشاء للطرف الثاني وفق التفاصيل
      الاتية:
    </p>
    <p class="lead" style="text-align: right">تفاصيل الشقة</p>
    <p class="lead" style="text-align: right">
      المحافظة:اربيل / القضاء: شقلاوە / المنطقة: سورک
    </p>
    <p class="lead" style="text-align: right">
      رقم القطعة المبني عليها المشروع: (1/409 16اراضی- ملک صرف)
    </p>
    <p class="lead" style="text-align: right">
      العمارة: شقلاوە ڤیو / الطابق: {{ property.streetNumber }} / رقم الشقة:
      {{ property.name }}
    </p>
    <p class="lead" style="text-align: right">
      المساحة الاجمالية : {{ property.buildUpArea }} م<sup>2</sup> / المساحة
      الفعلية : {{ property.area }} م<sup>2</sup>
    </p>
    <p class="lead" style="text-align: right">
      قيمة الشقة: {{ number2Text(property.finalPrice, "ar") }}
    </p>
    <div v-if="buyType === 'Installment'">
      <p class="lead" style="text-align: right">
        قام الطرف الاول ببيع الشقة الموصوفة اعلاه قيد الانشاء للطرف الثاني
        بالسعر المبين اعلاه وقد وافق الطرف الثاني على الشراء وفق التفاصيل
        الواردة في هذا العقد بعد ان اطلع على المشروع وكافة تفاصيله وحسب الخريطة
        المرفقة بهذا العقد.
      </p>
      <p class="lead" style="text-align: right">
        يلتزم الطرف الثاني بدفع جميع الاقساط الي الطرف الأول في مكتب المبيعات
        التابع له او رقم الحساب المصرفي الذي يحدده الطرف الاول ووفق مايلي:
      </p>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>القسط</th>
            <th>مبلغ القسط بالدولار الامريكي</th>
            <th>تاريخ استحقاق الدفع</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>القسط الاول</td>
            <td>
              {{
                contractPrePayments[0] &&
                parseFloat(
                  contractPrePayments[0].currentPayPrice
                ).toLocaleString()
              }}
              $
            </td>
            <td>عند التوقيع العقد</td>
          </tr>
          <tr>
            <td>القسط الثاني</td>
            <td>
              {{
                contractPrePayments[1] &&
                parseFloat(
                  contractPrePayments[1].currentPayPrice
                ).toLocaleString()
              }}
              $
            </td>
            <td>مع بدء عمل في الواجهة</td>
          </tr>
          <tr>
            <td>القسط الثالث</td>
            <td>
              {{
                contractDeliveryKeyPayments &&
                parseFloat(
                  contractDeliveryKeyPayments.currentPayPrice
                ).toLocaleString()
              }}
              $
            </td>
            <td>عند الاستلام</td>
          </tr>
          <tr>
            <td>المتبقي لمدة 18 شهراً</td>
            <td>
              {{
                contractPayments[0] &&
                parseFloat(
                  contractPayments[0].currentPayPrice * 18
                ).toLocaleString()
              }}
              $
            </td>
            <td>المتبقي</td>
          </tr>
          <tr>
            <td>الدفعات شهرية</td>
            <td>
              {{
                contractPayments[0] &&
                parseFloat(contractPayments[0].currentPayPrice).toLocaleString()
              }}
              $
            </td>
            <td>اقساط ١٨ شهر</td>
          </tr>
          <tr>
            <td>المجموع</td>
            <td>{{ contract.buyingPrice.toLocaleString() }} $</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="lead" style="text-align: right">
      تعتبر الخرائط والتصاميم والمخططات الهندسية التابعة للطرف الاول جزءاً لا
      يتجزأ من هذا العقد ويعتبر الطرف الثاني مطلعاً عليها جميعاً عند التوقيع على
      هذا العقد و انە عاین الشقة موضوع البیع المعاینة التامە علی الخریطة النافیة
      للجهالة و انە وضع یدە علیها بحالتها علی الخریطة دون ان یحق لە الرجوع علی
      الطرف الاول ولا يحق له طلب اية إضافات او تغييرات عليها ولا يكون الطرف
      الاول مسؤولاً عن التغييرات الحاصلة بناءً على طلب الجهات الرسمية او ضرورة
      العمل ويقر الطرف الثاني ان لديه معلومات كاملة عن الشقة ومواصفاتها ومواد
      بنائها وشكلها وكامل تفاصيلها ووافق عليها.
    </p>
    <p class="lead" style="text-align: right">
      يلتزم الطرف الاول بإكمال الشقة خلال مدة (20) شهر عمل اعتباراً من تاريخ
      توقيع هذا العقد.
    </p>
    <p class="lead" style="text-align: right">
      في حالة نكول الطرف الثاني بعد توقيع هذا العقد ودفعه مبلغ المقدمة وقبل دفع
      القسط الأول او بعد دفع القسط الاول فانه سيتحمل دفع مبلغ قدره (%20 ) عشرة
      بالمائة من اجمالي قيمة هذا العقد الي الطرف الأول ويفسخ العقد من تلقاء نفسه
      دون حاجة الي توجيه انذار رسمي من الكاتب العدل ويسقط حقه بالمطالبة بمبلغ
      التآمينات او القسط الأول ويسقط حقه في المراجعة المحاكم.
    </p>
    <p class="lead" style="text-align: right">
      في حالة تأخر الطرف الاول عن تسليم الشقة للطرف الثاني خلال المدة المذكورة
      اعلاه بعد طرح ايام التوقف فيلتزم الطرف الاول بدفع غرامة تأخيرية مقدارها
      (1000$) الف دولار امريكي عن كل شهر تأخير على ان لا تزيد في مجموعها عن (2%)
      من قيمة الشقة الكلية ويتم استحقاق وتسوية هذا الالتزام عند دفع القسط الاخير
      وتسليم المفتاح ولايحق للطرف الثاني المطالبة بالغرامات قبل ذلك.
    </p>
    <p class="lead" style="text-align: right">
      أ- في حالة اكمال بناء الشقة من قبل الطرف الاول فانه يتعين على الطرف الثاني
      ان يدفع الى الطرف الاول الاقساط المتبقية بذمته بالإضافة الى كافة المستحقات
      الواجبة عليه والمصاريف الخاصة بنقل وتحويل الملكية ومصاريف المعاملات.
    </p>
    <p class="lead" style="text-align: right">
      ب- يلتزم الطرف الثاني بدفع الأقساط في موعدها المحدد في هذا العقد، وفي حالة
      تأخيريه عن التسديد في المواعيد المقرره في هذا العقد فتضاف مدة التأخير الى
      مدة التنفيذ وموعد التسليم مع عدم الاخلال بحق الطرف الاول في المطالبة
      بالغرامات التأخيرية.
    </p>
    <p class="lead" style="text-align: right">
      ج- یلتزم الطرف الثانی باستلام الشقة بعد الانتهاء الاعمال في المشروع بمجرد
      اخبار الطرف الثاني بالارقام الهاتف المثبتة في العقد او العنوانه في العقد
      او انذاره.
    </p>
    <p class="lead" style="text-align: right">
      في حال اخلال الطرف الثاني باي مما ورد في هذه الفقرة (8/أ) أو(8/ب) أو(8/ج)
      يتحمل الطرف الثاني غرامة مقدارها (100$) مائة دولار امريكي عن كل يوم تأخير
      وفي حالة بلوغ مدة التأخير (30) ثلاثون يوما فمن حق الطرف الاول حصراً فسخ
      العقد دون الحاجة الى توجيه انذار او مراجعة المحاكم لاستصدار حكم قضائي بذلك
      وفي هذه الحالة يلتزم الطرف الثاني بدفع تعويض للطرف الأول مقداره (50%)
      خمسون بالمائة من القيمة الكلية للشقة وهذاالمبلغ متفق عليه بين الطرفين
      ولايجوز تعديله او تخفيضه من قبل القضاء مع استمرار الغرامات التاخيرية لحين
      دفع كامل التعويض والغرامات ولايلتزم الطرف الاول بإرجاع الاقساط الاخرى
      المسددة من قبل الطرف الثاني الا بعد بيع الشقة لمشترى اخر وفي حالة بيع
      الشقة بسعر اقل من سعر هذا العقد فيعاد الى الطرف الثاني المبلغ الاقل بعد
      استقطاع الغرامات التاخيرية ومبلغ التعويض المذكور أعلاه ولا يحق للطرف
      الثاني اقامة اية دعوى بخصوص الشقة موضوع هذا العقد، وكذلك الحال عند نكول
      الطرف الثاني عن تنفيذ أي من التزاماته الواردة في هذا العقد.
    </p>
    <p class="lead" style="text-align: right">
      بعد إتمام الشقة وتنفيذ الطرف الثاني لالتزاماته وموافقة الجهات المختصة
      يلتزم الطرف الاول بتسجيل الشقة باسم الطرف الثاني لدى الدوائر المختصة
      ويتحمل الطرف الثاني جميع المصاريف ورسوم التسجيل والاتعاب والاجور والرسوم
      والضرائب الخاصة بالإفراز والبيع والتنازل وغيرها المتعلقة بالشقة في دائرة
      التسجيل العقاري والضريبة وكافة الدوائر والجهات ذات العلاقة.
    </p>
    <p class="lead" style="text-align: right">
      استلام الطرف الثاني الشقة من الطرف الاول يعتبر تنازلاً وإسقاطاً منه لجميع
      الغرامات والتعويضات وتعتبر الشقة مسلمة له دون اية نواقص.
    </p>
    <p class="lead" style="text-align: right">
      الطرف الثاني يلتزم وفي اي وقت وحتى بعد استلامه الشقة وتسجيلها باسمه
      اصولياً ومرور الوقت بالامتناع عن القيام باي تغيير او تحوير او اضافة او حذف
      في الشقة الا بعد الحصول على موافقة تحريرية مسبقة من الطرف الاول والجهات
      المختصة على ان يتحمل الطرف الثاني مصاريف التغييرات وفي كل الاحوال لا يجوز
      اجراء اية تغييرات خارجية في الشقق كما ويلتزم الطرف الثاني بالتقييد
      بالتعليمات الصادرة من الطرف الأول بخصوص لوحات الإعلانات واللوحات
      التعريفية، واذا خالف الطرف الثاني هذه المادة فيجب عليه اعادة الحال الى ما
      كان عليه سابقا وعلى نفقته الخاصة بالإضافة الى تعويض الطرف الأول.
    </p>
    <p class="lead" style="text-align: right">
      الطرف الثاني ( المشتري) لا يحق له بٲي شكل من الاشكال ان يعترض علی مالك
      الشقق الٲخری في كيفية الاستفادة او استخدام شققهم واستٲجارها للآخرين، وذلك
      بسبب الموقع الجغرافي .
    </p>
    <p class="lead" style="text-align: right">
      يتعهد الطرف الثاني بالالتزام واحترام جميع القواعد والتعليمات التي تصدر من
      الإدارة المشروع .
    </p>
    <div class="lead" style="text-align: right">
      على الطرف الأول أو من يخوله حصراً تقديم الخدمات الواردة في هذه الفقرة الى
      الطرف الثاني بانتظام الا في حالة الظروف القاهرة ويلتزم الطرف الثاني بدفع
      أجور الخدمات التي تحدد من قبل الطرف الأول حتى بعد تسجيل الشقة باسمه رسمياً
      في دائرة التسجيل العقاري وان هذا الالتزام يبقى على الطرف الثاني الى ان
      يقرر الطرف الاول عدم الحاجة اليه، والخدمات هي:
      <ul>
        <li>
          التنظيف الخارجي للشقة ومنها الممرات والسلالم المشتركة وكراج السيارات
          وتنظيف الحدائق العامة للمجمع وادامتها وتنظيف المجاري وجميع النفايات
          ورميها خارج المجمع.
        </li>
        <li>
          صيانة المصاعد الكهربائية وتنظيفها وصيانة الانارة الخارجية للشقة وداخل
          الممرات ومرآب وقوف السيارات وادامة الهياكل الخارجية لبنايات الشقق
          وادامة منظومة اطفاء الحرائق وملاعب الأطفال.
        </li>
      </ul>
    </div>
    <p class="lead" style="text-align: right">
      يجوز للطرف الثاني التنازل عن الشقة للغير إذا كان ملتزما ببنود العقد
      وملتزما بسداد الاقساط في مواعيدها المثبتة والمحددة في العقد وبعد موافقة
      الطرف الاول تحريرياً ومسبقاً وقبول المشتري الجديد لجميع شروط والتزامات هذا
      العقد وبشرط التزام المتنازل له بدفع كامل قيمة الاقساط الباقية للطرف الاول
      عند التنازل.
    </p>
    <p class="lead" style="text-align: right">
      تعتبر العناوين المذكورة في هذا العقد هي الموطن الاختياري ومحلاً للتبليغ.
    </p>
    <p class="lead" style="text-align: right">
      من حق الطرف الاول اعلام الطرف الثاني باي تبليغ بخصوص هذا العقد عن طريق
      اعلان عام او خاص عن طريق الجرائد او عن طريق ارقام الهواتف المذكورة في هذا
      العقد او البريد الالكتروني او اية طريقة اخرى وتعتبر صحيحه ونافذه ومنتجة
      لاثارها القانونية.
    </p>
    <p class="lead" style="text-align: right">
      تكون محاكم مركز مدينة اربيل هي المختصة مكانياً في نظر اي خلاف ينشب بشأن
      هذا العقد ويسقط الطرفان حقهما في طلب إحالة أو نقل الدعوى لأية محكمة اخرى.
    </p>
    <h5 class="lead" style="text-align: right">
      تم توقيع هذا العقد من (18) فقرة في اربيل في ({{ formatDate(contract.buyingDate) }}).
    </h5>
    <div>
      <h4 style="text-align: right">المرفقات</h4>
      <ul>
        <li>خارطة البناء للشقة</li>
        <li>
          هوية الاحوال المدنية وشهادة الجنسية العراقية وبطاقة المعلومات للمشتري
        </li>
        <li>وصل قبض</li>
      </ul>
    </div>
    <b-row class="text-center" style="margin: 0; padding: 0">
      <b-col cols="6">
        <p class="lead">الطرف الاول</p>
        <p class="lead">جێگر جلال حمدامین</p>
      </b-col>
      <b-col cols="6">
        <p class="lead">الطرف الثاني</p>
        <p class="lead">
          {{
            customer.firstName +
            " " +
            customer.middleName +
            " " +
            customer.lastName
          }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import logo from '@/assets/ShaqlawaView.png'

export default {
  name: "ArabicContractShaqlawaView",
  props: {
    contract: {},
    buyType: {
      type: String,
    },
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
  data() {
    return {
      shaqlawaView: logo
    }
  }
};
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1.28rem;
  font-weight: 300;
}

table tr th,
table tr td {
  text-align: right !important;
}

ul li {
  text-align: right;
}
</style>
