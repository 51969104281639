<template>
  <div>
    <content-header>{{ $t("mainDashboard") }}</content-header>
    <v-content>
      <is-loading v-if="isLoading" />
      <b-row>
        <b-col
          v-for="(card, index) in cards"
          :key="index"
          cols="12"
          md="6"
          lg="4"
        >
          <b-card
            img-top
            tag="article"
            style="border-radius: 15px"
            class="mb-2"
          >
            <b-card-header header-class="text-center" style="height: 300px">
              <b-img
                :src="card.projectLogo"
                :alt="card.projectName"
                class="img-fluid img-thumbnail d-block"
                style="height: 250px; margin: 5px auto; object-fit: contain"
              ></b-img>
            </b-card-header>
            <b-card-body>
              <h4
                class="text-center"
                style="
                  border: 1px solid #000;
                  border-radius: 9px;
                  padding: 10px;
                "
              >
                {{ card.projectName }}
              </h4>
              <table class="table-bordered" style="width: 100%">
                <tbody>
                  <tr>
                    <td>{{ `${$t("totalProperties")}` }}</td>
                    <td>{{ card.totalProperties.toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <td>{{ `${$t("totalExpenses")}` }}</td>

                    <td>
                      {{
                        projectCurrencySymbol() +
                        card.totalExpenses.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("totalContracts") }}</td>
                    <td>{{ card.totalContracts.toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("totalVisits") }}</td>
                    <td>{{ card.totalVisits.toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
            <b-card-footer
              footer-class="text-center"
              footer-bg-variant="transparent"
            >
              <b-button
                variant="outline-primary"
                @click="goToProjectDashboard(card.id)"
                >{{ $t("projectDashboard") }}</b-button
              >
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </v-content>
  </div>
</template>

<script>
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import "flatpickr/dist/flatpickr.css";
import IsLoading from "@/components/IsLoading";
import { serverParams } from "@/plugins/constants";

export default {
  name: "MainDashboard",
  components: { IsLoading, "v-content": Content, ContentHeader },
  data() {
    return {
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(7, "d"),
      cards: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("mainDashboard/GET_PROJECTS_INFO")
        .then(() => {
          this.cards = this.$store.getters["mainDashboard/projectsInfo"];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goToProjectDashboard(projectId) {
      this.$store.dispatch("projects/CHANGE_PROJECT", projectId).then(() => {
        this.$router.push({ name: "dashboard" });
        window.location.reload();
      });
    },
  },
};
</script>

<style scoped>
.table-bordered td,
.table-bordered th {
  padding: 9px;
  width: 75%;
}
</style>
