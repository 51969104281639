import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const properties = {
    namespaced: true,
    state: {
        properties: {},
        createdProperties: {},
        getOneProperties: {},
        updatedProperties: {},
    },
    mutations: {
        SET_PROPERTIES (state, value) { state.properties = value },
        SET_CREATED_PROPERTIES (state, value) {state.createdProperties = value},
        SET_GET_ONE_PROPERTIES (state, value) {state.getOneProperties = value},
        SET_UPDATED_PROPERTIES (state, value) {state.updatedProperties = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Properties` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTIES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTIES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Properties/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTIES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTIES', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/Properties`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTIES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTIES', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/Properties/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTIES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTIES', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Properties/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        properties: state => state.properties,
        createdProperties: state => state.createdProperties,
        getOneProperties: state => state.getOneProperties,
        updatedProperties: state => state.updatedProperties,
    }
}

export default properties;
