import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const userTasks = {
    namespaced: true,
    state: {
        userTasks: {},
        createdUserTask: {},
        getOneUserTask: {},
        updatedUserTask: {},
    },
    mutations: {
        SET_USER_TASKS (state, value) { state.userTasks = value },
        SET_CREATED_USER_TASK (state, value) {state.createdUserTask = value},
        SET_GET_ONE_USER_TASK (state, value) {state.getOneUserTask = value},
        SET_UPDATED_USER_TASK (state, value) {state.updatedUserTask = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/UserTasks` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_USER_TASKS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_USER_TASKS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/UserTasks/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_USER_TASK', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_USER_TASK', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/UserTasks`, form)
                    .then((response) => {
                        commit('SET_CREATED_USER_TASK', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_USER_TASK', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/UserTasks/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_USER_TASK', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_USER_TASK', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/UserTasks/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        userTasks: state => state.userTasks,
        createdUserTask: state => state.createdUserTask,
        getOneUserTask: state => state.getOneUserTask,
        updatedUserTask: state => state.updatedUserTask,
    }
}

export default userTasks;
