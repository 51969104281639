import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const safeBoxes = {
    namespaced: true,
    state: {
        safeBoxes: {},
        createdSafeBox: {},
        getOneSafeBox: {},
        updatedSafeBox: {},
    },
    mutations: {
        SET_SAFE_BOXES (state, value) { state.safeBoxes = value },
        SET_CREATED_SAFE_BOX (state, value) {state.createdSafeBox = value},
        SET_GET_ONE_SAFE_BOX (state, value) {state.getOneSafeBox = value},
        SET_UPDATED_SAFE_BOX (state, value) {state.updatedSafeBox = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/SafeBoxes` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_SAFE_BOXES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_SAFE_BOXES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/SafeBoxes/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_SAFE_BOX', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_SAFE_BOX', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/SafeBoxes`, form)
                    .then((response) => {
                        commit('SET_CREATED_SAFE_BOX', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_SAFE_BOX', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/SafeBoxes/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_SAFE_BOX', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_SAFE_BOX', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/SafeBoxes/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        safeBoxes: state => state.safeBoxes,
        createdSafeBox: state => state.createdSafeBox,
        getOneSafeBox: state => state.getOneSafeBox,
        updatedSafeBox: state => state.updatedSafeBox,
    }
}

export default safeBoxes;
