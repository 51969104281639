var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("contractNumber")))]),_c('th',[_vm._v(_vm._s(_vm.$t("customerName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("buyingPrice")))]),_c('th',[_vm._v(_vm._s(_vm.$t("buyingDate")))]),_c('th',[_vm._v(_vm._s(_vm.$t("transferred")))]),_c('th',[_vm._v(_vm._s(_vm.$t("cancelled")))]),_c('th',[_vm._v(_vm._s(_vm.$t("note")))])])]),_c('tbody',_vm._l((_vm.allContracts),function(contract){return _c('tr',{key:contract.id,style:(contract.isTransfered
          ? { 'background-color': '#ffc1c1' }
          : contract.isCancelled
          ? { 'background-color': '#ff8e8e' }
          : { 'background-color': '#ffffff' })},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'buyContracts.show', params: { id: contract.id } }}},[_vm._v(" "+_vm._s(contract.contractNumber)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
            name: 'customers.edit',
            params: { id: contract.customerId },
          }}},[_vm._v(" "+_vm._s(((contract.customer.firstName) + " " + (contract.customer.middleName) + " " + (contract.customer.lastName)))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.projectCurrencySymbol() + contract.buyingPrice.toLocaleString() + _vm.projectCurrencySymbol(true))+" ")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(contract.buyingDate, "YYYY-MM-DD")))]),_c('td',[_vm._v(_vm._s(contract.isTransfered ? _vm.$t("yes") : _vm.$t("no")))]),_c('td',[_vm._v(_vm._s(contract.isCancelled ? _vm.$t("yes") : _vm.$t("no")))]),_c('td',[_vm._v(_vm._s(contract.note))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }