<template>
  <div
    v-if="
      hasPermission(permissions.customersEdit) ||
      hasPermission(permissions.customersShowAttachments)
    "
  >
    <content-header>Edit Customer</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card>
          <b-tab
            v-if="hasPermission(permissions.customersEdit)"
            :title="$t('edit')"
            active
          >
            <form class="forms-sample" @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-6">
                  <text-input
                    id="firstName"
                    :name="$t('firstName')"
                    :validate="$v"
                    :required="true"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="middleName"
                    :name="$t('middleName')"
                    :validate="$v"
                    :required="true"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="lastName"
                    :name="$t('lastName')"
                    :validate="$v"
                    :required="true"
                    :is-loading="isLoading"
                  />

                  <div class="col-6">
                    <div class="d-flex justify-content-start">
                      <label class="col-sm-4 px-3 col-form-label text-right"
                        >Gender:
                      </label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="maleLabel"
                          :checked="form.male"
                          @change="(e) => (form.male = e.target.checked)"
                        />
                        <label class="form-check-label" for="maleLabel">
                          Male
                        </label>
                      </div>
                      <div class="mx-2"></div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="femaleLabel"
                          :checked="!form.male"
                          @change="(e) => (form.male = !e.target.checked)"
                        />
                        <label class="form-check-label" for="femaleLabel">
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <boolean-input
                    id="male"
                    :name="$t('gender')"
                    :default-value="form.male"
                    checked-label="Male"
                    un-checked-label="Female"
                    @switched="(e) => (form.male = e)"
                  /> -->
                  <date-time-input
                    id="dob"
                    :name="$t('birthdate')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <div v-if="isSharedCustomer" class="form-group row">
                    <label
                      for="dob2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("birthdate") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="dob2"
                        v-model.trim="dob2"
                        :disabled="isLoading"
                        :placeholder="$t('birthdate')"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <text-input
                    id="address"
                    :name="$t('address')"
                    type="textarea"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="email"
                    :name="$t('email')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="phone"
                    :name="$t('phone')"
                    :validate="$v"
                    :required="true"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="phone2"
                    :name="$t('phone2')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                </div>
                <div class="col-6">
                  <text-input
                    id="nationality"
                    :name="$t('nationality')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="idcard"
                    :name="$t('idCard')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <div v-if="projectTenant() !== 'MassHills'">
                    <text-input
                      v-if="projectTenant() !== 'RoyalTower'"
                      id="placementCard"
                      :name="$t('residencyCard')"
                      :validate="$v"
                      :is-loading="isLoading"
                    />
                    <text-input
                      v-if="projectTenant() !== 'RoyalTower'"
                      id="jnsia"
                      :name="$t('jnsia')"
                      :validate="$v"
                      :is-loading="isLoading"
                    />
                  </div>
                  <text-input
                    v-if="projectTenant() !== 'RoyalTower'"
                    id="foodCard"
                    :name="
                      projectTenant() === 'MassHills'
                        ? $t('passport')
                        : $t('foodCard')
                    "
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    v-if="projectTenant() !== 'RoyalTower'"
                    id="informationCard"
                    :name="$t('informationCard')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    v-if="projectTenant() !== 'RoyalTower'"
                    id="securitySupport"
                    :name="$t('securitySupport')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-area-input
                    id="note"
                    :name="$t('note')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    id="job"
                    :name="$t('job')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                </div>
              </div>
              <submit-input
                icon="fa-save"
                :name="$t('save')"
                :disabled="isLoading"
              />
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
          <b-tab
            v-if="hasPermission(permissions.customersShowAttachments)"
            :title="$t('attachment')"
          >
            <b-button
              variant="outline-primary"
              class="float-right"
              @click.prevent="
                $router.push({
                  name: 'customer.attachment.create',
                  params: { id: $route.params.id },
                })
              "
            >
              <i class="fas fa-plus-circle"></i> {{ $t("create") }}
            </b-button>
            <customer-attachment-list
              :attachments="customerAttachments"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab
            v-if="hasPermission(permissions.customersShowContracts)"
            :title="$t('contracts')"
          >
            <customer-contracts-list :contracts="customerContracts" />
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
// import BooleanInput from "@/components/Inputs/BooleanInput";
import CustomerAttachmentList from "./attachments/List";
import { Customer } from "@/models/Customer";
import TextInput from "@/components/Inputs/TextInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import CustomerContractsList from "@/views/customers/contracts/List";
import { permissions } from "@/plugins/constants";

export default {
  name: "CustomerEdit",
  components: {
    CustomerContractsList,
    TextAreaInput,
    DateTimeInput,
    TextInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
    // BooleanInput,
    CustomerAttachmentList,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Customer().fillAbles),
      isLoading: false,
      customerAttachments: [],
      customerContracts: [],
      permissions: permissions,
      dob2: null,
      isSharedCustomer: false,
    };
  },
  mounted() {
    this.loadOneData();
  },
  validations: {
    form: {
      firstName: { required },
      middleName: { required },
      lastName: { required },
      dob: {},
      nationality: {},
      address: {},
      email: {},
      phone: { required },
      phone2: {},
      idcard: {},
      foodCard: {},
      informationCard: {},
      placementCard: {},
      securitySupport: {},
      note: {},
      male: {},
      jnsia: {},
      job: {},
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      if (this.isSharedCustomer) {
        this.form.howCame = this.dob2;
      }
      this.$store
        .dispatch("customers/UPDATE", {
          id: this.$route.params.id,
          form: this.form,
        })
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "customers" });
        })
        .catch(() => (this.isLoading = false));
    },
    loadOneData() {
      this.isLoading = true;
      this.$store
        .dispatch("customers/GET_ONE", this.$route.params.id)
        .then((response) => {
          this.form = response.data;
          if (response.data.isSharedCustomer) {
            this.isSharedCustomer = response.data.isSharedCustomer;
            this.dob2 = response.data.howCame;
          }
          this.customerAttachments = response.data.customerAttachments;
          this.customerContracts = this._.sortBy(response.data.buyContracts, [
            "buyingDate",
          ]).reverse();
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
