import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";

const dashboard = {
    namespaced: true,
    state: {
        mapInfo: {},
        mapInfoByZone: {},
        propertyInfoByType: {},
        propertyInfoByZone: {},
        cardsInfo: {},
        todaySummery: {},
        generalData: {},
        expiredHolds: {},
        upcomingPayments: {},
        passedPayments: {},
        propertyInfo: {},
        todayTasks: {},
    },
    mutations: {
        SET_MAP_INFO (state, value) { state.mapInfo = value },
        SET_MAP_INFO_BY_ZONE (state, value) { state.mapInfoByZone = value },
        SET_PROPERTY_INFO_BY_TYPE (state, value) { state.propertyInfoByType = value },
        SET_PROPERTY_INFO_BY_ZONE (state, value) { state.propertyInfoByType = value },
        SET_CARDS_INFO_DATA (state, value) { state.cardsInfo = value },
        SET_TODAY_SUMMARY_DATA (state, value) { state.todaySummery = value },
        SET_GENERAL_DATA (state, value) { state.generalData = value },
        SET_EXPIRED_HOLDS (state, value) { state.expiredHolds = value },
        SET_UPCOMING_PAYMENTS (state, value) { state.upcomingPayments = value },
        SET_PASSED_PAYMENTS (state, value) { state.passedPayments = value },
        SET_PROPERTY_INFO (state, value) { state.propertyInfo = value },
        SET_TODAY_TASKS (state, value) { state.todayTasks = value },
    },
    actions: {
        GET_MAP_INFO({commit}, byType = false) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetMapInfo?byType=${byType}`)
                    .then((response) => {
                        commit('SET_MAP_INFO', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_MAP_INFO', {})
                        reject(error)
                    });
            })
        },
        GET_PROPERTY_INFO_BY_TYPE({commit}, typeId) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetPropertyInfo?typeId=${typeId}`)
                    .then((response) => {
                        commit('SET_PROPERTY_INFO_BY_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_INFO_BY_TYPE', {})
                        reject(error)
                    });
            })
        },
        GET_PROPERTY_INFO_BY_ZONE({commit}, zone) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetPropertyInfo?zone=${zone}`)
                    .then((response) => {
                        commit('SET_PROPERTY_INFO_BY_ZONE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_INFO_BY_ZONE', {})
                        reject(error)
                    });
            })
        },
        GET_CARDS_INFO_DATA({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetCards`)
                    .then((response) => {
                        commit('SET_CARDS_INFO_DATA', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CARDS_INFO_DATA', {})
                        reject(error)
                    });
            })
        },
        GET_TODAY_SUMMARY_DATA({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetTodaySummary`)
                    .then((response) => {
                        commit('SET_TODAY_SUMMARY_DATA', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TODAY_SUMMARY_DATA', {})
                        reject(error)
                    });
            })
        },
        GET_GENERAL_DATA({commit}, {fromDate, toDate}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetGeneralData?startDate=${fromDate}&endDate=${toDate}`)
                    .then((response) => {
                        commit('SET_GENERAL_DATA', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GENERAL_DATA', {})
                        reject(error)
                    });
            })
        },
        GET_EXPIRED_HOLDS({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetExpiredHolds`)
                    .then((response) => {
                        commit('SET_EXPIRED_HOLDS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_EXPIRED_HOLDS', {})
                        reject(error)
                    });
            })
        },
        GET_UPCOMING_PAYMENTS({commit}, {fromDate, toDate, serverParams}) {
            return new Promise((resolve, reject) => {
                let ServerParams = serverParams ? resolveServerParams(serverParams) : '';
                let url = ServerParams ? `/Dashboard/GetAllUpcomingPayments` +
                        `${ServerParams._start}` +
                        `${ServerParams._end}` +
                        `${ServerParams._sort}` +
                        `${ServerParams._order}` +
                        `${ServerParams._search}` +
                        `&startDate=${fromDate}&endDate=${toDate}`
                    : `/Dashboard/GetAllUpcomingPayments?startDate=${fromDate}&endDate=${toDate}`;

                HTTP.get(url)
                    .then((response) => {
                        commit('SET_UPCOMING_PAYMENTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPCOMING_PAYMENTS', {})
                        reject(error)
                    });
            })
        },
        GET_PASSED_PAYMENT({commit}, {_end}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetPassedPayments?start=0&end=${_end}`)
                    .then((response) => {
                        commit('SET_PASSED_PAYMENTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PASSED_PAYMENTS', {})
                        reject(error)
                    });
            })
        },
        GET_PROPERTY_INFO({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetPropertyInfo`)
                    .then((response) => {
                        commit('SET_PROPERTY_INFO', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_INFO', {})
                        reject(error)
                    });
            })
        },
        GET_TODAY_TASKS({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetTodayTasks`)
                    .then((response) => {
                        commit('SET_TODAY_TASKS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TODAY_TASKS', {})
                        reject(error)
                    });
            })
        }
    },
    getters : {
        mapInfo: state => state.mapInfo,
        mapInfoByZone: state => state.mapInfoByZone,
        propertyInfoByType: state => state.propertyInfoByType,
        propertyInfoByZone: state => state.propertyInfoByZone,
        cardsInfo: state => state.cardsInfo,
        todaySummery: state => state.todaySummery,
        generalData: state => state.generalData,
        expiredHolds: state => state.expiredHolds,
        upcomingPayments: state => state.upcomingPayments,
        passedPayments: state => state.passedPayments,
        propertyInfo: state => state.propertyInfo,
        todayTasks: state => state.todayTasks,
    }
}

export default dashboard;
