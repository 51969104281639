import { i18n } from "@/plugins/i18n";

export class UpcomingPaymentReport {
  columns = [];
  id = "id";

  fillAbles = [
    ["contractNumber", i18n.t("contractNumber"), false],
    ["date", i18n.t("date"), false, true],
    ["mcPrice", i18n.t("price"), false],
    ["type", i18n.t("propertyName"), false],
    ["propertyZone", i18n.t("zone"), false],
    ["detail", i18n.t("detail"), false],
    ["phone", i18n.t("phone"), false],
    ["contractId", i18n.t("id"), false, false],
    ["customerId", i18n.t("id"), false, false], 
    ["propertyId", i18n.t("id"), false, false],
    ["name", i18n.t("name"), false], //field , order, visibility
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility: true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
