import { i18n } from "@/plugins/i18n";

export class UserTask {
  columns = [];
  id = "id";

  fillAbles = [
    ["title", i18n.t("title"), true],
    ["description", i18n.t("description"), true],
    ["date", i18n.t("date"), true],
    ["time", i18n.t("time"), true],
    ["dayName", i18n.t("dayName"), true],
    ["taskType", i18n.t("taskType"), true],
    ["isDone", i18n.t("isDone"), true, false],
    ["staffNote", i18n.t("staffNote"), true, false],
    ["doneAt", i18n.t("doneAt"), true, false],
    ["userId", i18n.t("userId"), true, false],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
