<template>
  <!-- <div
    id="property-hold-voucher-1"
    class="contract text-right d-none"
    dir="rtl"
    v-if="data && data.property"
  >
    <b-row class="text-center">
      <b-col cols="6">
        <img
          :src="logo"
          alt="Logo"
          style="object-fit: contain  "
          width="185"
          height="185"
        />
      </b-col>
      <b-col cols="6">
        <h1>{{ projectTenantName() }}</h1>
        <h3>مرکز المبیعات</h3>
      </b-col>
    </b-row>
    <h2 class="text-center mb-5">بطاقة حجز وحدة سکنیة</h2>
    <b-row class="text-center">
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          عمارة: <span class="selectedSpan">{{ data.property.zone }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          الطابق: <span class="selectedSpan">{{ data.property.floors }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          شقە: <span class="selectedSpan">{{ data.property.name }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          المساحة: <span class="selectedSpan">{{ data.property.area }}</span>
        </p>
      </b-col>
    </b-row>
    <p class="lead" style="text-align: start">
      الإسم:
      <span style="font-weight: bold">{{
        data.name
          ? data.name
          : `${data.customer && data.customer.firstName} ${
              data.customer && data.customer.middleName
            } ${data.customer && data.customer.lastName}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهاتف:
      <span style="font-weight: bold">{{
        data.customer && `${replacePhone(data.customer.phone)}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهاتف ٢:
      <span style="font-weight: bold">{{
        data.customer && data.customer.phone2 && `${data.customer.phone2}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      سعر المتر:
      <span style="font-weight: bold">{{
        parseFloat(data.property.meterPrice).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      سعر الکلی:
      <span style="font-weight: bold">{{
        parseFloat(data.property.finalPrice).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      المقدمة:
      <span style="font-weight: bold">{{
        parseFloat(data.holdPayment).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      المبلغ المتبقی:
      <span style="font-weight: bold">{{
        parseFloat(
          parseFloat(data.property.finalPrice) - parseFloat(data.holdPayment)
        ).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      ١.٥٪ من المبلغ الکلی:
      <span style="font-weight: bold">{{
        parseFloat(
          0.015 * parseFloat(data.property.finalPrice)
        ).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      عنوان السکن:
      <span style="font-weight: bold">{{
        data.customer && `${data.customer.address}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      الإطلالة:
      <span style="font-weight: bold">{{
        data.property &&
        `${data.property.extraProperty1 ? data.property.extraProperty1 : "-"}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      الواجهة:
      <span style="font-weight: bold">{{
        data.property &&
        `${data.property.extraProperty2 ? data.property.extraProperty2 : "-"}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهویة:
      <span style="font-weight: bold">{{
        data.customer && `${data.customer.idcard}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      تاریخ الحجز:
      <span style="font-weight: bold">{{ formatDate(data.date) }}</span>
    </p>
    <p class="lead" style="text-align: start">
      إسم الموظف: <span style="font-weight: bold">{{ getAuthUser() }}</span>
    </p>
  </div> -->
  <div
    id="property-hold-voucher-1"
    v-if="data && data.property"
    class="voucher-receipt"
    style="direction: rtl !important; display: none"
  >
    <div id="cloneable" style="direction: rtl !important">
      <b-row class="text-center" style="margin: 0; font-size: 28px">
        <b-col cols="4"> ماس هیڵس </b-col>
        <b-col cols="4">
          <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 42%"
          ></b-img>
        </b-col>
        <b-col cols="4"> ماس هیلس </b-col>
      </b-row>
      <b-row
        style="
          font-size: 20px;
          margin: 10px;
          border-top: 3px solid #b58151;
          border-bottom: 3px solid #b58151;
          padding: 5px;
        "
      >
        <b-col class="text-right" cols="6"> Email: sales@masshills.com </b-col>
        <b-col class="text-left" cols="6">
          Mob: 07502208181 - 07723807171
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 20px">
        <b-col class="text-right" style="font-size: 25px" cols="4">
          ژمارەی پسوولە:
          <span class="text-danger text-bold">
            {{ data && "Reserve-" + data.id }}</span
          >
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          { پسوولەی پارە وەرگرتن }
          <br />
          Receipt Voucher
        </b-col>
        <b-col class="text-left" style="font-size: 25px" cols="4">
          رێکەوت: {{ data && formatDate(data.date) }}
          <br />
          {{ datetime && formatDate(datetime, "HH:mm:ss A") }}
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          وەرگیرا لە بەڕێز (إستلم من السید / السیدة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          <h4>
            <b>{{
              data.name
                ? data.name
                : `${data.customer && data.customer.firstName} ${
                    data.customer && data.customer.middleName
                  } ${data.customer && data.customer.lastName}`
            }}</b>
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Received From:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3"> بڕی پارە (المبلغ) : </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          ${{ parseFloat(data.holdPayment).toLocaleString() }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          بڕی پارە بە نووسین (کتابة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          {{ amountText }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> ژمارەی ڤێلا (رقم الفیلا) : </b-col>
        <b-col class="text-center" cols="4">
          {{ data && data.property.name }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Property Name:</b-col
        >
      </b-row>
      <!-- <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> بە مەبەستی (و ذلك عن) : </b-col>
        <b-col class="text-center" cols="4"> {{ note && note }} </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Purpose of receipt</b-col
        >
      </b-row> -->
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center">
        <b-col cols="6">
          واژووی وەرگر | توقیع المستلم
          <br />
        </b-col>
        <b-col cols="6">
          واژووی لێوەرگیراو | توقیع المستلم منە
          <br />
          {{
            data.name
              ? data.name
              : `${data.customer && data.customer.firstName} ${
                  data.customer && data.customer.middleName
                } ${data.customer && data.customer.lastName}`
          }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 50px 0" />
    <br /><br />
    <div id="cloneable" style="direction: rtl !important">
      <b-row class="text-center" style="margin: 0; font-size: 28px">
        <b-col cols="4"> ماس هیڵس </b-col>
        <b-col cols="4">
          <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 42%"
          ></b-img>
        </b-col>
        <b-col cols="4"> ماس هیلس </b-col>
      </b-row>
      <b-row
        style="
          font-size: 20px;
          margin: 10px;
          border-top: 3px solid #b58151;
          border-bottom: 3px solid #b58151;
          padding: 5px;
        "
      >
        <b-col class="text-right" cols="6"> Email: sales@masshills.com </b-col>
        <b-col class="text-left" cols="6">
          Mob: 07502208181 - 07723807171
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 20px">
        <b-col class="text-right" style="font-size: 25px" cols="4">
          ژمارەی پسوولە:
          <span class="text-danger text-bold">
            {{ data && "Reserve-" + data.id }}</span
          >
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          { پسوولەی پارە وەرگرتن }
          <br />
          Receipt Voucher
        </b-col>
        <b-col class="text-left" style="font-size: 25px" cols="4">
          رێکەوت: {{ data && formatDate(data.date) }}
          <br />
          {{ datetime && formatDate(datetime, "HH:mm:ss A") }}
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          وەرگیرا لە بەڕێز (إستلم من السید / السیدة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          <h4>
            <b>{{
              data.name
                ? data.name
                : `${data.customer && data.customer.firstName} ${
                    data.customer && data.customer.middleName
                  } ${data.customer && data.customer.lastName}`
            }}</b>
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Received From:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3"> بڕی پارە (المبلغ) : </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          ${{ parseFloat(data.holdPayment).toLocaleString() }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          بڕی پارە بە نووسین (کتابة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          {{ amountText }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> ژمارەی ڤێلا (رقم الفیلا) : </b-col>
        <b-col class="text-center" cols="4">
          {{ data && data.property.name }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Property Name:</b-col
        >
      </b-row>
      <!-- <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> بە مەبەستی (و ذلك عن) : </b-col>
        <b-col class="text-center" cols="4"> {{ note && note }} </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Purpose of receipt</b-col
        >
      </b-row> -->
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center">
        <b-col cols="6">
          واژووی وەرگر | توقیع المستلم
          <br />
        </b-col>
        <b-col cols="6">
          واژووی لێوەرگیراو | توقیع المستلم منە
          <br />
          {{
            data.name
              ? data.name
              : `${data.customer && data.customer.firstName} ${
                  data.customer && data.customer.middleName
                } ${data.customer && data.customer.lastName}`
          }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 50px 0" />
  </div>
</template>

<script>
// import logo from "@/assets/MassHills.png";
import mixins from "@/mixins";

export default {
  name: "PropertyHoldDesignMassHills",
  // props: {
  //   data: {},
  // },
  props: {
    data: {},
    voucherNo: {
      type: String,
    },
    voucherDate: {
      type: String,
    },
    paidName: {
      type: String,
    },
    amount: {
      type: String,
    },
    purposeOfVoucher: {
      type: String,
    },
    note: {
      type: String,
    },
    accountantName: {
      type: String,
      default: "ناوی ژمێریار",
    },
    propertyName: {
      type: String,
    },
  },

  // data() {
  //   return {
  //     logo: logo,
  //   };
  // },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      datetime: null,
      amountText: "",
    };
  },
  mounted() {
    mixins.getCurrentDateTimeServer().then((response) => {
      if (response) {
        this.datetime = response.datetime;
      }
    });
    setTimeout(() => {
      mixins
        .getAmountInText(
          Math.round(parseFloat(parseFloat(this.data.holdPayment)))
        )
        .then((response) => {
          this.amountText = response;
        });
    }, 500);
  },
};
</script>

<style scoped>
/* .selectedSpan {  
  font-weight: bold;
  background-color: #cccccc;
  padding: 10px;
} */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
</style>
