import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const leadSources = {
    namespaced: true,
    state: {
        leadSources: {},
        createdLeadSource: {},
        getOneLeadSource: {},
        updatedLeadSource: {},
    },
    mutations: {
        SET_LEAD_SOURCES (state, value) { state.leadSources = value },
        SET_CREATED_LEAD_SOURCE (state, value) {state.createdLeadSource = value},
        SET_GET_ONE_LEAD_SOURCE (state, value) {state.getOneLeadSource = value},
        SET_UPDATED_LEAD_SOURCE (state, value) {state.updatedLeadSource = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/LeadSources` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_LEAD_SOURCES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_SOURCES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadSources/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_LEAD_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_LEAD_SOURCE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.post(`/LeadSources`, form)
                    .then((response) => {
                        commit('SET_CREATED_LEAD_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_LEAD_SOURCE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.put(`/LeadSources/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_LEAD_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_LEAD_SOURCE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/LeadSources/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        leadSources: state => state.leadSources,
        createdLeadSource: state => state.createdLeadSource,
        getOneLeadSource: state => state.getOneLeadSource,
        updatedLeadSource: state => state.updatedLeadSource,
    }
}

export default leadSources;
