<template>
  <apexchart ref="realtimeChart" type="bar" :options="chartOptions" :series="series"></apexchart>
</template>

<script>

export default {
  name: 'Chart',
  props: {
    chartData: {
      required: true
    },
    chartFields: {
      required: true
    },
    title: {
      required: true
    },
  },
  data(){
    return {
      rows: [],
      series: [{
        name: this.title,
        data: this.chartData
      }],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          }
        },
        title: {
          text: this.title,
          align: 'Center'
        },
        xaxis: {
          categories: this.chartFields,
        },
        dataLabels: {
          style: {
            colors: ['#000000']
          }
        },
      },
    }
  },
  watch: {
    chartData: function(newVal){
      this.$refs.realtimeChart.updateSeries([{
        data: newVal,
      }], false, true);
    }
  },
}
</script>