import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const withdraws = {
    namespaced: true,
    state: {
        withdraws: {},
        createdWithdraw: {},
        getOneWithdraw: {},
        updatedWithdraw: {},
    },
    mutations: {
        SET_WITHDRAWS (state, value) { state.withdraws = value },
        SET_CREATED_WITHDRAW (state, value) {state.createdWithdraw = value},
        SET_GET_ONE_WITHDRAW (state, value) {state.getOneWithdraw = value},
        SET_UPDATED_WITHDRAW (state, value) {state.updatedWithdraw = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Withdraws` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_WITHDRAWS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_WITHDRAWS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Withdraws/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_WITHDRAW', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_WITHDRAW', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Withdraws`, form)
                    .then((response) => {
                        commit('SET_CREATED_WITHDRAW', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_WITHDRAW', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Withdraws/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_WITHDRAW', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_WITHDRAW', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Withdraws/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        withdraws: state => state.withdraws,
        createdWithdraw: state => state.createdWithdraw,
        getOneWithdraw: state => state.getOneWithdraw,
        updatedWithdraw: state => state.updatedWithdraw,
    }
}

export default withdraws;
