import { i18n } from "@/plugins/i18n";

export class ForgivePriceReport {
  columns = [];
  id = "id";

  fillAbles = [
    ["buyContractId", i18n.t("buyContract"), true],
    ["currentPayPrice", i18n.t("currentPayPrice"), true],
    ["currentForgivePrice", i18n.t("currentForgivePrice"), true],
    ["payDate", i18n.t("payDate"), true],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
