<template>
  <transition name="fade">
    <div class="houses">
      <b-row>
        <b-col lg="3" md="6" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-info" @click="filterBlocks(1)">{{ $t('soldProperties') }} <span class="badge badge-light">{{ sold }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="3" md="6" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-success" @click="filterBlocks(2)">{{ $t('remainedProperties') }} <span class="badge badge-light">{{ remained }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="3" md="6" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-secondary" @click="filterBlocks(3)">{{ $t('onHoldProperties') }} <span class="badge badge-light">{{ onHold }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="3" md="6" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-danger" @click="filterBlocks(0)">{{ $t('reset') }}</button>
        </b-col>
      </b-row>
      <div>
        <div class="d-inline-block mx-2" v-for="blockHouse in searchBlock" v-bind:key="blockHouse.name">
          <b-card
              style="max-width: 20rem;min-height: 98px;padding: 5px;width: 150px;"
              class="my-2 text-white"
              :style="{'background-color': blockHouse.isSold ? '#17A2B8' : blockHouse.isHold ? '#5a6268' : '#28A745'}"
          >
            <div class="container">
              <h4 @click="$router.push({name: 'properties.edit', params: { id: blockHouse.id }})" style="cursor: pointer;">
                {{ blockHouse.name }}
              </h4>
              <p v-if="blockHouse.isSold" class="d-inline-block m-1 mr-2">
                <router-link style="font-size: 22px !important;" class="text-white" :to="{name: 'buyContracts.show', params: { id: blockHouse.contractId }}">
                  <i class="fas fa-file-contract"></i>
                </router-link>
              </p>
              <p v-if="blockHouse.isSold" class="d-inline-block m-1">
                <router-link style="font-size: 22px !important;" class="text-white" :to="{name: 'customers.edit', params: { id: blockHouse.cutomerId }}">
                  <i class="fas fa-user"></i>
                </router-link>
              </p>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import lodash from 'lodash';

export default {
  name: "DiplomatiSafiran",
  data() {
    return {
      sold: 0,
      remained: 0,
      onHold: 0,
      blockHouses: [],
      searchBlock: [],
    }
  },
  mounted() {
    this.loadBlocks()
  },
  methods: {
    loadBlocks() {
      this.$store.dispatch('dashboard/GET_PROPERTY_INFO').then((response) => {
        this.blockHouses = response.data;
        this.searchBlock = response.data;
        this.sold = lodash.filter(this.blockHouses, (x) => x.isSold).length;
        this.remained = lodash.filter(this.blockHouses, (x) => !x.isSold && !x.isHold).length;
        this.onHold = lodash.filter(this.blockHouses, (x) => x.isHold).length;
      }).catch(() => this.isLoading = false);
    },
    filterBlocks(type) {
      if (type === 1) {
        this.searchBlock = lodash.filter(this.blockHouses, (x) => x.isSold);
      } else if (type === 2) {
        this.searchBlock = lodash.filter(this.blockHouses, (x) => !x.isSold && !x.isHold);
      } else if (type === 3) {
        this.searchBlock = lodash.filter(this.blockHouses, (x) => x.isHold);
      } else {
        this.searchBlock = this.blockHouses;
      }
    }
  }
}
</script>

<style scoped>
.card-body {
  padding: 5px;
}

.block {
  transition: 0.3s;
}

.block:hover {
  background-color: #236398;
  transform: scale(1.05);
  color: #FFFFFF;
}

.blockElement {
  border-radius: 5px;
  padding: 4px;
  box-shadow: 1px 1px 1px 1px #000000;
  color: white;
}

.fade-enter-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
