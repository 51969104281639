import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const expenses = {
    namespaced: true,
    state: {
        expenses: {},
        reportExpenses: {},
        createdExpense: {},
        getOneExpense: {},
        updatedExpense: {},
    },
    mutations: {
        SET_EXPENSES (state, value) { state.expenses = value },
        SET_REPORT_EXPENSE (state, value) { state.reportExpenses = value },
        SET_CREATED_EXPENSE (state, value) {state.createdExpense = value},
        SET_GET_ONE_EXPENSE (state, value) {state.getOneExpense = value},
        SET_UPDATED_EXPENSE (state, value) {state.updatedExpense = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Expenses` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_EXPENSES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_EXPENSES', {})
                        reject(error)
                    });
            })
        },
        GET_ALL_REPORT({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Expenses` +
                    `${serverParams._start}` +
                    `${serverParams._end}` +
                    `${serverParams._sort}` +
                    `${serverParams._order}` +
                    `${serverParams._search}` +
                    `${serverParams.custom_params}`
                )
                    .then((response) => {
                        commit('SET_REPORT_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_REPORT_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Expenses/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Expenses`, form)
                    .then((response) => {
                        commit('SET_CREATED_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Expenses/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Expenses/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        expenses: state => state.expenses,
        reportExpenses: state => state.reportExpenses,
        createdExpense: state => state.createdExpense,
        getOneExpense: state => state.getOneExpense,
        updatedExpense: state => state.updatedExpense,
    }
}

export default expenses;
