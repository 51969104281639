<template>
  <div>
    <b-table
        striped
        hover
        responsive
        bordered
        sort-by="total"
        :sort-desc="true"
        :items="rows"
        :fields="fields"
        :busy="isLoading"
    >
      <template slot="bottom-row">
        <td style="font-weight: bold !important">{{ $t('total') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'women') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'men') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'total') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'f_time') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'm_time') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'l_time') }}</td>
        <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'noOfGroup') }}</td>
      </template>
    </b-table>
    <apexchart ref="realtimeChart" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

import lodash from "lodash";
import moment from "moment";
import {serverParams} from "@/plugins/constants";

export default {
  name: "LeadVisitByDate",
  props: ['fromDate', 'toDate', 'currentTab'],
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: 'date',
          label: this.$t('date'),
          sortable: true
        },
        {
          key: 'women',
          label: this.$t('women'),
          sortable: true,
        },
        {
          key: 'men',
          label: this.$t('men'),
          sortable: true,
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true
        },
        {
          key: 'f_time',
          label: '9:00 - 12:00',
          sortable: true,
        },
        {
          key: 'm_time',
          label: '12:00 - 14:00',
          sortable: true,
        },
        {
          key: 'l_time',
          label: '14:00 - 17:00',
          sortable: true,
        },
        {
          key: 'noOfGroup',
          label: this.$t('noOfGroups'),
          sortable: true,
        }
      ],
      rows: [],
      series: [{
        name: "Visits",
        data: []
      }],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          }
        },
        title: {
          text: this.$t('visitsByDate'),
          align: 'Center'
        },
        xaxis: {
          categories: [],
        }
      },
    }
  },
  watch: {
    fromDate: function () {
      this.loadData()
    },
    toDate: function () {
      this.loadData()
    },
    currentTab: function () {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      if (this.currentTab === 3) {
        this.isLoading = true;
        this.$store.dispatch('leadReports/GET_LEAD_VISIT_BY_DATE', {fromDate: this.fromDate, toDate: this.toDate})
            .then((response) => {
              let rows = [];
              let seriesData = [];
              let optionsData = [];
              lodash.each(response.data, function (value) {
                let fTime = 0;
                let mTime = 0;
                let lTime = 0;
                lodash.each(value, function (val) {
                  if (
                      moment(val.visitDate).isBetween(moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT11:59:59'))
                  ) {
                    fTime += (val.males + val.females);
                  } else if (
                      moment(val.visitDate).isBetween(moment().format('YYYY-MM-DDT12:00:00'), moment().format('YYYY-MM-DDT14:00:00'))
                  ) {
                    mTime += (val.males + val.females);
                  } else {
                    lTime += (val.males + val.females);
                  }
                });
                seriesData.push(lodash.sumBy(value, 'males') + lodash.sumBy(value, 'females'));
                optionsData.push(moment(value[0].visitDate).format('ddd - YYYY-MM-DD'));
                rows.push({
                  date: moment(value[0].visitDate).format('ddd - YYYY-MM-DD'),
                  women: lodash.sumBy(value, 'females'),
                  men: lodash.sumBy(value, 'males'),
                  total: lodash.sumBy(value, 'males') + lodash.sumBy(value, 'females'),
                  f_time: fTime,
                  m_time: mTime,
                  l_time: lTime,
                  noOfGroup: value.length,
                })
              })
              this.rows = rows;

              this.chartOptions = {...this.chartOptions, ...{
                  xaxis: {
                    categories: optionsData
                  }
                }};
              this.$refs.realtimeChart.updateSeries([{
                data: seriesData,
              }], false, true);

              this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
      }
    }
  }
}
</script>
