<template>
  <div id="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <!-- <b-row class="text-center" style="margin: 0; font-size: 28px">
        <img
          height="250px"
          src="../../../assets/images/ZaitonDiscountHeader.png"
          alt="" 
        />
      </b-row> -->
      <br />
      <br />
      <br />
      <b-row>


        <p style="text-align: right; font-size: 20px; margin-right: 100px">
          تاريخ: {{ contractDate }}
        </p>
      </b-row>

      <br /><br /><br /><br /><br />

      <h3 style="line-height: 70px; " class="text-justify">  
        
        تم البيع الوحدة السكنية المرقمة 
        ( {{ propertyName }} ) 
        للسيد 
        ( {{ customerName }} ) 
        على شكل اقساط بموجب العقد
        المرقم
         ( {{ contractNumber }} ) 
         الموقع بين الطرفين بتاريخ
         ( {{ contractDate }} )
         .علما اجمالي المبلغ القيمة
        الوحدة السكنية
         ($ {{ parseFloat(contractTotalPrice).toLocaleString() }})
         {{ buyingPriceText }}
         , و لكن حسبت الشركة هدية للشخص
        المعني بمبلغ
         ($ {{ parseFloat(discount).toLocaleString() }} )
         {{ discountPriceText }}
         من القسط الاخير
      </h3>
      
      <br /><br /><br /><br /><br />
      <h3 >شركة شجرة الزةيتون</h3>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/plugins/axios";
export default {
  name: "ArabicZaitonPlusCityDiscountReceipt",
  props: {
    propertyName: {
      type: String,
    },
    customerName: {
      type: String,
    },
    contractNumber: {
      type: String,
    },
    contractDate: {
      type: String,
    },
    contractTotalPrice: {
      type: String,
    },
    discount: {
      type: String,
    },
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      buyingPriceText: 0,
      discountPriceText: 0,
    };
  },
  async mounted(){
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    
    if(parseFloat(this.contractTotalPrice) > 0){
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.contractTotalPrice}`
    )
      .then((resp) => {
        this.buyingPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    }
    
if(parseFloat(this.discount) > 0){
      await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.discount}`
    )
      .then((resp) => {
        this.discountPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    }


  },
};
</script>
