<template>
  <div>
    <content-header>Create Property FollowUp</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="title" name="Title" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="date" name="Date" :default-value="getCurrentDate()" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="deadline" name="Dead Line" type="date" :validate="$v" :is-loading="isLoading" />
            <boolean-input id="done" name="Is Done" :default-value="false" :checked-label="$t('yes')" :un-checked-label="$t('no')" @switched="e => form.done = e"/>
            <boolean-input id="ShareableForCustomer" name="Sharable" :default-value="false" :checked-label="$t('yes')" :un-checked-label="$t('no')" @switched="e => form.ShareableForCustomer = e"/>
            <boolean-input id="Is360" name="Is 360" :default-value="false" :checked-label="$t('yes')" :un-checked-label="$t('no')" @switched="e => form.Is360 = e"/>
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../../../components/Content";
import ContentHeader from "../../../components/ContentHeader";
import { required } from 'vuelidate/lib/validators';
import IsLoading from "../../../components/IsLoading";
import {PropertyFollowUp} from "../../../models/PropertyFollowUp";
import TextInput from "../../../components/Inputs/TextInput";
import DateTimeInput from "../../../components/Inputs/DateTimeInput";
import BooleanInput from "../../../components/Inputs/BooleanInput";
import SubmitInput from "../../../components/Inputs/SubmitInput";

export default {
  name: "PropertyFollowUpCreate",
  components: {
    SubmitInput,
    BooleanInput,
    DateTimeInput,
    TextInput, IsLoading, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new PropertyFollowUp().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      title: {required},
      date: {required},
      deadline: {},
      done: {},
      ShareableForCustomer: {},
      Is360: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.propertyId = this.$route.params.id;
      this.form.done = this.form.done === true;
      this.form.ShareableForCustomer = this.form.ShareableForCustomer === true;
      this.form.is360 = this.form.is360 === true;
      this.isLoading = true;
      this.$store.dispatch('propertyFollowUp/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'properties.edit', params: {id: this.$route.params.id}, hash: '#followups'})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
