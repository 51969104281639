import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const leadVisits = {
    namespaced: true,
    state: {
        leadVisits: {},
        createdLeadVisits: {},
        getOneLeadVisits: {},
        updatedLeadVisits: {},
    },
    mutations: {
        SET_PROPERTY_LEAD_VISITS (state, value) { state.leadVisits = value },
        SET_CREATED_LEAD_VISITS (state, value) {state.createdLeadVisits = value},
        SET_GET_ONE_LEAD_VISITS (state, value) {state.getOneLeadVisits = value},
        SET_UPDATED_LEAD_VISITS (state, value) {state.updatedLeadVisits = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/LeadVisits` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_LEAD_VISITS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_LEAD_VISITS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadVisits/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_LEAD_VISITS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_LEAD_VISITS', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/LeadVisits`, form)
                    .then((response) => {
                        commit('SET_CREATED_LEAD_VISITS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_LEAD_VISITS', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/LeadVisits/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_LEAD_VISITS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_LEAD_VISITS', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/LeadVisits/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        leadVisits: state => state.leadVisits,
        createdLeadVisits: state => state.createdLeadVisits,
        getOneLeadVisits: state => state.getOneLeadVisits,
        updatedLeadVisits: state => state.updatedLeadVisits,
    }
}

export default leadVisits;
