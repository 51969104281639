<template>
  <div id="transfer-contract-voucher-receipt-zaiton-arabic" class="d-none" >
    <div style="line-height: 35px;">
      <h3 style="text-align: center; font-weight: bold">
        عقد التنازل <br />
      </h3>
      <br />
      <div style="direction: rtl !importantl" >
        <p>
          الطرف الأول ( المتنازل) :  
                    {{data.oldCustomer && data.oldCustomer.fullName }}
          <br />
          الطرف الثاني(المتنازل إليه) :  
                    {{data.oldCustomer && data.newCustomer.fullName }}.
        </p>
<p>
  اتفق الطرفان على مايلي :
</p>
        <p>

          إشارة إلى العقد الموقع المرقم ({{ data.contractNumber }}) 
           في تاريخ ({{ moment(data.buyingDate).format('YYYY-MM-DD')  }}) بين الطرف الاول (المتنازل) في هذا العقد مع شركة شجرة الزيتون

          <br />

          1_  وافق الطرف الاول (المتنازل) على التنازل عن الوحدة السكنية المرقمة ({{data.property && data.property.name }} )
           إلى الطرف  الثاني (المتنازل اليه) في العقد المشار إليه اعلاه. 
<br/>
          2_ استلم الطرف الاول (المتنازل) كافة حقوقه من الطرف (الثاني المتنازل) إليه .
<br/>
          3_ بعد توقيع عقد التنازل يلتزم الطرف الثاني (المتنازل إليه) بكافة الواجبات مقابل شركة شجرة الزيتون حسب العقد المشار إليه ،يلتزم بدفع الجميع مصاريف الخدمات في المشروع شهرياً. 
<br/>
          4_ يلتزم الطرف الاول (المتنازل) بدفع جميع الديون المترتبة عليه عن الخدمات المقدمه اليه في المشروع وبعد ابراء ذمة الطرف الاول (المتنازل) عن جميع الديون المترتبه عليه يبرم العقد الشراء الى الطرف الثاني (المتنازل إليه) في الشركة وبعد ذلك يلتزم الطرف الثاني (المتنازل إليه ( عن مصاريف الخدمات المقدمه اليه في المشروع. 
<br/>
          5_ يقر الطرف الاول (المتنازل) بأنه تنازل عن الدار المذكورة في العقد المرقم ({{ data.contractNumber }})
           إلى الطرف الثاني (المتنازل) إليه ولا يبقى لديه أي حق على شركة شجرة الزيتون .   
<br/>
          6- يتعهد الطرف الاول ( المتنازل ) بأْنه لم يبقي أْية نسخة من العقد الرسمي الموقع لديه و قد قام بارجاع النسخة الاصلية الى الشركة و لن يستخدمها بأْي شكل من الاشكال و لاْية اْغراض اْدارية و قانونية و مالية . وكذلك لشوْون المحاكم . 
<br/>
          7_ وقع هذا العقد في أربيل بتاريخ ({{ moment(data.transferDate).format('YYYY-MM-DD') }}) 


          <br />
          <br />
        </p>
      </div>

      <br />
      <br />
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div style="font-weight: bold; text-align: right">
          <p>
            الطرف الاول ( المتنازل )
                        <br />
                        الأسم : {{ data.oldCustomer && data.oldCustomer.fullName }} <br />
                        رقم نفوس: {{ data.oldCustomer && data.oldCustomer.idCard }} <br />
            العنوان : {{data.oldCustomer && data.oldCustomer.address }} <br />
            موبايل :  {{ data.oldCustomer && data.oldCustomer.phone && data.oldCustomer.phone.replace('+964','0') }}  –  {{data.oldCustomer && data.oldCustomer.phone2&& data.oldCustomer.phone2.replace('+964','0') }} 
            <br />
            <br />
            <br />
          </p>
        </div>

        <div style="font-weight: bold; text-align: right">
          <p>
            الطرف الثاني ( المتنازل إليه )            <br />
            الأسم : {{ data.newCustomer && data.newCustomer.fullName }} <br />
            رقم نفوس:{{ data.newCustomer && data.newCustomer.idCard }} <br />
            العنوان: {{data.newCustomer && data.newCustomer.address }} <br />
            موبايل: {{ data.newCustomer && data.newCustomer.phone && data.newCustomer.phone.replace('+964','0')}}  –  {{data.newCustomer && data.newCustomer.phone2&& data.newCustomer.phone2.replace('+964','0') }}
          </p>
        </div>
      </div>

      <div class="row" dir="rtl">

        <div class="col-5 text-right ">
          <span style="font-weight: normal">
            نسخة الى /<br />
            مديرية العامة للاستثمار
          </span>
        </div>

        <div class="col-3" />

        <div class="col-4 d-inline-block" style="font-weight: normal">
          <div class="text-center">
            تصديق الشركة

            <br />

            شركة شجرة الزيتون للتجارة العامة  <br />
            والاستثمارات العقارية و الصناعية            <br />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZaitonTransferContractDesignArabic",
  props: {
    data: {},
  },
  mounted() {
  },
};
</script>

<style scoped>
p {
  font-size: 19px !important;
  direction: rtl !important;
  text-align: start;
  font-family: Noto, Speda, sans-serif !important;
}

@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
