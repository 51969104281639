<template>
  <div id="cancel-contract-voucher-receipt" class="contract text-right d-none" dir="rtl">
    <h1 class="text-center">بسم الله الرحمن الرحيم</h1>
    <h3 class="text-center">(فسخ العقد)</h3>
    <p class="lead text-right">رقم العقد: {{ data.buyContract.contractNumber }}</p>
    <p class="lead text-right">التاريخ: {{ getCurrentDate() }}</p>
    <p class="lead text-right">الطــرف الأول : شركة گولـــوان للأستثمــار العقــاري تمثلها مديرها المفــوض السيد (احمد رمزي قرةني)</p>
    <p class="lead text-right" dir="rtl">الطــرف الثاني :السيد/ {{ `${data.buyContract.customer.firstName} ${data.buyContract.customer.middleName} ${data.buyContract.customer.lastName}` }}</p>
    <p class="lead text-right" dir="rtl">
      اتفق الطرفــان على فسخ العقد المرقم ({{ data.buyContract.contractNumber }}) والمـؤرخة بتاريخ ({{ formatDate(data.buyContract.buyingDate) }}) بخصوص بيع الفيلا المرقمة ({{ data.buyContract.property.name }}) والمصنفة ({{ data.buyContract.property.zone }}) وتم إعادة المبــالغ التي سبق وان قام الطرف الثاني بدفعها الى الطرف الاول، وعليه لم يبقى للطرف الثاني اية حق في ذمة الطرف الأول ويحق للطرف الأول التصرف بالفيلا المذكورة في مضمون هذا الفسخ وتنظيم عقد بيع جديد الى مشتري اخـــــر.
    </p>
    <p class="lead text-right">وعليه و بتراضي الطرفين تم التوقيع على هذا الفسخ ولم يعد للعقد المذكور اعلاه أي اثر قـــانوني</p>
    <p class="lead text-right">وحـــرر هذا الفسخ في أربيل بتاريخ {{ getCurrentDate() }} بنسختين أصليتين ووقع عليه الطرفـــان</p>
    <br />
    <b-row class="text-center">
      <b-col cols="4">
        <p class="lead">الطرف الثاني</p>
        <p class="lead">{{ `${data.buyContract.customer.firstName} ${data.buyContract.customer.middleName} ${data.buyContract.customer.lastName}` }}</p>
        <p class="lead" dir="rtl">رقم الهویة: {{ data.buyContract.customer.idcard }}</p>
      </b-col>
      <b-col cols="4">
        <p class="lead">المستشار القانوني للشركة</p>
        <p class="lead">المستشار/ احمد عثمان الأطرقضي</p>
      </b-col>
      <b-col cols="4">
        <p class="lead">الطرف الأول</p>
        <p class="lead">احمد رمزي قرني</p>
        <p class="lead">گولوان للأستثمار العقاري</p>
      </b-col>
    </b-row>
    <div class="my-5">
      <b-row class="text-center">
        <b-col cols="4">
          <p class="lead">Erbil – Dream City 984</p>
        </b-col>
        <b-col cols="4">
          <p class="lead">diplomatisafiran@gmail.com</p>
        </b-col>
        <b-col cols="4">
          <p class="lead" dir="ltr">
            +964 750 700 0020
            <br />
            +964 750 700 0030
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancelContractDesignDiplomatiSafiran",
  props: {
    data: {}
  }
}
</script>
