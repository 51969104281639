import Swal from "sweetalert2";
import store from "./../store";
import lodash from "lodash";
import { i18n } from "./i18n";

export const notify = (
  title,
  icon = "error",
  timer = 1000,
  position = "bottom"
) => {
  Swal.fire({
    customClass: "swal-wide",
    toast: true,
    icon: icon,
    position: position,
    title: title,
    showConfirmButton: false,
    timer: timer,
  });
};

export let serverParams = {
  _start: 0,
  _end: 10,
  _sort: "",
  _order: "",
  _search: "",
  custom_params: "",
};

export const tableConfigure = (
  name = null,
  globalSearch = true,
  showCheckbox = false
) => {
  return {
    card_title: name,
    server_mode: true,
    per_page_options: pagination,
    checkbox_rows: showCheckbox,
    show_refresh_button: false,
    show_reset_button: false,
    global_search: {
      placeholder: i18n.t("search"),
      visibility: globalSearch,
      case_sensitive: false,
    },
  };
};

export const pagination = [5, 10, 20, 50, 100, 500, 1000, 3000, 5000];

export const resolveServerParams = (serverParam) => {
  if (serverParam._start !== 0) {
    serverParam._start = `?start=${serverParam._start}`;
  } else {
    serverParam._start = `?start=0`;
  }
  if (serverParam._end !== 0) {
    serverParam._end = `&end=${serverParam._end}`;
  } else {
    serverParam._end = `&end=10`;
  }
  if (serverParam._sort !== "") {
    serverParam._sort = `&sort=${serverParam._sort}`;
  } else {
    serverParam._sort = ``;
  }
  if (serverParam._order !== "") {
    serverParam._order = `&order=${serverParam._order}`;
  } else {
    serverParam._order = ``;
  }
  if (serverParam._search !== "") {
    serverParam._search = `&search=${serverParam._search}`;
  } else {
    serverParam._search = ``;
  }
  if (
    serverParam.custom_params !== "" &&
    serverParam.custom_params !== undefined
  ) {
    let customParams = "";
    lodash.forOwn(serverParam.custom_params, (value, key) => {
      customParams += `&${key}=${value}`;
    });
    serverParam.custom_params = customParams;
  } else {
    serverParam.custom_params = "";
  }
  return serverParam;
};

export const tableActions = (
  createBtn,
  multipleDeleteBtn,
  multipleRestoreBtn,
  multipleForceDeleteBtn,
  exportBtn
) => {
  let buttons = [];
  if (createBtn) {
    buttons.push({
      btn_text: "<i class='fal fa-plus-circle'></i> " + i18n.t("create"),
      event_name: "on-create",
      class: "btn btn-outline-primary",
    });
  }
  if (multipleDeleteBtn) {
    buttons.push({
      btn_text: "<i class='fal fa-trash-alt'></i> " + i18n.t("multipleDelete"),
      event_name: "on-multiple-delete",
      class: "btn btn-outline-danger",
    });
  }
  if (multipleRestoreBtn) {
    buttons.push({
      btn_text:
        "<i class='fal fa-trash-restore-alt'></i> " + i18n.t("multipleRestore"),
      event_name: "on-multiple-restore",
      class: "btn btn-outline-success",
    });
  }
  if (multipleForceDeleteBtn) {
    buttons.push({
      btn_text: "<i class='fal fa-times'></i> " + i18n.t("multipleForceDelete"),
      event_name: "on-multiple-force-delete",
      class: "btn btn-outline-danger",
    });
  }
  if (exportBtn) {
    buttons.push({
      btn_text: "<i class='fal fa-cloud-download'></i> " + i18n.t("export"),
      event_name: "on-export",
      class: "btn btn-outline-info",
    });
  }
  return buttons;
};

export const swalMixin = (icon, title, timer = 1000, position = "top-end") => {
  if (title === "noRows") {
    title = i18n.t("noRowsSelected");
  }
  if (title === "wrong") {
    title = i18n.t("someThingWentWrong");
  }
  const Toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: timer,
  });
  Toast.fire({
    icon: icon,
    title: title,
  });
};

export const actions = async (action, selectedIds, type) => {
  if (selectedIds.length === 0) {
    swalMixin("error", "noRows");
    return false;
  }
  let convertedType = type.charAt(0).toUpperCase() + type.slice(1);
  let val = false;
  await Swal.fire({
    title: i18n.t("areYouSure"),
    text: i18n.t(`youWantTo${convertedType}PermanentlySelectedRows`),
    icon: "warning",
    showCancelButton: true,
    cancelButtonText: i18n.t("noCancel"),
    confirmButtonText: i18n.t(`yes${convertedType}`),
  }).then(async (result) => {
    if (result.value) {
      if (typeof selectedIds === "object") {
        let onlyIds = selectedIds.map((x) => x.id);
        onlyIds.map(async (id) => {
          await store
            .dispatch(action, id)
            .then(() => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: i18n.t(`yourDataHasBeen${convertedType}dPermanently`),
                showConfirmButton: false,
                timer: 1000,
              });
              val = true;
            })
            .catch(() => {
              val = false;
            });
        });
      } else {
        await store
          .dispatch(action, selectedIds)
          .then(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: i18n.t(`yourDataHasBeen${convertedType}dPermanently`),
              showConfirmButton: false,
              timer: 1000,
            });
            val = true;
          })
          .catch(() => {
            val = false;
          });
      }
    }
  });
  return val;
};

export const convertIds = (selectedRows) => {
  let id = "";
  if (typeof selectedRows === "object") {
    let onlyIds = selectedRows.map((x) => x.id);
    id = `?ids[]=${onlyIds[0]}`;
    onlyIds.shift();
    onlyIds.map((item) => {
      id += `&ids[]=` + item;
    });
  } else {
    id = `?ids=${selectedRows}`;
  }
  return id;
};

export const permissions = {
  //! Dashboard
  dashboardCard: "dashboard.card",
  dashboardTodaySummary: "dashboard.today.summary",
  dashboardGeneralData: "dashboard.general.data",
  dashboardPayments: "dashboard.payments",
  dashboardMap: "dashboard.map",

  //! Properties
  propertiesList: "properties.list",
  propertiesCreate: "properties.create",
  propertiesEdit: "properties.edit",
  propertiesDelete: "properties.delete",
  propertiesShow: "properties.show",
  propertiesShowAttachments: "properties.show.attachments",
  propertiesShowExpenses: "properties.show.expenses",
  propertiesShowFollowups: "properties.show.followups",
  propertiesShowContracts: "properties.show.contracts",
  propertiesShowFeatures: "properties.show.features",
  propertiesShowHolds: "properties.show.holds",

  //! Property Hold
  propertyHoldList: "property.hold.list",
  propertyHoldCreate: "property.hold.create",
  propertyHoldEdit: "property.hold.edit",
  propertyHoldDelete: "property.hold.delete",
  propertyHoldShow: "property.hold.show",

  //! Buy Contracts
  buyContractsList: "buy.contracts.list",
  buyContractsCreate: "buy.contracts.create",
  buyContractsEdit: "buy.contracts.edit",
  buyContractsDelete: "buy.contracts.delete",
  buyContractsShow: "buy.contracts.show",
  buyContractsShowPrintContracts: "buy.contracts.show.print.contracts",
  buyContractsShowPrintPayments: "buy.contracts.show.print.payments",
  buyContractsShowPay: "buy.contracts.show.pay",
  buyContractsShowPayPrint: "buy.contracts.show.pay.print",
  buyContractsShowTransferPaymentProcessButton:
    "buy.contracts.show.transfer.payment.process.button",
  buyContractsShowUnPayButton: "buy.contracts.show.un.pay.button",

  //! Transfer Contract
  transferContractsList: "transfer.contracts.list",
  transferContractsCreate: "transfer.contracts.create",
  transferContractsDelete: "transfer.contracts.delete",
  transferContractsShow: "transfer.contracts.show",

  //! Cancel Contracts
  cancelContractsList: "cancel.contracts.list",
  cancelContractsCreate: "cancel.contracts.create",
  cancelContractsDelete: "cancel.contracts.delete",
  cancelContractsShow: "cancel.contracts.show",

  //! Upcoming Payments
  upcomingPaymentsList: "upcoming.payments.list",

  //! Customers
  customersList: "customers.list",
  customersCreate: "customers.create",
  customersEdit: "customers.edit",
  customersDelete: "customers.delete",
  customersShow: "customers.show",
  customersShowAttachments: "customers.show.attachments",
  customersShowContracts: "customers.show.contracts",

  //! Lead Visits
  leadVisitsList: "lead.visits.list",
  leadVisitsCreate: "lead.visits.create",
  leadVisitsDelete: "lead.visits.delete",

  //! Lead Customers
  leadCustomersList: "lead.customers.list",
  leadCustomersCreate: "lead.customers.create",
  leadCustomersEdit: "lead.customers.edit",
  leadCustomersDelete: "lead.customers.delete",

  //! Lead Sources
  leadSourcesList: "lead.sources.list",
  leadSourcesCreate: "lead.sources.create",
  leadSourcesEdit: "lead.sources.edit",
  leadSourcesDelete: "lead.sources.delete",

  //! Lead Sub Sources
  leadSubSourcesList: "lead.sub.sources.list",
  leadSubSourcesCreate: "lead.sub.sources.create",
  leadSubSourcesEdit: "lead.sub.sources.edit",
  leadSubSourcesDelete: "lead.sub.sources.delete",

  //! Expenses
  expensesList: "expenses.list",
  expensesCreate: "expenses.create",
  expensesEdit: "expenses.edit",
  expensesDelete: "expenses.delete",
  expensesShow: "expenses.show",
  expensesPrint: "expenses.print",

  //! SmsMessage
  smsMessageList: "smsMessage.List",
  smsMessageCreate: "smsMessage.Create",

  //! Withdraws
  withdrawsList: "withdraws.list",
  withdrawsCreate: "withdraws.create",
  withdrawsEdit: "withdraws.edit",
  withdrawsDelete: "withdraws.delete",
  withdrawsShow: "withdraws.show",
  withdrawsPrint: "withdraws.print",

  //! Deposits
  depositsList: "deposits.list",
  depositsCreate: "deposits.create",
  depositsEdit: "deposits.edit",
  depositsDelete: "deposits.delete",
  depositsShow: "deposits.show",
  depositsPrint: "deposits.print",

  //! Transfers
  transfersList: "transfers.list",
  transfersCreate: "transfers.create",
  transfersEdit: "transfers.edit",
  transfersDelete: "transfers.delete",
  transfersShow: "transfers.show",
  transfersPrint: "transfers.print",

  //! Users
  usersList: "users.list",
  usersShow: "users.show",
  usersProjects: "users.projects",
  usersAddToProject: "users.add.to.project",
  usersPermissions: "users.permissions",

  //! Reports
  reportsLeadVisits: "reports.lead.visits",
  reportsLeadVisitsByDayOfWeek: "reports.lead.visits.by.day.of.week",
  reportsDailyLeadVisits: "reports.daily.lead.visits",
  reportsPerSources: "reports.per.sources",
  reportsProperty: "reports.property",
  reportsTransactions: "reports.transactions",
  reportsExpenses: "reports.expenses",
  reportsWithdraws: "reports.withdraws",
  reportsDeposits: "reports.deposits",
  reportsBuyContracts: "reports.buy.contracts",
  reportsPayment: "reports.payment",
  reportsSafeBoxTransactions: "reports.safe.box.transactions",
  reportsBuyContractsDetails: "reports.buy.contracts.details",
  reportsExpensesByType: "reports.expenses.by.type",
  reportsForgivePrice: "reports.forgive.price",
  reportsPropertyExpense: "reports.property.expense",

  //! Property Types
  propertyTypesList: "property.types.list",
  propertyTypesCreate: "property.types.create",
  propertyTypesEdit: "property.types.edit",
  propertyTypesDelete: "property.types.delete",

  //! Features
  featuresList: "features.list",
  featuresCreate: "features.create",
  featuresEdit: "features.edit",
  featuresDelete: "features.delete",

  //! Property Expense Types
  propertyExpenseTypesList: "property.expense.types.list",
  propertyExpenseTypesCreate: "property.expense.types.create",
  propertyExpenseTypesEdit: "property.expense.types.edit",
  propertyExpenseTypesDelete: "property.expense.types.delete",

  //! Expense Types
  expenseTypesList: "expense.types.list",
  expenseTypesCreate: "expense.types.create",
  expenseTypesEdit: "expense.types.edit",
  expenseTypesDelete: "expense.types.delete",

  //! Withdraw Types
  withdrawTypesList: "withdraw.types.list",
  withdrawTypesCreate: "withdraw.types.create",
  withdrawTypesEdit: "withdraw.types.edit",
  withdrawTypesDelete: "withdraw.types.delete",

  //! Deposit Types
  depositTypesList: "deposit.types.list",
  depositTypesCreate: "deposit.types.create",
  depositTypesEdit: "deposit.types.edit",
  depositTypesDelete: "deposit.types.delete",

  //! Projects
  projectsList: "projects.list",

  //! User Tasks
  userTasksList: "user.tasks.list",
  userTasksCreate: "user.tasks.create",
  userTasksEdit: "user.tasks.edit",
  userTasksDelete: "user.tasks.delete",

  //! Investors
  investorsList: "investors.list",
  investorsCreate: "investors.create",
  investorsEdit: "investors.edit",
  investorsDelete: "investors.delete",
};
