<template>
  <div>
    <div>
      <content-header>{{ $t('createCustomerAttachment') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent>
              <text-input id="title" name="Title" :validate="$v" :required="true" :is-loading="isLoading" />
              <file-input
                  id="attachment"
                  :required="true"
                  :name="$t('Attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <boolean-input
                  id="ShareableForCustomer"
                  :name="$t('sharable')"
                  :default-value="false"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  @switched="e => form.shareableForCustomer = e"
              />
              <boolean-input
                  id="Is360"
                  :name="$t('is360')"
                  :default-value="false"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  @switched="e => form.is360 = e"
              />
              <b-row>
                <b-col>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label"> </label>
                    <button class="btn btn-primary col-md-8" type="submit" :disabled="isLoading" @click="createAndNew">
                      <i class="fas fa-plus"></i>
                      {{ $t('createAndNew') }}
                    </button>
                  </div>
                </b-col>
                <b-col>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label"> </label>
                    <button class="btn btn-success col-md-8" type="submit" :disabled="isLoading" @click="onSubmit">
                      <i class="fas fa-plus"></i>
                      {{ $t('create') }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <is-loading v-if="isLoading" />
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {CustomerAttachment} from "@/models/CustomerAttachment";
import FileInput from "@/components/Inputs/FileInput";
import BooleanInput from "@/components/Inputs/BooleanInput";
import TextInput from "@/components/Inputs/TextInput";

export default {
  name: "CustomerAttachmentCreate",
  components: {IsLoading, ContentHeader, 'v-content': Content, FileInput, BooleanInput, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new CustomerAttachment().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      title: {required},
      attachment: {required},
      shareableForCustomer: {},
      is360: {},
    }
  },
  methods: {
    async onSubmit() {
      await this.submit();
      await this.$router.push({name: 'customers.edit', params: {id: this.$route.params.id}})
    },
    async createAndNew() {
      await this.submit();
      window.location.reload();
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.customerId = this.$route.params.id;
      this.isLoading = true;
      await this.$store.dispatch('customerAttachments/CREATE', this.form).then(() => {
        this.$v.$reset();
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
