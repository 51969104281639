<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row class="text-center" style="margin: 0; font-size: 28px">
        <b-col cols="4"> ماس هیڵس </b-col>
        <b-col cols="4">
          <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 42%"
          ></b-img>
        </b-col>
        <b-col cols="4"> ماس هیلس </b-col>
      </b-row>
      <b-row
        style="
          font-size: 20px;
          margin: 10px;
          border-top: 3px solid #b58151;
          border-bottom: 3px solid #b58151;
          padding: 5px;
        "
      >
        <b-col class="text-right" cols="6"> Email: sales@masshills.com </b-col>
        <b-col class="text-left" cols="6">
          Mob: 07502208181 - 07723807171
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 20px">
        <b-col class="text-right" style="font-size: 25px" cols="4">
          ژمارەی پسوولە:
          <span class="text-danger text-bold">
            {{ voucherNo && voucherNo }}</span
          >
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          { پسوولەی پارە وەرگرتن }
          <br />
          Receipt Voucher
        </b-col>
        <b-col class="text-left" style="font-size: 25px" cols="4">
          رێکەوت: {{ voucherDate && formatDate(voucherDate) }}
          <br />
          {{ datetime && formatDate(datetime, "HH:mm:ss A") }}
        </b-col>
      </b-row>
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          وەرگیرا لە بەڕێز (إستلم من السید / السیدة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          <h4>
            <b>{{ paidName && paidName }}</b>
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Received From:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3"> بڕی پارە (المبلغ) : </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          ${{ amount && Math.round(parseFloat(amount)).toLocaleString() }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="3">
          بڕی پارە بە نووسین (کتابة) :
        </b-col>
        <b-col style="direction: ltr" class="text-center" cols="6">
          {{ amountText }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="3">
          Amount:
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> ژمارەی ڤێلا (رقم الفیلا) : </b-col>
        <b-col class="text-center" cols="4">
          {{ propertyName && propertyName }}
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Property Name:</b-col
        >
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> بە مەبەستی (و ذلك عن) : </b-col>
        <b-col class="text-center" cols="4"> {{ note && note }} </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4"
          >Purpose of receipt</b-col
        >
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center">
        <b-col cols="6">
          واژووی وەرگر | توقیع المستلم
          <br />
        </b-col>
        <b-col cols="6">
          واژووی لێوەرگیراو | توقیع المستلم منە
          <br />
          {{ paidName && paidName }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 50px 0" />
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "VoucherReceiptMassHills",
  props: {
    voucherNo: {
      type: String,
    },
    voucherDate: {
      type: String,
    },
    paidName: {
      type: String,
    },
    amount: {
      type: String,
    },
    purposeOfVoucher: {
      type: String,
    },
    note: {
      type: String,
    },
    accountantName: {
      type: String,
      default: "ناوی ژمێریار",
    },
    propertyName: {
      type: String,
    },
  },
  mounted() {
    mixins.getCurrentDateTimeServer().then((response) => {
      this.datetime = response.datetime;
    });
      mixins
        .getAmountInText(Math.round(parseFloat(this.amount)))
        .then((response) => {
          this.amountText = response;
        });
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      datetime: null,
      amountText: "",
    };
  },
};
</script>

<style scoped>
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
</style>
