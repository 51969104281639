<template>
  <div v-if="hasPermission(permissions.withdrawsCreate)">
    <content-header>{{ $t('createWithdraw') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('paidTo')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <select-input
                id="withdrawType"
                :name="$t('reasonFor')"
                :validate="$v"
                :required="true"
                api-link="withdrawTypes/GET_ALL"
                label="name"
                @itemSelected="e => form.withdrawTypeId = e"
            />
            <select-input
                id="safeBox"
                :name="$t('safeBox')"
                :validate="$v"
                :required="true"
                api-link="safeBoxes/GET_ALL"
                label="name"
                @itemSelected="e => form.safeboxId = e"
            />
            <number-input id="mcPrice" :name="$t('price')" :show-locale-string="true" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-input id="accountant" :name="$t('accountant')" :validate="$v" :is-loading="isLoading" />
            <text-input id="withdrawer" :name="$t('withdrawer')" :validate="$v" :is-loading="isLoading" />
            <date-time-input id="date" :name="$t('date')" :default-value="getCurrentDate()" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {Withdraw} from "@/models/Withdraw";
import SubmitInput from "@/components/Inputs/SubmitInput";
import FileInput from "@/components/Inputs/FileInput";
import SelectInput from "@/components/Inputs/SelectInput";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "WithdrawCreate",
  components: {TextAreaInput, DateTimeInput, NumberInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, FileInput, SelectInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Withdraw().fillAbles),
      isLoading: false,
      selectedItem: {},
      permissions: permissions
    }
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      withdrawTypeId: {required},
      safeboxId: {required},
      mcPrice: {required},
      date: {required},
      accountant: {},
      withdrawer: {},
      note: {},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      delete this.form.mcTotalPrice;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('withdraws/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "withdraws"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
