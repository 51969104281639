import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const transfers = {
    namespaced: true,
    state: {
        transfers: {},
        createdTransfer: {},
        getOneTransfer: {},
        updatedTransfer: {},
    },
    mutations: {
        SET_SAFEBOX_TRANSFERS (state, value) { state.transfers = value },
        SET_CREATED_SAFEBOX_TRANSFER (state, value) {state.createdTransfer = value},
        SET_GET_ONE_SAFEBOX_TRANSFER (state, value) {state.getOneTransfer = value},
        SET_UPDATED_SAFEBOX_TRANSFER (state, value) {state.updatedTransfer = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`SafeboxTransfers` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_SAFEBOX_TRANSFERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_SAFEBOX_TRANSFERS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`SafeboxTransfers/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_SAFEBOX_TRANSFER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_SAFEBOX_TRANSFER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`SafeboxTransfers`, form)
                    .then((response) => {
                        commit('SET_CREATED_SAFEBOX_TRANSFER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_SAFEBOX_TRANSFER', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`SafeboxTransfers/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_SAFEBOX_TRANSFER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_SAFEBOX_TRANSFER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`SafeboxTransfers/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        transfers: state => state.transfers,
        createdTransfer: state => state.createdTransfer,
        getOneTransfer: state => state.getOneTransfer,
        updatedTransfer: state => state.updatedTransfer,
    }
}

export default transfers;
