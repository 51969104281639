<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row class="text-center" style="margin: 0;font-size: 28px">
        <b-col cols="4">
          شەقڵاوە ڤیو
        </b-col>
        <b-col cols="4">
          <b-img :src="systemImage" fluid alt="Responsive image" style="width: 58%;"></b-img>
        </b-col>
        <b-col cols="4">
          شەقڵاوە ڤیو
        </b-col>
      </b-row>
      <b-row style="font-size: 20px;margin: 10px;border-top: 3px solid #B58151;border-bottom: 3px solid #B58151;padding: 5px;">
        <b-col class="text-right" cols="6">
          Email: info@shaqlawaview.com
        </b-col>
        <b-col class="text-left" cols="6">
          Mob: 07506334000
        </b-col>
      </b-row>
      <b-row style="margin: 0;font-size: 20px">
        <b-col class="text-right" style="font-size: 25px" cols="4">
          ژمارەی پسوولە: <span class="text-danger text-bold"> {{ voucherNo && voucherNo }}</span>
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          پسوولەی وەرگرتن
          <br />
          Receipt Voucher
        </b-col>
        <b-col class="text-left" style="font-size: 25px" cols="4">
          رێکەوت: {{ voucherDate && formatDate(voucherDate) }}
        </b-col>
      </b-row>
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="6">
          وەرگیرا لە (إستلمت من) :
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="6">
          Received From: {{ paidName && paidName }}
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="6">
          بڕی (المبلغ) :
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="6">
          Amount: {{ amount && amount.toLocaleString() }}
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="6">
          بە مەبەستی (و ذلك عن) :
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="6">
          Purpose of receipt: {{ purposeOfVoucher && purposeOfVoucher }}
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="6">
          تێبینی (الملاحظة) :
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="6">
          Note: {{ note && note }}
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px" class="text-center">
        <b-col cols="6">
          واژووی وەرگر
          <br />
          {{ accountantName && accountantName }}
        </b-col>
        <b-col cols="6">
          واژووی پێدەر
          <br />
          {{ paidName && paidName }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #B58151;height: 0; margin: 40px 0"/>
  </div>
</template>

<script>
export default {
  name: "VoucherReceiptShaqlawaView",
  props: {
    voucherNo: {
      type: String
    },
    voucherDate: {
      type: String
    },
    paidName: {
      type: String
    },
    amount: {
      type: String
    },
    purposeOfVoucher: {
      type: String
    },
    note: {
      type: String
    },
    accountantName: {
      type: String,
      default: 'ناوی ژمێریار'
    },
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo()
    }
  },
}
</script>
