<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right" :class="{'required-field': required}">{{ name }}</label>
    <div :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}">
      <div class="input-group">
        <flat-pickr v-model="validate[form][id].$model" :config="config" class="form-control" placeholder="Select date" name="date"/>
        <div class="input-group-append">
          <button class="btn btn-default" type="button" title="Toggle" data-toggle>
            <i class="fas fa-calendar">
              <span aria-hidden="true" class="sr-only">Toggle</span>
            </i>
          </button>
        </div>
      </div>
      <div v-if="!validate[form][id].required && required" class="invalid-feedback">{{ name }} {{ $t('isRequired') }}</div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "DateTimeInput",
  components: {flatPickr},
  props: {
    form: {
      type: String,
      default: 'form'
    },
    id: {
      type: String
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      default: new Date().toISOString()
    },
    time: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    validate: {
      type: Object
    },
    required: {
      type: Boolean
    }
  },
  mounted() {
    if (this.defaultValue !== '') {
      this.validate[this.form][this.id].$model = this.defaultValue
    }
  },
  data() {
    return {
      config: {
        wrap: true,
        altFormat: this.time ? 'Y-m-d H:i:s' : 'Y-m-d',
        dateFormat: this.time ? 'Y-m-d H:i:s' : 'Y-m-d',
        defaultHour: "24",
        defaultDate: this.defaultValue,
        enableTime: this.time
      }
    }
  }
}
</script>
<style scoped>
.form-control:disabled, .form-control[readonly] {
  background-color: #FFFFFF !important;
}
</style>
