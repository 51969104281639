<template>
  <div>
    <content-header>{{ $t('createPropertyAttachment') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent>
            <text-input id="title" :name="$t('title')" :validate="$v" :required="true" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :required="true"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <boolean-input id="ShareableForCustomer" :name="$t('sharable')" :default-value="false" :checked-label="$t('yes')" :un-checked-label="$t('no')" @switched="e => form.ShareableForCustomer = e"/>
            <boolean-input id="Is360" :name="$t('is360')" :default-value="false" :checked-label="$t('yes')" :un-checked-label="$t('no')" @switched="e => form.Is360 = e"/>
            <b-row>
              <b-col>
                <div class="form-group row">
                  <label class="col-md-2 col-form-label"> </label>
                  <button class="btn btn-primary col-md-8" type="submit" :disabled="isLoading" @click="createAndNew">
                    <i class="fas fa-plus"></i>
                    {{ $t('createAndNew') }}
                  </button>
                </div>
              </b-col>
              <b-col>
                <div class="form-group row">
                  <label class="col-md-2 col-form-label"> </label>
                  <button class="btn btn-success col-md-8" type="submit" :disabled="isLoading" @click="onSubmit">
                    <i class="fas fa-plus"></i>
                    {{ $t('create') }}
                  </button>
                </div>
              </b-col>
            </b-row>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {PropertyAttachment} from "@/models/PropertyAttachment";
import FileInput from "@/components/Inputs/FileInput";
import TextInput from "@/components/Inputs/TextInput";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "PropertyAttachmentCreate",
  components: {BooleanInput, TextInput, IsLoading, ContentHeader, 'v-content': Content, FileInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new PropertyAttachment().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      title: {required},
      attachment: {required},
      ShareableForCustomer: {},
      Is360: {},
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      await this.submit();
      await this.$router.push({name: 'properties.edit', params: {id: this.$route.params.id}, hash: '#attachments'})
    },
    async createAndNew() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      await this.submit();
      window.location.reload();
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.propertyId = this.$route.params.id;
      this.form.ShareableForCustomer = this.form.ShareableForCustomer === true;
      this.form.is360 = this.form.is360 === true;
      this.isLoading = true;
      await this.$store.dispatch('propertyAttachments/CREATE', this.form).then(() => {
        this.$v.$reset();
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
