<template>
  <div>
    <BootstrapTable
      :rows="rows"
      :columns="columns"
      :config="config"
      :total-rows="totalRecords"
      :actions="actions"
      @on-create="
        $router.push({
          name: 'property.feature.create',
          params: { id: $route.params.id },
        })
      "
    >
      <template slot="empty-results">{{ $t("noResultsFound") }}</template>
      <template slot="pagination-info" slot-scope="props">
        <template v-if="props.currentPageRowsLength !== 0"
          >{{ totalRecords }} {{ $t("totalRecords") }}</template
        >
        <template v-else>{{ $t("noResultsFound") }}</template>
      </template>
      <template slot="extraPrice" slot-scope="props">
        {{
          projectCurrencySymbol() +
          props.row.extraPrice.toLocaleString() +
          projectCurrencySymbol(true)
        }}
      </template>
      <template slot="controls" slot-scope="props">
        <control-buttons
          :props="props"
          delete-link="propertyFeatures/DELETE"
          v-on:successAction="loadData(true)"
        />
      </template>
    </BootstrapTable>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import { PropertyFeature } from "@/models/PropertyFeature";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "PropertyFeaturesList",
  components: { IsLoading, ControlButtons, BootstrapTable },
  props: {
    features: {
      type: Array,
    },
  },
  watch: {
    features: function (value) {
      this.rows = value;
    },
  },
  data() {
    return {
      isLoading: false,
      model: new PropertyFeature(),
      columns: [],
      rows: this.features,
      totalRecords: 0,
      actions: tableActions(true),
      config: tableConfigure(this.$t("propertyFeatures"), false, false),
      serverParams: serverParams,
      selectedRows: [],
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.$emit("loadAll");
    },
  },
};
</script>
