<template>
  <div dir="rtl">
    <hr class="border border-secondary border-1 mx-3" />

    <main class="text-center px-4">
      <h6 class="font-weight-bold">
        عقد بيع الوحدات السكنية في مشروع يونيك تاورز
      </h6>
      <h6 class="font-weight-bold">(البيع بالنقد)</h6>

      <!-- ? Table one -->
      <div class="row mt-4">
        <div class="col-6">
          <table class="table table-bordered text-right">
            <tr>
              <td>تاريخ تنفيذ العقد</td>
              <td style="width: 35%" class="text-center">
                {{ formatDate(buyContract.buyingDate) }}
              </td>
            </tr>
            <tr>
              <td>رقم العقد</td>
              <td class="text-center">{{ buyContract.contractNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-bordered text-right">
            <tr>
              <td>الشركة المنفذة</td>
              <td class="text-center">رادەر</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ? Table Two-->
      <div class="row text-right pt-3">
        <div class="col">
          <p>
            الطرف الأول (البائع): شركة رادەر للتجارة العامة / المحدودة المسجل
            بالرقم (23289) في 17/8/2016
          </p>
          <table class="table table-bordered text-right">
            <tr>
              <td>السيد</td>
              <td class="text-center">
                بهمن عبداللە سلیمان / المدير المفوض للشركة اضافة لوظيفته
              </td>
            </tr>
            <tr>
              <td>رقم الموبايل</td>
              <td class="text-center" dir="ltr">
                0750 446 0438 – 0750 537 3000
              </td>
            </tr>
            <tr>
              <td>العنوان</td>
              <td class="text-center">
                مركز مبيعات يونيك تاورز – طريق بيرمام / قرب مطعم هرور
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ? Table Three-->
      <div class="row text-right pt-3">
        <div class="col">
          <p>الطرف الثاني (المشتري):</p>
          <table class="table table-bordered text-right">
            <tr>
              <td>السيد</td>
              <td class="text-center">
                {{
                  `${customer.firstName} ${customer.middleName}
                  ${customer.lastName}`
                }}
              </td>
            </tr>
            <tr>
              <td>رقم الهوية</td>
              <td class="text-center">{{ customer.idcard }}</td>
            </tr>
            <tr>
              <td>رقم الموبايل</td>
              <td dir="ltr" class="text-center">
                {{ replacePhone(customer.phone) }}
              </td>
            </tr>
            <tr>
              <td>العنوان الايميل</td>
              <td dir="ltr" class="text-center">{{ customer.email }}</td>
            </tr>
            <tr>
              <td>العنوان</td>
              <td class="text-center">
                {{ customer.address }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <hr class="border border-secondary border-1 mx-3" />

      <!-- ?section one-->
      <div class="text-right mx-2 pt-2">
        <p class="font-weight-bold mb-2">القسم الأول (مقدمة العقد)</p>
        <p>
          يقوم الطرف الأول شركة (رادەر) كشخص معنوي (بائع) ببناء مشروع للسكن
          العمودي (يونيك تاورز) علي قطعة الارض المرقمة (6/3) مقاطعة 92 شاويس،
          وبناءً على رغبة ونية الطرف الثاني (المشتري) في شراء وحدة سكنية في
          مشروع يونيك تاورز وفق الخصائص الفنية والهندسية المشار اليها من الطرف
          الأول وبالمواصفات المرفقة، وبعد ابداء الطرفين استعدادهم لاجراء العقد
          تم الاتفاق على ان يقوم الطرف الأول ببيع وحدة سكنية والتي في قيد البناء
          الى الطرف الثاني بموجب التفاصيل المدرجة ادناه:
        </p>
      </div>
      <br /><br /><br />

      <!-- ?section two-->

      <div class="text-right mx-2 pt-2">
        <p class="font-weight-bold mb-2">
          القسم الثاني (تفاصيل الوحدة السكنية)
        </p>
        <p>اربيل / مركز المدينة / شارع بيرمام</p>
        <p>رقم قطعة الارض المقام عليها المشروع: رقم (6/3) مقاطعة 92 شاويس</p>
        <p>
          بناية ({{ property.zone }}) طابق ({{ property.block }}) / الشقة رقم
          ({{ property.name.split("-")[2] }})
        </p>
        <p>
          مجموع المساحة العامة (كروس) شقة: ( {{ property.buildUpArea }} م2).
        </p>
      </div>

      <!-- ?section three-->
      <div class="text-right mx-2">
        <p class="text-right font-weight-bold mb-2">
          القسم الثالث (سعر الوحدة السكنية)
        </p>
        <ul class="px-5">
          <li>
            قيمة الشقة ({{ property.meterPrice }}){{ this.meterPriceText }}
            دينار عراقي لكل متر مربع ومجموع قيمة الشقة يبلغ (
            {{ property.meterPrice }} *{{ property.buildUpArea }}م =
            {{ buyContract.buyingPrice.toLocaleString() }}) ({{
              this.totalPriceText
            }}
            دينار عراقي)
          </li>
        </ul>
      </div>
      <!-- ?section four -->
      <div class="text-right mx-2">
        <p class="font-weight-bold mb-2">القسم الرابع (التزامات الطرف الاول)</p>
        <ol class="px-5">
          <li>
            يقوم الطرف الأول ببيع الوحدة السكنية المشار اليها اعلاه في مرحلة
            البناء الى الطرف الثاني بالمبلغ المتفق عليه بين الطرفين المحدد في
            العقد.
          </li>
          <li>
            يقوم الطرف الاول بانهاء الوحدة السكنية خلال (36) شهر من العمل، تبدأ
            من تاريخ توقيع العقد.
          </li>
          <li>
            يقوم الطرف الاول بتجهيز مكيف (سبلت) وكاونتر لمطبخ الوحدة السكنية.
          </li>
          <li>
            بعد الانتهاء من بناء الوحدة السكنية وتنفيذ التزامات الطرف الثاني
            وموافقة الجهات المختصة، يقوم الطرف الاول بعد انتهاء الاجراءات
            الرسمية بتسجيل الشقة بأسم الطرف الثاني في مديرية التسجيل العقاري
            الخاص بتسجيل الوحدات السكنية.
          </li>
          <li>
            الطرف الاول غير مسؤول عن التأخير في تسجيل الوحدة السكنية في حالة اذا
            ما كان السبب الجهات المختصة بتسجيل الوحدات السكنية أو الطرف الثاني
            (المشتري) أو عند وجود اي سبب قانوني اخر يعيق تسجيل الوحدة السكنية
            بأسم المشتري.
          </li>
          <li>
            يجب على الطرف الاول أو اي شخص مخول من الطرف الاول تقديم الخدمات
            المشار اليها في هذه الفقرة الى الطرف الثاني بالشكل الصحيح وبمواصفات
            المنطقة ما عدا الحالات غير الطبيعية وغير المتوقعة والتي بعد اكمال
            الوحدات السكنية تقوم مديرية ادارة المشروع بتنظيم هذه الخدمات
            واسعارها.
          </li>
          <li>
            تتكون الخدمات من:
            <ul>
              <li>
                تنظيف خارج الشقة كالممر والسلالم، موقف السيارات، تنظيف وحماية
                الحدئق العامة للمشروع، تنظيف المجاري والفضلات وازالتها الى خارج
                المشروع.
              </li>
              <li>
                حماية وتنظيف المصاعد الكهربائية، حماية الانارة الخارجية للشقة،
                مدخل الممرات وموقف السيارات، ساحة العاب الاطفال، حماية الشكل
                الخارجي للشقة، حماية نظام الحريق والانذار
              </li>
              <li>صيانة كهرباء المشروع والمولدة وكاميرات المراقبة</li>
              <li>خدمة حماية امن المشروع.</li>
            </ul>
          </li>
          <li>
            يقوم الطرف الاول بتبليغ الطرف الثاني عن طريق العنوان أو رقم الهاتف
            المشار اليه في العقد عند الحاجة.
          </li>
          <li>
            بعد تسليم الوحدة السكنية الى الطرف الثاني، في حالة وجود اي نقص في
            الوحدة سيتم معالجته وفق المخطط المتفق عليه خلال (30) ثلاثون يوم من
            تاريخ تسليم الوحدة، ويفقد الطرف الثاني هذا الحق في حالة عدم استلامه
            الوحدة في الموعد المحدد.
          </li>
        </ol>
      </div>

      <!-- ?section five -->
      <div class="text-right mx-2">
        <p class="font-weight-bold mb-2">
          القسم الخامس (التزامات الطرف الثاني)
        </p>
        <ol class="px-5">
          <li>
            ابدى الطرف الثاني موافقته على شراء الوحدة السكنية بعد قراءته للمشروع
            بكل تفاصيلها، وفق الخريطة المرفقة والتفاصيل الواردة في العقد.
          </li>
          <li>
            الطرف الثاني ملزم بدفع قيمة الشقة الى الطرف الاول في مركز مبيعات
            الطرف الاول أو في الرقم الحسابي المحدد من قبل الطرف الاول.
          </li>
          <li>
            الطرف الثاني ملزم بجميع مصاريف تسجيل الوحدة السكنية كالرسومات،
            الضرائب المتعلقة بالتسجيل، اجور التسجيل وجميع مصاريف المعاملة
            والمصاريف التي من الممكن فرضها في المستقبل.
          </li>
          <li>
            الطرف الثاني ملزم باستلام الشقة بعد انتهاء الاعمال في داخل المشروع،
            مباشرة بعد تبليغه بالعنوان او رقم الهاتف (موبايل) المسجل في العقد او
            عن طريق الرسائل القصيرة.
          </li>
          <li>
            الطرف الثاني ملزم بدفع اجور الماء، الكهرباء، الغاز والخدمات المشار
            اليها في (القسم الرابع / الفقرة 7) بعد تسليم الوحدة.
          </li>
          <li>
            يُعتبر الطرف الثاني مستلما للشقة دون اي نقص بعد استلامه للشقة من
            الطرف الاول بتنازله وادائه الغرامات والتعويض.
          </li>
          <li>
            ليس للطرف الثاني القيام بأي تغيير في الشكل الخارجي والمكونات الثابتة
            للشقة كالحائط الكونكريتي والحائط المشترك بين الشقق والخدمات والمطبخ
            والحمام والمرافق الصحية والشبابيك.
          </li>
          <li>
            اذا رغب الطرف الثاني القيام بتغيير موافق عليه في داخل الوحدة يجب
            عليه اخد كتاب موافقة رسمية من الطرف الاول ويتم عمل التغيرات تحت
            اشراف الطرف الاول وعلى عاتق الطرف الثاني وبخلافه يتحمل المسؤولية.
          </li>
          <li>
            الطرف الثاني ملزم باستخدام الوحدة السكنية لغرض السكن أو ايجاره لنفس
            الهدف واستخدامه بشكل يليق باداب واعراف المجتمع.
          </li>
          <li>
            يتعهد الطرف الثاني باحترام والالتزام بجميع القواعد والتعليمات
            الصادرة من ادارة المشروع.
          </li>
          <li>
            الطرف الثاني ملزم بدفع اجور الخدمات المحدد من قبل الطرف الاول حتى
            بعد تسجيل الوحدة باسمه بشكل رسمي في قسم تسجيل العقارات، وعليه
            الالتزام بكل القرارات الادارية الصادرة من ادارة المشروع.
          </li>
          <li>
            يستطيع الطرف الثاني بيع شقته الي طرف الثالث بشرط التزامه بشروط العقد
            والتزامه بدفع المصاريف الادارية والعقدية المحدد من قبل الطرف الاول
            ويحق للطرف الثاني بيع الشقة بعد انتهاء هيكل البناية فقط اي لا يحق له
            بيعها قبل انتهاء هيكل البناية.
          </li>
          <li>
            يجب على الطرف الثاني اخذ موافقة مسبقة من الطرف الاول في اي تصرف بيع
            وشراء على الوحدة وبخلافه يتم اعتبار العقد ملغياَ وللطرف الاول الحق
            في عدم الموافقة على ذلك.
          </li>
          <li>
            ليس للطرف الثاني باي شكل من الاشكال استعمال الجهاز اللاسلكي، الهوائي
            (اليار)، شبكة انترنيت خارجية، مولدة كهربائية او جهاز تبريد (مبردة).
          </li>
          <li>
            الطرف الثاني ملزم بتبليغ الطرف الاول في حالة تغيير رقم الموبايل أو
            العنوان المشار اليه في العقد.
          </li>
        </ol>
      </div>

      <!-- ?six section -->
      <div class="text-right mx-2">
        <p class="font-weight-bold mb-2">القسم السادس (طرق الاتصال والتبليغ)</p>
        <p class="px-5">
          يبدي الطرف الثاني موافقته على ان اي رسالة او تبليغ تصل اليه من الطرف
          الاول بموجب هذا العقد يكون باللغة العربية، الانكليزية او الكردية وبشكل
          شخصي او عن طريق خدمة البريد او البريد الالكتروني المسجل في العقد او
          بواسطة الرسائل القصيرة الى الرقم المسجل في العقد فانه يعتبر مستلما وفق
          الشروط المدرجة ادناه:
        </p>
        <ol class="px-5">
          <li>اذا تم ارساله وايصاله بشكل شخصي.</li>
          <li>
            ثلاثة ايام بعد ارساله عن طريق خدمة الايميل المسجل في العقد، او عن
            طريق خدمة الرسائل القصيرة (اس ام اس).
          </li>
          <li>
            في حالة ارساله بواسطة برامج شبكات التواصل الاجتماعية (فايبر، واتس اب
            وغيرها) (يجب ان يسجل الرقم في العقد مسبقا).
          </li>
          <li>
            في حالة ارساله عن طريق البريد الالكتروني (الايميل) ويظهر من البريد
            استلامه للايميل.
          </li>
        </ol>
      </div>

      <!-- ?seven section -->
      <div class="text-right mx-2">
        <p class="font-weight-bold mb-2">
          القسم السابع (الغرامات والغاء العقد)
        </p>
        <p class="px-5"></p>
        <ol class="px-5">
          <li>
            في حالة قيام الطرف الثاني بعمل تغيير في الوحدة دون اخذ موافقة رسمية
            من الطرف الاول، فانه ملزم بدفع غرامة بمبلغ (8,000,000) ثمانية ملايين
            دينار عراقي الى الطرف الاول واعادة حالة الوحدة الى شكله السابق وعلى
            حساب الطرف الثاني.
          </li>
          <li>
            في حالة تأخر الطرف الاول عن تسليم الوحدة الى الطرف الثاني في المدة
            المشار اليها اعلاه بعد خصم ايام ايقاف العمل، فأن الطرف الاول ملزم
            بدفع غرامة تأخيرية بمبلغ (1,000,000) مليون دينار عن كل شهر تأخير،
            بشرط ان لايزيد المبلغ الكلي عن (2%) من قيمة الشقة ويتم تنفيذ هذا
            الالتزام عندما يتم تسليمه المفتاح، ولايكون للطرف الثاني طلب غرامة
            قبل ذلك، بشرط ان لايكون سبب التأخير الحرب، الارهاب، مرض معدي،
            الكوارث الطبيعية، ازمة اقتصادية، تأخر الموافقة الحكومية، تأخر
            الاعمال الحكومية، تغيير التعليمات الحكومية، اعلان حالة الطوارئ من
            قبل الحكومة، منع التجول... الخ في هذه الحالات لایتم دفع الغرامة.
          </li>
        </ol>
      </div>

      <!-- ?eight section -->
      <div class="text-right mx-2">
        <p class="font-weight-bold mb-2">القسم الثامن (معالجة المشاكل)</p>
        <p class="px-5"></p>
        <ul class="px-5">
          <li>
            في حالة وجود اي مشكلة سيتم معالجته بالتحكيم، واذا لم يعالج سيتم
            اللجوء الى محكمة بداءة مدينة اربيل لمعالجة المشكلة بموجب القوانين
            النافذة.
          </li>
        </ul>
      </div>
      <p class="text-right">
        يتكون هذا العقد من أربعة صفحات يتضمن ثمانية اقسام غير قابل للتجزئة تم
        توقيعه بتاريخ ( {{ formatDate(buyContract.buyingDate) }}) في ( بغداد )
        بين الطرف الاول (البائع) والطرف الثاني (المشتري). ویتم تصديقه في مركز
        الرئيسي في أربیل.
      </p>
      <!-- ?Table fifve -->
      <div class="row text-right p-0 m-0">
        <div class="col-6">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="p-1">الطرف الاول / البائع</td>
            </tr>
            <tr>
              <td class="p-1" style="width: 25%">الاسم الكامل:</td>
              <td class="p-1 text-center">بهمن عبداللە سلیمان</td>
            </tr>
            <tr>
              <td colspan="2" class="p-1">
                عن/ المدير المفوض للشركة اضافة لوضيفته
              </td>
            </tr>
            <tr>
              <td
                class="p-1"
                style="width: 10%; height: 4rem; vertical-align: middle"
              >
                التوقيع
              </td>
              <td rowspan="2" class="p-1">&nbsp;</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="p-1">الطرف الثاني / المشتري</td>
            </tr>
            <tr>
              <td class="p-1" style="width: 25%">الاسم الكامل:</td>
              <td class="p-1 text-center">
                {{
                  `${customer.firstName} ${customer.middleName}
              ${customer.lastName}`
                }}
              </td>
            </tr>
            <tr>
              <td class="p-1" style="width: 20%">الهوية</td>
              <td class="p-1 text-center">{{ customer.idcard }}</td>
            </tr>
            <tr>
              <td
                class="p-1"
                style="width: 10%; height: 4rem; vertical-align: middle"
              >
                التوقيع
              </td>
              <td rowspan="2" class="p-1">&nbsp;</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ?Attachment -->
      <div class="text-center py-4 my-4">
        <p class="text-right">المرفقات:</p>
        <ul style="font-size: 0.7rem" class="text-right">
          <li>خريطة ومخطط الشقة في المشروع.</li>
          <li>
            هوية الاحوال المدينة وشهادة الجنسية الحافية و بطاقة سكن المشتري.
          </li>
          <li>وصل مبلغ النقود.</li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
import { HTTP } from "../../../plugins/axios";

export default {
  name: "ArabicContractUniqueTowersCashiqd",
  props: {
    contract: {},

    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    buyContract: {},
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
  data() {
    return {
      meterPriceText: 0,
      totalPriceText: 0,
    };
  },
  async mounted() {
    // if(this.contract.paymentProcess){
    //   this.contract.paymentProcess.filter((el) => el.isItKeyPayment === false ? this.paymentProccessFiltered.push(el) : "");
    // }
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.buyContract.property.meterPrice}&suffix=false`
    )
      .then((resp) => {
        this.meterPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.buyContract.buyingPrice}&suffix=false`
    )
      .then((resp) => {
        this.totalPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
};
</script>

<style scoped>
table,
tr,
td {
  border-width: 2px !important;
  border-color: rgb(100, 100, 100) !important;
  font-weight: bold !important;
}
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
}

strong {
  font-weight: bold;
}

* {
  font-size: large;
  /* text-align: justify !important; */
}
</style>
