import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const leadCustomers = {
    namespaced: true,
    state: {
        leadCustomers: {},
        createdLeadCustomer: {},
        getOneLeadCustomer: {},
        updatedLeadCustomer: {},
    },
    mutations: {
        SET_LEAD_CUSTOMERS (state, value) { state.leadCustomers = value },
        SET_CREATED_LEAD_CUSTOMER (state, value) {state.createdLeadCustomer = value},
        SET_GET_ONE_LEAD_CUSTOMER (state, value) {state.getOneLeadCustomer = value},
        SET_UPDATED_LEAD_CUSTOMER (state, value) {state.updatedLeadCustomer = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/LeadCustomers` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_LEAD_CUSTOMERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_CUSTOMERS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadCustomers/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_LEAD_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_LEAD_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.post(`/LeadCustomers`, form)
                    .then((response) => {
                        commit('SET_CREATED_LEAD_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_LEAD_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.put(`/LeadCustomers/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_LEAD_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_LEAD_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/LeadCustomers/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        leadCustomers: state => state.leadCustomers,
        createdLeadCustomer: state => state.createdLeadCustomer,
        getOneLeadCustomer: state => state.getOneLeadCustomer,
        updatedLeadCustomer: state => state.updatedLeadCustomer,
    }
}

export default leadCustomers;
