<template>
  <div class="contract text-right" dir="rtl">
    <!-- <div class="first-page"> -->
    <!-- <div>
        <div class="d-flex justify-content-between align-content-center">
          <div>
            <h1 class="BlueColor">شركة کاندل</h1>
            <h2 class="GoldColor">مشروع کاندل السکني</h2>
          </div>
          <div>
            <img
              class="logo"
              src="https://i.ibb.co/pbb0X0H/candle-logo-copy.png"
              alt=""
            />
          </div>
          <div dir="ltr" class="customFont">
            <h1 class="BlueColor">Candle Company</h1>
            <h2 class="GoldColor">Candle Residential Project</h2>
          </div>
        </div>
        <hr class="hr m-2" />
      </div> -->
    <h1
      style="text-align: center !important; font-size: 25px"
      class="text-center text-bold"
    >
      عقد بيع شقة بالنقد
    </h1>
    <p class="lead" style="text-align: right">
      <strong>رقم العقد/</strong> {{ buyContract.contractNumber }}
    </p>
    <p class="lead" style="text-align: right">
      <strong>التاریخ/</strong> {{ formatDate(buyContract.buyingDate) }}
    </p>
    <p class="lead" style="text-align: right">
      إنه في يوم الموافق ( {{ formatDate(buyContract.buyingDate) }} ) وفي محافظة
      اربيل / اقليم كوردستان / العراق تم الاتفاق بين كل من:
    </p>
    <p class="lead" style="text-align: right">
      <strong>الطرف الأول/</strong> البائع/ <strong>شركة (Candle)</strong>، و
      يمثله المدير المفوض السيد (رزگار عمر قادر) إضافة لوظيفته ، و المسجل برقم
      (3103) لدى دائرة تسجيل الشركات أربيل بتاريخ (30/8/2006)
    </p>
    <p class="lead" style="text-align: right" v-if="!customer.isSharedCustomer">
      <span
        ><strong>الطرف الثاني/</strong> المشتري / السيد/السيدة
        <strong
          >({{
            customer.firstName +
            " " +
            customer.middleName +
            " " +
            customer.lastName
          }})</strong
        ></span
      >
      <span> تولد /{{ formatDate(customer.dob) }}</span>
      <span> يحمل ({{ customer.idcard }})</span>
      <span> العنوان الدائم ({{ customer.address }})</span>
      <span> رقم الموبايل: {{ replacePhone(customer.phone) }}</span>
    </p>
    <p class="lead" style="text-align: right" v-else>
      <strong>الطرف الثاني/</strong> <br />
      1- المشتري / السيد/السيدة
      <strong>({{ customer.firstName }})</strong>
      <span> تولد /{{ formatDate(customer.dob) }}</span>
      <span> يحمل ({{ separatorString(customer.idcard)[0] }})</span>
      <span> العنوان الدائم ({{ separatorString(customer.address)[0] }})</span>
      <span> رقم الموبايل: {{ replacePhone(customer.phone) }}</span>
      <br />

      2- المشتري / السيد/السيدة
      <strong>({{ customer.lastName }})</strong>
      <span> تولد /{{ formatDate(customer.howCame) }}</span>
      <span> يحمل ({{ separatorString(customer.idcard)[1] }})</span>
      <span> العنوان الدائم ({{ separatorString(customer.address)[1] }})</span>
      <span> رقم الموبايل: {{ replacePhone(customer.phone2) }}</span>
    </p>
    <h4 style="text-align: right">
      بعد أن أقر الطرفان بأهليتهما للتعاقد والتصرفات القانونية،اتفقا على البنود
      المدرجة أدناه :-
    </h4>
    <p class="lead" style="text-align: right">
      <strong>البند الأول:</strong> باع الطرف الأول إلى الطرف الثاني في مشروع
      (Candle) في البناية (<strong>{{ property.zone }}</strong
      >) في الطابق (<strong>{{ property.block }}</strong
      >) الشقة رقم (<strong>{{ property.name.split("-")[1] }}</strong
      >) والمشيدة على جزء من القطعة المرقمة (32/8853 ك 44 وارش) والبالغ
      مساحتها(<strong>{{ property.buildUpArea }}</strong
      >) متراً مربعا وحسب الخريطة المرفقة بهذا العقد وهي جزء لا يتجزأ من هذا
      العقد بمبلغ اجمالي وقدره (<strong
        >${{ buyContract.buyingPrice.toLocaleString() }}</strong
      >) لاغير نقدا ويتم تسديدها كما مذكور في البند الثاني.
    </p>
    <p>
      <strong>البند الثاني</strong>
      : قبض الطرف الأول کامل بدل شقة البالغ ({{
        buyContract.buyingPrice.toLocaleString()
      }}) دولارا أمریکی نقدا و المشار الیە فی البند الأول
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثالث</strong>: يلتزم الطرف الأول بتسيلم الوحدة السكنية الي
      الطرف الثاني مجهزة (بتبرید و غاز مرکزی و کاونتر مطبخ و طباخ و فرن و مرشحة
      مطبخ مع تجهیزات الحمامات و الصحیات اللازمة).
    </p>
    <!-- <p class="lead" style="text-align: right">
      <strong>البند الرابع:</strong> على الطرف الثاني دفع الأقساط المترتبة بذمته
      حسب مواعيد استحقاقها لدى المصرف او لدى مركز مبيعات الطرف الاول وفي حالة
      رغبة الطرف الأول بتغيير المصرف فعلى الطرف الثاني الالتزام بذلك وايداع الأ
      قساط لدى المصرف الذي يحدده الطرف الأول.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الخامس:</strong> على الطرف الثاني تسديد و تسليم قسط المفتاح
      بعد (30) ثلاثون يوما من تأريخ تبلغه باستلام الشقة وإذا امتنع عن تسديد هذا
      القسط خلال هذه المدة فيحق للطرف الأول المطالبة بحقوقه المذكورة في البند
      الثاني من هذا العقد.
    </p>
    <p v-if="!customer.isSharedCustomer" class="lead" style="text-align: right">
      <strong>البند السادس:</strong> إذا دفع الطرف الثاني المقدمة وجزء من
      الأقساط وأمتنع عن دفع الباقي لأي سبب كان خلال (7) سبعة أيام من تاريخ
      استحقاقه حسب جدول الأقساط المذكورة في البند الثاني، فللطرف الأول الخيار
      بين فسخ العقد والاكتفاء بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف
      الثاني بباقي الأقساط أو مطالبة الطرف الثاني بالغرامة التأخيرية وبمبلغ
      (15$) خمسة عشرة دولار أمريكي لاغير عن كل يوم تأخير.
    </p>
    <p v-else>
      <strong>البند السادس:</strong> إذا دفع الطرف الثاني المقدمة وجزء من
      الأقساط وأمتنع عن دفع الباقي لأي سبب كان خلال (7) سبعة أيام من تاريخ
      استحقاقه حسب جدول الأقساط المذكورة في البند الثاني، فللطرف الأول الخيار
      بين فسخ العقد والاكتفاء بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف
      الثاني بباقي الأقساط ( تكافلا وتضامنا ) أو مطالبة الطرف الثاني ( تكافلا
      وتضامنا ) بالغرامة التأخيرية وبمبلغ (15$) خمسة عشرة دولار أمريكي لاغير عن
      كل يوم تأخير.
    </p> -->
    <p class="lead" style="text-align: right">
      <strong>البند الرابع:</strong> يلتزم الطرف الأول بتسليم الشقة بعد (36) ستة
      و ثلاثون شهراً من تاريخ توقيع العقد، ويحق للطرف الثاني ان يطالب الطرف
      الأول بالغرامات التأخيرية وبمبلغ (10$) عشرة دولار أمريكي لاغير عن كل يوم
      تأخير بعد مرور (6) أشهر من الموعد المتفق عليه للتسليم على أن لا تزيد عن
      نسبة (2%) إثنان بالمئة من المبلغ الاجمالي للشقة أن ينفذ الطرف الثاني كامل
      التزاماته ولم يتأخر في دفع الأقساط ولو ليوم واحد.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الخامس:</strong> يقر الطرف الثاني بأنه عاين الشقة موضوع
      البيع المعاينة التامة على الخريطة النافية للجهالة وأنه وضع يده عليها
      بحالتها على الخريطة دون أن يحق له الرجوع على الطرف الأول بأي شيء بسبب ذلك.
    </p>
    <!-- <p class="lead" style="text-align: right">
      <strong>البند التاسع:</strong> لا يحق للطرف الثاني بيع الشقة الى الغير الا
      بموافقة وشروط يحددها الطرف الأول
    </p> -->
    <p class="lead" style="text-align: right">
      <strong>البند السادس:</strong> يتعهد الطرف الثاني باحترام جميع القواعد
      والتعليمات التي يحددها الطرف الأول وبالأخص المتعلقة باللوحات التعريفية
      للشقق والمحلات التجارية ولوحات الأعلانات والنشرات في الممرات والطوابق
      والطرق الخارجية والحدائق داخل المشروع وعدم إحداث أية تعديلات في الأجزاء
      والممرات المشتركة بغير موافقة الطرف الأول وكذلك عدم اتخاد أي إجراء يؤثر
      على الجمالية والمظهر العام للبناية والمدخل والسلالم وكذلك مراعاة الحرص على
      مظهر المبنى الخارجي مع المحافظة التامة على سلامة المرافق والحدائق
      العامة،كما لا يحق للطرف الثاني تغيير التصميم الداخلي للشقة و تعميرها دون
      إستحصال موافقة تحريرية من الطرف الأول و عند موافقته فتتم تحت إشرافه و
      مراقبته وبخلافه يكون للطرف الأول الحق في اتخاذ الإجراءات القانونية كافة ضد
      الطرف الثاني مع تحمله كافة المصروفات لإعادة الوضع إلى ما كان عليه ويسري
      هذا البند حتى بعد تسجيل الشقة لدى مديرية التسجيل العقـاري،ولا يجوز للطرف
      الثاني نصب أية أجهزة تؤثر على عمل وخدمات منظومة (IBS) الموجودة لتغطية
      البناية بشبكة الهاتف النقال ويجب عليه عدم المساس بأجهزة ال (Pico Cell)
      الموجودة لنفس الغرض في الواجهة الداخلية من سقف البناية، ويجب عليه وبعد
      إعلامه تحريريا بتمكين الفنيين التابعين لشركات الهاتف النقال (كورك و
      اسياسيل) من دخول المكتب بصورة دورية أو في الحالات الضرورية والسماح لهم
      بنصب اجهزة اخرى اضافية تتعلق بالموقع متى ما اقتضى الأمر ذلك في المستقبل
      دون معارضة شرط أن يكون تنصيب هذه الأجهزة من أجل تقوية أو تطوير أو إصلاح
      خلل في الشبكة).
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند السابع:</strong> على الطرف الثاني استلام الشقة من تأريخ
      ارسال الطرف الأول بريد الكتروني الى الطرف الثاني باستلام الشقة ووضع اشارة
      (تم الأرسال) أو من تاريخ توجية انذار الى الطرف الثاني بواسطة كاتب العدل
      باستلام الشقة أو تاريخ نشر استلام الشقق بواسطة صحيفتين يوميتين محليتين.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثامن:</strong> يتعهد الطرف الأول بتسجيل (الشقة) المباعة
      موضوع العقد باسم الطرف الثاني في مديرية التسجيل العقاري المختصة بعد اكمال
      الأجراءات الرسمية بتمليك القطعة في دوائر الدولة وذلك بعد اكمال (الشقة)
      واستلام الطرف الأول كامل بدل البيع المتفق عليه وتكون كافة رسوم التسجيل
      والأتعاب على الطرف الثاني.
    </p>
    <p class="lead" style="text-align: right">
      <strong
        >البند التاسع: (خدمات ما بعد تسليم الشقة قبل وبعد تسجيلها باسم الطرف
        الثاني لدى مديرية التسجيل العقاري) :-</strong
      >
      يقوم الطرف الأول بتقديم خدمات الصيانة الى الطرف الثاني لمدة ستة أشهر
      اعتباراً من تأريخ تبليغه باستلام الشقة وهي تشمل أي خلل يطرأ على البناية ما
      لم يكن ناجماً عن سوء استخدام الطرف الثاني.

      <span
        >كما يقوم الطرف الأول بالأشراف والأتفاق مع الشركات الخدمية لتقديم
        الخدمات والأدارة الى الطرف الثاني مقابل مبلغ شهري يدفعه الطرف الثاني
        سلفاً الى الطرف الأول في بداية كل شهر وتشمل الفقرات التالية عدا اجور
        الماء والكهرباء:</span
      >
      <br />
      <span
        >1.التنظيف الخارجي للشقة ومنها تنظيف الممرات والسلام المشتركة والشوارع
        العامة والسرداب وكراج السيارات وتنظيف الحدائق العامة للمجمع وادامتها
        وتنظيف المجاري و جمع النفايات ورميها خارج المجمع.</span
      >
      <br />
      <span
        >2.الحراسة الأمنية للبوابات الرئيسية لمشروع
        <strong>(Candle)</strong> والأماكن العامة داخل المجمع.</span
      >
      <br />
      <span
        >3.صيانة المصاعد الكهربائية وتنظيفها وصيانة الانارة الخارجية للشقة و
        داخل الممرات وكراج وقوف السيارات وتنظيم السير في شوارع المجمع وفي كراج
        وقوف السيارات الداخلي وادامة الهياكل الخارجية لبنايات الشقق وادامة
        منظومة اطفاء الحرائق وادامة ملاعب الأطفال.</span
      >
      <br />
      <span
        >4.على الطرف الأول تقديم الخدمات المذكورة في هذا العقد الى الطرف الثاني
        بانتظام الا في حالة الظروف القاهرة كالكوارث الطبيعية أو حدوث تقلبات
        سياسية أو اقتصادية أو عسكرية.</span
      >
      <br />
      <span
        >5.على الطرف الثاني الألتزام بكل ما جاء في هذا البند حتى بعد تسجيل الشقة
        بأسمه رسميا،وأن هذا الالتزام يبقى على الطرف الثاني الى أن يقرر الطرف
        الأول عدم الحاجة اليه.</span
      >
      <br />
      <span
        >6.لا يحق للطرف الثاني التنصل من تنفيذ هذا البند وذلك بأبرام عقود
        الخدمات أو الأتفاق مع الشركات أو الأشخاص لتأمين تلك الخدمات.</span
      >
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند العاشر (أحكام عامة):</strong> تشكل هذه الأتفاقية الألتزامات
      الصحيحة والملزمة النافذة من قبل الطرفين وفقا لشروطها، ويمتثل الطرفان من
      كافة النواحي المتعلقة بالعقد لأحكام هذه الأتفاقية والقواعد وكافة القوانين
      والتعليمات والمراسيم والنظم والموافقات والرخص السارية حاليا في إقليم
      كوردستان/ العراق.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الحادي عشر (حل الخلافات والنزاعات) :</strong>
      <br />
      <span
        >1.في حال نشوء أي نزاع خلاف بين الطرفين فيما يتعلق بتفسير اية مادة أو
        بند من مواد وبنود هذا العقد فيتم تسويته بالطرق الودية و بعكسه تكون محاكم
        بداءة أربيل هي المختصة بفض النزاع.</span
      >
      <br />
      <span
        >2.يتحمل الطرف الثاني كافة النفقات التي يتكبدها الطرف الأول مثل أجور
        المحاماة والمعقبين نتيجة عدم التزامه ببنود هذا العقد.</span
      >
    </p>

    <!-- <div>
        <br /><br /><br /><br /><br /><br />
        <hr class="hr m-2" />
        <div class="footer d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <p class="m-0 p-0">www.candleresidence.com</p>
              <p class="m-0 p-0">office@candleresidence.com</p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-envelope"
                viewBox="0 0 20 20"
              >
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
                />
              </svg>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div dir="ltr">
              <p class="m-0 p-0">+964 771 433 6222</p>
              <p class="m-0 p-0">+964 771 433 7222</p>
            </div>

            <hr class="vl mx-3" />

            <div dir="ltr">
              <p class="m-0 p-0">+964 750 421 11 22</p>
              <p class="m-0 p-0">+964 750 421 11 00</p>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-telephone"
                viewBox="0 0 20 20"
              >
                <path
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div>
              <p class="p-0 m-0">
                AB Center – 2<sup>nd</sup> Floor – Gulan Street – Erbil / Iraq
              </p>
              <p class="p-0 m-0">
                بنایة AB سنتر – طابق الثاني – شارع گوالن – أربیل / العراق
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-geo-alt"
                viewBox="0 0 20 20"
              >
                <path
                  d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                />
                <path
                  d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="second-page"> -->
    <!-- <div>
        <div class="d-flex justify-content-between align-content-center">
          <div>
            <h1 class="BlueColor">شركة کاندل</h1>
            <h2 class="GoldColor">مشروع کاندل السکني</h2>
          </div>
          <div>
            <img
              class="logo"
              src="https://i.ibb.co/pbb0X0H/candle-logo-copy.png"
              alt=""
            />
          </div>
          <div dir="ltr" class="customFont">
            <h1 class="BlueColor">Candle Company</h1>
            <h2 class="GoldColor">Candle Residential Project</h2>
          </div>
        </div>
        <hr class="hr m-2" />
      </div> -->
    <p class="lead" style="text-align: right">
      <strong>البند الثاني عشر (المراسلات) :</strong> وافق الطرف الثاني أن يتم
      توجيه أي إشعار أو مراسلة من قبل الطرف ألأول بموجب هذا العقد بشكل خطي و
      باللغة العربية أو الأنكليزية أو الكردية وتسلم شخصياً أو ترسل من خلال خدمة
      إرسال الطرود أو <strong>(SMS)</strong> له عند الحاجة ويعد الأشعار أو
      الأنذار الصادر من الكاتب العدل من قبل الطرف الأول إلى الطرف الثاني قد تم
      استلامة إذا:
      <br />
      <span>1.تم تسليمه بشكل شخصي.</span>
      <br />
      <span
        >2.بعد ثلاثة أيام من إرساله من خلال خدمة أرسال الطرود أو البريد
        المسجل.</span
      >
      <br />
      <span
        >3.عند إرساله <strong>SMS</strong> حسب ما يظهر في تقرير الأرسال (تم
        الأرسال).</span
      >
      <br />
      <span
        >4.عند إرساله بالبريد الألكتروني حسب ما يظهر في تقرير الأرسال (تم
        الأرسال).</span
      >
      <br />
      ويقر ويوافق الطرف الثاني بأنه اتخذ من العناوين المذكورة أدناه في هذا العقد
      محلا له واية مراسلات أو إنذارات أو إخطارات ترسل عليها تعتبر صحيحه و نافذه
      ومنتجة لاثارها القانونية.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثالث العشر:</strong> يقر كل طرف بأنه أطلع على بنود هذا
      الأتفاقية إطلاعا نافيا للجهالة قانونا وأنه ملتزم بتنفيذ ما جاء فيها من
      بنود و عليه قام الطرفان بتوقيع هذا العقد بعد الأطلاع على كافة بنود وقد حرر
      بثلاث نسخ بيد كل طرف نسخة للعمل بموجبها عند الأقتضاء ونسخة لدى المستشار
      القانوني وكيل الطرف الأول.
    </p>
    <table class="table table-bordered">
      <tr>
        <td v-if="!customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري/
          <strong>{{
            customer.firstName +
            " " +
            customer.middleName +
            " " +
            customer.lastName
          }}</strong>
          <br />
          <br />
          العنوان الدائم/ <strong>{{ customer.address }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/ <strong>{{ customer.idcard }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          رقم الموبايل عند الحاجة/
          <strong>{{ replacePhone(customer.phone_2) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 1 /
          <strong>{{ customer.firstName }}</strong>
          <br />
          <br />
          العنوان الدائم 1 /
          <strong>{{ separatorString(customer.address)[0] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[0] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 2 /
          <strong>{{ customer.lastName }}</strong>
          <br />
          <br />
          العنوان الدائم 2 /
          <strong>{{ separatorString(customer.address)[1] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[1] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone2) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td style="text-align: right">
          شركة <strong>(Candle)</strong>
          <br />
          <br />
          المدير المفوض السيد <strong>(رزگار عمر قادر)</strong>
          <br />
          <br />
          إضافة لوظيفته.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
      </tr>
    </table>
    <!-- <div>
        <br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br />
        <br />
        <hr class="hr m-2" />
        <div class="footer d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <p class="m-0 p-0">www.candleresidence.com</p>
              <p class="m-0 p-0">office@candleresidence.com</p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-envelope"
                viewBox="0 0 20 20"
              >
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
                />
              </svg>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div dir="ltr">
              <p class="m-0 p-0">+964 771 433 6222</p>
              <p class="m-0 p-0">+964 771 433 7222</p>
            </div>

            <hr class="vl mx-3" />

            <div dir="ltr">
              <p class="m-0 p-0">+964 750 421 11 22</p>
              <p class="m-0 p-0">+964 750 421 11 00</p>
            </div>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-telephone"
                viewBox="0 0 20 20"
              >
                <path
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div>
              <p class="p-0 m-0">
                AB Center – 2<sup>nd</sup> Floor – Gulan Street – Erbil / Iraq
              </p>
              <p class="p-0 m-0">
                بنایة AB سنتر – طابق الثاني – شارع گوالن – أربیل / العراق
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-geo-alt"
                viewBox="0 0 20 20"
              >
                <path
                  d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                />
                <path
                  d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div> -->
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "ArabicContractCandleCash",
  props: {
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    buyContract: {},
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Edwardian Script ITC";
  src: url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.eot");
  src: url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/84ae358e627d67d90bd613fcedc20c10.svg#Edwardian Script ITC")
      format("svg");
}
/* .customFont {
  font-family: "Edwardian Script ITC";
}
.GoldColor {
  color: #d5a000;
}
.BlueColor {
  color: #274a85;
}
.logo {
  width: 100px;
}

.hr {
  border: 1px solid black;
}
.vl {
  border-left: 2px solid black;
  height: 35px;
}
.footer p {
  font-size: 0.8rem;
  font-weight: 400;
} */

.lead {
  font-size: 0.8rem;
  font-weight: 300;
}

strong {
  font-weight: bold;
}

* {
  /* font-size: large; */
  text-align: justify !important;
}
</style>
