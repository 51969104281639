<template>
  <div id="transfer-contract-voucher-receipt" class="contract d-none">
    <h1 class="text-center text-bold"
    >
    ملحق التنازل عن العقد
    {{ data.buyType === "Installment" || data.buyType === 0 ? "بالأقساط" : "نقداً" }}
    </h1>
    <h5 style="text-align: right">رقم العقد: {{ data.contractNumber }}</h5>
    <h5 style="text-align: right">
      التاریخ: {{ formatDate(data.transferDate) }}
    </h5>
    <h5 style="text-align: right">
      رقم الشقة: {{ data.property && data.property.name }}
    </h5>
    <h5 style="text-align: right">
      المساحة: {{ data.property && data.property.buildUpArea }}
    </h5>
    <br />
    <p class="lead" style="text-align: right">
      <strong>الطرف الأول</strong>: (المتنازل)
      <strong>{{ data.oldCustomer && `${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}` }}</strong>
      <span>
        العنوان:
        {{
          data.oldCustomer &&
          `${data.oldCustomer.address ? data.oldCustomer.address : "-"}`
        }}</span
      >
      <span>
        الموبايل:
        {{
          data.oldCustomer && `${replacePhone(data.oldCustomer.phone)}`
        }}</span
      >
    </p>
    <p class="lead" style="text-align: right">
      <strong>الطرف الثاني</strong>
      : المتنازل له (<strong>{{ data.newCustomer && `${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}` }}</strong>)
      <span>
        العنوان:
        {{
          data.newCustomer &&
          `${data.newCustomer.address ? data.newCustomer.address : "-"}`
        }}</span
      >
      <span>
        الموبايل:
        {{ data.newCustomer && `${replacePhone(data.newCustomer.phone)}` }}</span
      >
    </p>
    <p class="lead" style="text-align: right">
      <strong>الطرف الثالث</strong>: المدير المفوض لشركة (<strong>Candle</strong>) إضافة لوظيفته.
    </p>
    <h4 style="text-align: right">ينص هذا العقد على مايأتي:-</h4>
    <p class="lead" style="text-align: right">
      1. تنازل الطرف الأول عن الشقة المرقمة (<strong>{{ data.property && data.property.name }}</strong>) الطابق (<strong>{{ data.property && data.property.block }}</strong>) في البناية (<strong>{{ data.property && data.property.zone }}</strong>) للطرف الثاني ، مع استلامه لمستحقاته المالية كافة من الطرف الثاني، وخول
      الطرف الثالث بتسجيل الشقة المشاراليه باسم الطرف الثاني، وبهذا لايحق للطرف
      الأول مطالبة الطرف الثالث بأية مبالغ المتعلقة بالعقد في المحاكم المدنية
      بأي شكل من الأشكال.
    </p>
    <p class="lead" style="text-align: right">
      2. وافق الطرف الثاني عن التنازل الطرف الأول للشقة المشار اليه وبالشروط
      المثبتة نفسها في العقد المرقم (<strong>{{ data.contractNumber }}</strong>) المؤرخ ب (<strong>{{ formatDate(data.buyingDate) }}</strong>)
      {{ data.buyType !== "Installment" || data.buyType !== 0 ? ` وبموجب هذا العقد يلزم الطرف الثاني بدفع الأقساط المتبقية في مواعيدها
      المحددة، والبالغة دولارآ أمريكيا` : ''}}
      .
    </p>
    <p class="lead" style="text-align: right">
      3. لايحق للطرف الأول والطرف الثاني مطالبة الطرف الثالث بالغرامات التأخيرية
      المترتبة بذمة الطرف الثالث بموجب العقد المرقم (<strong>{{ data.contractNumber }}</strong>)
      المؤرخ ب (<strong>{{ formatDate(data.buyingDate) }}</strong>).
    </p>
    <p class="lead" style="text-align: right">
      4. يتعهد الطرف الثاني (المتنازل له) المشتري بدفع رسومات التسجيل العقاري و
      ضريبة الدخل والعقار و أجور الماء والكهرباء و أتعاب المحامي و كافة المصاريف
      الأخرى.
    </p>
    <p class="lead" style="text-align: right">
      5. وافق الطرف الثالث على التنازل المذكور أعلاه.
    </p>
    <p class="lead" style="text-align: right">
      6. كتب في أربيل بتاريخ (<strong>{{ formatDate(data.transferDate) }}</strong>)
    </p>
    <p class="lead" style="text-align: right">
      المرفقات : نسخة من العقد (<strong>{{ data.contractNumber }}</strong>) المؤرخ ب (<strong>{{ formatDate(data.transferDate) }}</strong>) مع قائمة الأقساط التي تعتبر جزءآ لايتجزآ من هذا التنازل عن العقد.
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="6">
        <b>الطرف الأول</b>
        <br />
        {{
          data.oldCustomer &&
          `${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}`
        }}
      </b-col>
      <b-col cols="6">
        <b>الطرف الثاني</b>
        <br />
        {{
          data.newCustomer &&
          `${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}`
        }}
      </b-col>
    </b-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="6">
        <b>الطرف الثالث</b>
        <br />
        مدير المفوض لشركة )Candle )إضافة لوظيفته.
      </b-col>
      <b-col cols="6">
        <b>الشعبة القانونیة</b>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TransferContractDesignCandle",
  props: {
    data: {},
  },
  mounted() {
    console.log(this.data);
  }
};
</script>

<style>
strong {
  font-weight: bold !important;
}
</style>