<template>
  <div class="attachment-modal">
    <b-button @click="showAttachmentModal" class="m-2 no-print" variant="success">
      + {{ $t("attachment") }}
    </b-button>
    <b-modal id="attachment-modal" :title="$t('addAttachment')" size="xl">
      <div class="d-block text-center">
        <input :disabled="isLoading" class="form-control" type="file" id="formFile" @change="imagesSelected">
        <img v-show="attachment" id="imgPreview" src="#" alt="your image" style="width: 100%; height: 550px; margin-top: 20px;" />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button :disabled="isLoading" variant="secondary" @click="cancel()">
          {{ $t("close") }}
        </b-button>
        <b-button :disabled="isLoading" variant="primary" @click="changeAttachment">
          {{ $t("create") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {swalMixin} from "@/plugins/constants";

export default {
  name: "AddAttachmentModal",
  props: {
    id: {
      required: true
    },
    updateUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      attachment: null
    }
  },
  methods: {
    showAttachmentModal() {
      this.$bvModal.show("attachment-modal");
    },
    async changeAttachment() {
      if (this.attachment === null) {
        swalMixin('error', this.$t('pleaseSelectAttachment'));
        return false;
      }
      this.isLoading = true;
      this.$store.dispatch(this.updateUrl, {
        id: this.id,
        attachment: this.attachment
      }).then(() => {
        swalMixin('success', this.$t('attachmentAdded'));
        this.$bvModal.hide("attachment-modal");
        this.$emit('success');
        this.isLoading = false;
      }).catch(() => this.isLoading = false)
    },
    async imagesSelected(e) {
      this.attachment = await this.$helpers.changeFileToBase64(e.target.files[0]);
      document.getElementById('imgPreview').src = URL.createObjectURL(e.target.files[0]);
    }
  }
}
</script>