import {i18n} from "@/plugins/i18n";

const language = {
    namespaced: true,
    state: {
        language: localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_LOCALE_DEFAULT,
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            localStorage.setItem('locale', lang);
            state.language = lang;
            i18n.locale = lang;
        }
    },
    actions: {
        CHANGE_LANGUAGE({ commit }, locale) {
            commit('SET_LANGUAGE', locale)
        },
    },
    getters : {
        language: state => state.language,
    }
}

export default language;
