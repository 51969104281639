<template>
  <div dir="rtl">
    <hr class="border border-secondary border-1 mx-3" />

    <div class="text-center px-4">
      <h6 class="fontBold">
        گرێبەستی فرۆشتنی یەکەی نیشتەجێبوون لە پڕۆژەی یونیک تاوەرز
      </h6>
      <h6 class="fontBold">(شێوازی فرۆشتنی قست)</h6>

      <!-- ? Table one -->
      <div class="row mt-4">
        <div class="col-6">
          <table class="table table-bordered text-right">
            <tr>
              <td>ڕێککەوتی جێبەجێکردنی گرێبەست</td>
              <td style="width: 35%" class="text-center">
                {{ formatDate(buyContract.buyingDate) }}
              </td>
            </tr>
            <tr>
              <td>ژمارەی گرێبەست</td>
              <td class="text-center">{{ buyContract.contractNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-bordered text-right">
            <tr>
              <td>کۆمپانیای جێبەجێکار</td>
              <td class="text-center">ڕادەر</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ? Table Two-->
      <div class="row text-right pt-3">
        <div class="col">
          <p>
            لایەنی یەکەم (فرۆشیار): کۆمپانیای ڕادەر بۆ بازرگانی گشتی / سنووردار
            کە لەژێر ژمارە (23289) تۆمارە لە ڕێکەوتی 17/8/2016
          </p>
          <table class="table table-bordered text-right">
            <tr>
              <td>بەڕێز</td>
              <td class="text-center">
                بهمن عبداللە سلیمان / بەڕێوەبەری ڕێپێدراوی کۆمپانیا سەڕەڕای
                کارەکەی
              </td>
            </tr>
            <tr>
              <td>ژمارە مۆبایل</td>
              <td class="text-center" dir="ltr">
                0750 446 0438 – 0750 536 3000
              </td>
            </tr>
            <tr>
              <td>ناونیشان</td>
              <td class="text-center">
                بنکەی فرۆشتنی یونیک تاوەرز – ڕێگای پیرمام / تەنیشت چێشتخانەی
                هرور
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ? Table Three-->
      <div class="row text-right pt-3">
        <div class="col">
          <p>لایەنی دووەم (کڕیار):</p>
          <table class="table table-bordered text-right">
            <tr>
              <td>بەڕێز</td>
              <td class="text-center">
                {{
                  `${customer.firstName} ${customer.middleName}
              ${customer.lastName}`
                }}
              </td>
            </tr>
            <tr>
              <td>ژمارەی ناسنامە</td>
              <td class="text-center">{{ customer.idcard }}</td>
            </tr>
            <tr>
              <td>ژمارە مۆبایل</td>
              <td dir="ltr" class="text-center">
                {{ replacePhone(customer.phone) }}
              </td>
            </tr>
            <tr>
              <td>ناونیشانی ئیمەیڵ</td>
              <td dir="ltr" class="text-center">{{ customer.email }}</td>
            </tr>
            <tr>
              <td>ناونیشان</td>
              <td class="text-center">
                {{ customer.address }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <hr class="border border-secondary border-1 mx-3" />

      <!-- ?section one-->
      <div class="text-right mx-2 pt-2">
        <p class="fontBold mb-2">بەشی یەکەم (پێشەکی گرێبەست)</p>
        <p>
          لایەنی یەکەم کۆمپانیای (ڕادەر) وەکو کەسێکی مەعنەوی (فرۆشیار) هەڵدەستێت
          بە درووستکردنی پڕۆژەی نیشتەجێبوونی ستوونی (یونیک تاوەرز) لەسەر زەوی
          ژمارە (6/3)ی کەرتی 92 شاوێس ، وە بەو پێیەی لایەنی دووەم (کڕیار) خواست
          و نیازی کڕینی یەکەیەکی نیشتەجێبوون لە پڕۆژەی یونیک تاوەرز هەیە کە
          بەپێی ئەو تایبەتمەندییە تەکنیک و ئەندازیاری لەلایەن لایەنی یەکەمەوە
          ئاماژەی پێکراوە بە گوێرەی نەخشەو پلانی هاوپێچ کراو، وە دوای ئەوەی
          هەردوو لایەن دانیان بە شایستەیی خۆیاندا نا بۆ گرێبەستکردن، ڕێککەوتن
          کرا کە لایەنی یەکەم یەکەیەکی نیشتەجێبوون کە لە قۆناغی دروستکردندایە بە
          لایەنی دووەم (بەرامبەر) بفرۆشێت بەپێی ئەم ووردەکاریانەی خوارەوە:
        </p>
      </div>
      <!-- <br /><br /> -->

      <!-- ?section two-->
      <div class="text-right mx-2 mt-5">
        <p class="fontBold mb-2">
          بەشی دووەم (ووردەکارییەکانی یەکەی نیشتەجێبوون)
        </p>
        <p>هەولێر / ناوەندی شار / شەقامی پیرمام</p>
        <p>
          ژمارەی پارچە زەوی کە پڕۆژەکە لەسەری دروستکراوە: ژمارە (6/3)ی کەرتی 92
          شاوێس
        </p>
        <p>
          باڵەخانەی ({{ property.zone }}) / نهۆمی ({{ property.block }}) /
          شووقەی ژمارە ( {{ property.name.split("-")[2] }} )
        </p>
        <p>
          کۆی ڕووبەری گشتی (گڕۆس) یەکەی نیشتەجێبوون: ({{ property.buildUpArea }}
          م2)ە.
        </p>
      </div>

      <!-- ?section three-->
      <div class="text-right mx-2 pt-2">
        <p class="text-right fontBold mb-2">
          بەشی سێ یەم ( نرخی یەکەی نیشتەجێبوون و شێوازی قستەکان )
        </p>
        <ol class="px-5">
          <li>
            بەهای شووقەکە : ({{ property.meterPrice }} $) ({{
              this.meterPriceText
            }}) بۆ هەر مەتر دووجایەک کە کۆی بەهای شووقەکە دەکاتە ({{
              property.meterPrice
            }}$ * {{ property.buildUpArea }} م2 =
            {{ buyContract.buyingPrice.toLocaleString() }} $) ({{
              this.totalPriceText
            }}
            ) .
          </li>

          <li>
            لایەنی دووەم پابەندە بە پێدانی بڕی (18٪) لە کۆی بەهای گشتی یەکەکە کە
            دەکاتە ({{
              Math.floor(buyContract.buyingPrice * 0.18).toLocaleString()
            }}
            $) ( {{ this.eighteenPrc }} دۆلاری ئەمریکی) لەگەڵ واژووکردنی
            گرێبەست.
          </li>
          <li>
            لایەنی دووەم پابەندە بە پێدانی بڕی (22٪) لە کۆی بەهای گشتی یەکەکە کە
            دەکاتە
            <span
              v-if="property.buildUpArea === 91.6 && property.zone === 'T1'"
            >
              (
              {{ Math.floor(buyContract.buyingPrice * 0.22).toLocaleString() }}
              $) ( {{ this.twentyTwoPrcFloor }}دۆلاری ئەمریکی)
            </span>

            <span v-else>
              (
              {{ Math.round(buyContract.buyingPrice * 0.22).toLocaleString() }}
              $) ( {{ this.twentyTwoPrcRound }}دۆلاری ئەمریکی)
            </span>
            لەگەڵ تەواوبوونی هەیکەلی باڵەخانە.
          </li>
          <li>
            لایەنی دووەم پابەندە بە پێدانی بڕی (25٪) لە کۆی بەهای گشتی یەکەکە کە
            دەکاتە ({{
              Math.round(buyContract.buyingPrice * 0.25).toLocaleString()
            }}
            $) ( {{ this.twentyFivePrc }} دۆلاری ئەمریکی) لەگەڵ ڕادەستکردنی
            کلیلی یەکەی نیشتەجێبوونەکە.
          </li>
          <li>
            لایەنی دووەم پا بەندە بە پێدانی بڕی ({{
              parseFloat((buyContract.buyingPrice * 0.35) / 60)
                .toFixed(2)
                .toLocaleString()
            }}) ({{ this.installmentText }})

            <!-- <span
              v-if="
                property.buildUpArea === 84.7 || property.buildUpArea === 83.9
              "
              >($400) ({{ this.fourHandText }} دۆلاری ئەمریکی)</span
            >
            <span
              v-else-if="
                property.buildUpArea === 91.6 || property.buildUpArea === 89.1
              "
              >($430) ({{ this.fourHandThirtyText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 90.6"
              >($450) ({{ this.fourHandfiftyText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 107.5"
              >($500) ({{ this.fiveHandText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 114"
              >($550) ({{ this.fiveHandfiftyText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 131"
              >($600) ({{ this.sixHandText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 133.1"
              >($650) ({{ this.sixHandfiftyText }} دۆلاری ئەمریکی)</span
            >
            <span v-else-if="property.buildUpArea === 147"
              >($700) ({{ this.sevenHandText }} دۆلاری ئەمریکی)</span
            >
            <span
              v-else-if="
                property.buildUpArea === 183.2 || property.buildUpArea === 186.4
              "
              >($850) ({{ this.eightHandfiftyText }} دۆلاری ئەمریکی)</span
            > -->
            بۆ هەر مانگێک بۆ ماوەی (60) مانگ لە ڕێکەوتی ڕادەستکردنی یەکەی
            نیشتەجێبوونەکە دەست پێ دەکات.
          </li>
          <li>
            ووردەکاری فرۆشتنی شووقەی ژمارە (
            {{ property.name.split("-")[2] }} ) بەم شێوەیە:
          </li>
          <div class="row text-center p-0 m-0">
            <table class="table table-bordered">
              <tr>
                <td class="p-1">جۆری شووقە</td>
                <td class="p-1">ڕووبەر</td>
                <td class="p-1">نرخی مەتر</td>
                <td class="p-1">نرخی گشتی</td>
                <td class="p-1">پێشەکی (18٪)</td>
                <td class="p-1">تەواو بوونی هەیکەل (22٪)</td>
                <td class="p-1">وەرگرتنی کلیل (25٪)</td>
                <td class="p-1">کۆی قیستی مانگانە دوای وەرگرتنی کلیل (35٪)</td>
                <td class="p-1">قیستی مانگانە بۆ ماوەی (60 مانگ)</td>
              </tr>

              <tr>
                <td class="p-1">{{ property.propertyType.name }}</td>
                <td class="p-1">{{ property.buildUpArea }}</td>
                <td class="p-1">${{ property.meterPrice }}</td>
                <td class="p-1">
                  ${{ buyContract.buyingPrice.toLocaleString() }}
                </td>
                <td class="p-1">
                  ${{
                    Math.floor(buyContract.buyingPrice * 0.18).toLocaleString()
                  }}
                </td>
                <td class="p-1">
                  $
                  <span
                    v-if="
                      property.buildUpArea === 91.6 && property.zone === 'T1'
                    "
                  >
                    {{
                      Math.floor(
                        buyContract.buyingPrice * 0.22
                      ).toLocaleString()
                    }}</span
                  >
                  <span v-else>{{
                    Math.round(buyContract.buyingPrice * 0.22).toLocaleString()
                  }}</span>
                </td>
                <td class="p-1">
                  ${{
                    Math.round(buyContract.buyingPrice * 0.25).toLocaleString()
                  }}
                </td>
                <td class="p-1">
                  ${{
                    Math.round(buyContract.buyingPrice * 0.35).toLocaleString()
                  }}
                </td>
                <td class="p-1">
                  ${{
                    parseFloat((buyContract.buyingPrice * 0.35) / 60)
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </td>
                <!-- <td class="p-1">
                  <span
                    v-if="
                      property.buildUpArea === 84.7 ||
                      property.buildUpArea === 83.9
                    "
                    >$400</span
                  >
                  <span
                    v-else-if="
                      property.buildUpArea === 91.6 ||
                      property.buildUpArea === 89.1
                    "
                    >$430</span
                  >
                  <span v-else-if="property.buildUpArea === 90.6">$450</span>
                  <span v-else-if="property.buildUpArea === 107.5">$500</span>
                  <span v-else-if="property.buildUpArea === 114">$550</span>
                  <span v-else-if="property.buildUpArea === 131">$600</span>
                  <span v-else-if="property.buildUpArea === 133.1">$650</span>
                  <span v-else-if="property.buildUpArea === 147">$700</span>
                  <span
                    v-else-if="
                      property.buildUpArea === 186.4 ||
                      property.buildUpArea === 183.2
                    "
                    >$850</span
                  >
                </td> -->
              </tr>
            </table>
          </div>
        </ol>
      </div>
      <!-- ?section four -->
      <div class="text-right mx-2 pt-2">
        <p class="fontBold mb-2">بەشی چوارەم ( پابەندیەکانی لایەنی یەکەم )</p>
        <ol class="px-5">
          <li>
            لایەنی یەکەم ئەو شووقەیەی کە لە سەرەوە باسکراوە لە قۆناغی
            دروستکردندایە دەیفرۆشێت بە لایەنی دووەم بەو نرخەی کە هەردوو لایەن
            لەسەری ڕێککەوتوون کە لە گرێبەست دیاری کراوە.
          </li>
          <li>
            لایەنی یەکەم لە ماوەی (36) مانگی کارکردندا شووقەکە تەواو دەکات،
            دەستپێکی کات لە ڕێککەوتی واژووکردنی ئەم گرێبەستەوە دەست پێدەکات.
          </li>
          <li>
            لایەنی یەکەم هەڵدەستێت بە دابینکردنی سپلیت و کەونتەری چێشتخانە بۆ
            یەکەکە.
          </li>
          <li>
            دوای تەواوبوونی شووقەکە و جێبەجێکردنی ئەرکەکانی لایەنی دووەم و
            ڕەزامەندی لایەنە پەیوەندیدارەکان، لایەنی یەکەم لە دوای تەواوبوونی
            ڕێوشوێنە فەرمیەکان هەڵدەستێ بە تاپۆکردنی شووقەکە بەناوی لایەنی
            دووەمەوە لە بەڕێوەبەرایەتیەی تۆماری خانووبەرە تایبەت بە بەناو کردنی
            یەکەی نشتەجێبوون، بە پێی ماددە (534) بڕگەی (3) لە یاسایی شارستانی
            عێڕاقی.
          </li>
          <li>
            لایەنی یەکەم بەرپرسیار نییە لە دواکەوتنی لە تۆمارکردن ئەم یەکەی
            نیشتەجێبوونە کە هۆیەکەی فەرمانگەی پەیوەندیدار تایبەت بە تۆمارکردنی
            یەکەی نیشتەجێبوونەکە یاخود لایەنی دووەم (کڕیار) هەر بەربەستێکی
            یاسایی هەبێت کە ببێتە هۆی تۆمارنەکردنی یەکەکە بە ناویەوە.
          </li>
          <li>
            لایەنی یەکەم یان هەرکەسێک کە لەلایەن لایەنی یەکەمەوە مۆڵەتی پێدراوە،
            دەبێت ئەو خزمەتگوزارییانەی لەم بڕگەیەدا هاتووە بە شێوەیەکی ڕێک
            پێشکەش بە لایەنی دووەم بکات بە ستانداردی زۆنەکە، جگە لە حاڵەتی
            نەخوازراو و ناسروشتی، کە دوای تەواو بوونی یەکەکانی نیشتەجێبوون
            بەڕێوەبەرایەتی کارگێڕی پڕۆژە هەڵدەستێ بە رێکخستنی ئەو خزمەتگوزاریانە
            و نرخەکانیان.
          </li>
          <li>
            خزمەتگوزارییەکان بریتین لە:
            <ul>
              <li>
                پاککردنەوەی دەرەوەی شووقەکە، لەوانەش کۆریدۆر (ڕێڕەو) و پلیکانە
                باوەکان، گەراجی ئۆتۆمبێلەکان، پاککردنەوە و پاراستنی باخچە
                گشتییەکانی پڕۆژەکە، پاککردنەوەی ئاوەڕۆکان و هەموو پاشماوەکان و
                فڕێدانی بۆ دەرەوەی پڕۆژەکە.
              </li>
              <li>
                پاراستن و پاککردنەوەی بەرزکەرەوەی کارەبایی، پاراستنی ڕووناکی
                دەرەوەی شووقەکە، ناوەوەی کۆریدۆرەکان و گەراجی وەستانی ئۆتۆمبێل،
                گۆڕەپانی یاری منداڵان، پاراستنی پێکهاتەی دەرەوەی باڵەخانەکان،
                پاراستنی سیستەمی ئاگرکوژێنەوە و ئاگادارکردنەوە.
              </li>
              <li>
                چاکردنەوەی تۆڕی کارەبایی ناو پڕۆژە و مۆلیدە و کامیرەی چاودێری.
              </li>
              <li>خزمەتگوزاری پاسەوانی ناو پڕۆژە.</li>
            </ul>
          </li>
          <li>
            لایەنی یەکەم هەڵدەستێت بە ئاگادارکردنەوەی لایەنی دووەم لە ڕیگای
            ناونیشان یاخود ژمارە تەلەفۆنانەی کە لە گرێبەستەکە ئاماژەی پێکراوە بۆ
            هەر پێویستیەک.
          </li>
          <li>
            دوای ڕادەستکردنی یەکەکە بە لایەنی دووەم، لە ئەگەری هەبوونی هەر کەمو
            کورتیەک لە یەکەکە ئەوا کەمو کورتیەکان چارەسەر دەکرێن بە پێی ئەو
            پلانەی لەسەری ڕێککەوتوون تاوەکو (30) ڕۆژ لە ڕێککەوتی ڕادەستکردنی
            یەکەکە، لایەنی دووەم ئەو مافەی لەدەست دەدات ئەگەر هاتوو یەکەکەی لە
            کاتی خۆیدا وەرنەگرت.
          </li>
        </ol>
      </div>

      <!-- ?section four -->
      <div class="text-right mx-2 pt-2">
        <p class="fontBold mb-2">بەشی پێنجەم ( پابەندیەکانی لایەنی دووەم )</p>
        <ol class="px-5">
          <li>
            لایەنی دووەم دوای پێداچوونەوە بە پڕۆژەکە و سەرجەم ووردەکارییەکانی،
            بە پێی ئەو نەخشەیەی کە هاوپێچ کراوە لەگەڵ ئەمەدا و ڕەزامەندی دەربڕی
            لەسەر کڕینی یەکەکە بەپێی ئەو ووردەکاریانەی لەم گرێبەستەدا هاتووە.
          </li>
          <li>
            لایەنی دووەم پابەندە بە پێدانی هەموو قیستەکان بە لایەنی یەکەم لە
            ئۆفیسی فرۆشتنی خۆی یان ئەو ژمارە حسابە بانکییەی کە دیاری دەکرێت لە
            لایەن لایەنی یەکەم.
          </li>
          <li>
            لایەنی دووەم پابەندە بە پێدانی قیستەکان لەو کاتەی کە لەم گرێبەستەدا
            دیاری کراوە.
          </li>
          <li>
            لایەنی دووەم پابەندە بە هەموو جۆرە خەرجییەکانی تۆمارکردنی یەکەی
            نیشتەجێبوونەکە کە بریتین لە ڕسومات، باجەکانی پەیوەست بە تۆمار کردن ،
            کرێی تۆمارکردن و تەواوی خەرجیەکانی مامەڵەکە وە ئەوانەشی کە لەوانەیە
            لە داهاتوودا بێنە کایەوە.
          </li>
          <li>
            لایەنی دووەم پابەندە بە وەرگرتنی شووقەکە دوای تەواوبوونی کارەکانی
            ناو پرۆژەکە، هەر کە لایەنی دووەم ئاگادارکرایەوە بە یەکێک لەو
            شێوازانەی کە لە بەشی شەشەمی ئەم گرێبەستە ئاماژەی پێکراوە.
          </li>
          <li>
            لایەنی دووەم پابەندە بە دانی کرێی ئاو، کارەبا، غاز هەروەها ئەو
            خزمەتگوزاریانەی کە لە ( بەشی چوارەم بڕگەی 7 ) ئاماژەی پێکراوە دوای
            ڕادەستکردنی یەکەکە.
          </li>
          <li>
            لایەنی دووەم بۆی نییە هیچ گۆڕانکاریەک لە دەرەوە و پێکهاتە جێگیرەکانی
            یەکەکە بکات کە بریتین دیواری کۆنکریتی دیواری هاوبەشی نێوان یەکەکان و
            خزمەتگوزاریەکان و شوێنی چیشتخانە و حەمام و توالێت و پەنجەرە.
          </li>
          <li>
            ئەگەر لایەنی دووەم ویستی گۆڕانکاریەکی ڕێگەپێدراو لەناوەوەی یەکەکەدا
            بکات دەبێت بە نووسراوی فەرمی ڕێگەپێدان وەربگرێت لەلایەن لایەنی
            یەکەمەوە وە لەژێر چاودێری لایەنی یەکەم گۆڕانکاریەکان ئەنجام بدات
            لەسەر ئەستۆی خۆی بە پێچەوانەوە بەرپرسیار دەبێت.
          </li>
          <li>
            لایەنی دووەم پابەندە بە بەکارهێنانی یەکەکە بۆ مەبەستی نیشتەجێبوون
            یاخود بۆ بەکرێدانی بۆ هەمان مەبەست، وە بەکارهێنانی بە شێوەیەک بێت کە
            لەگەڵ ئاداب و نەریتی کۆمەڵگا بگونجێ.
          </li>
          <li>
            لایەنی دووەم بەڵێن دەدات پابەند بێت و ڕێز لە هەموو ئەو ڕێسا و
            ڕێنماییانە بگرێت کە لەلایەن بەڕێوەبەرایەتی پڕۆژەکەوە دەردەچێت.
          </li>
          <li>
            لایەنی دووەم پابەندە بە دانی کرێی ئەو خزمەتگوزارییانەی کە لایەنی
            یەکەم دیاریی کردووە تەنانەت دوای ئەوەش کە شووقەکە بە فەرمی بەناوی
            ئەوەوە تۆماردەکرێ لە بەشی تۆماری خانووبەرە، وە پێویستە پابەندبێت بە
            هەموو بڕیارە کارگێڕیەکانی بەڕێوەبەرایەتی پڕۆژەکە.
          </li>
          <li>
            لایەنی دووەم دەتوانێت شووقەکە بفرۆشێت بە لایەنی سێیەم بە مەرجێک
            پابەند بێت بە مەرجەکانی گرێبەستەکە و پابەند بێت بە پێدانی قیستەکان
            کە لەم گرێبەستەدا دیاریکراون، وە پێدانی خەرجیە کارگێڕی و
            گرێبەستیەکان کە لایەنی یەکەم دیاری دەکات.
          </li>
          <li>
            هەر جۆرە مامەڵەیەکی کڕین و فرۆشتن لەسەر یەکەکە پێویستی لایەنی دووەم
            ڕەزامەندی پێشوەختەی لە لایەنی یەکەم وەربگرێت بە پێچەوانەوە
            گرێبەستەکە بە هەڵوەشاوە هەژمار دەکرێت وەهەروەها لایەنی یەکەم مافی
            ڕەزامەندی نەدانی هەیە.
          </li>
          <li>
            لایەنی دووەم بۆی نییە بە هیچ شێوەیەک ئامێری لاسلکی (بێ تەل)، ئالیار،
            تۆڕی ئینتەرنێتی دەرەکی، مۆلیدەی کارەبا یاخود ئامێری فێنککەرەوە
            (موبەڕیدە) بەکار بهێنێت.
          </li>
          <li>
            لایەنی دووەم پابەندە بە ئاگادارکردنەوەی لایەنی یەکەم لە کاتی گۆڕینی
            ژمارەی مۆبایل یاخود گۆڕینی ئەو ناونیشانی کە لە گرێبەستەکە ئاماژەی
            پێکراوە.
          </li>
        </ol>
      </div>

      <!-- ?six section -->
      <div class="row text-right mx-2 pt-2">
        <p class="fontBold mb-2">
          بەشی شەشەم (ڕێگاكانی پەیوەندی كردن و ئاگادار كردنەوە)
        </p>
        <p class="px-5">
          لایەنی دووەم ڕەزامەندی دەردەبڕێت لەسەر ئەوەی هەر ئاگادارییەک یان
          نامەیەک لە لایەنی یەکەمەوە كە پێی دەگات بەپێی ئەم گرێبەستە بە نووسراو
          بە زمانی عەرەبی، ئینگلیزی یان کوردی بنێردرێت و بە شێوەیەکی کەسی(شەخصی)
          یان لە ڕێگەی خزمەتگوزاری پۆستە یان ئیمەیلی تۆماركراو لە گرێبەستەکەدا
          بنێردرێت یان خزمەتگوزاری کورتەنامە بۆ ئەو ژمارە مۆبایلەی کە لە
          گرێبەستەکەدا تۆمارکراوە،ئەوا بە وەرگیراو دادەنرێت بەپێی ئەم مەرجانەی
          خوارەوە:
        </p>
        <ol class="px-5">
          <li>ئەگەر بە شێوەیەکی کەسی(شەخصی) گەیاندرابێت.</li>
          <li>
            سێ ڕۆژ دوای ناردنی لە ڕێگەی خزمەتگوزاری ناردنی ئیمەیلی تۆماركراو
            لەسەر گرێبەست، یان خزمەتگوزاری نامەی کورت ئیس ئم ئیس
          </li>
          <li>
            کاتێک لە ڕێگەی بەرنامەکانی تۆڕە کۆمەڵایەتیەکانەوە (ڤایبەر، واتسئەپ،
            یان ئەوانی تر) دەنێردرێت (پێشووتر ژمارەكە لەسەر گرێبەست تۆماركرابێت)
          </li>
          <li>
            کاتێک لە ڕێگەی ئیمەیڵەوە دەنێردرێت، لە ئیمەیلەكە بە دیار بكەوێت كە
            بە دەستی گەیشتووە.
          </li>
        </ol>
      </div>

      <!-- ?seven section -->
      <div class="row text-right mx-2 pt-2">
        <p class="fontBold mb-2">
          بەشی حەوتەم ( پێبژاردن و هەڵوەشاندنەوەی گرێبەست)
        </p>
        <p class="px-5"></p>
        <ol class="px-5">
          <li>
            لە ئەگەری ئەوەی لایەنی دووەم پێشێلی هەریەکێک لەو ناوەڕۆکی بڕگەکانی
            بەشی (سێ یەم و پێنجەم)ی ئەم گرێبەستەی کرد:
            <ul>
              <li>
                لە کاتی نەدانی قستەکان لە کاتی خۆی ئەوا لایەنی دووەم پابەندە بە
                پێدانی پێبژاردن بە لایەنی یەکەم بە بڕی ( 100 $) سەد دۆلاری
                ئەمریکی بۆ هەر ڕۆژێک دواکەوتن.
              </li>
              <li>
                لەکاتی بەردەوام بوونی لایەنی دووەم لە نەدانی قستەکان بۆ ماوەی (
                30 ) سی ڕۆژ ئەوە لایەنی یەکەم مافی هەیە گرێبەستەکە
                هەڵبوەشێنێتەوە، لەم بارەیەدا لایەنی دووەم پابەندە بە
                قەرەبووکردنەوەی لایەنی یەکەم بە بڕی (50%) لەسەدا پەنجا لە کۆی
                گشتی بەهای دیاریکراوی یەکەکە و خەرجیە کارگێڕیەکان.
              </li>
              <li>
                ئەگەر هاتوو لایەنی دووەم پاش دواکەوتنی لە نەدانی قستەکانی هەڵسا
                بە دانی قستەکان پێش ( 30 ) سی ڕۆژ ئەوە دەبێت تەواوی پێبژاردنی
                دواکەوتنی ڕۆژەکان بدات کە بۆ هەر ڕۆژێک (100 $) سەد دۆلاری
                ئەمریکییە.
              </li>
              <li>
                لە کاتی دەستکاریکردنی یەکەکە بەبێ ڕەزامەندی فەرمی لایەنی یەکەم ،
                لایەنی دووەم پابەندە بە پێبژاردن بە بڕی (5000 $) پێنج هەزار
                دۆلاری ئەمریکی بۆ لایەنی یەکەم و گەڕانەوەی دۆخی یەکەکە بۆ باری
                پێشووی خۆی لەسەر ئەستۆی لایەنی دووەم.
              </li>
            </ul>
          </li>
          <li>
            لە ئەگەری دواکەوتنی لایەنی یەکەم لە ڕادەستکردنی شووقەکە بە لایەنی
            دووەم لەم ماوەیەی سەرەوەدا دوای کەمکردنەوەی ڕۆژەکانی ڕاگرتن، لایەنی
            یەکەم پابەند دەبێت بە پێدانی پێبژاردنی دواکەوتن بە بڕی (500 $) پێنج
            سەد دۆلاری ئەمریکی بۆ هەر مانگێک دواکەوتن، بە مەرجێک کۆی گشتی لە
            (2%) زیاتر نەبێت لە بەهای شووقەکە ئەم پابەندبوونە کاتێک کۆدەبێتەوە و
            یەکلایی دەکرێتەوە کە کلیلەکە ڕادەستی دەکرێت و دوا قیست دەدرێت ، و
            لایەنی دووەم مافی نەبێت بۆ ئەوەی پێش ئەوە داوای پێبژاردن بکات، بە
            مەرجێ هۆکاری دواکەوتنەکە جەنگ،تیرۆر،پەتا،کارەساتی سروشتی،قەیرانی
            ئابووری، دواکەوتنی ڕەزامەندی حکومی، دواکەوتنی کارە حکومیەکان، گۆڕانی
            و ڕێسای حکومی، ڕاگەیاندنی کاتی نالەبار لەلایەن حکومەتەوە، قەدەغەی
            هاتووچۆ....هتد نەبێ لەم کاتانەدا پارەی پێبژاردن نادرێت.
          </li>
          <li>
            لە کاتی پاشگەزبوونەوەی لایەنی دووەم لە گرێبەستەکە بە هەر هۆکارێک
            ئەوە لایەنی دووەم پابەندە بە دانی بڕی (50%) سەدا پەنجای کۆی بەهای
            گشتی یەکەکە بە لایەنی یەکەم لە بڕی پەشیمان بوونەوەی هەروەها دەبێت
            تەواوی خەرجی کارگێڕی و فەرمی بدات بە لایەنی یەکەم.
          </li>
          <li>
            لە کاتی پابەندنەبوونی لایەنی دووەم بە پێدانی هەر یەک لە قستەکانی
            (22%) سەدا بیست و دوو بە تەواوبوونی هەیکەلی باڵەخانە، وە (25%) سەدا
            بیست و پێنجی ڕادەستکردنی کلیلی یەکەکە لە دوای (30) سی ڕۆژ لە کاتی
            دیاری کراوی خۆیدا ئەوا لایەنی یەکەم گرێبەستەکە هەڵدەوەشێنێتەوە دوای
            ئاگادارکردنەوەی بە هەڵوەشاندنەوەی گرێبەست بە یەکێک لەو شیوازانەی کە
            لە بەشی شەشەمی ئەم گرێبەستە ئاماژەی پێکراوە بەبێ پێویستی هۆشداری
            دادنووس یاخود گەڕانەوە بۆ دادگا ، لایەنی دووەم پابەندە بە دانی بڕی
            (50%) سەدا پەنجای کۆی بەهای گشتی یەکەکە بە لایەنی یەکەم لەگەڵ تەواوی
            پێبژاردن و خەرجی و زیانەکان بۆ لایەنی یەکەم .
          </li>
          <li>
            لە کاتی نەدانی بڕی پارەی دیاریکراوی قیستی مانگانە بۆ ماوەی (2) مانگ
            دوای ڕادەست کردنی یەکەی نیشتەجێبوونەکە، لایەنی یەکەم هەڵدەستێت بە
            هەڵوەشاندنەوەی گرێبەستی فرۆشتنی یەکەی نیشتەجێبوونەکە دوای
            ئاگادارکردنەوەی بە هەڵوەشاندنەوەی گرێبەست بە یەکێک لەو شێوازانەی کە
            لە بەشی شەشەمی ئەم گرێبەستە ئاماژەی پێکراوە بەبێ پێویستی هۆشداری
            دادنووس یاخود گەڕانەوە بۆ دادگا ، لایەنی دووەم پابەندە بە دانی بڕی
            (50%) سەدا پەنجای کۆی بەهای گشتی یەکەکە بە لایەنی یەکەم لەگەڵ تەواوی
            پێبژاردن و خەرجی و زیانەکان بۆ لایەنی یەکەم.
          </li>
          <li>
            هەر کاتێک گرێبەستەکە هەڵوەشایەوە بە پێی بەندەکانی ئەم گرێبەستە ئەوە
            لایەنی یەکەم هەڵدەستێت بە فرۆشتنەوەی شووقەکە بە نرخی ڕۆژ، لایەنی
            یەکەم هەموو توانای خۆی بە گەڕدەخات بۆ فرۆشتنەوەی یەکەکە (شوقەکە)
            ئەگەر بەنرخی کەمتر فرۆشرا ئەوە بڕی زەرەکە دەکەوێتە ئەستۆی لایەنی
            دووەم. سەرەڕایی (50%) سەدا پەنجای پێبژاردن لە نرخی کۆی گشتی شووقەکە
            وە ئەو خەرجیانەی خوارەوەی لێی دەردەکرێت:
            <ul>
              <li>
                قستە دواکەوتووەکان، خەرجی فرۆشتن، خەرجی ڕێکلام، پارەی
                خزمەتگوزاری، تێچووی چاکردنەوە، تێچووی یاسایی و ماندوبوونی
                پارێزەرایەتی ... هتد.
              </li>
            </ul>
          </li>
          <li>
            ئەگەر هاتوو لایەنی یەکەم هەر ڕێکارێکی یاسایی لە کاتی خۆی دا نەگرتە
            بەر مانای ئەوە نییە کە چاوپۆشی لەو مافەی خۆی کردووە بەڵکو دەتوانێت
            لە هەر کاتێکی تردا ئەو مافەی خۆی داوا بکات.
          </li>
          <li>
            بە هەماهەنگی هەردوو لایەن دەتواندرێت گۆڕانکاری لە بەندەکانی ئەم
            گرێبەستەدا بکرێت دوای ڕادەستکردنی یەکەکە.
          </li>
        </ol>
      </div>

      <!-- ?eight section -->
      <div class="text-right mx-2 pt-2">
        <p class="fontBold mb-2">بەشی هەشتەم (چارەسەرکردنی کێشەکان)</p>
        <p class="px-5"></p>
        <ul class="px-5">
          <li>
            لە کاتى بوونى هەر کێشەیەک بە ناوبژیوان کێشەکە چارەسەر دەکرێت، ئەگەر
            نەکرا لە دادگاى بەرایی شارى هەولێر چارەسەر دەکرێت، بەپێی یاسایی
            بەرکارەکان.
          </li>
        </ul>
      </div>
      <br /><br />
      <p class="text-right my-5">
        ئەم گرێبەستە لە شەش لاپەڕە پێکهاتووە کە لە هەشت بەشی سەرەکی لێک
        جیانەکراوە لەخۆ دەگرێت لە ڕێککەوتی (
        {{ formatDate(buyContract.buyingDate) }}) لە (هەولێر ) واژوو کرا لە
        نێوان هەردوو لایەنی یەکەم (فرۆشیار) و لایەنی دووەم (کڕیار).
      </p>
      <!-- ?Table fifve -->
      <div class="row text-right p-0 m-0">
        <div class="col-6">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="p-1">لایەنی یەکەم / فرۆشیار</td>
            </tr>
            <tr>
              <td class="p-1" style="width: 20%">ناوی تەواو:</td>
              <td class="p-1">بهمن عبداللە سلیمان</td>
            </tr>
            <tr>
              <td colspan="2" class="p-1">
                ل / بەڕێوەبەری ڕێپێدراوی کۆمپانیا سەڕەڕای کارەکەی
              </td>
            </tr>
            <tr>
              <td
                class="p-1"
                style="width: 10%; height: 4rem; vertical-align: middle"
              >
                واژوو
              </td>
              <td rowspan="2" class="p-1">&nbsp;</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="p-1">لایەنی دووەم / کڕیار</td>
            </tr>
            <tr>
              <td class="p-1" style="width: 20%">ناوی تەواو:</td>
              <td class="p-1">
                {{
                  `${customer.firstName} ${customer.middleName}
              ${customer.lastName}`
                }}
              </td>
            </tr>
            <tr>
              <td class="p-1" style="width: 20%">ناسنامە</td>
              <td>{{ customer.idcard }}</td>
            </tr>
            <tr>
              <td
                class="p-1"
                style="width: 10%; height: 4rem; vertical-align: middle"
              >
                واژوو
              </td>
              <td rowspan="2" class="p-1">&nbsp;</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- ?Attachment -->
      <div class="text-center py-4 my-4">
        <p class="text-right">هاوپێچەکان:</p>
        <ul style="font-size: 0.7rem" class="text-right">
          <li>نەخشە و پلانی شووقەکە لەسەر پڕۆژەکە</li>
          <li>
            ناسنامەی باری شارستانی و بڕوانامەی ڕەگەزنامەی عێراقی و کارتی زانیاری
            کڕیار.
          </li>
          <li>پسولەی پارە</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../plugins/axios";
export default {
  name: "KurdishContractUniqueTowersInstallment",
  props: {
    contract: {},

    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    buyContract: {},
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
  data() {
    return {
      meterPriceText: 0,
      totalPriceText: 0,
      eighteenPrc: 0,
      twentyTwoPrcFloor: 0,
      twentyTwoPrcRound: 0,
      twentyFivePrc: 0,
      thirtyFivePrc: 0,
      installmentText: 0,
      fourHandText: 0,
      fourHandThirtyText: 0,
      fourHandfiftyText: 0,
      fiveHandText: 0,
      fiveHandfiftyText: 0,
      sixHandText: 0,
      sixHandfiftyText: 0,
      sevenHandText: 0,
      eightHandfiftyText: 0,
    };
  },
  async mounted() {
    // if(this.contract.paymentProcess){
    //   this.contract.paymentProcess.filter((el) => el.isItKeyPayment === false ? this.paymentProccessFiltered.push(el) : "");
    // }
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.buyContract.property.meterPrice}`
    )
      .then((resp) => {
        this.meterPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.buyContract.buyingPrice} `
    )
      .then((resp) => {
        this.totalPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToKurdishText?number=${Math.floor(
        this.buyContract.buyingPrice * 0.18
      )} `
    )
      .then((resp) => {
        this.eighteenPrc = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToKurdishText?number=${Math.round(
        this.buyContract.buyingPrice * 0.22
      )} `
    )
      .then((resp) => {
        this.twentyTwoPrcRound = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToKurdishText?number=${Math.floor(
        this.buyContract.buyingPrice * 0.22
      )} `
    )
      .then((resp) => {
        this.twentyTwoPrcFloor = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToKurdishText?number=${Math.round(
        this.buyContract.buyingPrice * 0.25
      )} `
    )
      .then((resp) => {
        this.twentyFivePrc = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToKurdishText?number=${parseFloat(
        (this.buyContract.buyingPrice * 0.35) / 60
      )
        .toFixed(2)
        .toLocaleString()} `
    )
      .then((resp) => {
        this.installmentText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=400&suffix=false`
    // )
    //   .then((resp) => {
    //     this.fourHandText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=430&suffix=false`
    // )
    //   .then((resp) => {
    //     this.fourHandThirtyText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=450&suffix=false`
    // )
    //   .then((resp) => {
    //     this.fourHandfiftyText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=500&suffix=false`
    // )
    //   .then((resp) => {
    //     this.fiveHandTextHandText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=550&suffix=false`
    // )
    //   .then((resp) => {
    //     this.fiveHandfiftyText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=600&suffix=false`
    // )
    //   .then((resp) => {
    //     this.sixHandText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=650&suffix=false`
    // )
    //   .then((resp) => {
    //     this.sixHandfiftyText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=700&suffix=false`
    // )
    //   .then((resp) => {
    //     this.sevenHandText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
    // await HTTP.get(
    //   `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=850&suffix=false`
    // )
    //   .then((resp) => {
    //     this.eightHandfiftyText = resp.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response.status);
    //   });
  },
};
</script>

<style scoped>
#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
.body {
  font-weight: lighter !important;
}
.fontBold {
  font-weight: bold !important;
}
table,
tr,
td {
  border-width: 2px !important;
  border-color: rgb(100, 100, 100) !important;
}
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
}

strong {
  font-weight: bold;
}

* {
  font-size: large;
  /* text-align: justify !important; */
}
</style>
