import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const expenseTypes = {
    namespaced: true,
    state: {
        expenseTypes: {},
        reportExpenses: {},
        createdExpenseType: {},
        getOneExpenseType: {},
        updatedExpenseType: {},
    },
    mutations: {
        SET_EXPENSE_TYPES (state, value) { state.expenseTypes = value },
        SET_REPORT_EXPENSE (state, value) { state.reportExpenses = value },
        SET_CREATED_EXPENSE_TYPE (state, value) {state.createdExpenseType = value},
        SET_GET_ONE_EXPENSE_TYPE (state, value) {state.getOneExpenseType = value},
        SET_UPDATED_EXPENSE_TYPE (state, value) {state.updatedExpenseType = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/ExpenseTypes` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_EXPENSE_TYPES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_EXPENSE_TYPES', {})
                        reject(error)
                    });
            })
        },
        GET_ALL_REPORT({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Reports/GetExpensesByCategory` +
                    `${serverParams._start}` +
                    `${serverParams._end}` +
                    `${serverParams._sort}` +
                    `${serverParams._order}` +
                    `${serverParams._search}` +
                    `${serverParams.custom_params}`
                )
                    .then((response) => {
                        commit('SET_REPORT_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_REPORT_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ExpenseTypes/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/ExpenseTypes`, form)
                    .then((response) => {
                        commit('SET_CREATED_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/ExpenseTypes/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/ExpenseTypes/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        expenseTypes: state => state.expenseTypes,
        reportExpenses: state => state.reportExpenses,
        createdExpenseType: state => state.createdExpenseType,
        getOneExpenseType: state => state.getOneExpenseType,
        updatedExpenseType: state => state.updatedExpenseType,
    }
}

export default expenseTypes;
