import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyExpenses = {
    namespaced: true,
    state: {
        propertyExpenses: {},
        createdPropertyExpenses: {},
        getOnePropertyExpenses: {},
        updatedPropertyExpenses: {},
    },
    mutations: {
        SET_PROPERTY_EXPENSES (state, value) { state.propertyExpenses = value },
        SET_CREATED_PROPERTY_EXPENSE (state, value) {state.createdPropertyExpenses = value},
        SET_GET_ONE_PROPERTY_EXPENSE (state, value) {state.getOnePropertyExpenses = value},
        SET_UPDATED_PROPERTY_EXPENSE (state, value) {state.updatedPropertyExpenses = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/PropertyExpenses` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_EXPENSES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_EXPENSES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/PropertyExpenses/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/PropertyExpenses`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/PropertyExpenses/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_EXPENSE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_EXPENSE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/PropertyExpenses/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        REPORT({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Reports/GetPropertyExpenseReport` +
                    `${serverParams._start}` +
                    `${serverParams._end}` +
                    `${serverParams._sort}` +
                    `${serverParams._order}` +
                    `${serverParams._search}` +
                    `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_EXPENSES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_EXPENSES', {})
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyExpenses: state => state.propertyExpenses,
        createdPropertyExpenses: state => state.createdPropertyExpenses,
        getOnePropertyExpenses: state => state.getOnePropertyExpenses,
        updatedPropertyExpenses: state => state.updatedPropertyExpenses,
    }
}

export default propertyExpenses;
