<template>
  <div v-if="hasPermission(permissions.leadVisitsCreate)">
    <content-header>{{ $t('createLeadVisit') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
                id="leadCustomer"
                :name="$t('leadCustomer')"
                label="name,+phone"
                :validate="$v"
                :required="true"
                api-link="leadCustomers/GET_ALL"
                @itemSelected="selectedItem"
            />
            <text-input v-if="showOld" id="oldState" :name="$t('oldState')" :validate="$v" :readonly="true" :default-value="oldVisit"/>
            <select-input
                id="newState"
                :name="$t('newState')"
                label="name"
                :default-value="visitState[0]"
                :validate="$v"
                :required="true"
                :api-link="visitState"
                @itemSelected="e => form.newState = e"
            />
            <number-input id="males" :name="$t('males')" :validate="$v" :required="true" :is-loading="isLoading" />
            <number-input id="females" :name="$t('females')" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="visitDate" :name="$t('visitDate')" :time="true" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {LeadVisit} from "@/models/LeadVisit";
import SelectInput from "@/components/Inputs/SelectInput";
import LeadVisitState from "@/enums/LeadVisitState";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import moment from "moment";
import {permissions} from "@/plugins/constants";
import TextAreaInput from "@/components/Inputs/TextAreaInput";

export default {
  name: "LeadVisitCreate",
  components: {
    TextAreaInput,
    TextInput, SubmitInput, DateTimeInput, NumberInput, ContentHeader, 'v-content': Content, IsLoading, SelectInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new LeadVisit().fillAbles),
      isLoading: false,
      visitState: LeadVisitState,
      showOld: false,
      oldVisit: '',
      permissions: permissions
    }
  },
  validations: {
    form: {
      leadCustomerId: {required},
      newState: {required},
      oldState: {},
      males: {required},
      females: {required},
      visitDate: {required},
      note: {}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      delete this.form.oldState;
      delete this.form.leadCustomerName;
      this.form.visitDate = moment(this.form.visitDate).format('YYYY-MM-DDThh:mm:ss');
      delete this.form.leadCustomerPhone;
      this.$store.dispatch('leadVisits/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "leadVisits"})
      }).catch(() => this.isLoading = false);
    },
    selectedItem(value) {
      this.form.leadCustomerId = value
      this.showOld = false;
      this.$store.dispatch('leadCustomers/GET_ONE', value).then((response) => {
        this.showOld = true;
        this.oldVisit = response.data.state;
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
