<template>
  <div v-if="hasPermission(permissions.customersList)">
    <content-header>{{ $t("customers") }}</content-header>
    <v-content>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @on-create="$router.push({ name: 'customers.create' })"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="firstName" slot-scope="props">
          {{
            props.row.firstName +
            " " +
            props.row.middleName +
            " " +
            props.row.lastName
          }}
        </template>
        <template slot="phone" slot-scope="props">
          {{ props.row.phone.replace("+964", "0") }}
          <span v-if="props.row.phone2 !== null">
            - {{ props.row.phone2 }}</span
          >
        </template>
        <template slot="male" slot-scope="props">
          {{ props.row.male ? "M" : "F" }}
        </template>
        <template slot="hasAccount" slot-scope="props">
          <b-form-checkbox
            v-model="props.row.hasAccount"
            name="check-button"
            switch
            @change="(e) => changeHasAccount(e, props.row.id)"
          ></b-form-checkbox>
        </template>
        <template
          slot="controls"
          slot-scope="props"
          v-if="
            hasPermission(permissions.customersEdit) ||
            hasPermission(permissions.customersShow) ||
            hasPermission(permissions.customersShowAttachments) ||
            hasPermission(permissions.customersDelete)
          "
        >
          <control-buttons
            :props="props"
            :edit-link="
              (hasPermission(permissions.customersEdit) ||
                hasPermission(permissions.customersShowAttachments)) &&
              'customers.edit'
            "
            :delete-link="
              hasPermission(permissions.customersDelete) && 'customers/DELETE'
            "
            v-on:successAction="loadData(true)"
          />
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { Customer } from "@/models/Customer";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "CustomerList",
  components: {
    ControlButtons,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
  },
  data() {
    return {
      isLoading: false,
      model: new Customer(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.customersCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t("customersList")),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions,
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("customers/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
    changeHasAccount(e, id) {
      this.isLoading = true;
      if (e === true) {
        this.$store
          .dispatch("customers/CREATE_CUSTOMER_ACCOUNT", id)
          .then(() => {
            this.loadData();
          })
          .catch(() => (this.isLoading = false));
      } else {
        this.$store
          .dispatch("customers/DELETE_CUSTOMER_ACCOUNT", id)
          .then(() => {
            this.loadData();
          })
          .catch(() => (this.isLoading = false));
      }
    },
  },
};
</script>
