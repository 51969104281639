<template>
  <b-row>
    <b-col sm="3" v-for="attachment in allAttachments" :key="attachment.id">
      <b-card img-top style="max-width: 20rem">
        <b-link :href="url + attachment.attachment" target="_blank">
          <b-card-img :src="url + attachment.attachment" :img-alt="attachment.name" width="200" height="200" class="mb-2"/>
        </b-link>
        <boolean-input
            :show-label="false"
            id="ShareableForCustomer"
            :name="$t('sharable')"
            :load-on-mount="false"
            :default-value="attachment.shareableForCustomer"
            :checked-label="$t('share')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, attachment.id, 'shareable')"
        />
        <boolean-input
            :show-label="false"
            id="Is360"
            :name="$t('is360')"
            :load-on-mount="false"
            :default-value="attachment.is360"
            :checked-label="$t('is360')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, attachment.id, 'is360')"
        />
        <b-button href="#" variant="danger" @click.prevent="deleteAttachment(attachment.id)">{{ $t('delete') }}</b-button>
      </b-card>
    </b-col>
    <is-loading v-if="isLoading" />
  </b-row>
</template>

<script>
import IsLoading from "../../../components/IsLoading";
import {swalMixin} from "../../../plugins/constants";
import BooleanInput from "../../../components/Inputs/BooleanInput";

export default {
  name: "PropertyAttachmentsList",
  components: {BooleanInput, IsLoading},
  props: {
    attachments: {
      type: Array
    }
  },
  watch: {
    attachments: function (value) {
      this.allAttachments = value
    }
  },
  data() {
    return {
      allAttachments: this.attachments,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false
    }
  },
  methods: {
    switched(value, id, name) {
      value = true;
      this.$store.dispatch('propertyAttachments/TOGGLE_DATA', `?id=${id}&${name}=${value}`)
    },
    deleteAttachment(id) {
      this.$store.dispatch('propertyAttachments/DELETE', id).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      })
    }
  }
}
</script>
