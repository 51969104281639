<template>
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <img :src="image404" class="img-fluid mb-2" alt="404">
          <h1 class="font-weight-bold mb-22 mt-2 tx-80 text-muted">404</h1>
          <h4 class="mb-2">{{ $t('pageNotFound') }}</h4>
          <h6 class="text-muted mb-3 text-center">{{ $t('oopsThePageYouWereLookingForDoesntExist') }}</h6>
          <a href="#" class="btn btn-primary">{{ $t('backToDashboard') }}</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import image404 from './../../assets/images/404.svg';
export default {
  name: "NotFound",
  data: () => {
    return {
      image404
    }
  }
}
</script>
