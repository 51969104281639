<template>
  <div>
    <content-header>{{ $t('projects') }}</content-header>
    <v-content>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="controls" slot-scope="props">
          <control-buttons
              :props="props"
              edit-link="projects.edit"
              v-on:successAction="loadData(true)"
          />
        </template>
        <template slot="logo" slot-scope="props">
          <b-link v-if="props.row.logo" :href="props.row.logo" target="_blank">
            <b-img
                :src="props.row.logo"
                style="width: 200px; height: 100px"
                thumbnail rounded fluid
                :alt="props.row.logo"
            ></b-img>
          </b-link>
          <p v-else>-</p>
        </template>
        <template slot="attachment" slot-scope="props">
          <b-link v-if="props.row.attachment" :href="props.row.attachment" target="_blank">
            <b-img
                :src="props.row.attachment"
                style="width: 200px; height: 100px"
                thumbnail rounded fluid
                :alt="props.row.attachment"
            ></b-img>
          </b-link>
          <p v-else>-</p>
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {Project} from "@/models/Project";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {actions, serverParams, swalMixin, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "ProjectList",
  components: {ControlButtons, IsLoading, 'v-content': Content, ContentHeader, BootstrapTable},
  data() {
    return {
      isLoading: false,
      model: new Project(),
      columns: [],
      rows: [],
      actions: tableActions(),
      totalRecords: 0,
      config: tableConfigure(this.$t('projectsList')),
      serverParams: serverParams,
      selectedRows: []
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      this.isLoading = true;
      this.$store.dispatch('projects/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin('error', 'noRows')
        return false;
      }
      if (await actions('projects/DELETE', this.selectedRows, 'delete')) {
        this.loadData();
      }
    },
  }
}
</script>
