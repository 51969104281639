<template>
  <div>
    <button v-if="editLink" class="btn btn-outline-success m-1" @click="editClick"><i class="fal fa-edit"></i></button>
    <button v-if="deleteLink" class="btn btn-outline-danger m-1" @click="deleteClick"><i class="fal fa-trash"></i></button>
    <button v-if="showLink" class="btn btn-outline-info m-1" @click="showClick"><i class="fal fa-info-circle"></i></button>
    <button
        v-if="attachmentModal && props.row.attachment !== null"
        class="btn btn-outline-warning m-1"
        @click="attachmentModalShow"
    >
      <i class="fal fa-file"></i>
    </button>
    <button v-if="restoreLink" class="btn btn-success m-1" @click="restoreClick"><i class="fal fa-trash-restore"></i></button>
    <button v-if="forceDeleteLink" class="btn btn-danger m-1" @click="forceDeleteClick"><i class="fal fa-times"></i></button>
  </div>
</template>

<script>
import {actions} from "@/plugins/constants";

export default {
  name: "ControlButtons",
  props: ['props', 'editLink', 'deleteLink', 'showLink', 'attachmentModal', 'restoreLink', 'forceDeleteLink'],
  methods: {
    editClick() {
      this.$router.push({name: this.editLink, params: {id: this.props.row.id}});
    },
    async deleteClick() {
      if (await actions(this.deleteLink, this.props.row.id, 'delete')) {
        this.$emit('successAction');
      }
    },
    async restoreClick() {
      if (await actions(this.restoreLink, this.props.row.id, 'restore')) {
        this.$emit('successAction');
      }
    },
    async forceDeleteClick() {
      if (await actions(this.forceDeleteLink, this.props.row.id, 'delete')) {
        this.$emit('successAction');
      }
    },
    showClick() {
      this.$router.push({name: this.showLink, params: {id: this.props.row.id}});
    },
    attachmentModalShow() {
      this.$emit('showAttachmentModal', process.env.VUE_APP_BASE_URL + this.props.row.attachment);
    },
  },
}
</script>

<style>
.overflow-hidden {
  overflow: hidden;
}
</style>
