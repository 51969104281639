<template>
  <div v-if="hasPermission(permissions.buyContractsList)">
    <content-header>{{ $t("buyContracts") }}</content-header>
    <v-content>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @on-create="$router.push({ name: 'buyContracts.create' })"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="propertyName" slot-scope="props">
          <router-link
            :to="{
              name: 'properties.edit',
              params: { id: props.row.propertyId },
            }"
            >{{ props.row.propertyName }}</router-link
          >
        </template>
        <template slot="customerName" slot-scope="props">
          <router-link
            :to="{
              name: 'customers.edit',
              params: { id: props.row.customerId },
            }"
            >{{ props.row.customerName }}</router-link
          >
        </template>
        <template slot="buyingDate" slot-scope="props">
          {{ formatDate(props.row.buyingDate) }}
        </template>
        <template slot="buyingPrice" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.buyingPrice.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
        <template slot="discount" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.discount.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
        <template
          slot="controls"
          slot-scope="props"
          v-if="
            hasPermission(permissions.buyContractsShow) ||
            hasPermission(permissions.buyContractsDelete)
          "
        >
          <control-buttons
            :props="props"
            :show-link="
              hasPermission(permissions.buyContractsShow) && 'buyContracts.show'
            "
            :delete-link="
              hasPermission(permissions.buyContractsDelete) &&
              'buyContracts/DELETE'
            "
            v-on:successAction="loadData(true)"
          />
          <button
            class="btn btn-outline-success m-1"
            @click="
              $router.push({
                name: 'buyContract.attachments',
                params: { id: props.row.id },
              })
            "
          >
            <i class="fal fa-file"></i>
          </button>
          <button
            v-if="projectTenant() === 'MassHills'"
            class="btn btn-danger m-1"
            @click="
              $router.push({
                name: 'buyContract.expenses',
                params: { id: props.row.id },
              })
            "
          >
            <i class="fal fa-money-bill-alt"></i>
          </button>
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { BuyContract } from "@/models/BuyContract";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  serverParams,
  tableActions,
  tableConfigure,
  permissions,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "BuyContractList",
  components: {
    ControlButtons,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
  },
  data() {
    return {
      isLoading: false,
      model: new BuyContract(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.buyContractsCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t("buyContractsList")),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions,
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0]
        ? queryParams.sort[0].name
        : "Id";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "DESC";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
  },
};
</script>
