<template>
  <div v-if="hasPermission(permissions.cancelContractsShow)">
    <content-header>{{ $t('showCancelContract') }}</content-header>
    <v-content>
      <b-card>
        <template #header v-if="projectTenant() === 'DiplomatiSafiran'">
          <b-button variant="primary" @click="printVoucher">{{ $t('print') }}</b-button>
        </template>
        <b-card-body>
          <p class="lead">
            {{ $t('buyContract') }}:
            <router-link :to="{name: 'buyContracts.show', params: {id: data.buyContractId }}">{{ data.buyContractId }}</router-link>
          </p>
          <p class="lead">
            {{ $t('customer') }}:
            <router-link :to="{name: 'customers.edit', params: {id: data.buyContract.customer.id }}">{{ `${data.buyContract.customer.firstName} ${data.buyContract.customer.middleName} ${data.buyContract.customer.lastName}` }}</router-link>
          </p>
          <p class="lead">
            {{ $t('property') }}:
            <router-link :to="{name: 'properties.edit', params: {id: data.buyContract.property.id }}">{{ data.buyContract.property.name }}</router-link>
          </p>
          <p class="lead">{{ $t('returnedPrice') }}: {{ parseFloat(data.returnedPrice).toLocaleString() }}</p>
          <p class="lead">{{ $t('remainPrice') }}: {{ parseFloat(data.remainPrice).toLocaleString() }}</p>
          <p class="lead">{{ $t('date') }}: {{ formatDate(data.date) }}</p>
          <p class="lead">{{ $t('note') }}: {{ data.note }}</p>
          <p class="lead">{{ $t('attachment') }}: </p>
          <b-link v-if="data.attachment" :href="getImageUrl(data.attachment)" target="_blank">
            <b-img
                :src="getImageUrl(data.attachment)"
                style="width: 800px; height: 400px"
                thumbnail rounded fluid
                :alt="data.propertyId"
            ></b-img>
          </b-link>
          <p v-else>-</p>
          <add-attachment-modal update-url="cancelContracts/UPDATE_ATTACHMENT" :id="data.id" @success="loadData" />
        </b-card-body>
      </b-card>
      <cancel-contract-design-diplomati-safiran v-if="projectTenant() === 'DiplomatiSafiran'" :data="data" />
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading"
import CancelContractDesignDiplomatiSafiran from "./CancelContractDesignDiplomatiSafiran";
import {permissions, serverParams} from "@/plugins/constants";
import AddAttachmentModal from "@/components/AddAttachmentModal";

export default {
  name: "CancelContractShow",
  components: {AddAttachmentModal, CancelContractDesignDiplomatiSafiran, IsLoading, 'v-content': Content, ContentHeader},
  data() {
    return {
      isLoading: false,
      data: [],
      permissions: permissions
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      this.isLoading = true;
      this.$store.dispatch('cancelContracts/GET_ONE', this.$route.params.id).then((response) => {
        this.data       = response.data;
        this.isLoading  = false;
      }).catch(() => this.isLoading = false)
    },
    printVoucher() {
      let printContents = document.getElementById(`cancel-contract-voucher-receipt`).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload()
    },
  }
}
</script>
