import {HTTP} from "@/plugins/axios";
import mixin from "@/mixins";

const projects = {
    namespaced: true,
    state: {
        projects: {},
        getOneProject: {},
        updatedProject: {},
        currentProject: {}
    },
    mutations: {
        SET_PROJECTS (state, value) { state.projects = value },
        SET_GET_PROJECT (state, value) {state.getOneProject = value},
        SET_UPDATED_PROJECT (state, value) { state.updatedProject = value },
        SET_CURRENT_PROJECT (state, value) {
            localStorage.setItem('projectId', value);
            state.currentProject = value
        },
    },
    actions: {
        GET_ALL({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Projects?start=0&end=100`)
                    .then((response) => {
                        commit('SET_PROJECTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROJECTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Projects/${id}`)
                    .then((response) => {
                        commit('SET_GET_PROJECT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_PROJECT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/Projects/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROJECT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROJECT', {})
                        reject(error)
                    });
            })
        },
        CHANGE_PROJECT({ commit }, projectId) {
            commit('SET_CURRENT_PROJECT', projectId)
        },
    },
    getters : {
        projects: state => state.projects,
        getOneProject: state => state.getOneProject,
        updatedProject: state => state.updatedProject,
        currentProject: state => state.currentProject,
    }
}

export default projects;
