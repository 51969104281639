<template>
  <div v-if="hasPermission(permissions.reportsBuyContracts)">
    <content-header>{{ $t("buyContractReport") }}</content-header>
    <v-content>
      <b-row class="mb-4 justify-content-center align-items-end">
        <b-col sm="12" md="4">
          <label for="fromDate">{{ $t("fromDate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="fromDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col sm="12" md="4">
          <label for="toDate">{{ $t("toDate") }}</label>
          <flat-pickr
            id="toDate"
            v-model="toDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col sm="12" md="4">
          <label for="noOfInstallments">{{ $t("noOfInstallments") }}</label>
          <b-form-select
            id="noOfInstallments"
            v-model="installmentsNumber"
            :options="installmentsNumberOptions"
          ></b-form-select>
        </b-col>
        <!--        <b-col sm="12" md="4">-->
        <!--          <label for="installmentPeriod">{{ $t('installmentPeriod') }}</label>-->
        <!--          <b-form-select-->
        <!--              id="installmentPeriod"-->
        <!--              v-model="installmentsPeriod"-->
        <!--              :options="installmentsPeriodOptions"-->
        <!--          ></b-form-select>-->
        <!--        </b-col>-->
      </b-row>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="buyingPrice" slot-scope="props">
          {{ props.row.buyingPrice.toLocaleString() }}
        </template>
        <template slot="paidPrice" slot-scope="props">
          {{ props.row.paidPrice.toLocaleString() }}
        </template>
        <template slot="remainPrice" slot-scope="props">
          {{ props.row.remainPrice.toLocaleString() }}
        </template>
        <template slot="date" slot-scope="props">
          {{ formatDate(props.row.date) }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { BuyContractReport } from "@/models/BuyContractReport";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "BuyContractReport",
  components: {
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      model: new BuyContractReport(),
      columns: [],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t("buyContractReport"), true, false),
      serverParams: serverParams,
      selectedRows: [],
      selectedTypes: [],
      fromDate: this.$helpers.getCurrentDate(-1),
      toDate: this.$helpers.getCurrentDate(),
      permissions: permissions,
      installmentsNumber: 0,
      installmentsNumberOptions: [
        { value: 0, text: this.$t("all") },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
      ],
      installmentsPeriod: 0,
      installmentsPeriodOptions: [
        { value: 0, text: this.$t("all") },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
      ],
    };
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    installmentsNumber: function () {
      this.resetServerParams();
      this.loadData();
    },
    installmentsPeriod: function () {
      this.resetServerParams();
      this.loadData();
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  mounted() {
    this.loadData(true);
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("reports/GET_BUY_CONTRACT_REPORT", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        count: this.installmentsNumber,
        fts: this.installmentsPeriod,
      };
      this.loadData();
    },
    resetServerParams() {
      serverParams._start = 0;
      serverParams._end = 10;
      serverParams._sort = "";
      serverParams._order = "";
      serverParams._search = "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        count: this.installmentsNumber,
        fts: this.installmentsPeriod,
      };
    },
  },
};
</script>
