<template>
  <div v-if="hasPermission(permissions.withdrawsList)">
    <content-header>{{ $t("withdraws") }}</content-header>
    <v-content>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @on-create="$router.push({ name: 'withdraws.create' })"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.mcPrice.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format("YYYY-MM-DD") }}
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment
            :attachment="props.row.attachment"
            :title="props.row.attachment"
          />
        </template>
        <template
          slot="controls"
          slot-scope="props"
          v-if="
            hasPermission(permissions.withdrawsEdit) ||
            hasPermission(permissions.withdrawsDelete)
          "
        >
          <control-buttons
            :props="props"
            :edit-link="
              hasPermission(permissions.withdrawsEdit) && 'withdraws.edit'
            "
            :delete-link="
              hasPermission(permissions.withdrawsDelete) && 'withdraws/DELETE'
            "
            v-on:successAction="loadData(true)"
          />
          <b-button
            v-if="hasPermission(permissions.withdrawsPrint)"
            @click="prePrintVoucher(props.row.id)"
            variant="success"
            ><i class="fas fa-print"></i
          ></b-button>
          <div
            :id="'withdrawVoucherReceipt_' + props.row.id"
            style="display: none"
          >
            <div v-if="projectTenant() === 'Candle'">
              <withdraw-voucher-candle
                :voucherNo="props.row.id"
                :voucherDate="props.row.date"
                :paidTo="props.row.name"
                :amount="props.row.mcPrice"
                :reason="props.row.withdrawType.name"
                :note="props.row.note"
                type="پارە پێدان"
              />
              <withdraw-voucher-candle
                :voucherNo="props.row.id"
                :voucherDate="props.row.date"
                :paidTo="props.row.name"
                :amount="props.row.mcPrice"
                :reason="props.row.withdrawType.name"
                :note="props.row.note"
                type="پارە پێدان"
              />
            </div>
            <div v-if="projectTenant() === 'UniqueTowers'">
              <WithdrawVoucherUniqueTowers
                :voucherNo="props.row.id"
                :voucherDate="props.row.date"
                :paidTo="props.row.name"
                :amount="props.row.mcPrice"
                :reason="props.row.withdrawType.name"
                :note="props.row.note"
                type="پارە پێدان"
              />
              <WithdrawVoucherUniqueTowers
                :voucherNo="props.row.id"
                :voucherDate="props.row.date"
                :paidTo="props.row.name"
                :amount="props.row.mcPrice"
                :reason="props.row.withdrawType.name"
                :note="props.row.note"
                type="پارە پێدان"
              />
            </div>
            <withdraw-voucher-royal
              v-else
              :voucherNo="props.row.id"
              :voucherDate="props.row.date"
              :paidTo="props.row.withdrawer"
              :amount="props.row.mcPrice"
              :reason="props.row.withdrawType.name"
              :note="props.row.note"
            />
          </div>
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { Withdraw } from "@/models/Withdraw";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";
import WithdrawVoucherCandle from "@/views/voucherDesigns/WithdrawVoucherCandle";
import WithdrawVoucherUniqueTowers from "@/views/voucherDesigns/WithdrawVoucherUniqueTowers";
import WithdrawVoucherRoyal from "@/views/voucherDesigns/WithdrawVoucherRoyal";

export default {
  name: "WithdrawList",
  components: {
    WithdrawVoucherRoyal,
    WithdrawVoucherUniqueTowers,
    WithdrawVoucherCandle,
    ShowAttachment,
    ControlButtons,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
  },
  data() {
    return {
      isLoading: false,
      model: new Withdraw(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.withdrawsCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t("withdrawList")),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions,
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("withdraws/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
    prePrintVoucher(index) {
      let printContents = document.getElementById(
        `withdrawVoucherReceipt_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>
