import { permissions } from "@/plugins/constants";

export default {
  superadmin: ["*"],
  admin: ["*"],
  accountant: [
    //! Dashboard
    permissions.dashboardCard,
    permissions.dashboardTodaySummary,
    permissions.dashboardGeneralData,
    permissions.dashboardPayments,
    permissions.dashboardMap,

    //! Buy Contract
    permissions.buyContractsList,
    permissions.buyContractsShow,
    permissions.buyContractsShowPrintPayments,
    permissions.buyContractsShowPay,
    permissions.buyContractsShowPayPrint,
    permissions.buyContractsShowUnPayButton,
    permissions.buyContractsShowTransferPaymentProcessButton,

    //! Customers
    permissions.customersList,
    permissions.customersEdit,
    permissions.customersShow,
    permissions.customersShowAttachments,
    permissions.customersShowContracts,

    //! Withdraws
    permissions.withdrawsList,
    permissions.withdrawsCreate,
    permissions.withdrawsEdit,
    permissions.withdrawsDelete,
    permissions.withdrawsShow,
    permissions.withdrawsPrint,

    //! Deposits
    permissions.depositsList,
    permissions.depositsCreate,
    permissions.depositsEdit,
    permissions.depositsDelete,
    permissions.depositsShow,
    permissions.depositsPrint,

    //! Expenses
    permissions.expensesList,
    permissions.expensesCreate,
    permissions.expensesEdit,
    permissions.expensesDelete,
    permissions.expensesShow,
    permissions.expensesPrint,

    //! Transfers
    permissions.transfersList,
    permissions.transfersCreate,
    permissions.transfersEdit,
    permissions.transfersDelete,
    permissions.transfersShow,
    permissions.transfersPrint,

    //! Reports
    permissions.reportsProperty,
    permissions.reportsTransactions,
    permissions.reportsExpenses,
    permissions.reportsWithdraws,
    permissions.reportsDeposits,
    permissions.reportsBuyContracts,
    permissions.reportsPayment,
    permissions.reportsSafeBoxTransactions,
    permissions.reportsBuyContractsDetails,
    permissions.reportsExpensesByType,
    permissions.propertyExpenseTypesList,
    permissions.propertyExpenseTypesCreate,
    permissions.propertyExpenseTypesEdit,
    permissions.propertyExpenseTypesDelete,
    permissions.reportsForgivePrice,

    //! Expense Types
    permissions.expenseTypesList,
    permissions.expenseTypesCreate,
    permissions.expenseTypesEdit,
    permissions.expenseTypesDelete,

    //! Withdraw Types
    permissions.withdrawTypesList,
    permissions.withdrawTypesCreate,
    permissions.withdrawTypesEdit,
    permissions.withdrawTypesDelete,

    //! Deposit Type
    permissions.depositTypesList,
    permissions.depositTypesCreate,
    permissions.depositTypesEdit,
    permissions.depositTypesDelete,

    //! Investors
    permissions.investorsList,
    permissions.investorsCreate,
    permissions.investorsDelete,
    permissions.investorsEdit,
  ],
  sales: [
    //! Dashboard
    permissions.dashboardCard,
    permissions.dashboardMap,

    //! Customers
    permissions.customersList,
    permissions.customersCreate,
    permissions.customersEdit,
    permissions.customersShow,
    permissions.customersShowAttachments,
    permissions.customersShowContracts,

    //! Properties
    permissions.propertiesList,
    permissions.propertiesShow,
    permissions.propertiesShowAttachments,
    permissions.propertiesShowExpenses,
    permissions.propertiesShowFollowups,
    permissions.propertiesShowContracts,
    permissions.propertiesShowFeatures,
    permissions.propertiesShowHolds,

    //! Property Hold
    permissions.propertyHoldList,
    permissions.propertyHoldCreate,
    permissions.propertyHoldEdit,
    permissions.propertyHoldDelete,
    permissions.propertyHoldShow,

    //! Lead Visits
    permissions.leadVisitsList,
    permissions.leadVisitsCreate,
    permissions.leadVisitsDelete,

    //! Lead Customers
    permissions.leadCustomersList,
    permissions.leadCustomersCreate,
    permissions.leadCustomersEdit,
    permissions.leadCustomersDelete,

    //! Lead Sources
    permissions.leadSourcesList,
    permissions.leadSourcesCreate,
    permissions.leadSourcesEdit,
    permissions.leadSourcesDelete,

    //! Lead Sub Sources
    permissions.leadSubSourcesList,
    permissions.leadSubSourcesCreate,
    permissions.leadSubSourcesEdit,
    permissions.leadSubSourcesDelete,

    //! Reports
    permissions.reportsLeadVisits,
    permissions.reportsLeadVisitsByDayOfWeek,
    permissions.reportsDailyLeadVisits,
    permissions.reportsPerSources,
  ],
  lawyer: [
    //! Dashboard
    permissions.dashboardCard,
    permissions.dashboardMap,

    //! Properties
    permissions.propertiesList,
    permissions.propertiesShow,
    permissions.propertiesShowAttachments,
    permissions.propertiesShowExpenses,
    permissions.propertiesShowFollowups,
    permissions.propertiesShowContracts,
    permissions.propertiesShowFeatures,
    permissions.propertiesShowHolds,

    //! Property Hold
    permissions.propertyHoldList,
    permissions.propertyHoldCreate,
    permissions.propertyHoldEdit,
    permissions.propertyHoldDelete,
    permissions.propertyHoldShow,

    //! Buy Contracts
    permissions.buyContractsList,
    permissions.buyContractsCreate,
    permissions.buyContractsDelete,
    permissions.buyContractsShow,
    permissions.buyContractsShowPrintContracts,
    permissions.buyContractsShowPrintPayments,
    permissions.buyContractsShowPayPrint,

    //! Transfer Contract
    permissions.transferContractsList,
    permissions.transferContractsCreate,
    permissions.transferContractsDelete,
    permissions.transferContractsShow,

    //! Cancel Contracts
    permissions.cancelContractsList,
    permissions.cancelContractsCreate,
    permissions.cancelContractsDelete,
    permissions.cancelContractsShow,

    //! Customers
    permissions.customersList,
    permissions.customersEdit,
    permissions.customersShow,
    permissions.customersShowAttachments,
    permissions.customersShowContracts,
  ],
  viewer: [
    //! Dashboard
    permissions.dashboardCard,
    permissions.dashboardTodaySummary,
    permissions.dashboardGeneralData,
    permissions.dashboardPayments,
    permissions.dashboardMap,

    //! Properties
    permissions.propertiesList,
    permissions.propertiesShow,
    permissions.propertiesShowAttachments,
    permissions.propertiesShowExpenses,
    permissions.propertiesShowFollowups,
    permissions.propertiesShowContracts,
    permissions.propertiesShowFeatures,
    permissions.propertiesShowHolds,

    //! Property Hold
    permissions.propertyHoldList,
    permissions.propertyHoldShow,

    //! Buy Contracts
    permissions.buyContractsList,
    permissions.buyContractsShow,
    permissions.buyContractsShowPrintContracts,
    permissions.buyContractsShowPrintPayments,
    permissions.buyContractsShowPayPrint,

    //! Transfer Contract
    permissions.transferContractsList,
    permissions.transferContractsShow,

    //! Cancel Contracts
    permissions.cancelContractsList,
    permissions.cancelContractsShow,

    //! Upcoming Payments
    permissions.upcomingPaymentsList,

    //! Customers
    permissions.customersList,
    permissions.customersShow,
    permissions.customersShowAttachments,
    permissions.customersShowContracts,

    //! Lead Visits
    permissions.leadVisitsList,

    //! Lead Customers
    permissions.leadCustomersList,

    //! Lead Sources
    permissions.leadSourcesList,

    //! Lead Sub Sources
    permissions.leadSubSourcesList,

    //! Expenses
    permissions.expensesList,
    permissions.expensesShow,
    permissions.expensesPrint,

    //! Withdraws
    permissions.withdrawsList,
    permissions.withdrawsShow,
    permissions.withdrawsPrint,

    //! Deposits
    permissions.depositsList,
    permissions.depositsShow,
    permissions.depositsPrint,

    //! Users
    permissions.usersList,

    //! Reports
    permissions.reportsLeadVisits,
    permissions.reportsLeadVisitsByDayOfWeek,
    permissions.reportsDailyLeadVisits,
    permissions.reportsPerSources,
    permissions.reportsProperty,
    permissions.reportsTransactions,
    permissions.reportsExpenses,
    permissions.reportsWithdraws,
    permissions.reportsDeposits,
    permissions.reportsBuyContracts,
    permissions.reportsSafeBoxTransactions,
    permissions.reportsBuyContractsDetails,
    permissions.reportsExpensesByType,

    //! Property Types
    permissions.propertyTypesList,

    //! Features
    permissions.featuresList,

    //! Property Expense Types
    permissions.propertyExpenseTypesList,

    //! Expense Types
    permissions.expenseTypesList,

    //! Withdraw Types
    permissions.withdrawTypesList,

    //! Deposit Types
    permissions.depositTypesList,
  ],
  accountantAssistant: [
    //! Dashboard
    permissions.dashboardCard,

    //! Withdraws
    permissions.withdrawsList,
    permissions.withdrawsCreate,
    permissions.withdrawsEdit,
    permissions.withdrawsDelete,
    permissions.withdrawsShow,
    permissions.withdrawsPrint,

    //! Deposits
    permissions.depositsList,
    permissions.depositsCreate,
    permissions.depositsEdit,
    permissions.depositsDelete,
    permissions.depositsShow,
    permissions.depositsPrint,

    //! Expenses
    permissions.expensesList,
    permissions.expensesCreate,
    permissions.expensesEdit,
    permissions.expensesDelete,
    permissions.expensesShow,
    permissions.expensesPrint,

    //! Reports
    permissions.reportsExpenses,
    permissions.reportsWithdraws,
    permissions.reportsDeposits,
    permissions.reportsExpensesByType,
    permissions.propertyExpenseTypesList,
    permissions.propertyExpenseTypesCreate,
    permissions.propertyExpenseTypesEdit,
    permissions.propertyExpenseTypesDelete,

    //! Expense Types
    permissions.expenseTypesList,
    permissions.expenseTypesCreate,
    permissions.expenseTypesEdit,
    permissions.expenseTypesDelete,

    //! Withdraw Types
    permissions.withdrawTypesList,
    permissions.withdrawTypesCreate,
    permissions.withdrawTypesEdit,
    permissions.withdrawTypesDelete,

    //! Deposit Type
    permissions.depositTypesList,
    permissions.depositTypesCreate,
    permissions.depositTypesEdit,
    permissions.depositTypesDelete,
  ],
  dataentry: [
    //! Dashboard
    permissions.dashboardMap,
    //! Property Hold
    permissions.propertyHoldList,
    permissions.propertyHoldCreate,
    permissions.propertyHoldEdit,
    permissions.propertyHoldDelete,
    permissions.propertyHoldShow,
  ],
};
