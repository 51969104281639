import { i18n } from "@/plugins/i18n";

export class SafeBoxTransaction {
  columns = [];
  id = "id";

  fillAbles = [
    ["referenceNumber", i18n.t("referenceNumber"), true],
    ["mcPrice", i18n.t("price"), true],
    ["lost", i18n.t("withdraw"), true],
    ["reason", i18n.t("reason"), true],
    ["table", i18n.t("type"), true],
    ["date", i18n.t("date"), true],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
