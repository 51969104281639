import { i18n } from "../plugins/i18n";

export class TransferContract {
  columns = [];
  id = "id";

  fillAbles = [
    ["contractNumber", i18n.t("contractNumber"), true],
    ["oldCustomer", i18n.t("fromCustomer"), true],
    ["newCustomer", i18n.t("toCustomer"), true],
    ["oldContractId", i18n.t("oldContract"), true],
    ["newContractId", i18n.t("newContract"), true],
    ["transferDate", i18n.t("date"), true],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
