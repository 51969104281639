import {HTTP} from "@/plugins/axios";

const mainDashboard = {
    namespaced: true,
    state: {
        projectsInfo: {},
    },
    mutations: {
        SET_PROJECTS_INFO (state, value) { state.projectsInfo = value },
    },
    actions: {
        GET_PROJECTS_INFO({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GeneralDashboard`)
                    .then((response) => {
                        commit('SET_PROJECTS_INFO', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROJECTS_INFO', {})
                        reject(error)
                    });
            })
        }
    },
    getters : {
        projectsInfo: state => state.projectsInfo,
    }
}

export default mainDashboard;
