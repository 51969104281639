<template>
  <b-link v-if="attachment" :href="getImageUrl(attachment)" target="_blank">
    <b-img
        :src="getImageUrl(attachment)"
        style="width: 200px; height: 100px"
        thumbnail rounded fluid
        :alt="title"
    ></b-img>
  </b-link>
  <p v-else>-</p>
</template>

<script>
export default {
  name: "ShowAttachment",
  props: {
    attachment: {},
    title: {
      default: '-'
    }
  }
}
</script>
