export default {
  // Start Generals
  kurdish: "کوردی",
  kurdish_badini: "کوردی بادینی",
  arabic: "عربی",
  english: "English",
  language: "لغة",
  controls: "ضوابط",
  dashboard: "لوحة القيادة",
  kurdishName: "إسم كردي",
  arabicName: "إسم عربي",
  englishName: "إسم إنكليزي",
  isoCode: "رمز ISO",
  postalCode: "الرمز البريدي",
  city: "مدينة",
  noResultsFound: "لا توجد نتائج",
  totalRecords: "إجمالي التسجيلات",
  save: "خزن",
  yes: "نعم",
  no: "لا",
  delete: "حذف",
  someThingWentWrong: "حدث خطأ",
  settings: "الإعدادات",
  listIsEmpty: "القائمة فارغة",
  id: "الرقم التعريفي",
  addedSuccessfully: "تمت إضافته بنجاح",
  // End Generals

  // Start Custom Components
  isRequired: "إنه مطلوب",
  remove: "إزالة",
  fileIsTooBig: "الملف كبير جدا",
  typeNotSupported: "النوع غير مدعوم",
  maxFileUploadReach: "وصول أقصى حد من تحميل الملف",
  pleaseSelect: "من فضلك حدد",
  mustHaveAtLeast: "يجب أن يحتوي على الأقل",
  letters: "حروف",
  mustNotHaveGreaterThen: "لا يجب أن يحتوي على أكثر من",
  attachments: "ملصقات",
  close: "غلق",
  search: "بحث",
  multipleDelete: "حذف متعدد",
  multipleRestore: "إرجاع متعدد",
  multipleForceDelete: "حذف متعدد بالقوة",
  export: "تصدير",
  noRowsSelected: "لا صفوف محدودة",
  youWantToDeletePermanentlySelectedRows: "أنت تريد حذف الصفوف المحدودة نهائيا",
  youWantToRestorePermanentlySelectedRows:
    "أنت تريد إرجاع الصفوف المحدودة نهائيا",
  yesDelete: "نعم, حذف",
  yesRestore: "نعم, إرجاع",
  yourDataHasBeenDeletedPermanently: "بياناتك تم حذفها نهائيا",
  yourDataHasBeenRestoredPermanently: "بياناتك تم إرجاعها نهائيا",
  // End Custom Components

  // Start Routes
  editProperties: "تعديل الملكيات",
  createCustomer: "إضافة عميل",
  editCustomer: "تعديل العميل",
  createExpenseAttachment: "إضافة ملصق المصروفات",
  createPropertyFollowUp: "إضافة متابعة الخاصية",
  editLeadSubSource: "تعديل مصدر الفرعي للقيادة",
  buyContractCreate: "إضافة عقد الشراء",
  showBuyContracts: "عرض عقد الشراء",
  voucherReceipt: "فاتورة القسيمة",
  holds: "معلقات",
  // End Routes

  // Start 404
  pageNotFound: "الصفحة غير موجودة",
  oopsThePageYouWereLookingForDoesntExist:
    "للأسف!! الصفحة التي تبحث عنها غير موجودة",
  backToDashboard: "العودة إلى لوحة القيادة",
  notFound: "غير موجود",
  // End 404

  // Start 403
  notAuthorized: "غير موثق",
  oopsYouAreNotAllowedToSeeThisPage: "للأسف!! أنت غير مرخص لرؤية هذه الصفحة",
  // End 403

  // Start Login
  username: "إسم المستخدم",
  password: "كلمة السر",
  login: "تسجيل دخول",
  // End Login

  // Start Sidebar
  customers: "عملاء",
  buyers: "عملاء",
  list: "قائمة",
  create: "إضافة",
  properties: "ملكيات",
  additionalPlotArea: "المساحة الإضافة",
  createAndNew: "إضافة و جديد",
  propertyHolds: "عقد الملكية",
  propertyTypes: "أنواع الملكيات",
  villaTypes: "أنواع الفيلا",
  propertyExpenseTypes: "أنواع مصاريف الملكية",
  features: "خصائص",
  buyContracts: "شراء العقود",
  transferContracts: "تحويل العقود",
  cancelContracts: "إلغاء العقود",
  isCash: "هو نقدي؟",
  leadSources: "مصادر القيادة",
  leadSubSources: "مصادر الفرعية للقيادة",
  leadCustomers: "قيادة العملاء",
  leadVisits: "قيادة الزيارات",
  expenses: "المصاريف",
  expenseTypes: "أنواع المصاريف",
  reports: "التقريرات",
  leadReports: "قيادة التقريرات",
  transactions: "المعاملات",
  logout: "تسجيل الخروج",
  initials: "البدائيات",
  contracts: "العقود",
  leads: "قيادات",
  createLeadVisits: "إضافة قيادة الزيارات",
  createLeadCustomers: "إضافة قيادة العملاء",
  accounting: "الحسابات",
  // End Sidebar

  // Start Main Dashboard
  mainDashboard: "لوحة القيادة الرئيسية",
  projectDashboard: "لوحة قيادة المشروع",
  totalProperties: "إجمالي الملكيات",
  totalExpenses: "إجمالي المصاريف",
  remainingPayableAmount: "المبلغ المتبقي المستحق",
  // End Main Dashboard

  // Start Dashboard
  todayVisits: "زيارات اليوم",
  totalVisits: "إجمالي الزيارات",
  todayContracts: "عقود اليوم",
  totalContracts: "إجمالي العقود",
  todaySummary: "ملخص اليوم",
  generalData: "البيانات العامة",
  invoiceNumber: "رقم الفاتورة",
  referenceNumber: "رقم المرجع",
  withdraw: "انسحب",
  reason: "سبب",
  expiredPropertyHolds: " ملكية معلقة منتهية الصلاحية",
  fromDate: "من تأريخ",
  toDate: "إلى تأريخ",
  upcomingPayments: "المدفوعات القادمة",
  upcomingPaymentsList: "قائمة المدفوعات القادمة",
  payments: "المدفوعات",
  next10Days: "10 أيام التالية",
  next20Days: "20 أيام التالية",
  delayed: "تأجل",
  payableAmount: "المبلغ",
  total: "إجمالي",
  remain: "الباقي",
  remain2: "الباقي",
  sold: "بيع",
  notSold: "الغیر مباع",
  hold: "معلق",
  back: "رجوع",
  soldProperties: "المباعة",
  remainedProperties: "الباقية",
  onHoldProperties: "المعلقة",
  reset: "إعادة تعيين",
  cancelledContracts: "الغقود الملغية",
  collectedMoney: "النقود المجموعة",
  currentContractCount: "رقم العقود الحالية",
  propertyExpenses: "مصاريف الملكيات",
  remainPrices: "ألأسعار المتبقية المتبقي",
  returnedPrices: "ألأسعار المرجوعة",
  remainAmount: "الكمية المتبقية",
  returnedAmount: "الكمية المرجوعة",
  safeBox: "صندوق الأمان",
  remainToCollect: "متبقي للتجميع",
  price: "سعر",
  totalSells: "إجمالي المبيعات",
  holdInfo: "معلومات المعلق",
  // End Dashboard

  // Start Customers
  customersList: "قائمة العملاء",
  firstName: "إلأسم",
  middleName: "إسم الأب",
  lastName: "إسم الجد",
  gender: "جنس",
  birthdate: "تأريخ الميلاد",
  address: "عنوان",
  email: "بريد إلكتروني",
  phone: "رقم الهاتف",
  phone2: "رقم الهاتف 2",
  nationality: "جنسية",
  idCard: "بطاقة الهوية",
  foodCard: "بطاقة الطعام",
  informationCard: "بطاقة المعلومات",
  placementCard: "بطاقة التوضيف",
  residencyCard: "بطاقة الإقامة",
  securitySupport: "الدعم الأمني",
  note: "ملاحظة",
  job: "وضيفة",
  jnsia: "جنسية",
  fullName: "الأسم الكامل",
  male: "ذكر",
  hasAccount: "لديه حساب",
  edit: "تعديل",
  attachment: "ملصق",
  createCustomerAttachment: "إضافة ملصق العميل",
  sharable: "قابل للنشر",
  is360: "هو 360",
  title: "عنوان",
  shareableForCustomer: "قابل للنشر للعميل",
  share: "نشر",
  deletedSuccessfully: "تم الحذف بنجاح",
  // End Customers

  // Start Properties
  createProperties: "إضافة الملكيات",
  name: "إسم",
  priceMeter: "سعر المتر",
  floor: "طابق",
  villaNumber: "رقم الفيلا",
  propertyNumber: "رقم الملكية",
  villaType: "نوع الفيلا",
  additionalInfo: "معلومات إضافية",
  zone: "منطقة",
  tower: "برج",
  extraProperty1: "إطلالە",
  extraProperty2: "واجهە",
  apartmentType: "نوع الشقة",
  apartmentTypes: "أنواع الشقق",
  apartmentTypesList: "قائمة أنواع الشقق",
  apartment: "شقة",
  apartmentPrice: "سعر الشقة",
  apartmentNo: "رقم الشقة",
  block: "مجموعة",
  streetNumber: "رقم الشارع",
  streetWidth: "عرض الشارع",
  rooms: "غرف",
  floors: "طوابق",
  realEstateAgent: "عقارات",
  propertyType: "نوع الملكية",
  plotArea: "مساحة البناء",
  buildUpArea: "وحدة السعر لأرض إضافي",
  holdArea: "المساحة المعلقة",
  soldArea: "المساحة المباعة",
  meterPrice: "السعر لكل متر",
  amount: "كمية",
  area: "مساحة كلية",
  variance: "التعدد",
  extraCost: "المصاريف الإضافية",
  initialPrice: "السعر البدائي",
  feature: "خاصية",
  finalPrice: "السعر النهائي",
  numberOfRooms: "عدد الغرف",
  followUp: "المتابعة",
  createPropertyAttachment: "إضافة ملصق الملكية",
  date: "تأريخ",
  deadline: "اخر يوم",
  done: "تم",
  propertiesList: "قائمة الملكيات",
  transferred: "محولة",
  cancelled: "ملغية",
  area2: "مساحة",
  meterPrice2: "السعر متر",
  meterPriceAfterDiscount: "س.م.خ",
  buyPriceAfterDiscount: "س.ب.خ.",
  totalPaidPrice: "م.م.ك",
  purchase2: "شراء",
  // End Properties

  // Start Property Hold
  propertyName: "إسم الملكية",
  holdPayment: "تعليق الدفع",
  propertyHoldsList: "قائمة الملكيات المعلقة",
  createPropertyHold: "إضافة ملكية معلقة",
  showPropertyHold: "عرض ملكية معلقة",
  property: "ملكية",
  isCustomer: "هل هو عمیل؟",
  payType: "نوع الدفع",
  bankName: "إسم البنك",
  bankSlipNumber: "رقم إيصال البنك",
  bankSlipDate: "تاريخ إيصال البنك",
  bankSlipNote: "ملاحظة إيصال البنك",
  isBankPayment: "هل هو دفع بنکی؟",
  bankSlipAttachment: "ملحقات إيصال البنك",
  // End Property Hold

  // Start Property Type
  propertyTypesList: "قائمة أنواع الملكيات",
  createPropertyType: "إضافة نوع ملكية",
  editPropertyType: "تعديل نوع ملكية",
  // End Property Type

  // Start Feature
  extraPrice: "سعر إضافي",
  featuresList: "قائمة الخصائص",
  createFeature: "إضافة خاصية",
  editFeature: "تعديل خاصية",
  // End Feature

  // Start Buy Contract
  contractNumber: "رقم العقد",
  contractNumber2: "رقم",
  customerName: "إسم العميل",
  buyingDate: "تأريخ الشراء",
  buyingPrice: "سعر الشراء",
  buyContractsList: "قائمة عقود البيع",
  createBuyContract: "إضافة عقد البيع",
  paidPrice: "المدفوعات",
  street: "شارع",
  propertyPrice: "سعر الملكية",
  installments: "التقسيطات",
  noOfInstallments: "عدد التقسيطات",
  periodOfInstallments: "مدة التقسيطات",
  installment: "قسط",
  percentage: "نسبة مئوية",
  percentageOfInstallments: "النسبة المئوية التقسيطات",
  prepayment: "دفع المقدمة",
  percent: "نسبة",
  deliveringKeys: "تسليم المفاتيح",
  discount: "تخفيض",
  showDiscount: "عرض التخفيض",
  pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice:
    "من فضلك أعيد النظر في الأقساط إجمالي الأقساط لا يساوي الدفع المبدئي للملكية",
  showContract: "عرض العقد",
  print: "طبع",
  withExpense: "مع المصاريف",
  printKurdishContract: "طبع العقد الكردي",
  printArabicContract: "طبع العقد العربي",
  printArabicContractIQD: " طبع العقد العربي بالدینار",
  isPaid: "هو مدفوع",
  action: "فعل",
  paidBy: "مدفوع من قبل",
  pay: "دفع",
  partialPaymentPrice: "سعر الدفع الجزئي",
  partialPaymentNote: "ملاحظة الدفع الجزئي",
  partialPaymentDate: "تأريخ الدفع الجزئي",
  partialPaymentDeadline: "اخر يوم محدد للدفع الجزئي",
  extraNote: "ملاحظة إضافية",
  partialPay: "الدفع الجزئي",
  areYouSure: "هل أنت متأكد؟",
  youWantToMakePayment: "هل تريد أن تدفع",
  noCancel: "لا, إلغاء",
  paymentSuccessfullyDone: "تم الدفع بنجاح",
  payment: "دفع",
  printPayments: "طبع المدفوعات",
  transferContract: "تحويل العقد",
  customer: "عميل",
  cancelContract: "إلغاء العقد",
  changeInstallments: "تغییر الأقساط",
  difference: "الفرق",
  addAttachments: "إضافة ملصقات",
  paymentToTransfer: "الدفع للنقل",
  transferPayment: "نقل الدفعة",
  paymentPriceAfter: "الدفعة بعد النقل",
  discountAr: "خصم ع",
  discountKu: "خصم ك",
  // End Buy Contract

  // Start Lead Sources
  leadSourcesList: "قائمة مصادر القيادة",
  createLeadSource: "إضافة مصدر قيادة",
  editLeadSource: "تعديل مصدر قيادة",
  // End Lead Sources

  // Start Lead Sub Sources
  leadSourceName: "إسم مصدر القيادة",
  leadSubSourcesList: "قائمة المصادر الفرعية للقيادة",
  createLeadSubSource: "إضافة مصدر فرعي للقيادة",
  leadSource: "مصدر القيادة",
  editSubLeadSource: "تعديل المصدر الفرعي  للقيادة",
  // End Lead Sub Sources

  // Start Lead Customers
  isMale: "هو ذكر",
  leadSubSourceName: "إسم قيادة المصدر الفرعي",
  state: "الحالة",
  males: "ذكور",
  females: "إناث",
  visitNote: "ملاحظة الزيارة",
  leadCustomersList: "قائمة قيادة العملاء",
  createLeadCustomer: "إضافة قيادة عميل",
  female: "أنثى",
  leadSubSource: "قيادة مصدر فرعي",
  customerNote: "ملاحظة العميل",
  editLeadCustomer: "تعديل قيادة العميل",
  // End Lead Customers

  // Start Lead Visits
  leadCustomer: "قيادة العميل",
  leadCustomerPhone: "رقم هاتف قيادة العميل",
  newState: "الحالة الجديدة",
  oldState: "الحالة السابقة",
  visitDate: "تأريخ الزيارة",
  leadVisitsList: "قائمة قيادة الزيارات",
  createLeadVisit: "إضافة قيادة الزيارة",
  // End Lead Visits

  // Start Expenses
  expenseType: "نوع المصروف",
  mcPrice: "سعر",
  qty: "كمية",
  mcTotalPrice: "السعر الإجمالي",
  expenseList: "قائمة المصاريف",
  createExpense: "إضافة المصاريف",
  editExpense: "تعديل المصاريف",
  safeBoxBalanceError:
    "الخزنة المتوفرة لا يمكن أن تكون أقل من المصاريف المدفوعة",
  // End Expenses

  // Start Expense Types
  expenseTypeList: "قائمة نوع المصاريف",
  createExpenseType: "إضافة نوع المصاريف",
  description: "وصف",
  editExpenseType: "تعديل نوع المصروف",
  // End Expense Types

  // Start Lead Reports
  selectDate: "تحديد التأريخ",
  leadVisitByDayOfWeek: "قيادة الزيارة بيوم الأسبوع",
  dailyLeadVisits: "قيادة الزيارات اليومية",
  perSources: "لكل مصدر",
  women: "مرأة",
  men: "رجل",
  day: "يوم",
  noOfGroups: "عدد المجموعات",
  visitsByDate: "الزيارات بالتأريخ",
  type: "نوع",
  detail: "تفاصيل",
  // End Expense Types

  // Start Transfer Contracts
  transferContractList: "قائمة تحويل العقد",
  fromCustomer: "من العميل",
  toCustomer: "إلى العميل",
  oldContract: "العقد السابق",
  newContract: "العقد الجديد",
  showTransferContract: "عرض تحويل العقد",
  printArabic: "طباعة العربية",
  printKurdish: "طباعة الكردية",
  printArabicOrder: "طباعة العربية(طلب)",
  printKurdishOrder: "طباعة الكردية(طلب)",
  // End Transfer Contracts

  // Start Cancel Contracts
  cancelContractList: "إلغاء قائمة العقد",
  returnedPrice: "السعر المرجوع",
  remainPrice: "السعر المتبقي",
  buyContract: "شراء العقد",
  showCancelContract: "عرض إلغاء العقد",
  // End Cancel Contracts

  // Start Property Expense Types
  propertyExpenseTypesList: "قائمة أنواع مصاريف الملكية",
  createPropertyExpenseType: "إضافة نوع مصروف الملكية",
  editPropertyExpenseType: "تعديل نوع مصروف الملكية",
  propertyExpenseType: "نوع مصروف الملكية",
  // End Property Expense Types

  // Start Property Report
  propertyReport: "تقرير الملكية",
  partialPaymentReport: "تقرير الدفع الجزئي",
  // End Property Report

  // Start Expense By Type
  expensesByType: "المصاريف حسب النوع",
  // End Expense By Type

  // Start Settings
  propertySettings: "إعدادات الملكية",
  contractSettings: "إعدادات العقد",
  contractTopSpaces: "المساحات العلوية للعقد",
  safeBoxSettings: "إعدادات الصندوق الآمن",
  defaultExpenseSafeBox: "الصندوق الآمن الافتراضي للنفقات",
  // End Settings

  // Start Projects
  logo: "شعار",
  projectsList: "قائمة المشاريع",
  editProject: "تعديل المشروع",
  // End Projects

  // Start Users
  users: "مستخدمين",
  usersList: "قائمة المستخدمين",
  phoneNumber: "رقم الهاتف",
  showUser: "عرض المستخدم",
  project: "مشروع",
  projects: "مشاريع",
  addProjectToUser: "إضافة مشروع للمستخدم",
  addProjectToCustomer: "إضافة مشروع للعميل",
  addToProject: "إضافة إلى المشروع",
  // End Users

  // Start Permissions
  permissions: "االرخص",
  permissionsList: "قائمة الرخصات",
  editPermission: "تعديل الرخصة",
  permissionsSaved: "تم تخزين الرخصات",
  errorInSavingPermissions: "خلل في خزن الرخصات",
  canPayContractPayment: "يمكن دفع مدفوعات العقد",
  canAddContract: "يمكن إضافة عقد",
  canAddProperty: "يمكن إضافة ملكية",
  canAddHoldProperty: "يمكن إضافة عقد معلق",
  canDeleteHoldProperty: "يمكن حذف العقد المعلق",
  canCreateExpense: "يمكن إضافة المصاريف",
  canViewTransaction: "يمكن عرض المعاملات",
  canViewExpenseByTypeReport: "يمكن عرض المصاريف بنوع التقرير",
  canTransferContract: "يمكن تحويل العقد",
  canCancelContract: "يمكن إلغاء العقد",
  // End Permissions

  // Start Profile
  accountInfo: "معلومات الحساب",
  profile: "الصفحة الشخصية",
  updateAvatar: "تحديث الصورة الرمزية",
  updatePassword: "تحديث كلمة السر",
  oldPassword: "الرمز القديم",
  newPassword: "الرمز الجديد",
  confirmNewPassword: "تأكيد الرمز الجديد",
  pleaseWriteFullName: "من فضلك أكتب ألأسم الكامل",
  namesMustBeMoreThan3Chars: "ألأسماء لا يجب أن تكون أكثر من 3 حروف",
  updated: "تم التحديث",
  pleaseWriteAllPasswords: "من فضلك أكتب جميع كلمات السر",
  passwordsAreNotMatch: "كلمات السر غير متطابقة",
  updateInfo: "تحديث المعلومات",
  // End Profile

  // Start Transactions
  totalIncomes: "إجمالي الدخل",
  totalOutcomes: "إجمالي النتائج",
  all: "الکل",
  incomes: "إرادات",
  outcomes: "إخراجات",
  types: "أنواع",
  // End Transactions

  // Start Expense Report
  expenseReport: "قائمة الصروف",
  expensesReport: "قائمة المصاريف",
  mcTotal: "إجمالي",
  expenseCount: "عدد المصروف",
  // End Expense Report

  // Start Deposit Report
  depositReport: "تقرير الإيداع",
  depositsReport: "تقرير الودائع",
  depositCount: "عدد الودائع",
  // End Deposit Report

  // Start Withdraw Report
  withdrawReport: "سحب التقرير",
  withdrawsReport: "تقرير الانسحاب",
  withdrawCount: "سحب العد",
  // End Deposit Report

  // Start Property Features
  propertyFeatures: "خصائص الملكية",
  propertyFeaturesList: "قائمو خصائص الملكية",
  createPropertyFeature: "إضافة خاصية الملكية",
  editPropertyFeature: "تعديل خاصية الملكية",
  // End Property Features

  // Start Deposit Types
  depositTypes: "أنواع الإداع",
  depositTypesList: "قائمة أنواع الإداع",
  depositTypeList: "قائمة أنواع الإداع",
  createDepositType: "إظافة نوع إداع",
  editDepositType: "تغییر نوع إداع",
  // End Deposit Types

  // Start Withdraw Types
  withdrawTypes: "‌أنواع السحب",
  withdrawTypesList: "قائمة أنواع السحب",
  withdrawTypeList: "قائمة أنواع السحب",
  createWithdrawType: "إظافة نوع سحب",
  editWithdrawType: "تغییر نوع سحب",
  // End Withdraw Types

  // Start Deposits
  deposits: "إیداعات",
  depositsList: "قائمة إیداعات",
  depositList: "قائمة إیداعات",
  createDeposit: "إظافة إیداع",
  editDeposit: "تغییر إیداع",
  depositer: "المودع",
  accountant: "محاسب",
  depositType: "نوع الإيداع",
  // End Deposits

  // Start Withdraws
  withdraws: "سحوبات",
  withdrawsList: "قائمة سحوبات",
  withdrawList: "قائمة سحوبات",
  createWithdraw: "إظافة سحب",
  editWithdraw: "تغییر سحب",
  withdrawer: "منسحب",
  withdrawType: "نوع السحب",
  // End Withdraws

  // Start Buy Contract Report
  buyContractReport: "تقریر العقد",
  buyContractsReport: "تقریر العقود",
  buyContractCount: "عدد عقد شراء",
  // End Buy Contract Report

  // Start Buy Contract Details
  buyContractDetail: "تفاصيل شراء العقد",
  buyContractsDetails: "تفاصيل شراء العقود",
  unitSolds: "الوحدات المباعة مع معلومات المشترين",
  startDateToToday: "من تاريخ بداية البيع الى اليوم",
  building: "البناية",
  property2: "العقار",
  paid: "المدفوع",
  remaining: "المتبقي",
  dateOfSale: "تاريخ البيع",
  identifier: "المعرف",
  bondNumber: "رقم السند",
  nOfTheBatch: "اسم الدفعة",
  amountPrice: "المبلغ",
  paymentDate: "تاريخ التسديد",
  buyerName: "اسم المشتري",
  page: "الصفحة",
  DeliveryKey: "تسليم المفتاح",
  Cash: "دفع المقدمة",
  Installment: "التقسيطات",
  of: "من",
  hBd: "تم القيام به من قبل",
  priceOfProperty: "سعر العقار",
  statisticTitle: "احصائيات متممة لهذا التقرير",
  numberOfUnits: "عدد الوحدات",
  numberOfBuyers: "عدد المشترين",
  amountsRecived: "المبالغ المستلمة",
  remainingAmounts: "المبالغ المتبقية",
  valueOfAllUnits: "قيمة جميع الوحدات",
  totalActualArea: "مجموع المساحة الفعلية",
  totalArea: "المساحة الكلية",
  totalAreaPlus: "مجموع المساحة",
  averageSalePerMeter: "معدل بيع المتر الواحد",
  // End Buy Contract Details

  // Start Payment Process Report
  paymentProcessReport: "تقرير عملية الدفع",
  paymentProcessesReport: "تقرير عمليات الدفع",
  paymentProcessCount: "عدد عمليات الدفع",
  paymentReport: "تقرير الدفع",
  paymentsReport: "تقرير المدفوعات",
  buyPrice: "سعر الشراء",
  // End Payment Process Report

  // Start Safe Box Transaction Report
  safeBoxTransactionReport: "معاملة صندوق الأمانات",
  safeBoxTransactionsReport: "معاملات الصناديق الآمنة",
  safeBoxTransactionCount: "عدد المعاملات في الصناديق الآمنة",
  safeBoxTransaction: "معاملة صندوق الأمانات",
  // End Safe Box Transaction Report

  // Start Safe Box Transfers
  transfers: "التحويلات",
  transfersList: "قائمة التحويلات",
  transferList: "قائمة التحويلات",
  fromSafeBox: "من صندوق الأمانات",
  toSafeBox: "إلی صندوق الأمانات",
  createTransfer: "إضافة تحویل",
  // end Safe Box Transfers

  // Start User Tasks
  userTasks: "مهام المستخدم",
  userTasksList: "قائمة مهام المستخدم",
  userTaskList: "قائمة مهام المستخدم",
  createUserTask: "إنشاء مهمة مستخدم",
  editUserTask: "تعدیل مهمة المستخدم",
  time: "الوقت",
  dayName: "اسم اليوم",
  taskType: "نوع المهمة",
  youHaveTasks: "لدیک مهام",
  tasks: "مهام",
  // End User Tasks

  // Start Financial Dashboard
  financialDashboard: "لوحة المعلومات المالية",
  moreInfo: "مزيد من المعلومات",
  totalIncomeFromSell: "إجمالي الدخل من البيع",
  totalDeposits: "إجمالي الودائع",
  totalWithdraws: "إجمالي عمليات السحب",
  defaultSafeBox: "الصندوق رئيسي",
  otherExpenseSafeBox: "صندوق آمن للنفقات الأخرى",
  projectExpenses: "مصارف المشروع",
  bankSafeBox: "صندوق الأمانات المصرفية",
  safeBoxes: "صناديق الأمانات",
  currentMonth: "الشهر الحالي",
  totalTillNow: "المجموع حتى الآن",
  latestDeposits: "أحدث الودائع",
  latestWithdraws: "آخر عمليات السحب",
  latestExpenses: "أحدث المصاريف",
  latestTransfers: "أحدث التحويلات",
  totalOfExpenses: "مجموع المصاريف",
  paymentsFromAgents: "المدفوعات من الوكلاء",
  january: "يناير",
  february: "فبراير",
  march: "مارس",
  april: "أبريل",
  may: "مايو",
  june: "يونيو",
  july: "يوليو",
  august: "أغسطس",
  september: "سبتمبر",
  october: "أكتوبر",
  november: "نوفمبر",
  december: "ديسمبر",
  passedPayments: "المدفوعات المأجلة",
  unpaidContracts: "العقود غیر مدفوعة",
  // End Financial Dashboard

  // Start Investors
  investors: "المستثمرون",
  investorsList: "قائمة المستثمرين",
  investorList: "قائمة المستثمرين",
  createInvestor: "إنشاء المستثمر",
  editInvestor: "تحرير المستثمر",
  investor: "المستثمر",
  meter: "متر",
  needToPay: "تحتاج ان تدفع",
  // End Investors

  // Start Agents
  agents: "دفع العقارات",
  agentsList: "قائمة دفع العقارات",
  agentList: "قائمة دفع العقارات",
  createAgent: "إضافة دفع العقارات",
  editAgent: "تعدیل دفع العقارات",
  agent: "العقارات",
  // End Agents

  // Start Extras
  totalExpense: "مجموع المصروفات",
  totalDiscount: "مجموع الخصومات",
  extraPayments: "المدفوعات الإضافية",
  totalExpenseCount: "مجموع عدد المصروفات",
  youWantToMakeUnpayPayment: "هل تريد إسترجاع الدفعة؟",
  unpay: "إسترجاع الدفعة",
  paidDate: "تاريخ الدفع",
  forgivePrice: "السعر المعفو",
  createBuyContractAttachment: "إضافة مرفقات عقد الشراء",
  loadMore: "تحميل المزيد",
  showContractAttachments: "عرض مرفقات العقد",
  status: "الحالة",
  forgivePriceReport: "تقرير السعر المعفو",
  totalForgivePrice: "إجمالي سعر المعفو",
  currentPayPrice: "سعر الدفع",
  currentForgivePrice: "السعر المعفو",
  payDate: "التاریخ",
  perMonth: "فی شهر",
  ordering: "فرز",
  desc: "ی - أ",
  asc: "أ - ی",
  sorting: "نوع الفرز",
  corner: "الرکن",
  road20m: "الشارع 20m",
  facingPark: "مقابل الحدیقة",
  reserved: "محجوز",
  available: "متوفر",
  isSharedCustomer: "عمیل مشترک",
  additionalShortagePlotArea: "المساحة الإضافة / الناقصة",
  pleaseTypeSecondCustomerInformation: "رجاء أکتب معلومات المشتری الثانی",
  pleaseSelectAttachment: "رجاء أختر الملصق",
  addAttachment: "إضافة ملصق",
  attachmentAdded: "تم إضافة الملصق",
  passport: "جواز السفر",
  is20Percent: "20%",
  paidTo: "إعطیە إلی",
  reasonFor: "السبب",
  propertyExpense: "مصاریف الملکیة",
  totalPropertyExpense: "مجموع مصاریف الملکیة",
  propertyExpenseList: "قائمة مصاریف الملکیة",
  buyContractsExpenses: "مصاريف العقود",
  buyContractExpensesList: "قائمة مصاريف العقد",
  // End Extras
  downpayment: "الدفعة الاولى",
  otherExpenses: "مصروفات اخرى",

  //safeBox transactions
  addWithdraw: "إضافة سحب",
  deleteWithdraw: "حذف السحب",
  safeBoxTransfer: "نقل الصندوق الآمن",
  deleteSafebox: "حذف الصندوق الآمن",
  deletePrpertyExpense: "حذف نفقات الملكية",
  addInvestor: "إضافة مستثمر",
  deleteInvestor: "حذف المستثمر",
  addExpense: "إضافة المصاريف",
  deleteExpense: "حذف المصاريف",
  addDeposit: "إضافة إيداع",
  deleteDeposit: "حذف الإيداع",
  unpayContractPayment: "دفع العقد غير المدفوع",
  contractPayment: "دفع العقد",
  buyContractPayment: "دفع عقد الشراء",
  deleteCancleContract: "حذف إلغاء العقد",
  cancleContract: "إلغاء العقد",
  deleteContractPayment: "حذف دفع العقد",
  deleteBuyContractPayment: "حذف دفع عقد الشراء",
  deleteSafeBoxTransferFrom: "حذف SafeBox Transfer From",
  m: "م",
  totalRemainArea: "المساحة المتبقية",
  contractNote: "مذكرة العقد",
  dari: "عقد دارى",
  textMessage: "رسالة نصية",
  smsMessages: "الرسائل القصيرة",
  createSmsMessage: "إنشاء رسالة SMS",
  NotAvailable: "ليس متاحا",
};
