<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-table
            striped
            hover
            responsive
            bordered
            sort-by="total"
            :sort-desc="true"
            :items="rows"
            :fields="fields"
            :busy="isLoading"
        >
          <template slot="bottom-row">
            <td style="font-weight: bold !important">Total</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'total') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'f_time') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'm_time') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'l_time') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'women') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'men') }}</td>
          </template>
        </b-table>
      </b-col>
      <b-col>
        <apexchart type="bar" height="400" :options="dataByTime" :series="seriesDataByTime"></apexchart>
      </b-col>
      <b-col>
        <apexchart type="pie" height="400" :options="dataByGender" :series="seriesDataByGender"></apexchart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import lodash from 'lodash';
import moment from 'moment';
import {serverParams} from "@/plugins/constants";

export default {
  name: "PerSources",
  props: ['fromDate', 'toDate', 'currentTab'],
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: 'name',
          label: this.$t('name'),
          sortable: true
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true
        },
        {
          key: 'f_time',
          label: '9:00 - 12:00',
          sortable: true,
        },
        {
          key: 'm_time',
          label: '12:00 - 14:00',
          sortable: true,
        },
        {
          key: 'l_time',
          label: '14:00 - 17:00',
          sortable: true,
        },
        {
          key: 'women',
          label: this.$t('women'),
          sortable: true,
        },
        {
          key: 'men',
          label: this.$t('men'),
          sortable: true,
        }
      ],
      rows: [],
      seriesDataByTime: [{
        data: [0, 0, 0]
      }],
      dataByTime: {
        xaxis: {
          categories: ['9:00 - 12:00', '12:00 - 14:00', '14:00 - 17:00'],
        }
      },
      seriesDataByGender: [0, 0],
      dataByGender: {
        labels: [this.$t('males'), this.$t('females')],
      },
    }
  },
  watch: {
    fromDate: function () {
      this.loadData()
    },
    toDate: function () {
      this.loadData()
    },
    currentTab: function () {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      if (this.currentTab === 4) {
        this.isLoading = true;
        this.$store.dispatch('leadReports/GET_PER_SOURCES', {fromDate: this.fromDate, toDate: this.toDate})
            .then((response) => {
              let row = [];
              lodash.each(response.data, function (value) {
                let fTime = 0;
                let mTime = 0;
                let lTime = 0;
                lodash.each(value, function (val) {
                  if (
                      moment(val.visitDate).isBetween(moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT11:59:59'))
                  ) {
                    fTime += (val.males + val.females);
                  } else if (
                      moment(val.visitDate).isBetween(moment().format('YYYY-MM-DDT12:00:00'), moment().format('YYYY-MM-DDT14:00:00'))
                  ) {
                    mTime += (val.males + val.females);
                  } else {
                    lTime += (val.males + val.females);
                  }
                })
                row.push({
                  name: value[0].subSourceName,
                  total: lodash.sumBy(value, 'males') + lodash.sumBy(value, 'females'),
                  f_time: fTime,
                  m_time: mTime,
                  l_time: lTime,
                  women: lodash.sumBy(value, 'females'),
                  men: lodash.sumBy(value, 'males')
                })
              })
              this.rows = row;
              this.isLoading = false
              this.seriesDataByTime = [{
                data: [
                  lodash.sumBy(this.rows, 'f_time'),
                  lodash.sumBy(this.rows, 'm_time'),
                  lodash.sumBy(this.rows, 'l_time')
                ]
              }];
              this.seriesDataByGender = [
                lodash.sumBy(this.rows, 'men'),
                lodash.sumBy(this.rows, 'women')
              ];
            })
            .catch(() => this.isLoading = false);
      }
    }
  }
}
</script>
