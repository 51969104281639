<template>
  <b-row>
    <b-col sm="3" v-for="followUp in allFollowUp" :key="followUp.id">
      <b-card img-top style="max-width: 20rem">
        <b-card-text>{{ $t('title') }}: {{ followUp.title }}</b-card-text>
        <b-card-text>{{ $t('date') }}: {{ moment(followUp.date).format('YYYY-MM-DD') }}</b-card-text>
        <b-card-text>{{ $t('deadline') }}: {{ followUp.deadline ? moment(followUp.deadline).format('YYYY-MM-DD') : '-' }}</b-card-text>
        <boolean-input
            :show-label="false"
            id="done"
            :name="$t('done')"
            :load-on-mount="false"
            :default-value="followUp.done"
            :checked-label="$t('done')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, followUp.id, 'done')"
        />
        <boolean-input
            :show-label="false"
            id="ShareableForCustomer"
            :name="$t('sharable')"
            :load-on-mount="false"
            :default-value="followUp.shareableForCustomer"
            :checked-label="$t('share')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, followUp.id, 'shareable')"
        />
        <boolean-input
            :show-label="false"
            id="Is360"
            :name="$t('is360')"
            :load-on-mount="false"
            :default-value="followUp.is360"
            :checked-label="$t('is360')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, followUp.id, 'is360')"
        />
        <b-button href="#" variant="danger" @click.prevent="deleteFollowUp(followUp.id)">{{ $t('delete') }}</b-button>
      </b-card>
    </b-col>
    <is-loading v-if="isLoading" />
  </b-row>
</template>

<script>
import IsLoading from "../../../components/IsLoading";
import {swalMixin} from "../../../plugins/constants";
import BooleanInput from "../../../components/Inputs/BooleanInput";

export default {
  name: "PropertyFollowUpList",
  components: {BooleanInput, IsLoading},
  props: {
    followUps: {
      type: Array
    }
  },
  watch: {
    followUps: function (value) {
      this.allFollowUp = value
    }
  },
  data() {
    return {
      allFollowUp: this.followUps,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false
    }
  },
  methods: {
    switched(value, id, name) {
      value = true;
      this.$store.dispatch('propertyFollowUp/TOGGLE_DATA', `?id=${id}&${name}=${value}`)
    },
    deleteFollowUp(id) {
      this.$store.dispatch('propertyFollowUp/DELETE', id).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      })
    }
  }
}
</script>
