<template>
  <div v-if="hasPermission(permissions.leadCustomersList)">
    <content-header>{{ $t('leadCustomers') }}</content-header>
    <v-content>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-create="$router.push({name: 'leadCustomers.create'})"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="controls" slot-scope="props" v-if="hasPermission(permissions.leadCustomersEdit) || hasPermission(permissions.leadCustomersDelete)">
          <control-buttons
              :props="props"
              :edit-link="hasPermission(permissions.leadCustomersEdit) && 'leadCustomers.edit'"
              :delete-link="hasPermission(permissions.leadCustomersDelete) && 'leadCustomers/DELETE'"
              v-on:successAction="loadData(true)"
          />
        </template>
        <template slot="createdAt" slot-scope="props">
          {{ formatDate(props.row.createdAt) }}
        </template>
        <template slot="phone" slot-scope="props">
          {{ props.row.phone.replace('+964', '0') }}
        </template>
        <template slot="isMale" slot-scope="props">
          {{ props.row.isMale ? 'Male' : 'Female' }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {LeadCustomer} from "@/models/LeadCustomer";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {permissions, serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "LeadCustomerList",
  components: {ControlButtons, IsLoading, 'v-content': Content, ContentHeader,  BootstrapTable},
  data() {
    return {
      isLoading: false,
      model: new LeadCustomer(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.leadCustomersCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t('leadCustomersList')),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      this.isLoading = true;
      this.$store.dispatch('leadCustomers/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
  }
}
</script>
