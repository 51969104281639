<template>
  <div v-if="hasPermission(permissions.smsMessageCreate)">
    <content-header>{{ $t("createSmsMessage") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
              id="customer"
              :name="$t('customer')"
              :validate="$v"
              :required="true"
              api-link="customers/GET_ALL"
              label="fullName,+phone"
              @itemSelected="(e) => (this.form.customerId = e)"
            />
            <!-- <p style="text-align: center;">test</p> -->
            <!-- <text-input
              id="phoneNumber"
              :name="$t('phoneNumber')"
              :validate="$v"
              :is-loading="isLoading"
            /> -->
            <text-area-input
              id="textMessage"
              :name="$t('textMessage')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-area-input
              id="note"
              :name="$t('note')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import { Withdraw } from "@/models/Withdraw";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import { permissions } from "@/plugins/constants";

export default {
  name: "WithdrawCreate",
  components: {
    TextAreaInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
    SelectInput,
    
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Withdraw().fillAbles),
      isLoading: false,
      selectedItem: {},
      permissions: permissions,
    };
  },
  validations: {
    form: {
      customerId: { required },
      textMessage: {},
      note: {},
      phoneNumber:{},
    },
  },
  methods: {
    onSubmit() {

      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store
        .dispatch("smsMessages/CREATE", this.form)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "smsMessages" });
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
