import { i18n } from "@/plugins/i18n";
import mixins from "@/mixins";

export class PaymentProcessReport {
  columns = [];
  id = "id";

  fillAbles = [
    [
      "invoiceNumber",
      i18n.t("invoiceNumber"),
      true,
      process.env.VUE_APP_PROJECT_NAME === "RoyalTower" ? true : false,
    ],
    ["customer", i18n.t("customer"), true],
    ["propertyName", i18n.t("propertyName"), true],
    // ["zone", i18n.t("zone"), true],
    // ["block", i18n.t("block"), true],
    ["floor", i18n.t("floor"), true, mixins.projectTenant() === "Candle"],
    ["area", i18n.t("area2"), true],
    ["meterPrice", i18n.t("meterPrice2"), true],
    ["buyPrice", i18n.t("buyPrice"), true],
    ["meterPriceAfterDiscount", i18n.t("meterPriceAfterDiscount"), false],
    ["discount", i18n.t("discount"), true],
    ["buyPriceAfterDiscount", i18n.t("buyPriceAfterDiscount"), false],
    ["paidPrice", i18n.t("paidPrice"), true],
    ["date", i18n.t("date"), true, false],
    ["totalPaidPrice", i18n.t("totalPaidPrice"), true],
    ["remain", i18n.t("remain2"), true],
    ["currentPayDate", i18n.t("paidDate"), true, true],
    ["payType", i18n.t("payType"), true, true], //field , order, visibility
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: true,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ||process.env.VUE_APP_PROJECT_NAME === "RoyalTower"  ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
