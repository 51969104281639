<template>
  <div v-if="hasPermission(permissions.expenseTypesEdit)">
    <content-header>{{ $t('editExpenseType') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-area-input id="description" :name="$t('description')" :validate="$v" :is-loading="isLoading"/>
            <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {ExpenseType} from "@/models/ExpenseType";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "ExpenseTypeEdit",
  components: {SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput, TextAreaInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ExpenseType().fillAbles),
      isLoading: false,
      selectedItem: {},
      permissions: permissions
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('expenseTypes/GET_ONE', this.$route.params.id).then((response) => {
      this.form       = response.data;
      this.isLoading  = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      description: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('expenseTypes/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "expenseTypes"})
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
