<template>
  <div id="transfer-contract-voucher-receipt-zaiton-v2-arabic" class="d-none" >
    <div style="line-height: 35px;">


<div class="header">
    <p  style="text-align: center;
    font-weight: bold;
    font-size: 55px;
    " >الی / شرکة شجرة الزيتون <br> <br>
        م / التنازل
    </p>
</div>  

<div class="body" >
   <br> <br> 
<p style="font-size: 55px; text-align: justify;"> 
إني ( {{ data.oldCustomer && data.oldCustomer.fullName }}  ) 
مشتري الوحدة السكنية المرقمة ( {{ data.property && data.property.name }} ) 
 ضمن منطقة ({{ data.property && data.property.zone }}) 
 <span v-if="data.property && data.property.zone !== 'L'" >
شارع ( {{ data.property && data.property.streetnumber }}) بلوك ( {{ data.property && data.property.block }} ) 
</span>
من 
شركتكم حسب العقد الموقع المرقم ({{ data.contractNumber }}) بتأريخ(
 {{ moment(data.buyingDate).format('YYYY-MM-DD') }}) , إنني و بناءا على رغبتي أتنازل بتأريخ 
 
({{ moment(data.transferDate).format('YYYY-MM-DD') }} ) عن الوحدة السكنية المشار اليها اعلاه لصالح السيد 
( {{ data.newCustomer && data.newCustomer.fullName }}  ), وقد تلقيت كافة حقوقي منه ولم 
يبقى لي اية حقوق على شركتكم (شركة شجرة الزيتون) و ليس لدي الحق في الرجوع الى شركتكم. <br/>

لهذا الغرض, و بمحض ارادتي اطلب من سيادتكم التفضل بالموافقة على عقد التنازل بين نفسي بصفة الطرف الأول و السيد 
( {{ data.newCustomer && data.newCustomer.fullName }} ) بصفة الطرف الثاني في عقد التنازل بيننا.  <br/>

و علية انني موافق على اربرام و توقيع عقد جديد مع الطرف الثاني السيد ( {{ data.newCustomer && data.newCustomer.fullName }} ) وفي النهاية تسجيل الوحدة  
السكنية بأسم (الطرف الثاني), و بصفتي الطرف الأول لم يبقى لدي اية حقوق على <br>
الوحدة السكنية المشارة اليها أعلاه, ولا على شركة شجرة الزيتون للتجارة العامة و الإستثمار العقاري و الصناعي. <br>
</p>
<div style="text-align: center;  margin-top: 250px;">مع التقدير......</div>
<br/>
<div >
    <p style=" text-align: center;" class="d-inline-flex">
        مقدم الطلب (المتنازل) <br>
        {{ data.oldCustomer && data.oldCustomer.fullName }} <br>
        {{ moment(data.transferDate).format('YYYY-MM-DD')  }}<br>
    </p>
</div>

</div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ZaitonTransferContractDesignV2Arabic",
  props: {
    data: {},
  },
  mounted() {
  },
};
</script>

<style scoped>
p {
  font-size: 19px !important;
  direction: rtl !important;
  text-align: start;
  font-family: Noto, Speda, sans-serif !important;
}

@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
