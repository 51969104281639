<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <p>System</p>
      </div>
      <is-loading v-if="isLoading" />
      <div class="card">
        <div class="card-body login-card-body" style="padding: 15px !important">
          <form id="login_form" @submit.prevent="login" method="POST" accept-charset="UTF-8" enctype="multipart/form-data" class="form-horizontal">
            <div class="form-group row input-group-lg">
              <label for="username"> </label>
              <input
                  v-model="username"
                  class="form-control col-md-12 "
                  id="username"
                  :placeholder="$t('username')"
                  required="required"
                  autocomplete="off"
                  type="text"
                  autofocus
                  :disabled="isLoading"
              />
            </div>
            <div class="form-group row input-group-lg">
              <label for="password"> </label>
              <input
                  v-model="password"
                  class="form-control col-md-12"
                  id="password"
                  :placeholder="$t('password')"
                  required="required"
                  autocomplete="off"
                  type="password"
                  :disabled="isLoading"
              />
            </div>
            <button class="btn btn-primary btn-lg" type="submit" :disabled="isLoading">{{ $t('login') }}</button>
          </form>
        </div>
      </div>
      <h5 class="text-center">
        By: <a href="https://tech-eye.com" target="_blank"><b>Techeye</b>Co.</a>
      </h5>
    </div>
  </div>
</template>
<script>
import IsLoading from "@/components/IsLoading";
import {swalMixin} from "@/plugins/constants";
export default {
  name: "Login",
  components: {IsLoading},
  data() {
    return {
      isLoading: false,
      username : '',
      password : ''
    }
  },
  methods: {
    login: function () {
      this.isLoading = true;
      let authData = {
        UserName: this.username,
        Password: this.password,
      }
      this.$store.dispatch('auth/LOGIN', authData)
          .then(() => {
            if (this.projectTenant() === 'Cantal' || this.projectTenant() === 'Techeye') {
              this.$router.push({name: "mainDashboard"});
            } else {
              this.$router.push({name: "dashboard"})
            }
            window.location.reload();
          })
          .catch(() => {
            swalMixin('error', this.$t('error'))
          }).finally(() => {
            this.isLoading = false;
          });
    }
  }
}
</script>

<style lang="css" scoped>
button[type=submit] {
  float: right;
}
</style>
