<template>
  <div
    v-if="hasPermission(permissions.buyContractsCreate)"
    class="buy-contract"
  >
    <content-header>{{ $t("createBuyContract") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col>
              <select-input
                id="property"
                :name="$t('property')"
                label="name,+propertyTypeName"
                api-link="properties/GET_ALL"
                :api-params="{ OnlyAvailable: true }"
                @itemSelected="propertySelected"
                :return-object="true"
              />
              <select-input
                id="customers"
                :name="$t('buyers')"
                label="firstName,middleName,lastName,+phone"
                api-link="customers/GET_ALL"
                @itemSelected="customerSelected"
              />
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('area')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :disabled="true"
                    :placeholder="$t('area')"
                    :value="property.area + '/' + property.buildUpArea"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('type')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-if="property"
                >
                  <b-form-input
                    :placeholder="$t('streetWidth')"
                    :value="property.propertyTypeName"
                  ></b-form-input>
                  <b-form-input
                    :placeholder="$t('block')"
                    v-model="property.block"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('zone')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('zone')"
                    v-model="property.zone"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('contractNumber')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('contractNumber')"
                    v-model="contractNumber"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-input-group
                      :prepend="$t('initialPrice')"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-input
                        disabled
                        :placeholder="$t('initialPrice')"
                        v-model="finalPrice"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-input-group
                      :prepend="$t('finalPrice')"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-input
                        disabled
                        :placeholder="$t('finalPrice')"
                        :value="finalPrice - discount"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <b-input-group
                  :prepend="$t('installments')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    type="number"
                    :placeholder="$t('noOfInstallments')"
                    v-model="installmentsNumber"
                    @change="calculateInstallments"
                  ></b-form-input>
                  <b-form-input
                    type="number"
                    :placeholder="$t('periodOfInstallments')"
                    v-model="installmentPeriod"
                    @change="calculateInstallments"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-form-group>
              <b-form-checkbox switch v-model="isCash"
                >{{ $t("isCash") }}
              </b-form-checkbox>
            </b-form-group>
          </b-row>
          <b-row v-if="!isCash">
            <b-col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("installment") }}</th>
                    <th>{{ $t("percentage") }}</th>
                    <th>{{ $t("amount") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ $t("prepayment") }} 1</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment1Percent"
                          @change="calculateInstallments"
                          :disabled="!prePayment1IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="prePayment1"
                          @change="calculateInstallments"
                          :disabled="prePayment1IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox"
                                name="percentCheckbox"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment1IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment1Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("payment") }} 2</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment2Percent"
                          @change="calculateInstallments"
                          :disabled="!prePayment2IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="prePayment2"
                          @change="calculateInstallments"
                          :disabled="prePayment2IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox2"
                                name="percentCheckbox2"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment2IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment2Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("payment") }} 3</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment3Percent"
                          @change="calculateInstallments"
                          :disabled="!prePayment3IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="prePayment3"
                          @change="calculateInstallments"
                          :disabled="prePayment3IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox3"
                                name="percentCheckbox3"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment3IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment3Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("deliveringKeys") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="deliveryKeyPercent"
                          @change="calculateInstallments"
                          :disabled="!deliveryKeyIsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="deliveryKey"
                          @change="calculateInstallments"
                          :disabled="deliveryKeyIsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                v-model="deliveryKeyIsPercent"
                                id="deliveryKeyCheckbox"
                                name="deliveryKeyCheckbox"
                                :value="true"
                                :unchecked-value="false"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="deliveryKeyDate"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("installments") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="currentInstallmentsPercent"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                      <br />
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          :value="
                            (currentInstallmentsPercent / 100) * finalPrice
                          "
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('contractNote')">
                        <b-form-textarea
                          id="note"
                          v-model="buyContractNote"
                        ></b-form-textarea>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                  <tr
                    v-for="installment in installments"
                    v-bind:key="installment.index"
                  >
                    <th>{{ installment.index }}</th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="installment.currentPayPrice"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="installment.currentPayDate"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('discount')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    type="number"
                    :max="property.initialPrice"
                    min="0"
                    :placeholder="$t('discount')"
                    v-model="discount"
                    @change="calculateDiscount"
                    :disabled="discountByPercent"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('discount') + ' (%)'"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    type="number"
                    max="100"
                    min="0"
                    :placeholder="$t('discount')"
                    v-model="percentDiscount"
                    @change="calculateDiscountPercent"
                    :disabled="!discountByPercent"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="discountByPercent"
                  name="check-button"
                  switch
                  @change="!discountByPercent"
                >
                  {{ $t("percent") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="showDiscount"
                  name="check-button"
                  switch
                  >{{ $t("showDiscount") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.withExpense variant="info">{{
                      $t("withExpense")
                    }}</b-button>
                  </b-card-header>
                  <b-collapse
                    id="withExpense"
                    accordion="withExpenseAccordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-form-group>
                        <b-input-group
                          :prepend="$t('title')"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-form-input
                            type="text"
                            :placeholder="$t('title')"
                            v-model="expenseTitle"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <select-input
                          label="name"
                          id="propertyExpenseTypeId"
                          :name="$t('propertyExpenseType')"
                          api-link="propertyExpenseTypes/GET_ALL"
                          :required="true"
                          @itemSelected="(e) => (expenseTypeId = e)"
                        />
                      </b-form-group>
                      <b-form-group>
                        <b-input-group
                          :prepend="$t('amount')"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-form-input
                            type="number"
                            min="0"
                            :placeholder="$t('amount')"
                            v-model="expensePrice"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <button
            class="btn btn-outline-primary col-md-3"
            type="submit"
            :disabled="isLoading"
            @click="onSubmit"
          >
            <i class="fas fa-plus"></i> {{ $t("create") }}
          </button>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import SelectInput from "../../components/Inputs/SelectInput";
import moment from "moment";
// import Swal from "sweetalert2";
import { permissions } from "@/plugins/constants";

export default {
  name: "CreateMassHills",
  components: { ContentHeader, "v-content": Content, SelectInput },
  data() {
    return {
      isLoading: false,
      installments: [],
      property: "",
      customer: 0,
      contractNumber: "0",
      installmentsNumber: 25,
      installmentPeriod: 1,
      prePayment1: 0,
      prePayment1Percent: 20,
      prePayment1IsPercent: true,
      prePayment1Date: this.$helpers.getCurrentDate(),
      prePayment2: 0,
      prePayment2Percent: 15,
      prePayment2IsPercent: true,
      prePayment2Date: this.$helpers.getCurrentDate(6),
      prePayment3: 0,
      prePayment3Percent: 15,
      prePayment3IsPercent: true,
      prePayment3Date: this.$helpers.getCurrentDate(12),
      installmentsPercent: 25,
      buyContractNote: "",
      deliveryKey: 0,
      deliveryKeyPercent: 25,
      deliveryKeyIsPercent: true,
      deliveryKeyDate: this.$helpers.getCurrentDate(18),
      currentInstallmentsPercent: 25,
      discount: 0,
      percentDiscount: 0,
      discountByPercent: false,
      finalPrice: 0,
      showDiscount: false,
      expenseTitle: "",
      expenseTypeId: 0,
      expensePrice: 0,
      permissions: permissions,
      isCash: false,
    };
  },
  mounted() {
    this.calculateInstallments();
  },
  methods: {
    customerSelected(customerId) {
      this.customer = customerId;
    },
    propertySelected(property) {
      this.property = property;
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/GET_BUY_CONTRACT_NUMBER")
        .then((response) => {
          this.contractNumber = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
      this.calculateInstallments();
    },
    calculateInstallments() {
      let customInstallments = [];
      let percent =
        parseFloat(this.deliveryKeyPercent) +
        parseFloat(this.prePayment1Percent) +
        parseFloat(this.prePayment2Percent) +
        parseFloat(this.prePayment3Percent);

      percent += parseFloat(this.percentDiscount);

      this.currentInstallmentsPercent = 100 - percent;
      let propertyInitial = 0;

      propertyInitial =
        (this.property.finalPrice * (this.currentInstallmentsPercent / 100)) /
        this.installmentsNumber;

      this.prePayment2Date = moment(this.prePayment1Date)
        .add(6, "M")
        .format("YYYY-MM-DD");
      this.prePayment3Date = moment(this.prePayment1Date)
        .add(12, "M")
        .format("YYYY-MM-DD");
      this.deliveryKeyDate = moment(this.prePayment1Date)
        .add(18, "M")
        .format("YYYY-MM-DD");

      for (let i = 1; i <= this.installmentsNumber; i++) {
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Installment",
          index: i,
          currentPayPrice: propertyInitial.toFixed(4),
          currentPayDate: moment(this.deliveryKeyDate)
            .add(this.installmentPeriod * i, "M")
            .format("YYYY-MM-DD"),
        });
      }
      this.installments = customInstallments;
      if (this.prePayment1IsPercent) {
        this.prePayment1 = (
          (this.prePayment1Percent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.prePayment1Percent =
          (this.prePayment1 / (this.property.finalPrice - this.discount)) * 100;
      }
      if (this.prePayment2IsPercent) {
        this.prePayment2 = (
          (this.prePayment2Percent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.prePayment2Percent =
          (this.prePayment2 / (this.property.finalPrice - this.discount)) * 100;
      }
      if (this.prePayment3IsPercent) {
        this.prePayment3 = (
          (this.prePayment3Percent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.prePayment3Percent =
          (this.prePayment3 / (this.property.finalPrice - this.discount)) * 100;
      }
      if (this.deliveryKeyIsPercent) {
        this.deliveryKey = (
          (this.deliveryKeyPercent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.deliveryKeyPercent =
          (this.deliveryKey / (this.property.finalPrice - this.discount)) * 100;
      }
      this.finalPrice = this.property.finalPrice;
    },
    onSubmit() {
      let customInstallments = [];
      if (this.isCash) {
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.property.finalPrice - this.discount,
          currentPayDate: this.prePayment1Date,
        });
      } else {
        console.log("instalments = ", this.installments);
        console.log("customInstallments = ", customInstallments);
        customInstallments = this._.clone(this.installments);
        console.log("customInstallments = ", customInstallments);
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Installment",
          index: 1,
          currentPayPrice: this.prePayment1,
          currentPayDate: this.prePayment1Date,
        });
        console.log("customInstallments = ", customInstallments);
      }
      if (!this.isCash) {
        if (this.prePayment2 > 0) {
          customInstallments.push({
            isItPrePayment: true,
            isItKeyPayment: false,
            isPayed: false,
            paymentProcessType: "Cash",
            currentPayPrice: this.prePayment2,
            currentPayDate: this.prePayment2Date,
          });
        }
        if (this.prePayment3 > 0) {
          customInstallments.push({
            isItPrePayment: true,
            isItKeyPayment: false,
            isPayed: false,
            paymentProcessType: "Cash",
            currentPayPrice: this.prePayment3,
            currentPayDate: this.prePayment3Date,
          });
        }
        if (this.deliveryKey > 0) {
          customInstallments.push({
            isItPrePayment: false,
            isItKeyPayment: true,
            isPayed: false,
            paymentProcessType: "DeliveryKey",
            currentPayPrice: this.deliveryKey,
            currentPayDate: this.deliveryKeyDate,
          });
        }
      }
      let sum = this._.sumBy(customInstallments, (val) => {
        return parseFloat(val.currentPayPrice);
      });
      let sum2 = this._.sumBy(this.installments, (val) => {
        return parseFloat(val.currentPayPrice);
      });
      console.log(
        sum2,
        sum,
        this.discount,
        parseFloat(sum.toFixed(4)),
        parseFloat(this.discount),
        this.property.finalPrice,
        " sdfsdfsdf"
      );

      // if (
      //   !(
      //     // sum <= this.property.finalPrice + 1 - parseFloat(this.discount) &&
      //     // sum >= this.property.finalPrice - 1 - parseFloat(this.discount)

      //     (
      //       parseFloat(sum.toFixed(4)) + parseFloat(this.discount) <=
      //         this.property.finalPrice + 1 &&
      //       parseFloat(sum.toFixed(4)) + parseFloat(this.discount) >=
      //         this.property.finalPrice - 1
      //     )
      //   )
      // ) {
      //   Swal.fire({
      //     position: "middle-center",
      //     icon: "error",
      //     title: this.$t(
      //       "pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice"
      //     ),
      //     showConfirmButton: true,
      //   });
      //   return;
      // }

      // if (
      //   !(
      //     parseFloat(sum.toFixed(4)) + parseFloat(this.discount) <=
      //       this.property.finalPrice + 1 &&
      //     parseFloat(sum.toFixed(4)) + parseFloat(this.discount) >=
      //       this.property.finalPrice - 1
      //   )
      // ) {
      //   Swal.fire({
      //     position: "middle-center",
      //     icon: "error",
      //     html:
      //       this.$t(
      //         "pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice"
      //       ) +
      //       "  <br />" +
      //       this.$t("propertyPrice") +
      //       ": " +
      //       this.property.finalPrice.toLocaleString() +
      //       " <br />" +
      //       this.$t("installments") +
      //       ": " +
      //       sum.toLocaleString() +
      //       "  <br />" +
      //       this.$t("difference") +
      //       ": " +
      //       (this.property.finalPrice - sum).toLocaleString(),
      //     showConfirmButton: true,
      //   });
      //   return false;
      // }

      let propertyExpense;
      if (this.expenseTitle) {
        propertyExpense = {
          title: this.expenseTitle,
          byMain: true,
          mcPrice: this.expensePrice,
          qty: 1,
          mcTotalPrice: this.expensePrice,
          date: this.prePayment1Date,
          propertyExpenseTypeId: this.expenseTypeId,
        };
      } else {
        propertyExpense = null;
      }
      let data = {
        buyContract: {
          contractNumber: this.contractNumber,
          buyingDate: this.prePayment1Date,
          buyingPrice: this.property.finalPrice - this.discount,
          buyType: this.isCash ? "Cash" : "Installment",
          isCancelled: false,
          isPrePaymentPayed: false,
          discount: this.discount,
          showDiscount: this.showDiscount,
          propertyId: this.property.id,
          customerId: this.customer,
          paymentProcess: customInstallments,
          note: this.buyContractNote,
        },
        propertyExpense: propertyExpense,
      };
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/CREATE", data)
        .then(() => {
          this.$router.push({ name: "buyContracts" });
        })
        .catch(() => (this.isLoading = false));
    },
    calculateDiscount() {
      this.percentDiscount = (this.discount / this.property.finalPrice) * 100;
      this.calculateInstallments();
    },
    calculateDiscountPercent() {
      this.discount = (this.percentDiscount / 100) * this.property.finalPrice;
      this.calculateInstallments();
    },
  },
};
</script>
