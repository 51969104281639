<template>
  <div>
    <content-header>{{ $t("dashboard") }}</content-header>
    <v-content>
      <div v-if="hasPermission(permissions.dashboardCard)" class="row no-print">
        <div class="col-lg-3 col-6 text-light">
          <div class="small-box bg-red-g">
            <div class="inner">
              <h3>{{ cardsInfo[0] }}</h3>
              <p>{{ $t("todayVisits") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-map-marker-alt"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6 text-light">
          <div class="small-box bg-blue-g">
            <div class="inner">
              <h3>{{ cardsInfo[1] }}</h3>
              <p>{{ $t("totalVisits") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-map-marked-alt"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6 text-light">
          <div class="small-box bg-green-g">
            <div class="inner">
              <h3>{{ cardsInfo[2] }}</h3>
              <p>{{ $t("todayContracts") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-file-signature"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6 text-light">
          <div class="small-box bg-purple-g">
            <div class="inner">
              <h3>{{ cardsInfo[3] }}</h3>
              <p>{{ $t("totalContracts") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-file-contract"></i>
            </div>
          </div>
        </div>
      </div>
      <b-row>
        <b-col id="hideOnClick" class="no-print" xl="3" lg="12" md="12" sm="12">
          <div class="accordion" role="tablist">
            <b-card
              v-if="
                (projectTenant() === 'RoyalTower' ||
                  projectTenant() === 'Candle') &&
                hasPermission(permissions.dashboardPayments)
              "
              no-body
              class="mb-1"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.safeBoxes variant="info">{{
                  $t("safeBox")
                }}</b-button>
              </b-card-header>
              <b-collapse
                id="safeBoxes"
                accordion="dashboardAccordionSafeBox"
                role="tabpanel"
              >
                <b-card-body>
                  <b-list-group
                    v-for="safeBox in safeBoxes"
                    :key="safeBox.name"
                  >
                    <b-list-group-item
                      >{{ safeBox.name }}:
                      {{
                        projectCurrencySymbol() +
                        safeBox.mcCurrentBalance.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}</b-list-group-item
                    >
                  </b-list-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="m-2" v-show="expiredHolds">
            <animation-button
              :text="$t('expiredPropertyHolds')"
              :link="{ name: 'propertyHolds' }"
            />
          </div>
          <div
            v-if="projectTenant() === 'Cantal'"
            class="m-2"
            v-show="tasks.length > 0"
          >
            <b-button class="alertButton btn btn-lg" v-b-modal.tasks>{{
              $t("youHaveTasks")
            }}</b-button>
            <b-modal id="tasks" hide-footer size="lg">
              <template #modal-title>
                {{ $t("tasks") }}
              </template>
              <div class="d-block text-center">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>{{ $t("title") }}</th>
                      <th>{{ $t("description") }}</th>
                      <th>{{ $t("time") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="task in tasks" :key="task.id">
                      <td>{{ task.title }}</td>
                      <td>{{ task.description }}</td>
                      <td>{{ task.time }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-modal>
          </div>
          <div v-if="hasPermission(permissions.dashboardPayments)">
            <b-tabs content-class="mt-3" align="center">
              <h4>{{ $t("payments") }}</h4>
              <b-tab active @click="currentTab = 1">
                <template #title>
                  {{ $t("next10Days") }}
                  <span class="badge badge-pill badge-danger">{{
                    next10DaysCount
                  }}</span>
                </template>
                <div v-for="next10Day in next10Days" :key="next10Day.id">
                  <h5>
                    <a href="#"
                      >{{ next10Day.name }}
                      {{ replacePhone(next10Day.phone) }}</a
                    >
                  </h5>
                  <p>
                    {{ $t("price") }}:
                    <span class="font-weight-bold">{{
                      projectCurrencySymbol() +
                      next10Day.mcPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                  </p>
                  <div class="mt-1">
                    <router-link
                      :to="{
                        name: 'properties.edit',
                        params: { id: next10Day.propertyId },
                      }"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-home"></i
                    ></router-link>
                    &nbsp;
                    <router-link
                      :to="{
                        name: 'buyContracts.show',
                        params: { id: next10Day.contractId },
                      }"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-file"></i
                    ></router-link>
                  </div>
                  <div style="color: #777; text-align: right; font-size: 20px">
                    <i>
                      {{ moment(next10Day.date).format("DD/MM/YYYY") }}
                    </i>
                  </div>
                  <hr />
                </div>
                <button
                  class="btn text-light bg-blue-g"
                  @click="loadMoreNext10Days"
                >
                  {{ $t("loadMore") }}
                </button>
              </b-tab>
              <b-tab @click="currentTab = 2">
                <template #title>
                  {{ $t("next20Days") }}
                  <span class="badge badge-pill badge-danger">{{
                    next20DaysCount
                  }}</span>
                </template>
                <div v-for="next20Day in next20Days" :key="next20Day.id">
                  <h5>
                    <router-link
                      :to="{
                        name: 'customers.edit',
                        params: { id: next20Day.customerId },
                      }"
                      tag="a"
                      >{{ next20Day.name }}
                      {{ replacePhone(next20Day.phone) }}
                    </router-link>
                  </h5>
                  <p>
                    {{ $t("price") }}:
                    <span class="font-weight-bold">{{
                      projectCurrencySymbol() +
                      next20Day.mcPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                  </p>
                  <div class="mt-1">
                    <router-link
                      :to="{
                        name: 'properties.edit',
                        params: { id: next20Day.propertyId },
                      }"
                      tag="a"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-home"></i
                    ></router-link>
                    &nbsp;
                    <router-link
                      :to="{
                        name: 'buyContracts.show',
                        params: { id: next20Day.contractId },
                      }"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-file"></i
                    ></router-link>
                  </div>
                  <div style="color: #777; text-align: right; font-size: 20px">
                    <i>
                      {{ moment(next20Day.date).format("DD/MM/YYYY") }}
                    </i>
                  </div>
                  <hr />
                </div>
                <button
                  class="btn text-light bg-blue-g"
                  @click="loadMoreNext20Days"
                >
                  {{ $t("loadMore") }}
                </button>
              </b-tab>
              <b-tab @click="currentTab = 3">
                <template #title>
                  {{ $t("delayed") }}
                  <span class="badge badge-pill badge-danger">{{
                    passedCount
                  }}</span>
                </template>
                <div v-for="pass in passed" :key="pass.id">
                  <h5>
                    <a href="#"
                      >{{ pass.name }} {{ replacePhone(pass.phone) }}</a
                    >
                  </h5>
                  <p>
                    {{ $t("payableAmount") }}:
                    <span class="font-weight-bold">{{
                      projectCurrencySymbol() +
                      pass.mcPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                  </p>
                  <div class="mt-1">
                    <router-link
                      :to="{
                        name: 'properties.edit',
                        params: { id: pass.propertyId },
                      }"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-home"></i
                    ></router-link>
                    &nbsp;
                    <router-link
                      :to="{
                        name: 'buyContracts.show',
                        params: { id: pass.contractId },
                      }"
                      class="btn text-light bg-blue-g"
                      ><i class="fas fa-file"></i
                    ></router-link>
                  </div>
                  <div style="color: #777; text-align: right; font-size: 20px">
                    <i>
                      {{ moment(pass.date).format("DD/MM/YYYY") }}
                    </i>
                  </div>
                  <hr />
                </div>
                <button
                  class="btn text-light bg-blue-g"
                  @click="loadMorePassed"
                >
                  {{ $t("loadMore") }}
                </button>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>

        <b-col
          id="changeClassOnClick"
          v-if="hasPermission(permissions.dashboardMap)"
          xl="9"
          lg="12"
          md="12"
          sm="12"
        >
          <diplomati-safiran-map
            v-if="projectTenant() === 'DiplomatiSafiran'"
          />
          <candle-map v-if="projectTenant() === 'Candle'" />
          <RoyalTowerMap v-else-if="projectTenant() === 'RoyalTower'" />
          <!--Upon their request we made it like kempeniski-->
          <cantal-map v-else-if="projectTenant() === 'Cantal'" />
          <shaqlawa-view-map v-else-if="projectTenant() === 'ShaqlawaView'" />
          <mass-hills-map v-else />
        </b-col>
      </b-row>
    </v-content>
  </div>
</template>

<script>
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import MassHillsMap from "./maps/MassHills";
import DiplomatiSafiranMap from "./maps/DiplomatiSafiran";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AnimationButton from "../components/AnimationButton";
import mixin from "../mixins";
import CandleMap from "@/views/maps/CandleMap";
import RoyalTowerMap from "@/views/maps/RoyalTowerMap";
import CantalMap from "@/views/maps/CantalMap";
import ShaqlawaViewMap from "@/views/maps/ShaqlawaViewMap";
import { serverParams, permissions } from "@/plugins/constants";

export default {
  name: "Dashboard",
  components: {
    ShaqlawaViewMap,
    CantalMap,
    CandleMap,
    RoyalTowerMap,
    AnimationButton,
    "v-content": Content,
    ContentHeader,
    // flatPickr,
    MassHillsMap,
    DiplomatiSafiranMap,
  },
  data() {
    return {
      cardsInfo: [0, 0, 0, 0],
      currentTab: 1,
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(7, "d"),
      todaySummary: [],
      generalData: [],
      expiredHolds: false,
      next10Days: [],
      next20Days: [],
      passed: [],
      next10DaysCount: 0,
      next20DaysCount: 0,
      passedCount: 0,
      next10DaysLoadMore: 10,
      next20DaysLoadMore: 10,
      passedLoadMore: 10,
      safeBoxes: [],
      tasks: [],
      permissions: permissions,
    };
  },
  watch: {
    fromDate: function () {
      this.loadGeneralData();
    },
    toDate: function () {
      this.loadGeneralData();
    },
    currentTab: function () {
      this.loadUpcomingPayments();
      this.loadPassedPayments();
    },
  },
  mounted() {
    this.loadCardsInfo();
    this.loadGeneralData();
    this.loadExpiredHolds();
    this.loadTodaySummary();
    this.loadUpcomingPayments();
    this.currentTab = 2;
    this.loadUpcomingPayments();
    this.loadPassedPayments();
    this.loadSafeBoxes();
    this.loadTodayTasks();
  },
  methods: {
    loadCardsInfo() {
      this.$store.dispatch("dashboard/GET_CARDS_INFO_DATA").then((response) => {
        this.cardsInfo = [
          response.data.todayVisites,
          response.data.totalVIsits,
          response.data.todayContracts,
          response.data.totalContracts,
        ];
      });
    },
    loadTodaySummary() {
      this.$store
        .dispatch("dashboard/GET_TODAY_SUMMARY_DATA")
        .then((response) => {
          this.todaySummary = [
            {
              name: this.$t("totalSells"),
              value: parseInt(response.data.todaySale).toLocaleString(),
            },
            {
              name: this.$t("propertyExpenses"),
              value: parseInt(response.data.propertyExpenses).toLocaleString(),
            },
          ];
          if (mixin.projectTenant() !== "MassHills") {
            this.todaySummary.push({
              name: this.$t("expenses"),
              value: parseInt(response.data.expenses).toLocaleString(),
            });
            this.todaySummary.push({
              name: this.$t("remainToCollect"),
              value: parseInt(response.data.remainToCollect).toLocaleString(),
            });
            this.todaySummary.push({
              name: this.$t("safeBox"),
              value: parseInt(response.data.safeBox).toLocaleString(),
            });
          }
        });
    },
    loadGeneralData() {
      let fromDate = this.fromDate;
      let toDate = this.toDate;
      this.$store
        .dispatch("dashboard/GET_GENERAL_DATA", { fromDate, toDate })
        .then((response) => {
          this.generalData = [
            {
              name: this.$t("cancelledContracts"),
              value: parseInt(
                response.data.cancelContractsCount
              ).toLocaleString(),
            },
            {
              name: this.$t("collectedMoney"),
              value: parseInt(response.data.collectedMoney).toLocaleString(),
            },
            {
              name: this.$t("currentContractCount"),
              value: parseInt(
                response.data.currentContractCount
              ).toLocaleString(),
            },
            // {name: this.$t('expenses'), value: parseInt(response.data.mcExpenses).toLocaleString()},
            {
              name: this.$t("propertyExpenses"),
              value: parseInt(
                response.data.mcPropertyExpenses
              ).toLocaleString(),
            },
            {
              name: this.$t("remainingPayableAmount"),
              value: parseInt(response.data.remainPrice).toLocaleString(),
            },
            {
              name: this.$t("returnedAmount"),
              value: parseInt(response.data.returnPrice).toLocaleString(),
            },
            // {name: this.$t('safeBox'), value: parseInt(response.data.safeBox).toLocaleString()}
          ];
        });
    },
    loadExpiredHolds() {
      this.$store.dispatch("dashboard/GET_EXPIRED_HOLDS").then((response) => {
        this.expiredHolds = response.data === "true";
      });
    },
    loadUpcomingPayments() {
      let fromDate = this.$helpers.getCurrentDate(0, "d");
      let toDate = this.$helpers.getCurrentDate(10, "d");
      let toDate2 = this.$helpers.getCurrentDate(20, "d");
      this.$store
        .dispatch("dashboard/GET_UPCOMING_PAYMENTS", {
          fromDate,
          toDate,
          serverParams: {
            _start: 0,
            _end: this.next10DaysLoadMore,
            _sort: "date",
            _order: this.projectTenant() === "ZaitonPlusCity" ? "Asc" : "desc",
            _search: "",
          },
        })
        .then((response) => {
          this.next10Days = response.data;
          this.next10DaysCount = parseInt(response.headers["x-total-count"]);
        });
      this.$store
        .dispatch("dashboard/GET_UPCOMING_PAYMENTS", {
          fromDate,
          toDate: toDate2,
          serverParams: {
            _start: 0,
            _end: this.next20DaysLoadMore,
            _sort: "date",
            _order: this.projectTenant() == "ZaitonPlusCity" ? "Asc" : "desc",
            _search: "",
          },
        })
        .then((response) => {
          this.next20Days = response.data;
          this.next20DaysCount = parseInt(response.headers["x-total-count"]);
        });
    },
    loadPassedPayments() {
      this.$store
        .dispatch("dashboard/GET_PASSED_PAYMENT", { _end: this.passedLoadMore })
        .then((response) => {
          this.passed = response.data;
          this.passedCount = parseInt(response.headers["x-total-count"]);
        });
    },
    loadSafeBoxes() {
      this.$store
        .dispatch("safeBoxes/GET_ALL", serverParams)
        .then((response) => {
          this.safeBoxes = response.data;
        });
    },
    loadTodayTasks() {
      this.$store.dispatch("dashboard/GET_TODAY_TASKS").then((response) => {
        this.tasks = response.data;
      });
    },
    loadMoreNext10Days() {
      this.next10DaysLoadMore += 10;
      this.loadUpcomingPayments();
    },
    loadMoreNext20Days() {
      this.next20DaysLoadMore += 10;
      this.loadUpcomingPayments();
    },
    loadMorePassed() {
      this.passedLoadMore += 10;
      this.loadPassedPayments();
    },
  },
};
</script>

<style scoped>
.bg-red-g {
  background: rgb(250, 68, 12);
  background: linear-gradient(
    313deg,
    rgb(219, 59, 11) 0%,
    rgb(175, 4, 15) 100%
  );
}
.bg-blue-g {
  background: linear-gradient(
    313deg,
    rgba(46, 149, 210, 1) 0%,
    rgba(1, 92, 146, 1) 100%
  );
}
.bg-green-g {
  background: linear-gradient(
    313deg,
    rgba(40, 198, 100, 1) 0%,
    rgba(17, 128, 59, 1) 100%
  );
}
.bg-gray-g {
  background: linear-gradient(
    313deg,
    rgba(121, 120, 120, 1) 0%,
    rgb(55, 55, 55) 100%
  );
}
.bg-purple-g {
  background: linear-gradient(
    313deg,
    rgba(124, 73, 177, 1) 0%,
    rgba(85, 37, 134, 1) 100%
  );
}
.bg-yellow-g {
  background: linear-gradient(
    313deg,
    rgba(190, 172, 0, 1) 0%,
    rgba(152, 109, 0, 1) 100%
  );
}
.bg-info-g {
  background: linear-gradient(
    313deg,
    rgb(7, 213, 202) 0%,
    rgb(10, 176, 154) 100%
  );
}
.alertButton {
  animation: alert 1s infinite ease-in-out;
  background-color: #ff0000;
}

@keyframes alert {
  from {
    background-color: #ff0000;
  }
  to {
    background-color: #fdfdfd;
  }
}
</style>
