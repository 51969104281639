import { i18n } from "@/plugins/i18n";

export class CancelContract {
  columns = [];
  id = "id";

  fillAbles = [
    ["buyContractId", i18n.t("buyContract"), true],
    ["customerId", i18n.t("customer"), true],
    ["propertyId", i18n.t("property"), true],
    ["returnedPrice", i18n.t("returnedPrice"), true],
    ["remainPrice", i18n.t("remainPrice"), true],
    ["date", i18n.t("date"), true],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
