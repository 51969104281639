<template>
  <div v-if="hasPermission(permissions.transferContractsShow)">
    <content-header>{{ $t("showTransferContract") }}</content-header>
    <v-content>
      <b-card>
        <template #header v-if="projectTenant() === 'ZaitonPlusCity'">
          <b-button variant="primary" @click="printVoucherZaitonArabic">{{
            $t("printArabic")
          }}</b-button>
          <span>&nbsp;</span>
          <b-button variant="primary" @click="printVoucherZaitonKurdish">{{
            $t("printKurdish")
          }}</b-button>
            <span>&nbsp;</span>
          <b-button variant="secondary" @click="printVoucherZaitonV2Arabic">{{
            $t("printArabicOrder")
          }}</b-button>
           <span>&nbsp;</span>
          <b-button variant="secondary" @click="printVoucherZaitonV2Kurdish">{{
            $t("printKurdishOrder")
          }}</b-button>
        </template>
        <template #header v-else-if="projectTenant() !== 'MassHills'">
          <b-button variant="primary" @click="printVoucher">{{
            $t("print")
          }}</b-button>
        </template>
        <b-card-body>
          <p class="lead">
            {{ $t("contractNumber") }}:
            <router-link
              :to="{
                name: 'buyContracts.show',
                params: { id: data.newContractId },
              }"
              >{{ data.contractNumber }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("fromCustomer") }}:
            <router-link
              v-if="data.oldCustomer"
              :to="{
                name: 'customers.edit',
                params: { id: data.oldCustomer.id },
              }"
              >{{
                `${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}`
              }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("toCustomer") }}:
            <router-link
              v-if="data.newCustomer"
              :to="{
                name: 'customers.edit',
                params: { id: data.newCustomer.id },
              }"
              >{{
                `${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}`
              }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("oldContract") }}:
            <router-link
              :to="{
                name: 'buyContracts.show',
                params: { id: data.oldContractId },
              }"
              >{{ data.oldContractId }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("newContract") }}:
            <router-link
              :to="{
                name: 'buyContracts.show',
                params: { id: data.newContractId },
              }"
              >{{ data.newContractId }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("date") }}: {{ formatDate(data.transferDate) }}
          </p>
          <p class="lead">{{ $t("note") }}: {{ data.note }}</p>
          <p class="lead">{{ $t("attachment") }}:</p>
          <b-link
            v-if="data.attachment"
            :href="getImageUrl(data.attachment)"
            target="_blank"
          >
            <b-img
              :src="getImageUrl(data.attachment)"
              style="width: 800px; height: 400px"
              thumbnail
              rounded
              fluid
              :alt="data.propertyId"
            ></b-img>
          </b-link>
          <p v-else>-</p>
          <add-attachment-modal
            update-url="transferContracts/UPDATE_ATTACHMENT"
            :id="data.id"
            @success="loadData"
          />
        </b-card-body>
      </b-card>
      <transfer-contract-design-diplomati-safiran
        v-if="projectTenant() === 'DiplomatiSafiran'"
        :data="data"
      />
      <transfer-contract-design-candle
        v-if="projectTenant() === 'Candle'"
        :data="data"
      />
      <zaiton-transfer-contract-design-arabic
        v-if="projectTenant() === 'ZaitonPlusCity'"
        :data="data"
      />
      <zaiton-transfer-contract-design-kurdish
        v-if="projectTenant() === 'ZaitonPlusCity'"
        :data="data"
      />
      <zaiton-transfer-contract-design-v2-kurdish
        v-if="projectTenant() === 'ZaitonPlusCity'"
        :data="data"
      />
      <zaiton-transfer-contract-design-v2-arabic
        v-if="projectTenant() === 'ZaitonPlusCity'"
        :data="data"
      />
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import IsLoading from "../../components/IsLoading";
import TransferContractDesignDiplomatiSafiran from "./TransferContractDesignDiplomatiSafiran";
import ZaitonTransferContractDesignArabic from "./ZaitonTransferContractDesignArabic";
import ZaitonTransferContractDesignKurdish from "./ZaitonTransferContractDesignKurdish";
import ZaitonTransferContractDesignV2Kurdish from "./ZaitonTransferContractDesignV2Kurdish";
import ZaitonTransferContractDesignV2Arabic from "./ZaitonTransferContractDesignV2Arabic";
import TransferContractDesignCandle from "@/views/transferContracts/TransferContractDesignCandle";
import { permissions, serverParams } from "@/plugins/constants";
import AddAttachmentModal from "@/components/AddAttachmentModal";

export default {
  name: "TransferContractShow",
  components: {
    AddAttachmentModal,
    TransferContractDesignCandle,
    TransferContractDesignDiplomatiSafiran,
    ZaitonTransferContractDesignArabic,
    ZaitonTransferContractDesignKurdish,
    ZaitonTransferContractDesignV2Kurdish,
    ZaitonTransferContractDesignV2Arabic,
    IsLoading,
    "v-content": Content,
    ContentHeader,
  },
  data() {
    return {
      isLoading: false,
      data: [],
      permissions: permissions,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("transferContracts/GET_ONE", this.$route.params.id)
        .then((response) => {
          this.data = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    printVoucher() {
      let printContents = document.getElementById(
        `transfer-contract-voucher-receipt`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printVoucherZaitonArabic() {
      let printContents = document.getElementById(
        `transfer-contract-voucher-receipt-zaiton-arabic`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },printVoucherZaitonKurdish() {
      let printContents = document.getElementById(
        `transfer-contract-voucher-receipt-zaiton-kurdish`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printVoucherZaitonV2Arabic() {
      let printContents = document.getElementById(
        `transfer-contract-voucher-receipt-zaiton-v2-arabic`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printVoucherZaitonV2Kurdish() {
      let printContents = document.getElementById(
        `transfer-contract-voucher-receipt-zaiton-v2-kurdish`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>
