import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const transferContracts = {
    namespaced: true,
    state: {
        transferContracts: {},
        createdTransferContract: {},
        getOneTransferContract: {},
        updatedTransferContract: {},
    },
    mutations: {
        SET_TRANSFER_CONTRACTS (state, value) { state.transferContracts = value },
        SET_CREATED_TRANSFER_CONTRACT (state, value) {state.createdTransferContract = value},
        SET_GET_ONE_TRANSFER_CONTRACT (state, value) {state.getOneTransferContract = value},
        SET_UPDATED_TRANSFER_CONTRACT (state, value) {state.updatedTransferContract = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/TransferContracts` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_TRANSFER_CONTRACTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TRANSFER_CONTRACTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/TransferContracts/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_TRANSFER_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_TRANSFER_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/TransferContracts`, form)
                    .then((response) => {
                        commit('SET_CREATED_TRANSFER_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_TRANSFER_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/TransferContracts/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_TRANSFER_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_TRANSFER_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/TransferContracts/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        UPDATE_ATTACHMENT(commit, form) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/TransferContracts/UploadAttachment`, form)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        transferContracts: state => state.transferContracts,
        createdTransferContract: state => state.createdTransferContract,
        getOneTransferContract: state => state.getOneTransferContract,
        updatedTransferContract: state => state.updatedTransferContract,
    }
}

export default transferContracts;
