<template>
  <div v-if="hasPermission(permissions.reportsBuyContractsDetails)">
    <content-header>{{ $t("buyContractDetail") }}</content-header>
    <v-content>
      <div class="m-2 no-print">
        <button class="btn btn-primary" @click="resetAll">
          {{ $t("reset") }}
        </button>
      </div>
      <div class="m-2 no-print">
        <select-input
          id="customers"
          :name="$t('customers')"
          label="firstName,middleName,lastName,+phone"
          api-link="customers/GET_ALL"
          @itemSelected="customerSelected"
          :showClearButton="false"
        />
      </div>
      <div class="m-2 no-print row">
        <label for="sorting" class="col-sm-2 col-form-label text-right">{{
          $t("sorting")
        }}</label>
        <div class="col-sm-4">
          <select id="sorting" class="form-select" v-model="defaultSort">
            <option value="buyingDate">{{ $t("buyingDate") }}</option>
            <option value="buyingPrice">{{ $t("buyingPrice") }}</option>
            <option value="id">{{ $t("id") }}</option>
            <option value="customerName">{{ $t("customerName") }}</option>
            <option value="contractNumber">{{ $t("contractNumber") }}</option>
          </select>
        </div>
        <label for="ordering" class="col-sm-2 col-form-label text-right">{{
          $t("ordering")
        }}</label>
        <div class="col-sm-4">
          <select id="ordering" class="form-select" v-model="defaultOrder">
            <option value="DESC">{{ $t("desc") }}</option>
            <option value="ASC">{{ $t("asc") }}</option>
          </select>
        </div>
      </div>
      <div class="card">
        <div class="card-header" style="border: none; position: relative">
          <table class="table headerTop">
            <tr>
              <td style="text-align: start; border: none !important">
                <img :src="logo1" width="210" alt="royalowerImg" />
              </td>
              <td style="text-align: center; border: none !important">
                <div class="p-2 shadow">
                  <h3>UNITS SOLD</h3>
                  <h3>الوحدات المباعة</h3>
                </div>
              </td>
              <td style="text-align: end; border: none !important">
                <img :src="logo2" width="210" alt="rgelImg" />
              </td>
            </tr>
          </table>
          <p class="pr-3 pl-3" style="border-bottom: 2px solid black">
            {{ $t("hBd") + " : " + fullName }}
          </p>
          <div class="row pr-3 pl-3 pt-2">
            <div class="col-lg-12 d-flex justify-content-center">
              <div
                class="div w-100 text-center p-2"
                style="
                  background-color: rgb(221, 197, 119);
                  border: none;
                  border-radius: 0px;
                "
              >
                <h2>
                  <b>{{ $t("unitSolds") }}</b>
                </h2>
                <p>
                  <b>{{ $t("startDateToToday") }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <table
            class="table table-bordered"
            v-for="(item, index) in rows"
            :key="index"
            style="text-align: center"
          >
            <thead>
              <tr>
                <th style="width: 100px">{{ $t("building") }}</th>
                <th style="width: 220px">{{ $t("property2") }}</th>
                <th style="width: 59px">{{ $t("floor") }}</th>
                <th style="width: 120px">{{ $t("priceOfProperty") }}</th>
                <th style="width: 100px">{{ $t("paid") }}</th>
                <th style="width: 100px" class="bl">{{ $t("remaining") }}</th>
                <th style="width: 150px">{{ $t("meterPrice") }}</th>
                <th style="width: 150px">{{ $t("dateOfSale") }}</th>
                <th colspan="2" rowspan="2" class="middleText cn">
                  <h5>
                    <b>{{ $t("contractNumber") }}</b>
                  </h5>
                </th>
                <th>{{ $t("identifier") }}</th>
              </tr>
            </thead>
            <tbody style="border-bottom: 7px solid green">
              <tr>
                <td>{{ item.propertyZone }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'properties.edit',
                      params: { id: item.propertyId },
                    }"
                    style="color: black"
                    >{{ item.propertyName }}</router-link
                  >
                </td>
                <td>{{ item.propertyName.split("-")[1] }}</td>
                <td>{{ item.buyingPrice.toLocaleString() }}</td>
                <td>{{ item.paidPrice.toLocaleString() }}</td>
                <td class="bl">{{ item.remainPrice.toLocaleString() }}</td>
                <td>{{ item.meterPrice.toLocaleString() }}</td>
                <td>{{ moment(item.buyingDate).format("DD-MM-YYYY") }}</td>
                <td colspan="2" class="middleText">
                  <h5>
                    <b>{{ item.contractNumber.padStart(4, "0") }}</b>
                  </h5>
                </td>
                <td>{{ item.propertyId }}</td>
              </tr>
              <tr>
                <th>{{ $t("bondNumber") }}</th>
                <th colspan="2">{{ $t("nOfTheBatch") }}</th>
                <th>{{ $t("amountPrice") }}</th>
                <th colspan="2" class="bl">{{ $t("paymentDate") }}</th>
                <!-- <td v-if="localStorage.getItem('locale') === 'en'" colspan="5" :rowspan="item.paymentProcesses.length + 1"> <i class="fas fa-arrow-left"></i>  {{ $t('payments') }} </td> -->
                <td colspan="5" :rowspan="item.paymentProcesses.length + 1">
                  &lt;= {{ $t("payments") }}
                </td>
              </tr>
              <tr
                v-for="(paymentItem, pIndex) in item.paymentProcesses"
                :key="pIndex"
              >
                <td>{{ paymentItem.id }}</td>
                <td colspan="2">
                  {{ $t(`${paymentItem.paymentType}`) }}
                  <span v-if="paymentItem.paymentType === 'Installment'">{{
                    ++pIndex
                  }}</span>
                </td>
                <td>{{ paymentItem.currentPayPrice.toLocaleString() }}</td>
                <td colspan="2" class="bl">
                  {{ moment(paymentItem.payDate).format("DD-MM-YYYY") }}
                </td>
              </tr>
              <tr>
                <th colspan="3">{{ $t("buyerName") }}</th>
                <th colspan="3" class="bl">{{ $t("phone") }}</th>
                <th colspan="4">{{ $t("address") }}</th>
                <th>{{ $t("identifier") }}</th>
              </tr>
              <tr>
                <td colspan="3">
                  <router-link
                    :to="{
                      name: 'customers.edit',
                      params: { id: item.customerId },
                    }"
                    style="color: black"
                    >{{ item.customerName }}</router-link
                  >
                </td>
                <td colspan="3" class="bl">
                  {{ replacePhone(item.phone) }}
                  {{ item.phone2 ? "- " + replacePhone(item.phone2) : "" }}
                </td>
                <td colspan="4">{{ item.address }}</td>
                <td>{{ item.customerId }}</td>
              </tr>
            </tbody>
            <hr class="hrCss" />
          </table>
          <!-- amarakan start here -->
          <div class="row pr-3 pl-3 pt-2">
            <div class="col-lg-12 d-flex justify-content-center">
              <div
                class="div w-100 text-center p-2"
                style="
                  background-color: rgb(221, 197, 119);
                  border: none;
                  border-radius: 0px;
                "
              >
                <h2>
                  <b>{{ $t("statisticTitle") }}</b>
                </h2>
                <p>
                  <b>{{ $t("startDateToToday") }}</b>
                </p>
              </div>
            </div>
          </div>
          <div class="pr-3 pl-3">
            <table class="table tabel-bordered text-center">
              <tr>
                <td
                  style="
                    border: none !important;
                    vertical-align: top !important;
                  "
                >
                  <table class="table text-center">
                    <tr>
                      <th style="width: 50%">{{ $t("numberOfUnits") }}</th>
                      <td>{{ PropertiesCount.toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <th style="width: 50%">{{ $t("numberOfBuyers") }}</th>
                      <td>{{ CustomersCount.toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <th style="width: 50%">{{ $t("amountsRecived") }}</th>
                      <td>{{ totalCollectedPrice.toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <th style="width: 50%">{{ $t("remainingAmounts") }}</th>
                      <td>{{ totalRemainPrice.toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("valueOfAllUnits") }}</th>
                      <td>{{ TotalAmount.toLocaleString() }}</td>
                    </tr>
                  </table>
                </td>
                <td class="p-5" style="border: none !important"></td>
                <td class="p-5" style="border: none !important"></td>
                <td
                  style="
                    border: none !important;
                    vertical-align: top !important;
                  "
                >
                  <table class="table tabel-bordered text-center">
                    <tr>
                      <th rowspan="2">{{ $t("totalActualArea") }}</th>
                      <th colspan="2">{{ $t("totalArea") }}</th>
                    </tr>
                    <tr>
                      <th>{{ $t("totalAreaPlus") }}</th>
                      <th>{{ $t("averageSalePerMeter") }}</th>
                    </tr>
                    <tr>
                      <td>{{ TotalArea.toLocaleString() }}</td>
                      <td>{{ TotalArea.toLocaleString() }}</td>
                      <td>{{ MeterAverage.toLocaleString() }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row p-3 no-print">
          <div class="col-lg-4">
            <b-pagination
              v-model="currentPage"
              pills
              :total-rows="totalRecords"
              :per-page="perPage"
            ></b-pagination>
          </div>
          <div class="col-lg-4 d-flex justify-content-center">
            <b-form-select
              class="w-50"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </div>
          <div class="col-lg-4 d-flex justify-content-end">
            <p>
              {{ $t("page") }} {{ currentPage }} {{ $t("of") }}
              {{ Math.ceil(totalRecords / perPage) }}
            </p>
          </div>
        </div>
      </div>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import Content from "@/components/Content";
import IsLoading from "@/components/IsLoading";
import { pagination, permissions, serverParams } from "@/plugins/constants";
import logo_1 from "@/assets/RoyalTower/royal.png";
import logo_2 from "@/assets/RoyalTower/gel.png";
import SelectInput from "@/components/Inputs/SelectInput";
import mixins from "@/mixins";

export default {
  name: "BuyContractDetail",
  components: { SelectInput, "v-content": Content, ContentHeader, IsLoading },
  data() {
    return {
      isLoading: false,
      logo1:
        this.projectTenant() !== "RoyalTower"
          ? this.projectTenantLogo()
          : logo_1,
      logo2:
        this.projectTenant() !== "RoyalTower"
          ? this.projectTenantLogo()
          : logo_2,
      rows: [],
      items: [],
      serverParams: serverParams,
      fromDate: this.$helpers.getCurrentDate(-12),
      toDate: this.$helpers.getCurrentDate(),
      pageOptions: pagination,
      currentPage: 1,
      perPage: 5,
      totalRecords: 0,
      pageCount: 3,
      permissions: permissions,
      fullName: "",
      customerId: 0,
      CustomersCount: 0,
      PropertiesCount: 0,
      TotalAmount: 0,
      TotalArea: 0,
      MeterAverage: 0,
      totalRemainPrice: 0,
      totalCollectedPrice: 0,
      totalPaidPrice: 0,
      defaultOrder: "DESC",
      defaultSort: "buyingDate",
    };
  },
  watch: {
    currentPage: {
      deep: true,
      handler() {
        this.loadData(true);
      },
    },
    perPage: {
      deep: true,
      handler() {
        this.loadData(true);
      },
    },
    defaultOrder: {
      deep: true,
      handler() {
        this.loadData(true);
      },
    },
    defaultSort: {
      deep: true,
      handler() {
        this.loadData(true);
      },
    },
  },
  mounted() {
    this.loadData(true);
    this.fullName = localStorage.getItem("fullName");
  },
  methods: {
    loadData(reset = false) {
      this.pageCount = this.totalRecords / this.perPage;
      let start = (this.currentPage - 1) * this.perPage;
      let end = this.currentPage * this.perPage;
      if (reset) {
        serverParams._start = start;
        serverParams._end = end;
        serverParams._sort = this.defaultSort;
        serverParams._order = this.defaultOrder;
        serverParams._search = "";
        if (this.customerId !== 0) {
          serverParams.custom_params = {
            startDate: this.fromDate,
            endDate: this.toDate,
            subLevelId: this.customerId,
            enableSubLevelId: true,
          };
        } else {
          serverParams.custom_params = {
            startDate: this.fromDate,
            endDate: this.toDate,
          };
        }
      }
      this.isLoading = true;

      this.$store
        .dispatch("reports/GET_BUY_CONTRACT_DETAILS", this.serverParams)
        .then((response) => {
          this.rows = response.data.map((a) => {
            a.paymentProcesses = a.paymentProcesses.sort(
              (d1, d2) =>
                new Date(d1.payDate && d1.payDate) -
                new Date(d1.payDate && d2.payDate)
            );
            return a;
          });
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.totalRemainPrice = parseInt(response.headers["remaintocollect"]);
          this.totalCollectedPrice = parseInt(response.headers["collected"]);
          this.CustomersCount = parseInt(response.headers["customerscount"]);
          this.PropertiesCount = parseInt(response.headers["propertiescount"]);
          this.TotalAmount = parseFloat(response.headers["totalamount"]);
          this.TotalArea =
            mixins.projectTenant() === "RoyalTower"
              ? parseFloat(response.headers["totalarea"])
              : parseFloat(response.headers["builduparea"]);
          this.MeterAverage = parseFloat(response.headers["meteraverage"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    customerSelected(customer) {
      this.customerId = customer;
      this.loadData(true);
    },
    resetAll() {
      this.customerId = 0;
      this.defaultOrder = "DESC";
      this.defaultSort = "buyingDate";
      document.getElementById("clearSelected").click();
      this.loadData(true);
    },
  },
};
</script>

<style scoped>
* {
  font-size: large;
  font-weight: bolder;
}

.middleText {
  text-align: center;
  vertical-align: middle;
}
.table th:not(.cn) {
  background-color: #eee;
  border-bottom: 2px solid rgb(119, 115, 115);
}
.table td,
.table th {
  padding: 1px;
  vertical-align: middle;
  border: 1px solid black !important;
}
.topCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hrCss {
  height: 2px;
  margin: 0;
  background-color: transparent;
}
.bl {
  border-left: 3px solid black;
}
</style>
