<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item">
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-navbar"
            type="search"
            name="property_name"
            :placeholder="$t('propertyName')"
            aria-label="Search"
            v-model="searchInput"
          />
          <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div v-if="searchResult.length > 0" id="searchResult">
          <ul>
            <li
              v-for="(search, index) in searchResult"
              :key="index"
              @click="gotoInfo(search.id)"
            >
              {{ search.name }}
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'settings' }">
          <i class="fas fa-cogs nav-icon"></i>
        </router-link>
      </li>
      <li
        v-if="
          isMainDashboardRoute &&
          projectTenant() === 'Cantal' &&
          authUserType() === 'admin'
        "
        class="nav-item lastLi"
      >
        <b-form-group
          style="margin-bottom: 0"
          label-cols="3"
          :label="$t('projects')"
          label-for="input-default"
        >
          <b-form-select
            v-model="selected"
            :options="options"
            @change="projectChanged"
          ></b-form-select>
        </b-form-group>
      </li>
    </ul>
    <switch-language />
  </nav>
</template>

<script>
import SwitchLanguage from "../SwitchLanguage";

export default {
  name: "Navbar",
  components: { SwitchLanguage },
  data() {
    return {
      isMainDashboardRoute: false,
      selected: null,
      options: [{ value: null, text: "Please select a project" }],
      searchInput: null,
      searchResult: [],
    };
  },
  watch: {
    $route(to) {
      this.isMainDashboardRoute = to.name === "mainDashboard";
      this.selectDefaultProject();
    },
    searchInput(value) {
      if (value && value.length >= 2) {
        this.search(value);
      } else {
        this.searchResult = [];
      }
    },
  },
  mounted() {
    this.isMainDashboardRoute = this.$route.name === "mainDashboard";
    this.selectDefaultProject();
    if (this.$route.name === "mainDashboard") {
      this.getProjects();
    }
  },
  methods: {
    projectChanged() {
      this.$store
        .dispatch("projects/CHANGE_PROJECT", this.selected)
        .then(() => {
          window.location.reload();
        });
    },
    selectDefaultProject() {
      this.selected = localStorage.getItem("projectId") ?? 1;
    },
    getProjects() {
      this.$store.dispatch("projects/GET_ALL").then(() => {
        this.$store.getters["projects/projects"].map((project) => {
          this.options.push({ value: project.id, text: project.name });
        });
      });
    },
    search(value) {
      let serverParams = {
        _start: 0,
        _end: 15,
        _sort: "name",
        _order: "ASC",
        _search: value,
      };
      this.$store.dispatch("properties/GET_ALL", serverParams).then(() => {
        this.searchResult = this.$store.getters["properties/properties"];
      });
    },
    gotoInfo(id) {
      this.$router
        .push({ name: "properties.edit", params: { id: id } })
        .then(() => {
          this.searchInput = null;
          window.location.reload();
        });
    },
  },
};
</script>

<style scoped>
.lastLi {
  border-left: 1px solid #000;
  padding: 0 15px;
}

#searchResult {
  border: 1px dotted #ccc;
  padding: 3px;
  margin: 4px 0;
  position: absolute;
  background-color: white;
  width: 220px;
}

#searchResult ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#searchResult ul li {
  padding: 8px 3px;
  cursor: pointer;
}

#searchResult ul li:hover {
  background: #eee;
}
</style>
