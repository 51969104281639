import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";
import mixin from "@/mixins";

const smsMessages = {
  namespaced: true,
  state: {
    smsMessages: {},
    createdSmsMessage: {},
    getOneSmsMessage: {},
    updatedSmsMessage: {},
  },
  mutations: {
    SET_SMSMESSAGES(state, value) {
      state.smsMessages = value;
    },
    SET_CREATED_SMSMESSAGE(state, value) {
      state.createdSmsMessage = value;
    },
    SET_GET_ONE_SMSMESSAGE(state, value) {
      state.getOneSmsMessage = value;
    },
    SET_UPDATED_SMSMESSAGE(state, value) {
      state.updatedSmsMessage = value;
    },
  },
  actions: {
    GET_ALL({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/SmsMessages` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_SMSMESSAGES", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_SMSMESSAGES", {});
            reject(error);
          });
      });
    },
    GET_ONE({ commit }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/SmsMessages/${id}`)
          .then((response) => {
            commit("SET_GET_ONE_SMSMESSAGE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_GET_ONE_SMSMESSAGE", {});
            reject(error);
          });
      });
    },
    CREATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.post(`/SmsMessages`, form)
          .then((response) => {
            commit("SET_CREATED_SMSMESSAGE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CREATED_SMSMESSAGE", {});
            reject(error);
          });
      });
    },
    UPDATE({ commit }, { id, form }) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.put(`/SmsMessages/${id}`, form)
          .then((response) => {
            commit("SET_UPDATED_SMSMESSAGE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_SMSMESSAGE", {});
            reject(error);
          });
      });
    },
    DELETE(commit, ids) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/SmsMessages/${ids}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    smsMessages: (state) => state.smsMessages,
    createdSmsMessage: (state) => state.createdSmsMessage,
    getOneSmsMessage: (state) => state.getOneSmsMessage,
    updatedSmsMessage: (state) => state.updatedSmsMessage,
  },
};

export default smsMessages;
