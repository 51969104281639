<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-table
            striped
            hover
            responsive
            bordered
            sort-by="total"
            :sort-desc="true"
            :items="rows"
            :fields="fields"
            :busy="isLoading"
        >
          <template slot="bottom-row">
            <td style="font-weight: bold !important">{{ $t('total') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'women') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'men') }}</td>
            <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'total') }}</td>
          </template>
        </b-table>
      </b-col>
      <b-col cols="6">
        <apexchart type="pie" height="400" :options="dataByDay" :series="seriesDataByDay"></apexchart>
      </b-col>
      <b-col cols="6">
        <apexchart type="pie" height="400" :options="dataByGender" :series="seriesDataByGender"></apexchart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import lodash from 'lodash';
import DaysOfWeek from "../../../enums/DaysOfWeek";
import {serverParams} from "@/plugins/constants";

export default {
  name: "LeadVisitByDayOfWeek",
  props: ['fromDate', 'toDate', 'currentTab'],
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: 'day',
          label: this.$t('day'),
          sortable: true
        },
        {
          key: 'women',
          label: this.$t('women'),
          sortable: true,
        },
        {
          key: 'men',
          label: this.$t('men'),
          sortable: true,
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true
        },
      ],
      rows: [],
      seriesDataByDay: [],
      dataByDay: {
        labels: [],
      },
      seriesDataByGender: [0, 0],
      dataByGender: {
        labels: [this.$t('males'), this.$t('females')],
      },
    }
  },
  watch: {
    fromDate: function () {
      this.loadData()
    },
    toDate: function () {
      this.loadData()
    },
    currentTab: function () {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      if (this.currentTab === 2) {
        this.isLoading = true;
        this.$store.dispatch('leadReports/GET_LEAD_VISITS_BY_DAY_OF_WEEK_REPORT', {
          fromDate: this.fromDate,
          toDate: this.toDate
        }).then((response) => {
          let rows = [];
          let seriesByDay = [];
          let labelsByDay = [];
          lodash.each(response.data, function (value) {
            let dayName = DaysOfWeek[new Date(value[0].visitDate).getDay()].name;
            seriesByDay.push(lodash.sumBy(value, 'males') + lodash.sumBy(value, 'females'));
            labelsByDay.push(dayName);
            rows.push({
              day: dayName,
              men: lodash.sumBy(value, 'males'),
              women: lodash.sumBy(value, 'females'),
              total: lodash.sumBy(value, 'males') + lodash.sumBy(value, 'females')
            })
          });
          this.rows = rows;
          this.seriesDataByDay = seriesByDay;

          this.dataByDay.labels = labelsByDay;
          this.$set(this.dataByDay, labelsByDay)
          this.seriesDataByGender = [
            lodash.sumBy(this.rows, 'men'),
            lodash.sumBy(this.rows, 'women')
          ];
          this.isLoading = false
        }).catch(() => this.isLoading = false)
      }
    }
  }
}
</script>
