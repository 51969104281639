import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyExpenseTypes = {
    namespaced: true,
    state: {
        propertyExpenseTypes: {},
        createdPropertyExpenseType: {},
        getOnePropertyExpenseType: {},
        updatedPropertyExpenseType: {},
    },
    mutations: {
        SET_PROPERTY_EXPENSE_TYPES (state, value) { state.propertyExpenseTypes = value },
        SET_CREATED_PROPERTY_EXPENSE_TYPE (state, value) {state.createdPropertyExpenseType = value},
        SET_GET_ONE_PROPERTY_EXPENSE_TYPE (state, value) {state.getOnePropertyExpenseType = value},
        SET_UPDATED_PROPERTY_EXPENSE_TYPE (state, value) {state.updatedPropertyExpenseType = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/PropertyExpenseTypes` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_EXPENSE_TYPES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_EXPENSE_TYPES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/PropertyExpenseTypes/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/PropertyExpenseTypes`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/PropertyExpenseTypes/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_EXPENSE_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_EXPENSE_TYPE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/PropertyExpenseTypes/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyExpenseTypes: state => state.propertyExpenseTypes,
        createdPropertyExpenseType: state => state.createdPropertyExpenseType,
        getOnePropertyExpenseType: state => state.getOnePropertyExpenseType,
        updatedPropertyExpenseType: state => state.updatedPropertyExpenseType,
    }
}

export default propertyExpenseTypes;
