import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const buyContractsAttachments = {
    namespaced: true,
    state: {
        buyContractsAttachments: {},
        createdBuyContractAttachments: {},
        getOneBuyContractAttachments: {},
        updatedBuyContractAttachments: {},
    },
    mutations: {
        SET_BUY_CONTRACTS_ATTACHMENTS (state, value) { state.buyContractsAttachments = value },
        SET_CREATED_BUY_CONTRACT_ATTACHMENT (state, value) {state.createdBuyContractAttachments = value},
        SET_GET_ONE_BUY_CONTRACT_ATTACHMENT (state, value) {state.getOneBuyContractAttachments = value},
        SET_UPDATED_BUY_CONTRACT_ATTACHMENT (state, value) {state.updatedBuyContractAttachments = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/BuyContractAttachments` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_BUY_CONTRACTS_ATTACHMENTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_BUY_CONTRACTS_ATTACHMENTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/BuyContractAttachments/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_BUY_CONTRACT_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_BUY_CONTRACT_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/BuyContractAttachments`, form)
                    .then((response) => {
                        commit('SET_CREATED_BUY_CONTRACT_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_BUY_CONTRACT_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/BuyContractAttachments/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_BUY_CONTRACT_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_BUY_CONTRACT_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/BuyContractAttachments/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/BuyContractAttachments/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        buyContractsAttachments: state => state.buyContractsAttachments,
        createdBuyContractAttachments: state => state.createdBuyContractAttachments,
        getOneBuyContractAttachments: state => state.getOneBuyContractAttachments,
        updatedBuyContractAttachments: state => state.updatedBuyContractAttachments,
    }
}

export default buyContractsAttachments;
