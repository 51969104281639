<template>
  <b-table
      striped
      hover
      responsive
      bordered
      :items="rows"
      :fields="fields"
      :busy="isLoading"
  >
    <template slot="bottom-row">
      <td colspan="2" style="font-weight: bold !important">{{ $t('total') }}</td>
      <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'females') }}</td>
      <td style="font-weight: bold !important">{{ _.sumBy(this.rows, 'males') }}</td>
      <td style="font-weight: bold !important"></td>
    </template>
  </b-table>
</template>

<script>

import moment from "moment";
import {serverParams} from "@/plugins/constants";

export default {
  name: "LeadVisitsReport",
  props: ['fromDate', 'toDate', 'currentTab'],
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: 'leadCustomerName',
          label: this.$t('customerName'),
          sortable: true
        },
        {
          key: 'leadCustomerPhone',
          label: this.$t('customerPhone'),
          sortable: true
        },
        {
          key: 'males',
          label: this.$t('women'),
          sortable: true,
        },
        {
          key: 'females',
          label: this.$t('men'),
          sortable: true,
        },
        {
          key: 'visitDate',
          label: this.$t('visitDate'),
          sortable: true,
        },
      ],
      rows: []
    }
  },
  watch: {
    fromDate: function () {
      this.loadData()
    },
    toDate: function () {
      this.loadData()
    },
    currentTab: function () {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      if (this.currentTab === 1) {
        this.isLoading = true;
        this.$store.dispatch('leadReports/GET_LEAD_VISITS_REPORT', {fromDate: this.fromDate, toDate: this.toDate})
            .then((response) => {
              this.rows = response.data.map((value) => {
                value.leadCustomerPhone = value.leadCustomerPhone.replace('+964', '0');
                value.visitDate = moment(value.visitDate).format('YYYY-MM-DD hh:mm:ss A');
                return value;
              });
              this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
      }
    }
  }
}
</script>
