<template>
  <router-view />
</template>

<script>
export const toCsv = function (table, fileName) {
  const rows = table.querySelectorAll("tr");
  const text = [].slice
    .call(rows)
    .map(function (row) {
      // Query all cells
      const cells = row.querySelectorAll("th,td");
      return [].slice
        .call(cells)
        .map(function (cell) {
          return cell.textContent.replace(",", "").replace(",", "");
        })
        .join(",");
    })
    .join("\n");
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
  );
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default {
  name: "App",
};
</script>
<style>
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/nunito.ttf) format("truetype");
}

@font-face {
  font-family: "Speda";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/speda.ttf) format("truetype");
}

* {
  font-family: Nunito, Speda, sans-serif;
}

table > thead > tr > th.vbt-column-header {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.only-print {
  display: none !important;
}

@media print {
  .only-print {
    display: block !important;
  }
}
@page {
  size: auto !important;
}

.swal-wide {
  width: 850px !important;
}
</style>
