<template>
  <div
    id="property-hold-voucher-1"
    class="contract text-right d-none"
    dir="rtl"
    v-if="data && data.property"
  >
    <b-row class="text-center">
      <b-col cols="6">
        <img :src="logo" alt="Logo" width="185" height="185" />
      </b-col>
      <b-col cols="6">
        <h1>{{ projectTenantName() }}</h1>
        <h3>مرکز المبیعات</h3>
      </b-col>
    </b-row>
    <h2 class="text-center mb-5">بطاقة حجز وحدة سکنیة</h2>
    <b-row class="text-center">
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          عمارة: <span class="selectedSpan">{{ data.property.zone }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          الطابق: <span class="selectedSpan">{{ data.property.floors }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          شقە: <span class="selectedSpan">{{ data.property.name }}</span>
        </p>
      </b-col>
      <b-col cols="3">
        <p class="lead" style="text-align: start">
          المساحة: <span class="selectedSpan">{{ data.property.area }}</span>
        </p>
      </b-col>
    </b-row>
    <p class="lead" style="text-align: start">
      الإسم:
      <span style="font-weight: bold">{{
        data.name
          ? data.name
          : `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهاتف:
      <span style="font-weight: bold">{{
        data.customer && `${replacePhone(data.customer.phone)}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهاتف ٢:
      <span style="font-weight: bold">{{
        data.customer && data.customer.phone2 && `${data.customer.phone2}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      سعر المتر:
      <span style="font-weight: bold">{{
        parseFloat(data.property.meterPrice).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      سعر الکلی:
      <span style="font-weight: bold">{{
        parseFloat(data.property.finalPrice).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      المقدمة:
      <span style="font-weight: bold">{{
        parseFloat(data.holdPayment).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      المبلغ المتبقی:
      <span style="font-weight: bold">{{
        parseFloat(
          parseFloat(data.property.finalPrice) - parseFloat(data.holdPayment)
        ).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      ١.٥٪ من المبلغ الکلی:
      <span style="font-weight: bold">{{
        parseFloat(
          0.015 * parseFloat(data.property.finalPrice)
        ).toLocaleString()
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      عنوان السکن:
      <span style="font-weight: bold">{{
        data.customer && `${data.customer.address}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      الإطلالة:
      <span style="font-weight: bold">{{
        data.property &&
        `${data.property.extraProperty1 ? data.property.extraProperty1 : "-"}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      الواجهة:
      <span style="font-weight: bold">{{
        data.property &&
        `${data.property.extraProperty2 ? data.property.extraProperty2 : "-"}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      رقم الهویة:
      <span style="font-weight: bold">{{
        data.customer && `${data.customer.idcard}`
      }}</span>
    </p>
    <p class="lead" style="text-align: start">
      تاریخ الحجز:
      <span style="font-weight: bold">{{ formatDate(data.date) }}</span>
    </p>
    <p class="lead" style="text-align: start">
      إسم الموظف: <span style="font-weight: bold">{{ getAuthUser() }}</span>
    </p>
  </div>
</template>

<script>
import logo from "@/assets/RoyalTower/royal.png";

export default {
  name: "PropertyHoldDesignRoyalTower",
  props: {
    data: {},
  },
  data() {
    return {
      logo: logo,
    };
  },
};
</script>

<style scoped>
.selectedSpan {
  font-weight: bold;
  background-color: #cccccc;
  padding: 10px;
}
</style>
