import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyFeatures = {
    namespaced: true,
    state: {
        propertyFeatures: {},
        createdPropertyFeature: {},
        getOnePropertyFeature: {},
        updatedPropertyFeature: {},
    },
    mutations: {
        SET_PROPERTY_FEATURES (state, value) { state.propertyFeatures = value },
        SET_CREATED_PROPERTY_FEATURE (state, value) {state.createdPropertyFeature = value},
        SET_GET_ONE_PROPERTY_FEATURE (state, value) {state.getOnePropertyFeature = value},
        SET_UPDATED_PROPERTY_FEATURE (state, value) {state.updatedPropertyFeature = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Properties/PostPropertyFeature` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_FEATURES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_FEATURES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Properties/PostPropertyFeature/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_FEATURE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/Properties/PostPropertyFeature`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_FEATURE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/Properties/PostPropertyFeature/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_FEATURE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_FEATURE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Properties/DeletePropertyFeature?propertyFeatureId=${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyFeatures: state => state.propertyFeatures,
        createdPropertyFeature: state => state.createdPropertyFeature,
        getOnePropertyFeature: state => state.getOnePropertyFeature,
        updatedPropertyFeature: state => state.updatedPropertyFeature,
    }
}

export default propertyFeatures;
