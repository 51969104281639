import { i18n } from "@/plugins/i18n";

export class BuyContractExpense {
  columns = [];
  id = "id";

  fillAbles = [
    ["title", i18n.t("title"), false],
    ["propertyExpenseTypeName", i18n.t("name"), false, false],
    ["mcPrice", i18n.t("amount"), false],
    ["date", i18n.t("date"), false],
    ["note", i18n.t("note"), false],
    ["attachment", i18n.t("attachment"), false, false],
    ["controls", i18n.t("controls"), false, false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility: process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
