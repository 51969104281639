import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyFollowUp = {
    namespaced: true,
    state: {
        propertyFollowUp: {},
        createdPropertyFollowUp: {},
        getOnePropertyFollowUp: {},
        updatedPropertyFollowUp: {},
    },
    mutations: {
        SET_PROPERTY_FOLLOW_UP (state, value) { state.propertyFollowUp = value },
        SET_CREATED_PROPERTY_FOLLOW_UP (state, value) {state.createdPropertyFollowUp = value},
        SET_GET_ONE_PROPERTY_FOLLOW_UP (state, value) {state.getOnePropertyFollowUp = value},
        SET_UPDATED_PROPERTY_FOLLOW_UP (state, value) {state.updatedPropertyFollowUp = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/PropertyFollowUp` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_FOLLOW_UP', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_FOLLOW_UP', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/PropertyFollowUp/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_FOLLOW_UP', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_FOLLOW_UP', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/PropertyFollowUp`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_FOLLOW_UP', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_FOLLOW_UP', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/PropertyFollowUp/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_FOLLOW_UP', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_FOLLOW_UP', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/PropertyFollowUp/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/PropertyFollowUp/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyFollowUp: state => state.propertyFollowUp,
        createdPropertyFollowUp: state => state.createdPropertyFollowUp,
        getOnePropertyFollowUp: state => state.getOnePropertyFollowUp,
        updatedPropertyFollowUp: state => state.updatedPropertyFollowUp,
    }
}

export default propertyFollowUp;
