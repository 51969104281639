<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row
        class="text-center"
        style="margin: 0; font-size: 28px; border-bottom: 3px solid #b58151"
      >
        <b-col cols="6">
          <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 42%"
          ></b-img>
        </b-col>
        <b-col cols="6" class="text-left p-2">
          <h5 class="text-bold">
            info@royaltower.com
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            accounting@royaltower.com
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            0750 446 0438 – 0750 536 3000
            <i class="fal fa-phone" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            Iraq - Baghdad - Al-Mansour - Albejiah - Beside Al-Muthanna
            Airport<i class="fal fa-map-marker" style="color: #9e7f49"></i>
          </h5>
        </b-col>
      </b-row>
      <b-row class="m-2" style="margin: 0; font-size: 20px">
        <b-col class="text-right" style="font-size: 25px" cols="4">
          <h5>
            <span class="text-bold">تسلسل::&nbsp;</span>
            <span style="border-bottom: 5px dotted #9e7f49; padding: 0 20px">{{
              voucherNo && voucherNo
            }}</span>
          </h5>
          <h5>
            <span class="text-bold">تاریخ:&nbsp;</span>
            <span style="border-bottom: 5px dotted #9e7f49; padding: 0 20px">{{
              voucherDate && formatDate(voucherDate)
            }}</span>
          </h5>
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          <strong>إيصال الاستلام</strong>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> المبلغ </b-col>
        <b-col class="text-center" cols="4">
          <strong>${{ amount && numberFormat(amount) }}</strong>
          <h4 style="border-bottom: 2px solid #9e7f49; padding-bottom: 15px">
            {{ amount && number2Text(amount, "ar") }}
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4">
          Amount
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> و ذلك عن </b-col>
        <b-col class="text-center" cols="4">
          <strong>{{ note && note }}</strong>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4">
          Purpose of receipt
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center mt-5">
        <b-col cols="6">
          <strong>توقیع المستلم</strong>
          <br />
          {{ accountantName && accountantName }}
        </b-col>
        <b-col cols="6">
          <strong>توقیع المودع</strong>
          <br />
          {{ paidName && paidName }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 40px 0" />
  </div>
</template>

<script>
import logo from "@/assets/RoyalTower/royal.png";
export default {
  name: "DepositReceiptRoyal",
  props: {
    voucherNo: {
      type: String,
    },
    voucherDate: {
      type: String,
    },
    paidName: {
      type: String,
    },
    amount: {
      type: String,
    },
    purposeOfVoucher: {
      type: String,
    },
    note: {
      type: String,
    },
    accountantName: {
      type: String,
      default: "ناوی ژمێریار",
    },
    property: {
      type: Object,
    },
  },
  data() {
    return {
      systemImage: logo,
    };
  },
};
</script>
