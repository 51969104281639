var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission(_vm.permissions.transferContractsShow))?_c('div',[_c('content-header',[_vm._v(_vm._s(_vm.$t("showTransferContract")))]),_c('v-content',[_c('b-card',{scopedSlots:_vm._u([(_vm.projectTenant() === 'ZaitonPlusCity')?{key:"header",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.printVoucherZaitonArabic}},[_vm._v(_vm._s(_vm.$t("printArabic")))]),_c('span',[_vm._v(" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.printVoucherZaitonKurdish}},[_vm._v(_vm._s(_vm.$t("printKurdish")))]),_c('span',[_vm._v(" ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.printVoucherZaitonV2Arabic}},[_vm._v(_vm._s(_vm.$t("printArabicOrder")))]),_c('span',[_vm._v(" ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.printVoucherZaitonV2Kurdish}},[_vm._v(_vm._s(_vm.$t("printKurdishOrder")))])]},proxy:true}:(_vm.projectTenant() !== 'MassHills')?{key:"header",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.printVoucher}},[_vm._v(_vm._s(_vm.$t("print")))])]},proxy:true}:null],null,true)},[_c('b-card-body',[_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("contractNumber"))+": "),_c('router-link',{attrs:{"to":{
              name: 'buyContracts.show',
              params: { id: _vm.data.newContractId },
            }}},[_vm._v(_vm._s(_vm.data.contractNumber))])],1),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("fromCustomer"))+": "),(_vm.data.oldCustomer)?_c('router-link',{attrs:{"to":{
              name: 'customers.edit',
              params: { id: _vm.data.oldCustomer.id },
            }}},[_vm._v(_vm._s(((_vm.data.oldCustomer.firstName) + " " + (_vm.data.oldCustomer.middleName) + " " + (_vm.data.oldCustomer.lastName))))]):_vm._e()],1),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("toCustomer"))+": "),(_vm.data.newCustomer)?_c('router-link',{attrs:{"to":{
              name: 'customers.edit',
              params: { id: _vm.data.newCustomer.id },
            }}},[_vm._v(_vm._s(((_vm.data.newCustomer.firstName) + " " + (_vm.data.newCustomer.middleName) + " " + (_vm.data.newCustomer.lastName))))]):_vm._e()],1),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("oldContract"))+": "),_c('router-link',{attrs:{"to":{
              name: 'buyContracts.show',
              params: { id: _vm.data.oldContractId },
            }}},[_vm._v(_vm._s(_vm.data.oldContractId))])],1),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("newContract"))+": "),_c('router-link',{attrs:{"to":{
              name: 'buyContracts.show',
              params: { id: _vm.data.newContractId },
            }}},[_vm._v(_vm._s(_vm.data.newContractId))])],1),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.$t("date"))+": "+_vm._s(_vm.formatDate(_vm.data.transferDate))+" ")]),_c('p',{staticClass:"lead"},[_vm._v(_vm._s(_vm.$t("note"))+": "+_vm._s(_vm.data.note))]),_c('p',{staticClass:"lead"},[_vm._v(_vm._s(_vm.$t("attachment"))+":")]),(_vm.data.attachment)?_c('b-link',{attrs:{"href":_vm.getImageUrl(_vm.data.attachment),"target":"_blank"}},[_c('b-img',{staticStyle:{"width":"800px","height":"400px"},attrs:{"src":_vm.getImageUrl(_vm.data.attachment),"thumbnail":"","rounded":"","fluid":"","alt":_vm.data.propertyId}})],1):_c('p',[_vm._v("-")]),_c('add-attachment-modal',{attrs:{"update-url":"transferContracts/UPDATE_ATTACHMENT","id":_vm.data.id},on:{"success":_vm.loadData}})],1)],1),(_vm.projectTenant() === 'DiplomatiSafiran')?_c('transfer-contract-design-diplomati-safiran',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.projectTenant() === 'Candle')?_c('transfer-contract-design-candle',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.projectTenant() === 'ZaitonPlusCity')?_c('zaiton-transfer-contract-design-arabic',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.projectTenant() === 'ZaitonPlusCity')?_c('zaiton-transfer-contract-design-kurdish',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.projectTenant() === 'ZaitonPlusCity')?_c('zaiton-transfer-contract-design-v2-kurdish',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.projectTenant() === 'ZaitonPlusCity')?_c('zaiton-transfer-contract-design-v2-arabic',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.isLoading)?_c('is-loading'):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }