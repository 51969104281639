<template>
  <div v-if="hasPermission(permissions.leadSubSourcesCreate)">
    <content-header>{{ $t('createLeadSubSource') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :is-loading="isLoading" />
            <select-input
                id="leadSource"
                :name="$t('leadSource')"
                label="name"
                :validate="$v"
                :required="true"
                api-link="leadSources/GET_ALL"
                @itemSelected="e => form.leadSourceId = e"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {LeadSubSource} from "@/models/LeadSubSource";
import SelectInput from "@/components/Inputs/SelectInput";
import TextInput from "@/components/Inputs/TextInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "LeadSubSourceCreate",
  components: {TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, SelectInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new LeadSubSource().fillAbles),
      isLoading: false,
      permissions: permissions
    }
  },
  validations: {
    form: {
      name: {required},
      leadSourceId: {required}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('leadSubSources/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "leadSubSources"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
