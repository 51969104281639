<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="" class="brand-link text-center">
      <span class="brand-text font-weight-light">{{
        projectTenantName()
      }}</span>
    </a>
    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            :src="getAuthUserProfileImage()"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <router-link :to="{ name: 'profile' }" class="d-block">{{
            getAuthUser()
          }}</router-link>
        </div>
      </div>
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li
            v-if="
              hasPermission([
                permissions.dashboardCard,
                permissions.dashboardTodaySummary,
                permissions.dashboardMap,
                permissions.dashboardPayments,
                permissions.dashboardGeneralData,
              ])
            "
            class="nav-item"
          >
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'dashboard' }"
              :class="activeClass(['dashboard', 'admin', 'dashboard.page'])"
            >
              <i class="fal fa-tachometer-alt nav-icon"></i>
              <p>{{ $t("dashboard") }}</p>
            </router-link>
          </li>

          <li
            v-if="
              authUserType() === 'admin' ||
              authUserType() === 'accountant' ||
              authUserType() === 'viewer'
            "
            class="nav-item"
          >
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'financialDashboard' }"
              :class="activeClass(['financialDashboard'])"
            >
              <i class="fal fa-tachometer-alt nav-icon"></i>
              <p>{{ $t("financialDashboard") }}</p>
            </router-link>
          </li>

          <li v-if="authUserType() === 'admin'" class="nav-item">
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'mainDashboard' }"
              :class="activeClass(['mainDashboard'])"
            >
              <i class="fal fa-tachometer-alt nav-icon"></i>
              <p>{{ $t("mainDashboard") }}</p>
            </router-link>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.propertiesList,
                permissions.propertiesCreate,
                permissions.propertyHoldList,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'properties',
                  'properties.create',
                  'properties.edit',
                  'propertyHolds',
                  'propertyHolds.create',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'properties',
                  'properties.create',
                  'properties.edit',
                  'propertyHolds',
                  'propertyHolds.create',
                ])
              "
            >
              <i class="nav-icon fas fa-home-lg-alt"></i>
              <p>
                {{ $t("properties") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'properties',
                  'properties.create',
                  'properties.edit',
                  'propertyHolds',
                  'propertyHolds.create',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.propertiesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'properties' }"
                  :class="activeClass(['properties'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("properties") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.propertiesCreate])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'properties.create' }"
                  :class="activeClass(['properties.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("createProperties") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.propertyHoldList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'propertyHolds' }"
                  :class="activeClass(['propertyHolds'])"
                >
                  <i class="fal fa-hand-paper nav-icon"></i>
                  <p>{{ $t("propertyHolds") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.buyContractsList,
                permissions.buyContractsCreate,
                permissions.transferContractsList,
                permissions.cancelContractsList,
                permissions.upcomingPaymentsList,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'buyContracts',
                  'buyContracts.create',
                  'buyContracts.edit',
                  'buyContracts.show',
                  'transferContracts',
                  'cancelContracts',
                  'upcomingPayments',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'buyContracts',
                  'buyContracts.create',
                  'buyContracts.edit',
                  'buyContracts.show',
                  'transferContracts',
                  'cancelContracts',
                  'upcomingPayments',
                ])
              "
            >
              <i class="nav-icon fas fa-file-signature"></i>
              <p>
                {{ $t("contracts") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'buyContracts',
                  'buyContracts.create',
                  'buyContracts.edit',
                  'buyContracts.show',
                  'transferContracts',
                  'cancelContracts',
                  'upcomingPayments',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.buyContractsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'buyContracts' }"
                  :class="activeClass(['buyContracts'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("buyContracts") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.buyContractsCreate])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'buyContracts.create' }"
                  :class="activeClass(['buyContracts.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("createBuyContract") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.transferContractsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'transferContracts' }"
                  :class="
                    activeClass(['transferContracts', 'transferContracts.show'])
                  "
                >
                  <i class="fal fa-file-alt nav-icon"></i>
                  <p>{{ $t("transferContracts") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.cancelContractsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'cancelContracts' }"
                  :class="
                    activeClass(['cancelContracts', 'cancelContracts.show'])
                  "
                >
                  <i class="fal fa-file-times nav-icon"></i>
                  <p>{{ $t("cancelContracts") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.upcomingPaymentsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'upcomingPayments' }"
                  :class="
                    activeClass(['upcomingPayments', 'upcomingPayments.show'])
                  "
                >
                  <i class="fal fa-money-check nav-icon"></i>
                  <p>{{ $t("upcomingPayments") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.customersList,
                permissions.customersCreate,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                ['customers', 'customers.create', 'customers.edit'],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass(['customers', 'customers.create', 'customers.edit'])
              "
            >
              <i class="nav-icon fas fa-users"></i>
              <p>
                {{
                  projectTenant() === "MassHills"
                    ? $t("buyers")
                    : $t("customers")
                }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'customers',
                  'customers.create',
                  'customers.edit',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.customersList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'customers' }"
                  :class="activeClass(['customers'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("list") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.customersCreate])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'customers.create' }"
                  :class="activeClass(['customers.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("create") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.leadVisitsList,
                permissions.leadVisitsCreate,
                permissions.leadCustomersList,
                permissions.leadCustomersCreate,
                permissions.leadSourcesList,
                permissions.leadSubSourcesList,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'leadCustomers',
                  'leadCustomers.create',
                  'leadCustomers.edit',
                  'leadVisits',
                  'leadVisits.create',
                  'leadVisits.edit',
                  'leadSources',
                  'leadSources.create',
                  'leadSources.edit',
                  'leadSubSources',
                  'leadSubSources.create',
                  'leadSubSources.edit',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'leadCustomers',
                  'leadCustomers.create',
                  'leadCustomers.edit',
                  'leadVisits',
                  'leadVisits.create',
                  'leadVisits.edit',
                  'leadSources',
                  'leadSources.create',
                  'leadSources.edit',
                  'leadSubSources',
                  'leadSubSources.create',
                  'leadSubSources.edit',
                ])
              "
            >
              <i class="nav-icon fas fa-search"></i>
              <p>{{ $t("leads") }}<i class="fal fa-angle-left right"></i></p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'leadCustomers',
                  'leadCustomers.create',
                  'leadCustomers.edit',
                  'leadVisits',
                  'leadVisits.create',
                  'leadVisits.edit',
                  'leadSources',
                  'leadSources.create',
                  'leadSources.edit',
                  'leadSubSources',
                  'leadSubSources.create',
                  'leadSubSources.edit',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.leadVisitsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadVisits' }"
                  :class="activeClass(['leadVisits'])"
                >
                  <i class="fal fa-file-invoice-dollar nav-icon"></i>
                  <p>{{ $t("leadVisits") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.leadVisitsCreate])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadVisits.create' }"
                  :class="activeClass(['leadVisits.create'])"
                >
                  <i class="fal fa-file-plus nav-icon"></i>
                  <p>{{ $t("createLeadVisits") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.leadCustomersList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadCustomers' }"
                  :class="activeClass(['leadCustomers'])"
                >
                  <i class="fal fa-user-plus nav-icon"></i>
                  <p>{{ $t("leadCustomers") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.leadCustomersCreate])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadCustomers.create' }"
                  :class="activeClass(['leadCustomers.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("createLeadCustomers") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.leadSourcesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadSources' }"
                  :class="activeClass(['leadSources'])"
                >
                  <i class="fal fa-search-plus nav-icon"></i>
                  <p>{{ $t("leadSources") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.leadSubSourcesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadSubSources' }"
                  :class="activeClass(['leadSubSources'])"
                >
                  <i class="fal fa-print-search nav-icon"></i>
                  <p>{{ $t("leadSubSources") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.expensesList,
                permissions.depositsList,
                permissions.withdrawsList,
                permissions.transfersList,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'expenses',
                  'expenses.create',
                  'expenses.edit',
                  'deposits',
                  'deposits.create',
                  'deposits.edit',
                  'withdraws',
                  'withdraws.create',
                  'withdraws.edit',
                  'transfers',
                  'transfers.create',
                  'smsMessages',
                  'smsMessages.create',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'expenses',
                  'expenses.create',
                  'expenses.edit',
                  'deposits',
                  'deposits.create',
                  'deposits.edit',
                  'withdraws',
                  'withdraws.create',
                  'withdraws.edit',
                  'transfers',
                  'transfers.create',
                  'smsMessages',
                  'smsMessages.create',
                ])
              "
            >
              <i class="nav-icon fas fa-money-bill-wave"></i>
              <p>
                {{ $t("accounting") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'expenses',
                  'expenses.create',
                  'expenses.edit',
                  'deposits',
                  'deposits.create',
                  'deposits.edit',
                  'withdraws',
                  'withdraws.create',
                  'withdraws.edit',
                  'transfers',
                  'transfers.create',
                  'smsMessages',
                  'smsMessages.create',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.expensesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'expenses' }"
                  :class="activeClass(['expenses'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("expenses") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.depositsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'deposits' }"
                  :class="activeClass(['deposits'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("deposits") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.withdrawsList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'withdraws' }"
                  :class="activeClass(['withdraws'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("withdraws") }}</p>
                </router-link>
              </li>
              <li
                v-if="
                  hasPermission([permissions.smsMessageList]) && hasSMSModule()
                "
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'smsMessages' }"
                  :class="activeClass(['smsMessages'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("smsMessages") }}</p>
                </router-link>
              </li>
              <li
                v-if="
                  (projectTenant() === 'RoyalTower' ||
                    projectTenant() === 'Candle') &&
                  hasPermission([permissions.transfersList])
                "
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'transfers' }"
                  :class="activeClass(['transfers'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("transfers") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="hasPermission([permissions.investorsList])"
            class="nav-item"
          >
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'investors' }"
              :class="activeClass(['investors', 'investors.show'])"
            >
              <i class="fal fa-money-check-edit-alt nav-icon"></i>
              <p>
                {{
                  projectTenant() !== "Candle" ? $t("investors") : $t("agents")
                }}
              </p>
            </router-link>
          </li>

          <li v-if="hasPermission([permissions.usersList])" class="nav-item">
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'users' }"
              :class="activeClass(['users', 'users.show'])"
            >
              <i class="fal fa-users nav-icon"></i>
              <p>{{ $t("users") }}</p>
            </router-link>
          </li>

          <li
            v-if="
              hasPermission([
                permissions.reportsLeadVisits,
                permissions.reportsLeadVisitsByDayOfWeek,
                permissions.reportsDailyLeadVisits,
                permissions.reportsPerSources,
                permissions.reportsProperty,
                permissions.reportsTransactions,
                permissions.reportsExpenses,
                permissions.reportsWithdraws,
                permissions.reportsDeposits,
                permissions.reportsBuyContracts,
                permissions.reportsPayment,
                permissions.reportsSafeBoxTransactions,
                permissions.reportsBuyContractsDetails,
                permissions.reportsExpensesByType,
                permissions.reportsForgivePrice,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'leadReports',
                  'propertyReport',
                  'transactions',
                  'expensesReport',
                  'expensesByType',
                  'depositsReport',
                  'withdrawsReport',
                  'buyContractReport',
                  'paymentProcessReport',
                  'upcommingPaymentReport',
                  'partialPaymentReport',
                  'safeBoxTransactionReport',
                  'buyContractDetail',
                  'forgivePriceReport',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'leadReports',
                  'propertyReport',
                  'transactions',
                  'expensesReport',
                  'expensesByType',
                  'depositsReport',
                  'withdrawsReport',
                  'buyContractReport',
                  'paymentProcessReport',
                  'upcommingPaymentReport',
                  'partialPaymentReport',
                  'safeBoxTransactionReport',
                  'buyContractDetail',
                  'forgivePriceReport',
                ])
              "
            >
              <i class="nav-icon fas fa-file-search"></i>
              <p>{{ $t("reports") }}<i class="fal fa-angle-left right"></i></p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'leadReports',
                  'propertyReport',
                  'transactions',
                  'expensesReport',
                  'expensesByType',
                  'depositsReport',
                  'withdrawsReport',
                  'buyContractReport',
                  'paymentProcessReport',
                  'upcommingPaymentReport',
                  'partialPaymentReport',
                  'safeBoxTransactionReport',
                  'buyContractDetail',
                  'forgivePriceReport',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.reportsLeadVisits])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'leadReports' }"
                  :class="activeClass(['leadReports'])"
                >
                  <i class="fal fa-file-plus nav-icon"></i>
                  <p>{{ $t("leadReports") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsProperty])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'propertyReport' }"
                  :class="activeClass(['propertyReport'])"
                >
                  <i class="fal fa-house-return nav-icon"></i>
                  <p>{{ $t("propertyReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsTransactions])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'transactions' }"
                  :class="activeClass(['transactions'])"
                >
                  <i class="fal fa-exchange nav-icon"></i>
                  <p>{{ $t("transactions") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsExpenses])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'expensesReport' }"
                  :class="activeClass(['expensesReport'])"
                >
                  <i class="fal fa-money-bill nav-icon"></i>
                  <p>{{ $t("expensesReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsPropertyExpense])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'propertyExpenseReportList' }"
                  :class="activeClass(['propertyExpenseReportList'])"
                >
                  <i class="fal fa-money-bill nav-icon"></i>
                  <p>{{ $t("propertyExpenseList") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsDeposits])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'depositsReport' }"
                  :class="activeClass(['depositsReport'])"
                >
                  <i class="fal fa-money-bill-wave-alt nav-icon"></i>
                  <p>{{ $t("depositsReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsWithdraws])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'withdrawsReport' }"
                  :class="activeClass(['withdrawsReport'])"
                >
                  <i class="fal fa-hand-holding-usd nav-icon"></i>
                  <p>{{ $t("withdrawsReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsBuyContracts])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'buyContractReport' }"
                  :class="activeClass(['buyContractReport'])"
                >
                  <i class="fal fa-money-bill-wave-alt nav-icon"></i>
                  <p>{{ $t("buyContractReport") }}</p>
                </router-link>
              </li>

              <li
                v-if="hasPermission([permissions.reportsPayment])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'paymentProcessReport' }"
                  :class="activeClass(['paymentProcessReport'])"
                >
                  <i class="fal fa-hand-holding-box nav-icon"></i>
                  <p>{{ $t("paymentsReport") }}</p>
                </router-link>
              </li>

              <li
                v-if="hasPermission([permissions.reportsPayment])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'upcommingPaymentReport' }"
                  :class="activeClass(['upcommingPaymentReport'])"
                >
                  <i class="fal fa-hand-holding-box nav-icon"></i>
                  <p>{{ $t("upcomingPayments") }}</p>
                </router-link>
              </li>

              <li
                v-if="hasPermission([permissions.reportsPayment])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'partialPaymentReport' }"
                  :class="activeClass(['partialPaymentReport'])"
                >
                  <i class="fal fa-hand-holding-box nav-icon"></i>
                  <p>{{ $t("partialPaymentReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsSafeBoxTransactions])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'safeBoxTransactionReport' }"
                  :class="activeClass(['safeBoxTransactionReport'])"
                >
                  <i class="fal fa-box-alt nav-icon"></i>
                  <p>{{ $t("safeBoxTransactionReport") }}</p>
                </router-link>
              </li>
              <li
                v-if="
                  hasPermission([permissions.reportsBuyContractsDetails]) &&
                  (projectTenant() === 'RoyalTower' ||
                    projectTenant() === 'Candle' ||
                    projectTenant() === 'Techeye')
                "
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'buyContractDetail' }"
                  :class="activeClass(['buyContractDetail'])"
                >
                  <i class="fal fa-file-contract nav-icon"></i>
                  <p>{{ $t("buyContractDetail") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsExpensesByType])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'expensesByType' }"
                  :class="activeClass(['expensesByType'])"
                >
                  <i class="fal fa-text-width nav-icon"></i>
                  <p>{{ $t("expensesByType") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.reportsForgivePrice])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'forgivePriceReport' }"
                  :class="activeClass(['forgivePriceReport'])"
                >
                  <i class="fal fa-file-contract nav-icon"></i>
                  <p>{{ $t("forgivePriceReport") }}</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="
              projectTenant() === 'Cantal' &&
              hasPermission([permissions.userTasksList])
            "
            class="nav-item"
          >
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'userTasks' }"
              :class="activeClass(['userTasks'])"
            >
              <i class="fal fa-check-circle nav-icon"></i>
              <p>{{ $t("userTasks") }}</p>
            </router-link>
          </li>
          <li
            v-if="
              hasPermission([
                permissions.propertyTypesList,
                permissions.featuresList,
                permissions.propertyExpenseTypesList,
                permissions.expenseTypesList,
                permissions.depositTypesList,
                permissions.withdrawTypesList,
              ])
            "
            class="nav-item"
            :class="
              activeClass(
                [
                  'propertyTypes',
                  'propertyTypes.create',
                  'propertyTypes.edit',
                  'features',
                  'features.create',
                  'features.edit',
                  'propertyExpenseTypes',
                  'propertyExpenseTypes.create',
                  'propertyExpenseTypes.edit',
                  'expenseTypes',
                  'expenseTypes.create',
                  'expenseTypes.edit',
                  'depositTypes',
                  'depositTypes.create',
                  'depositTypes.edit',
                  'withdrawTypes',
                  'withdrawTypes.create',
                  'withdrawTypes.edit',
                  'projects',
                  'projects.edit',
                ],
                'open-menu'
              )
            "
          >
            <a
              href=""
              class="nav-link"
              :class="
                activeClass([
                  'propertyTypes',
                  'propertyTypes.create',
                  'propertyTypes.edit',
                  'features',
                  'features.create',
                  'features.edit',
                  'propertyExpenseTypes',
                  'propertyExpenseTypes.create',
                  'propertyExpenseTypes.edit',
                  'expenseTypes',
                  'expenseTypes.create',
                  'expenseTypes.edit',
                  'depositTypes',
                  'depositTypes.create',
                  'depositTypes.edit',
                  'withdrawTypes',
                  'withdrawTypes.create',
                  'withdrawTypes.edit',
                  'projects',
                  'projects.edit',
                ])
              "
            >
              <i class="nav-icon fas fa-abacus"></i>
              <p>{{ $t("initials") }}<i class="fal fa-angle-left right"></i></p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'propertyTypes',
                  'propertyTypes.create',
                  'propertyTypes.edit',
                  'features',
                  'features.create',
                  'features.edit',
                  'propertyExpenseTypes',
                  'propertyExpenseTypes.create',
                  'propertyExpenseTypes.edit',
                  'expenseTypes',
                  'expenseTypes.create',
                  'expenseTypes.edit',
                  'depositTypes',
                  'depositTypes.create',
                  'depositTypes.edit',
                  'withdrawTypes',
                  'withdrawTypes.create',
                  'withdrawTypes.edit',
                  'projects',
                  'projects.edit',
                ])
              "
            >
              <li
                v-if="hasPermission([permissions.propertyTypesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'propertyTypes' }"
                  :class="activeClass(['propertyTypes'])"
                >
                  <i class="fal fa-house-day nav-icon"></i>
                  <p>
                    {{ projectTenant() === "Candle" ? $t("type") : $t("type") }}
                  </p>
                </router-link>
              </li>
              <li
                v-if="
                  hasPermission([permissions.featuresList]) &&
                  projectTenant() !== 'MassHills'
                "
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'features' }"
                  :class="activeClass(['features'])"
                >
                  <i class="fal fa-cFheck-double nav-icon"></i>
                  <p>{{ $t("features") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.propertyExpenseTypesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'propertyExpenseTypes' }"
                  :class="activeClass(['propertyExpenseTypes'])"
                >
                  <i class="fal fa-money-bill-alt nav-icon"></i>
                  <p>{{ $t("propertyExpenseTypes") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.expenseTypesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'expenseTypes' }"
                  :class="activeClass(['expenseTypes'])"
                >
                  <i class="fal fa-list-alt nav-icon"></i>
                  <p>{{ $t("expenseTypes") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.depositTypesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'depositTypes' }"
                  :class="activeClass(['depositTypes'])"
                >
                  <i class="fal fa-list-alt nav-icon"></i>
                  <p>{{ $t("depositTypes") }}</p>
                </router-link>
              </li>
              <li
                v-if="hasPermission([permissions.withdrawTypesList])"
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'withdrawTypes' }"
                  :class="activeClass(['withdrawTypes'])"
                >
                  <i class="fal fa-list-alt nav-icon"></i>
                  <p>{{ $t("withdrawTypes") }}</p>
                </router-link>
              </li>
              <li
                v-if="
                  projectTenant() === 'Cantal' &&
                  authUserType() === 'admin' &&
                  hasPermission([permissions.projectsList])
                "
                class="nav-item"
              >
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'projects' }"
                  :class="activeClass(['projects'])"
                >
                  <i class="fal fa-file-chart-line nav-icon"></i>
                  <p>{{ $t("projects") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a class="nav-link" @click.prevent="logout" style="cursor: pointer">
              <i class="fal fa-sign-out-alt nav-icon"></i>
              <p>{{ $t("logout") }}</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { permissions } from "@/plugins/constants";
import { HTTP } from "@/plugins/axios";

export default {
  name: "Sidebar",
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      permissions: permissions,
    };
  },
  methods: {
    hasSMSModule() {
      return process.env.VUE_APP_SMS_MODULE === "true";
    },
    async logout() {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      delete HTTP.defaults.headers.common["Authorization"];

      localStorage.removeItem("userInfo");
      localStorage.removeItem("userType");
      localStorage.removeItem("code");
      localStorage.removeItem("secondCode");

      this.$router.push({ name: "login" });
      await this.$store.dispatch("auth/LOGOUT");
    },
    activeClass(routesName, className = "active") {
      if (routesName.includes(this.$route.name)) {
        return className;
      }
      return "";
    },
    displaySubList(routesName) {
      if (routesName.includes(this.$route.name)) {
        return "display: block";
      }
      return "display: none";
    },
  },
};
</script>
