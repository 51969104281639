<template>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <img :src="languageImage" class="img-fluid" width="16" height="16" :alt="$t('language')">
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a href="#" class="dropdown-item" @click.prevent="changeLanguage('ku')">
          <img :src="kurdishFlag" class="img-fluid" width="16" height="16" :alt="$t('kurdish')">
          {{ $t('kurdish') }}
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" @click.prevent="changeLanguage('ku_badini')">
          <img :src="kurdishFlag" class="img-fluid" width="16" height="16" :alt="$t('kurdish_badini')">
          {{ $t('kurdish_badini') }}
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" @click.prevent="changeLanguage('ar')">
          <img :src="arabicFlag" class="img-fluid" width="16" height="16" :alt="$t('arabic')">
          {{ $t('arabic') }}
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" @click.prevent="changeLanguage('en')">
          <img :src="englishFlag" class="img-fluid" width="16" height="16" :alt="$t('english')">
          {{ $t('english') }}
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import languageImage from './../assets/images/flags/language.svg';
import kurdishFlag from './../assets/images/flags/ku.svg';
import arabicFlag from './../assets/images/flags/ar.svg';
import englishFlag from './../assets/images/flags/en.svg';

export default {
  name: "SwitchLanguage",
  data() {
    return {
      languageImage,
      kurdishFlag,
      arabicFlag,
      englishFlag
    }
  },
  methods: {
    changeLanguage(locale) {
      if (this.$store.getters['language/language'] !== locale) {
        this.$store.dispatch('language/CHANGE_LANGUAGE', locale).then(() => window.location.reload())
      }
    }
  }
}
</script>
