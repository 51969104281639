import ku from './ku';
import ku_badini from './ku_badini';
import ar from './ar';
import en from './en';

export default {
    ku,
    ar,
    en,
    ku_badini,
};
