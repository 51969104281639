<template>
  <div class="contract text-right" dir="rtl">
    <h1 class="text-center">(( گرێبەستى فرۆشتنى یەكەى نیشتەجێبوون ))</h1>
    <p class="lead" style="text-align: right">
      لە رۆژى : {{ getCurrentDate() }} لە شارى هەولێر رەزامەندى درا لە لایەن هەر
      یەك لە:
    </p>
    <p class="lead" style="text-align: right">
      1- لایەنى یەكەم (فرۆشیار) بەڕێزان/كۆمپانیاى ماس-عێڕاق بۆ وەبەرهێنانى
      عەقارى كە نوێنەرەكەى لەم گرێبەستە بەڕێز/ {{ accountant }} / ژمارەی مۆبایل
      / {{ accountantPhone }}
    </p>
    <p class="lead" style="text-align: right">
      2- لایەنى دووەم: (كڕیار) بەڕێز/
      {{
        customer.firstName + " " + customer.middleName + " " + customer.lastName
      }}
      هەڵگرى ڕەگەزنامەى {{ customer.nationality }} بە پێى كارتى نشتیمانى یان
      ژمارە پاسەپۆڕت {{ customer.idcard }}.
    </p>
    <h2 class="text-center">(( پێشەکی ))</h2>
    <p class="lead" style="text-align: right">
      كۆمپانیاى ماس-عێڕاق، بە پێی مۆڵەتى وەبەرهێنان ژمارە (44) كە دەرچووە لە
      25/06/2012 لە بەڕێوەبەرایەتى گشتى وەبەرهێنان/هەولێر لە 25/06/2012،
      هەڵدەستێت بە دروست كردن و سەرپەرشتى كردنى كۆمەڵگایەكى نیشتەجێبوون كە
      دەكەوێتە سەر ڕێگاى (هەولێر-پیرمام) لەسەر پارچەى ژمارە / 68 و 69 و 444
      كەرتی 90 پیرزین .... و 15 و 7 كەرتی 91 بێركۆت . لایەنى یەكەم هەڵسا بە
      فرۆشتنى یەكەى نیشتەجێبوونى ژمارە: {{ property.name }}
    </p>
    <b-row style="margin: 0; padding: 0">
      <b-col cols="4">
        <p class="lead" style="text-align: right">
          ڕووبەرى زەوى: {{ property.streetWidth }}
        </p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: right">
          ڕووبەرى دروستكردن: {{ property.area }}
        </p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: right">
          جۆرى یەكە: {{ property.streetNumber }}
        </p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: right">
          ناوى كەرت: {{ property.zone }}
        </p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: right">
          ژمارەى بلۆك: {{ property.block }}
        </p>
      </b-col>
    </b-row>
    <p class="lead" style="text-align: right">
      بەهاى یەكەى نیشتەجێبوونەكە: {{ property.initialPrice }} دۆلارى ئەمریكییە.
    </p>
    <p class="lead" style="text-align: right">
      وە بە گوێرەى ئارەزووى كڕینى لایەنى دووەم، هەردوو لایەن كە شیاوى تەواون لە
      ڕووى بەرپرسیارەتى و یاسایى، پێك هاتن لەسەر ناوەڕۆكى ئەم گرێبەستە وە
      پێشەكییەكەى ئەم گرێبەستە بەشێكە جیاواز نابێتەوە لەم گرێبەستە وە پێك دێت
      لەم بڕگانە:
    </p>
    <p class="lead" style="text-align: right">
      یەكەم: بەها و شێوەى پارەدانەكە.
    </p>
    <p class="lead" style="text-align: right">
      دووەم: وەرگرتنى یەكەى نیشتەجێبوونەكە.
    </p>
    <p class="lead" style="text-align: right">
      سێەم: پابەندبوونەكانى لایەنى دووەم و گەرەنتییەكانى بۆ لایەنى یەكەم.
    </p>
    <p class="lead" style="text-align: right">
      چوارەم: دواكەوتن لە پابەندبوونەكان و كۆتایى پێهێنانى گرێبەستەكە.
    </p>
    <p class="lead" style="text-align: right">پێنجەم: مەرجە گشتییەكان.</p>
    <p class="lead" style="text-align: right">شەشەم: هاوپێچەكانى گرێبەستەكە.</p>
    <p class="lead" style="text-align: right">
      حەوتەم: واژووكردن لەسەر گرێبەستەكە.
    </p>
    <p class="lead" style="text-align: right">یەكەم: بەها و شێوەى پارەدانەكە</p>
    <p class="lead" style="text-align: right">
      كۆى گشتى كە لەسەرى ڕێككەوتوون بریتییە لە
      {{ property.initialPrice.toLocaleString() }} دۆلارى ئەمریكی وە بڕەكە بەم
      شێوەیە دەدرێت:
    </p>
    <!-- Start Payment Process -->
    <p class="lead" style="text-align: right">
      - الدفعة الاولى 20% من قيمة الوحده السكنية تدفع عند توقيع العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الثانية 15% من قيمة الوحده السكنية تدفع بعد 6 أشهر من توقيع
      العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الثالثة 15 % من قيمة الوحده السكنية تدفع بعد 12 شهراً من توقيع
      العقد.
    </p>
    <p class="lead" style="text-align: right">
      - الدفعة الرابعة 25% من قيمه الوحده السكنية وتدفع بعد 18 شهراً من توقيع
      العقد وعند استلام الوحدة السكنية.
    </p>
    <p class="lead" style="text-align: right">
      - ويسدد المبلغ المتبقي البالغ (%25) من قيمة الوحده السكنية بـ ( 25 ) قسطاً
      شهرياً متساويا.
    </p>
    <!-- End Payment Process -->
    <p class="lead" style="text-align: right">
      دووەم: وەرگرتنى یەكەى نیشتەجێبوونەكە
    </p>
    <p class="lead" style="text-align: right">
      لایەنى یەكەم یەكەى نیشتەجێبوونەكە ڕادەستى لایەنى دووەم دەكات لە دواى
      تێپەڕبوونى (16 مانگى كاركردن) لە واژووكردنى گرێبەستەكە بە مەرجێك لایەنى
      دووەم تەواوى بڕى كڕینەكەى دابێت وە تەواوى ئەركەكانى گرێبەستەكەى خۆى جێ بە
      جێ كردبێت كە پارەدانى تەواوى ئەو بڕانەیە كە لە سەروو ئاماژەى پێكراوە كە
      كۆى دەكاتە (75%) تەواوى بەهاى خانووەكە.
    </p>
    <p class="lead" style="text-align: right">
      گواستنەوەى مولكیەت: یەكەى نیشتەجێبوونەكە تۆمار دەكرێت بە ناوى لایەنى دووەم
      دواى دانى تەواوى بڕە پارەكان بە لایەنى یەكەمكە ئەو قیستانەى كە ماوەتەوەش
      دەیگرێتەوە، لایەنى دووەمیش تەواوى خەرجى و ڕسوماتى فەرمانگەى تۆمارى عەقارى
      تایبەت و فەرمانگەكانى تر دەگرێتە ئەستۆ لە چوارچێوەى ئەم گرێبەستە.
    </p>
    <p class="lead" style="text-align: right">
      بەدواداچوونى كارەكانى پڕۆژەكە-لایەنى دووەم پێی ڕاگەیەنراوە وە رەزامەندى
      دەربڕیوە لەسەر ئەوەى كە بابەتى یەكەى نیشتەجێبوونى ئەم گرێبەستە بە شێكە لە
      پڕۆژەیەكى تەواو، وە كارەكانى دروست كردنى پرۆژەكە بە دواداچوونى بۆ دەكرێت
      بە پێى پلان و بەرنامەى كاركردنى ئەم پڕۆژەیە. بۆیە لایەنى دووەم مافى ئەوەى
      نابێت كە بە دواداچوون بكات بۆ كارەكانى ئەم پڕۆژەیە یان داواكردنى هەر
      قەرەبوویەك یان پەككەوتنێك یان زیانێك سەبارەت بەم بابەتە.
    </p>
    <p class="lead" style="text-align: right">
      سێیەم: پابەندبوونەكانى لایەنى دووەم و گەرەنتییەكانى بۆ لایەنى یەكەم
    </p>
    <p class="lead" style="text-align: right">
      لایەنى دووەم ئەم گەرەنتیانە دەدات بە لایەنى سێیەمەوە:
    </p>
    <p class="lead" style="text-align: right">
      - نابێت شوێنى یەكەى نیشتەجێبوونەكان بۆ مەبەستى تر بەكاربهێندرێن.
    </p>
    <p class="lead" style="text-align: right">
      - لایەنى دووەم بەرپرسە لە خەرجییەكانى ئاو و كارەبا و خزمەتگوزارییەكانى تر
      وە هەموو خەرجییەكانى تر وەكو باج و رسومات لەسەر موڵكەكان لە بەروارى
      وەرگرتنى یەكەكە.
    </p>
    <p class="lead" style="text-align: right">
      - لایەنى دووەم بۆى نییە هیچ گۆڕانكارییەك بكات یان زیادە بخاتە سەر رووى
      دەرەوەى یەكەى نیشتەجێبوونەكە وە مافى ئەوەى نییە هیچ دیوارێك بشكێنێت لە
      دواى وەرگرتنى یەكەى نیشتەجێبوونەكە تەنانەت دواى تۆماركردنى لە فەرمانگەى لە
      فەرمانگەى تۆماركردنى خانوو و بەرە وە لایەنى دووەم پابەند دەبێت بە ڕێنمایى
      و بڕیارەكان كە لە بەڕێوەبەرایەتى پڕۆژەكە دەردەچێت وە لە حاڵەتى پابەند
      نەبوون بەرپرس دەبێت لە ڕووى یاسا.
    </p>
    <p class="lead" style="text-align: right">
      - لایەنى دووەم مافى ئەوەى نییە پاشگەز بێتەوە لەم گرێبەستە پێش ئەوەى گشت
      بڕە پارەكان بدات، تەنها بە نووسرواێكى پەسندكراو نەبێت كە لە لایەن لایەنى
      یەكەمەوە دەرچووبێت وە كڕیار دەبێت پابەند بێـت بەم گرێبەستە وە دەبێت بڕە
      پارەكانى تر بدات بە لایەنى یەكەمەوە وە كڕیارى نوێ پابەند بێت بە گشت
      بڕگەكانى ئەم گرێبەستە وە گشت بڕە پارەكان بدات كە ماوەتەوە لە بەروارى هاتن
      بۆ ناو ئەم گرێبەستە.
    </p>
    <p class="lead" style="text-align: right">
      - لایەنى دووەم پابەند دەبێت بە ئەنجام نەدانى هیچ كارێك كە ببێتە هۆى
      ئازاردان و ناڕەحەتى كردنى یەكە نیشتەجێبووەكانى تر یان ناوچە هاوبەشەكان یان
      ئەو كەسانەى كە تێیداینە.
    </p>
    <p class="lead" style="text-align: right">
      - لایەنى دووەم پابەند دەبێـت بە ڕێنمایى و مەرجەكانى پڕۆژەكە لە لایەنى
      ئەمنى و ژینگەییەوە وە پاراستنى گشت ئەم شتانەى كە لە نێو پڕۆژەكەدا هەیە
      (هێلەكانى كارەبا، ئاو، غاز، ....هتد) وە پارچەكانى ترى ژێرخان.
    </p>
    <p class="lead" style="text-align: right">
      - لە حاڵەتى بوونى هەر ناكۆكییەك لە نێوان هەردووك لایەن ئەوا پەنا دەبردرێتە
      بەر دادگاكانى هەولێر وە بە گوێرەى یاساكانى هەرێمى كوردستان پەیڕەو دەكرێت.
    </p>
    <p class="lead" style="text-align: right">
      چوارەم: دواكەوتن لە پابەندبوونە داراییەكان و كۆتایى پێهێنانى گرێبەستەكە:
    </p>
    <p class="lead" style="text-align: right">
      1- لە حاڵەتى دوا كەوتنى لایەنى دووەم لە پارەدان لە بەروارى دیاریكراودا
      ئەوا لە ماوەى كەمتر لە هەفتە ئاگادار دەكرێـتەوە، وە لە حاڵەتى وەڵام
      نەدانەوە ئەوا بڕى 150$ پێبژاردنى پێدەكرێت (سەد و پەنجا دۆلارى ئەمریكی) بۆ
      هەر ڕۆژێكى دوا كەوتن، وە ئەگەر لایەنى دووەم لە پارەدانى دوو قیست دوا كەوت
      لە بەروارى دیاریكراودا ئەوا ئەوا لایەنى یەكەم بۆى هەیە ئەم گرێبەستە پووچەڵ
      بكاتەوە بە شێوەیەكى كۆتایى بە بێ ئاگاداركردنەوەى لایەنى دووەم وە مافى
      ئەوەى ڤێلایەكە بفرۆشێت وە وەك خاوەنى كارى پێبكات، ئەمەش دواى فرۆشتنى
      ڤێلایەكە بۆ لایەنێكى تر وە وەرگرتنى تەواوى پارەكە پابەند دەبێت بە
      گەڕاندنەوەى گشت ئەو بڕانەى كە دراوە لە لایەن لایەنى دووەمەوە دواى
      دیاریكردنى بەهاى سزاى دیاریكاو بە 10% بەهاى گرێبەستە نوێیەكە وە دەرهێنانى
      پێبژاردنەكانى دواكەوتن.
    </p>
    <p class="lead" style="text-align: right">
      2- لە حاڵەتى دوا كەوتنى لاەینى یەكەم لە ماوەى 16 مانگى كاركردن لە دواى
      واژووكردنى گرێبەستى یەكەى نیشتەجێبوونەكە، ئەوا پابەند دەبێت بە دانى
      پێبژاردنى دارایى بە بڕى 50%$ (تەنها پەنجا دۆلارى ئەمریكی) بۆ هەر ڕۆژێكى
      دواكەوتن ئەگەر لاەینى یەكەم پابەند بێـت بە دانى قیستەكان لە بەروارى خۆى،
      ئەم پابەندبوونەش لەگەڵ سازشت دەكرێـت لەگەڵ پارەدانى دوا بڕى پارەكە لە كاتى
      وەرگرتنى كلیلەكان ئەم بڕگەیەش جیادەكرێتەوە لە حاڵەتى بارودۆخى لە ناكاوى
      بەدەر لە ویستى لایەنى یەكەم وەك بڕیارەكان یان بارودۆخی سیاسى و كارەساتە
      سروشتییەكان لافاوەكان و بومەلەرزەكان و ڕووداوى ترى كۆمەڵایەتى و سەربازى
      یان سەرهەڵدانى ناكۆكى یان دەركەوتنى ناكۆكى چەكدراى یان جەنگە گشتییەكان)
      ئەوا ئەم كاتە وەك كاتێكى زیادكراو هەژماردەكرێت بۆ لایەنى یەكەم.
    </p>
    <p class="lead" style="text-align: right">
      3- لە كاتى داواكردنى لایەنى یەكەم بۆ پووچەڵ كردنەوەى گرێبەستەكە دواى واژوو
      كردنى ئەوا پێبژاردنى دارایى پێ دەكرێـت بە بڕى 20% بەهاى ئەو بڕانەى كە
      دراوە بە لایەنى یەكەم وە بڕە پارەكەى ترى بۆ دەگەڕێتەوە بە گوێرەى مەرجەكانى
      لایەنى یەكەم.
    </p>
    <p class="lead" style="text-align: right">پێنجەم: مەرجە گشتییەكان:</p>
    <p class="lead" style="text-align: right">
      1- لایەنى یەكەم پابەند دەبێـت بە چاككردنەوە بۆ ماوەى یەك ساڵ لە دواى
      بەروارى نیشتەجێبوون ئەم چاككردنەوەیەش یەكەكە و و ئەم ئامێرانى ناو یەكەكە
      دەگرێتەوە تەنها ئەو كەم و كوڕیانە نەبێـت كە بە هۆى كەم تەرخەمى یان خراپ
      بەكارهێنان ڕوویانداوە.
    </p>
    <p class="lead" style="text-align: right">
      2- هەمو پەیوەندییەكان و ئاگاداركردنەوەكان لە ڕێگاى كورتە نامە یان ئیمێڵ
      دەبێت یان لە ڕێگاى تەلەفۆنەوە دەبێت بۆ ئەم ناونیشانانەى دوو لایەنەكە كە لە
      پێشەوەى گرێبەستەكەدا هاتووە ئەگەر هیچ گۆڕانكارییەك ڕوونەدات بە
      ئاگاداركردنەوەى لایەنەكەى تر.
    </p>
    <p class="lead" style="text-align: right">
      3- پابەند نەبوونى هەر لایەنێك بە بڕگەكانى ئەم گرێبەستە یان لە سوود وەرگرتن
      لە مافەكانى كە پێیدراوە، نابێتە هۆى ئەوەى كە لەم بەندە پاشگەز دەبێتەوە یان
      شكاندنى ماف كەسانى تر وە هەموو مافەكان وەك خۆیان دەمێننەوە لەسەر هەردوو
      لایەن.
    </p>
    <p class="lead" style="text-align: right">
      4- هەردوو لاەین پابەند دەبن بە یاساكان و بڕیارەكان و ئەو ڕێنماییانەى
      دەرچووە وە هەر چى لە داهاتوودا دەردەچێت لە لایەن لایەنە فەرمییەكانى
      تایبەت، لە هەرێمى كوردستانى عێڕاق.
    </p>
    <p class="lead" style="text-align: right">
      5- ئەم گرێبەستە بەگوێرەى مافەكان و ئەركەكانى هەردوو لایەن كە باس كراوە وە
      شلۆڤەكردنى و جێبەجێكردنى یاساكان و سیستەمەكانى هەرێمى كوردستانى عێڕاقە وە
      دادگاكانى هەولێر لایەنى تایبەتن بە هەموو بابەتێكەوە.
    </p>
    <p class="lead" style="text-align: right">شەشەم: هاوپێچەكان:</p>
    <p class="lead" style="text-align: right">
      • ئەمانەى خوارەوە هاوپێچ دەكرێن لەگەڵ گرێبەستەكە وە بەشێكى جیانەكراوەیە
      لەم گرێبەستە:
    </p>
    <p class="lead" style="text-align: right">
      • نەخشە و خەسڵەتەكانى ڤێلایەكە.
    </p>
    <p class="lead" style="text-align: right">
      • ناسنامەى بارى شارستانى یان كارتى نشتیمانى یان پاسەپۆرت.
    </p>
    <p class="lead" style="text-align: right">• خشتەى پارەدان.</p>
    <p class="lead" style="text-align: right">
      • دۆسەى ڕاپۆرتەكانى پشكنینى بیناسازى و كارەبایى و میكانیكى لە دواى تەواو
      كردنى پشكنینى شوێنەكان.
    </p>
    <p class="lead" style="text-align: right">حەوتەم: واژووكردن</p>
    <p class="lead" style="text-align: right">
      ئەم گرێبەستە بە دوو وێنەى ڕەسەن دەرچووە وە ( ) بڕگە لە خۆ دەگرێت وە لە ( )
      لاپەڕ پێك دێت.
    </p>
    <p class="lead" style="text-align: right">
      ئەم گرێبەستە لە لاەین هەردوو لایەن واژوو لەسەر كرا لە هەولێر لە بەروارى:
      {{ getCurrentDate() }}
    </p>
    <b-row class="text-center" style="margin: 0; padding: 0">
      <b-col cols="6">
        <p class="lead" style="text-align: right">لایەنى یەكەم:-</p>
      </b-col>
      <b-col cols="6">
        <p class="lead" style="text-align: right">لایەنى دووەم:-</p>
      </b-col>
    </b-row>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>واژوو:</td>
          <td class="w-32"></td>
          <td>واژوو:</td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>ناوى سيانى:</td>
          <td class="w-32"></td>
          <td>ناوى سيانى:</td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>
            ژمارەى ناسنامەى بارى شارستانى:
            <br />
            پاسەپۆڕت:
          </td>
          <td class="w-32"></td>
          <td>
            ژمارەى ناسنامەى بارى شارستانى:
            <br />
            پاسەپۆڕت:
          </td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>ناونيشانى دانيشتنى:</td>
          <td class="w-32"></td>
          <td>ناونيشانى دانيشتنى:</td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>ژمارەى تەلەفۆن:</td>
          <td class="w-32"></td>
          <td>ژمارەى تەلەفۆن:</td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>ژمارەى تەلەفۆن:</td>
          <td class="w-32"></td>
          <td>ژمارەى تەلەفۆن:</td>
          <td class="w-32"></td>
        </tr>
        <tr>
          <td>ئیمێڵ:</td>
          <td class="w-32"></td>
          <td>ئیمێڵ:</td>
          <td class="w-32"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "KurdishContract",
  props: {
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
};
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1.28rem;
  font-weight: 300;
}
</style>
