import { i18n } from "@/plugins/i18n";
import mixins from "@/mixins";

export class Properties {
  columns = [];
  id = "id";

  fillAbles = [
    [
      "name",
      mixins.projectTenant() === "MassHills"
        ? i18n.t("villaNumber")
        : i18n.t("name"),
      true,
    ],
    [
      "zone",
      mixins.projectTenant() === "Candle" ? i18n.t("tower") : i18n.t("zone"),
      true,
    ],
    [
      "block",
      mixins.projectTenant() === "Candle" ? i18n.t("floor") : i18n.t("block"),
      true,
    ],
    [
      "propertyTypeName",
      mixins.projectTenant() === "Candle"
        ? i18n.t("apartmentType")
        : i18n.t("propertyType"),
      true,
    ],
    ["streetNumber", i18n.t("streetNumber"), true, false],
    ["numberOfRooms", i18n.t("numberOfRooms"), true, false],
    ["floors", i18n.t("floors"), true, false],
    [
      "buildUpArea",
      mixins.projectTenant() === "Candle"
        ? i18n.t("apartment")
        : i18n.t("area"),
      true,
      mixins.projectTenant() !== "MassHills",
    ],
    ["area", i18n.t("area"), true, mixins.projectTenant() === "MassHills"],
    ["streetWidth", i18n.t("streetWidth"), true, false],
    [
      "initialPrice",
      i18n.t("initialPrice"),
      true,
      mixins.projectTenant() === "MassHills",
    ],
    [
      "features",
      i18n.t("corner"),
      true,
      mixins.projectTenant() === "MassHills",
    ],
    [
      "features1",
      i18n.t("road20m"),
      true,
      mixins.projectTenant() === "MassHills",
    ],
    [
      "features2",
      i18n.t("facingPark"),
      true,
      mixins.projectTenant() === "MassHills",
    ],
    [
      "variance",
      i18n.t("additionalShortagePlotArea"),
      true,
      mixins.projectTenant() === "MassHills",
    ],
    [
      "finalPrice",
      mixins.projectTenant() === "Candle"
        ? i18n.t("apartmentPrice")
        : i18n.t("finalPrice"),
      true,
    ],
    ["meterPrice", i18n.t("meterPrice"), true, false],
    ["extraCost", i18n.t("extraCost"), true, false],
    ["extraProperty1", i18n.t("extraProperty1"), true, false],
    ["extraProperty2", i18n.t("extraProperty2"), true, false],
    ["note", i18n.t("note"), true],
    ["isSold", i18n.t("status"), true],
    ["isHold", i18n.t("status"), true, false],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility: mixins.projectTenant() !== "MassHills",
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
