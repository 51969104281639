<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row class="text-center" style="margin: 0;font-size: 28px;border-bottom: 3px solid #B58151">
        <b-col cols="6">
          <b-img :src="systemImage" fluid rounded="circle" alt="Responsive image" style="width: 42%;"></b-img>
        </b-col>
        <b-col cols="6" class="text-left p-2">
          <h5 class="text-bold">info@royaltower.com <i class="fal fa-envelope" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">accounting@royaltower.com <i class="fal fa-envelope" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">07844402001 - 07844402002 <i class="fal fa-phone" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">Iraq - Baghdad - Al-Mansour - Albejiah - Beside Al-Muthanna Airport<i class="fal fa-map-marker" style="color: #9E7F49"></i></h5>
        </b-col>
      </b-row>
      <b-row class="m-2" style="margin: 0;font-size: 20px">
        <b-col class="text-center" style="font-size: 25px" cols="8">
          <h3 class="text-center">دفع الفواتير</h3>
        </b-col>
        <b-col cols="4">
          <h5>
            <span class="text-bold">رقم:&nbsp;</span>
            <span class="underLine width-200">{{ voucherNo && voucherNo }}</span>
          </h5>
          <h5>
            <span class="text-bold">تاريخ:&nbsp;</span>
            <span class="underLine width-200">{{ voucherDate && formatDate(voucherDate) }}</span>
          </h5>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          أعطيت ل
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ paidTo && paidTo }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          مبلغ من المال حسب الرقم
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">${{ amount && numberFormat(amount) }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          مبلغ المال عن طريق الكتابة
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ amount && number2Text(amount, 'ar') }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row v-if="reason" style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          لغرض
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ reason !== null ? reason : '-' }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          ملاحظه
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ note !== null ? note : '-' }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px" class="text-center mt-5">
        <b-col cols="6">
          <strong>توقيع المستلم</strong>
          <br />
          <strong>المستلم</strong>
        </b-col>
        <b-col cols="6">
          <strong>توقيع التذييل</strong>
          <br />
          <strong>المسلم</strong>
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #B58151;height: 0; margin: 40px 0"/>
  </div>
</template>

<script>
import logo from '@/assets/RoyalTower/royal.png'
export default {
  name: "WithdrawVoucherRoyal",
  props: {
    voucherNo: {},
    voucherDate: {
      type: String
    },
    paidTo: {
      type: String
    },
    amount: {
      type: Number
    },
    reason: {
      type: String
    },
    note: {
      type: String
    },
  },
  data() {
    return {
      systemImage: logo
    }
  },
}
</script>

<style>
.underLine {
  display: inline-block;
  border-bottom: 5px dotted #9E7F49;
  padding: 0 20px
}

.width-200 {
  width: 200px;
}

.width-700 {
  width: 700px;
}
</style>
