import {HTTP} from "@/plugins/axios";

const leadReports = {
    namespaced: true,
    state: {
        perSources: {},
        setLeadVisitByDate: {},
        setLeadVisitsReport: {},
        setLeadVisitsByDayOfWeekReport: {},
    },
    mutations: {
        SET_PER_SOURCES (state, value) { state.perSources = value },
        SET_LEAD_VISIT_BY_DATE (state, value) { state.setLeadVisitByDate = value },
        SET_LEAD_VISITS_REPORT (state, value) { state.setLeadVisitsReport = value },
        SET_LEAD_VISITS_BY_DAY_OF_WEEK_REPORT (state, value) { state.setLeadVisitsByDayOfWeekReport = value },
    },
    actions: {
        GET_PER_SOURCES ({commit}, {fromDate, toDate}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadReports/LeadVisitBySubSource?startDate=${fromDate}&endDate=${toDate}`)
                    .then((response) => {
                        commit('SET_PER_SOURCES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PER_SOURCES', {})
                        reject(error)
                    });
            })
        },
        GET_LEAD_VISIT_BY_DATE ({commit}, {fromDate, toDate}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadReports/LeadVisitByDate?startDate=${fromDate}&endDate=${toDate}`)
                    .then((response) => {
                        commit('SET_LEAD_VISIT_BY_DATE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_VISIT_BY_DATE', {})
                        reject(error)
                    });
            })
        },
        GET_LEAD_VISITS_REPORT({commit}, {fromDate, toDate}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadVisits?startDate=${fromDate}&endDate=${toDate}`)
                    .then((response) => {
                        commit('SET_LEAD_VISITS_REPORT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_VISITS_REPORT', {})
                        reject(error)
                    });
            })
        },
        GET_LEAD_VISITS_BY_DAY_OF_WEEK_REPORT({commit}, {fromDate, toDate}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadReports/LeadVisitByDayOfWeek?startDate=${fromDate}&endDate=${toDate}`)
                    .then((response) => {
                        commit('SET_LEAD_VISITS_BY_DAY_OF_WEEK_REPORT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_VISITS_BY_DAY_OF_WEEK_REPORT', {})
                        reject(error)
                    });
            })
        },
    },
    getters : {
        perSources: state => state.perSources,
        setLeadVisitByDate: state => state.setLeadVisitByDate,
        setLeadVisitsReport: state => state.setLeadVisitsReport,
        setLeadVisitsByDayOfWeekReport: state => state.setLeadVisitsByDayOfWeekReport,
    }
}

export default leadReports;
