<template>
  <div v-if="hasPermission(permissions.transfersCreate)">
    <content-header>{{ $t('createTransfer') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
                id="fromSafeBoxId"
                :name="$t('fromSafeBox')"
                :validate="$v"
                :required="true"
                api-link="safeBoxes/GET_ALL"
                label="name,+mcCurrentBalance"
                @itemSelected="e => form.fromSafeboxId = e"
            />
            <select-input
                id="toSafeBoxId"
                :name="$t('toSafeBox')"
                :validate="$v"
                :required="true"
                api-link="safeBoxes/GET_ALL"
                label="name,+mcCurrentBalance"
                @itemSelected="e => form.toSafeboxId = e"
            />
            <number-input id="mcPrice" :name="$t('price')" :show-locale-string="true" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="dateTime" :name="$t('date')" :default-value="getCurrentDate()" time :validate="$v" :required="true" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {SafeboxTransfer} from "@/models/SafeboxTransfer";
import SubmitInput from "@/components/Inputs/SubmitInput";
import FileInput from "@/components/Inputs/FileInput";
import SelectInput from "@/components/Inputs/SelectInput";
import NumberInput from "@/components/Inputs/NumberInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "SafeboxTransferCreate",
  components: {TextAreaInput, DateTimeInput, NumberInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, FileInput, SelectInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new SafeboxTransfer().fillAbles),
      isLoading: false,
      selectedItem: {},
      permissions: permissions
    }
  },
  validations: {
    form: {
      fromSafeboxId: {required},
      toSafeboxId: {required},
      mcPrice: {required},
      dateTime: {required},
      note: {},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      delete this.form.mcTotalPrice;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('transfers/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "transfers"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
