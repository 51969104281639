import axios from 'axios';
import router from "@/router";
import Swal from "sweetalert2";
import {i18n} from "./i18n";

const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) ?? null;
export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        'Access-Control-Expose-Headers': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'projectId': localStorage.getItem('projectId') ?? 1,
        'Language': localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_LOCALE_DEFAULT,
        'code': localStorage.getItem('code'),
        'secondCode': localStorage.getItem('secondCode'),
    }
});

HTTP.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME)
        delete HTTP.defaults.headers.common['Authorization']
        localStorage.removeItem('userInfo')
        router.push({ name: 'login' })
    }
    if (error.response.status === 400 || error.response.status === 422) {
        if (error.config.url === "/Customers" && error.response.data.message.startsWith("Duplicate phone found for")) {
            let errorMessage = error.response.data.message.split(',')[0].trim();
            let customerId = error.response.data.message.split(',')[1].trim();
            Swal.fire({
                icon: 'error',
                title: errorMessage,
                showConfirmButton: false,
                html: `<a href="/Customers/${customerId}/edit">${customerId}</a>`,
                timer: 5000000
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message ?? error.response.data,
                showConfirmButton: false,
                timer: 2500
            });
        }
    }
    if (error.response.status === 404) {
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: i18n.t('notFound'),
            showConfirmButton: false,
            timer: 1000
        });
        // router.go(-1)
    }
    return Promise.reject(error)
})
