<template>
  <div>
    <content-header>Create Buy Contract Expense</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="title" name="Title" :validate="$v" :required="true" :is-loading="isLoading" />
            <select-input
                label="name"
                id="propertyExpenseTypeId"
                :name="$t('expenseType')"
                api-link="propertyExpenseTypes/GET_ALL"
                :required="true"
                @itemSelected="e => form.propertyExpenseTypeId = e"
            />
            <number-input id="mcPrice" name="Price" :show-locale-string="true" default-value="0" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="date" name="Date" :default-value="getCurrentDate()" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-area-input id="note" name="Note" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                name="Attachment"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {BuyContractExpense} from "@/models/BuyContractExpense";
import FileInput from "@/components/Inputs/FileInput";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "BuyContractExpenseCreate",
  components: {
    SelectInput,
    SubmitInput,
    TextAreaInput,
    DateTimeInput,
    NumberInput, TextInput, IsLoading, ContentHeader, 'v-content': Content, FileInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new BuyContractExpense().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      title: {required},
      propertyExpenseTypeId: {required},
      mcPrice: {required},
      date: {required},
      note: {},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.buyContractId = this.$route.params.id;
      this.form.propertyId = this.$route.params.property;
      this.form.qty = 1;
      this.isLoading = true;
      this.$store.dispatch('propertyExpenses/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'buyContract.expenses', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
