import { HTTP } from "@/plugins/axios";
import axios from "axios";
import mixin from "../../mixins";

const auth = {
  namespaced: true,
  state: {
    isLoggedIn: false,
    token: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || "",
    userInfo: {},
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_USER_INFO(state, value) {
      state.userInfo = value;
    },
  },
  actions: {
    LOGIN({ commit }, auth) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BASE_API_URL}/Authentication/Login`,
            auth
          )
          .then((response) => {
            localStorage.setItem("fullName", response.data.fullName);
            localStorage.setItem("userName", response.data.userName);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("useIQD", response.data.byMain === false);
            localStorage.setItem("projectId", response.data.projectId);

            if (
              response.data.image &&
              response.data.image !== "" &&
              response.data.image !== null &&
              response.data.image !== undefined
            ) {
              localStorage.setItem("image", response.data.image);
            }

            localStorage.setItem(
              "userType",
              mixin.encrypt(response.data.userType)
            );
            localStorage.setItem("code", response.data.code);
            localStorage.setItem("secondCode", response.data.secondCode);

            localStorage.setItem("firstName", response.data.firstName);
            localStorage.setItem("middleName", response.data.middleName);
            localStorage.setItem("lastName", response.data.lastName);

            localStorage.setItem(
              process.env.VUE_APP_TOKEN_NAME,
              response.data.token
            );
            HTTP.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token;

            commit("SET_TOKEN", response.data.token);
            commit("SET_IS_LOGGED_IN", true);
            commit("SET_USER_INFO", response.data.fullName);
            resolve(response);
          })
          .catch((error) => {
            // localStorage.removeItem('fullName')
            // localStorage.removeItem('userName')
            // localStorage.removeItem('email')
            // localStorage.removeItem('image')
            //
            // localStorage.removeItem('userType')
            // localStorage.removeItem('code')
            // localStorage.removeItem('secondCode')
            //
            // localStorage.remove('firstName')
            // localStorage.remove('middleName')
            // localStorage.remove('lastName')
            //
            // localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME)
            // delete HTTP.defaults.headers.common['Authorization']
            //
            // commit('SET_TOKEN', '')
            // commit('SET_IS_LOGGED_IN', false)
            // commit('SET_USER_INFO', {})
            reject(error);
          });
      });
    },
    LOGOUT({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BASE_API_URL}/Authentication/LogOut`,
            {},
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem(
                  process.env.VUE_APP_TOKEN_NAME
                )}`,
                projectId: localStorage.getItem("projectId") ?? 1,
                Language:
                  localStorage.getItem("locale") ??
                  process.env.VUE_APP_I18N_LOCALE_DEFAULT,
                code: localStorage.getItem("code"),
                secondCode: localStorage.getItem("secondCode"),
              },
            }
          )
          .then((response) => {
            commit("SET_TOKEN", "");
            commit("SET_IS_LOGGED_IN", false);
            commit("SET_USER_INFO", {});
            resolve(response);
          })
          .catch((error) => {
            commit("SET_TOKEN", "");
            commit("SET_IS_LOGGED_IN", false);
            commit("SET_USER_INFO", {});
            reject(error);
          });
      });
    },
  },
  getters: {
    token: (state) => state.token,
    isLoggedIn: (state) => state.isLoggedIn,
    userInfo: (state) => state.userInfo,
  },
};

export default auth;
