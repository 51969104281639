<template>
  <div
    v-if="hasPermission(permissions.buyContractsCreate)"
    class="buy-contract"
  >
    <content-header>{{ $t("createBuyContract") }} sdfrdsfsdfsd</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col>
              <select-input
                id="property"
                :name="$t('property')"
                label="name"
                api-link="properties/GET_ALL"
                :api-params="{ OnlyAvailable: true }"
                @itemSelected="propertySelected"
                :return-object="true"
              />
              <select-input
                id="customers"
                :name="$t('customers')"
                label="firstName,middleName,lastName,+phone"
                api-link="customers/GET_ALL"
                @itemSelected="customerSelected"
              />
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('apartment')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('apartment')"
                    v-model="property.buildUpArea"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('apartmentType')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('apartmentType')"
                    v-model="property.propertyTypeName"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('tower')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('tower')"
                    v-model="property.zone"
                    disabled
                  ></b-form-input>
                  <b-form-input
                    :placeholder="$t('floor')"
                    v-model="property.block"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('contractNumber')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('contractNumber')"
                    v-model="contractNumber"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-input-group
                      :prepend="$t('propertyPrice')"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-input
                        disabled
                        :placeholder="$t('propertyPrice')"
                        v-model="property.initialPrice"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-input-group
                      :prepend="$t('finalPrice')"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-input
                        disabled
                        :placeholder="$t('finalPrice')"
                        v-model="finalPrice"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group v-if="!isCash">
                <!-- :prepend="$t('installments')" -->
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-col>
                    <b-form-group>
                      <b-input-group
                        :prepend="$t('noOfInstallments')"
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <b-form-select
                          v-model="installmentsNumber"
                          :options="installmentsNumberOptions"
                          @change="calculateNumberOfInstallments"
                        ></b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- <b-form-input type="number" :placeholder="$t('noOfInstallments')" v-model="installmentsNumber" @change="calculateInstallments" :disabled="true"></b-form-input> -->
                  <b-form-input
                    type="number"
                    :placeholder="$t('periodOfInstallments')"
                    v-model="installmentPeriod"
                    @change="calculateInstallments"
                    :disabled="true"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.withExpense variant="info">{{
                      $t("withExpense")
                    }}</b-button>
                  </b-card-header>
                  <b-collapse
                    id="withExpense"
                    accordion="withExpenseAccordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-form-group>
                        <b-input-group
                          :prepend="$t('title')"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-form-input
                            type="text"
                            :placeholder="$t('title')"
                            v-model="expenseTitle"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <select-input
                          label="name"
                          id="propertyExpenseTypeId"
                          :name="$t('propertyExpenseType')"
                          api-link="propertyExpenseTypes/GET_ALL"
                          :required="true"
                          @itemSelected="(e) => (expenseTypeId = e)"
                        />
                      </b-form-group>
                      <b-form-group>
                        <b-input-group
                          :prepend="$t('price')"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-form-input
                            type="number"
                            min="0"
                            :placeholder="$t('price')"
                            v-model="expensePrice"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-form-group>
              <b-form-checkbox switch v-model="isCash">{{
                $t("isCash")
              }}</b-form-checkbox>
            </b-form-group>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- <b-form-group>
              <b-form-checkbox
                v-model="showDiscount"
                name="check-button"
                switch
                >{{ $t("dari") }}</b-form-checkbox
              >
            </b-form-group> -->
          </b-row>
          <b-row v-if="!isCash">
            <b-col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("installment") }}</th>
                    <th>{{ $t("percentage") }}</th>
                    <th>{{ $t("percentageOfInstallments") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ $t("prepayment") }} 1</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment1Percent"
                          @change="calculateInstallments"
                          :disabled="!prePayment1IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="IQD">
                        <b-form-input
                          type="number"
                          v-model="prePayment1"
                          @change="calculateInstallments"
                          :disabled="prePayment1IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox"
                                name="percentCheckbox"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment1IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment1Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("payment") }} 2</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment2Percent"
                          @change="calculateInstallments"
                          :disabled="!prePayment2IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="IQD">
                        <b-form-input
                          type="number"
                          v-model="prePayment2"
                          @change="calculateInstallments"
                          :disabled="prePayment2IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox"
                                name="percentCheckbox"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment2IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment2Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("deliveringKeys") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="deliveryKeyPercent"
                          @change="calculateInstallments"
                          :disabled="!deliveryKeyIsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="IQD">
                        <b-form-input
                          type="number"
                          v-model="deliveryKey"
                          @change="calculateInstallments"
                          :disabled="deliveryKeyIsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                v-model="deliveryKeyIsPercent"
                                id="deliveryKeyCheckbox"
                                name="deliveryKeyCheckbox"
                                :value="true"
                                :unchecked-value="false"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="deliveryKeyDate"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("installments") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="currentInstallmentsPercent"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('contractNote')">
                        <b-form-textarea
                          id="note"
                          v-model="buyContractNote"
                        ></b-form-textarea>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                  <tr
                    v-for="installment in installments"
                    v-bind:key="installment.index"
                  >
                    <th>{{ installment.index }}</th>
                    <th>
                      <b-input-group prepend="IQD">
                        <b-form-input
                          type="number"
                          v-model="installment.currentPayPrice"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="installment.currentPayDate"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('discount')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    type="number"
                    :max="property.initialPrice"
                    min="0"
                    :placeholder="$t('discount')"
                    v-model="discount"
                    @change="calculateDiscount"
                    :disabled="discountByPercent"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('discount') + ' (%)'"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    type="number"
                    max="100"
                    min="0"
                    :placeholder="$t('discount')"
                    v-model="percentDiscount"
                    @change="calculateDiscountPercent"
                    :disabled="!discountByPercent"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="discountByPercent"
                  name="check-button"
                  switch
                  @change="!discountByPercent"
                  >{{ $t("percent") }}</b-form-checkbox
                >
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group>
                <b-form-checkbox v-model="showDiscount" name="check-button" switch>{{ $t('showDiscount') }}</b-form-checkbox>
              </b-form-group>
            </b-col> -->
          </b-row>
          <button
            class="btn btn-outline-primary col-md-3"
            type="submit"
            :disabled="isLoading"
            @click="onSubmit"
          >
            <i class="fas fa-plus"></i> {{ $t("create") }}
          </button>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import SelectInput from "../../components/Inputs/SelectInput";
import moment from "moment";
import Swal from "sweetalert2";
import { permissions } from "@/plugins/constants";

export default {
  name: "BuyContractsCreateCandle",
  components: { ContentHeader, "v-content": Content, SelectInput },
  data() {
    return {
      isLoading: false,
      installments: [],
      property: "",
      customer: 0,
      contractNumber: "0",
      installmentsNumber: 96,
      installmentsNumberOptions: [{ value: 96, text: 96 }],
      installmentPeriod: 1,
      prePayment1: 0,
      prePayment1Percent: 18,
      prePayment1IsPercent: true,
      prePayment1Date: this.$helpers.getCurrentDate(),
      prePayment2: 0,
      prePayment2Percent: 22,
      prePayment2IsPercent: true,
      prePayment2Date: this.$helpers.getCurrentDate(6),
      deliveryKey: 0,
      deliveryKeyPercent: 30,
      deliveryKeyIsPercent: true,
      deliveryKeyDate: this.$helpers.getCurrentDate(12),
      installmentsPercent: 30,
      buyContractNote: "",
      currentInstallmentsPercent: 30,
      discount: 0,
      percentDiscount: 0,
      discountByPercent: false,
      finalPrice: 0,
      showDiscount: false,
      expenseTitle: "",
      expenseTypeId: 0,
      expensePrice: 0,
      permissions: permissions,
      isCash: false,
    };
  },
  mounted() {
    this.calculateInstallments();
  },
  methods: {
    customerSelected(customerId) {
      this.customer = customerId;
    },
    propertySelected(property) {
      this.property = property;
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/GET_BUY_CONTRACT_NUMBER")
        .then((response) => {
          this.contractNumber = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
      this.calculateInstallments();
    },
    calculateNumberOfInstallments() {
      if (this.installmentsNumber == 48) {
        this.prePayment1Percent = 20;
        this.prePayment1Percent = 20;
        this.deliveryKeyPercent = 30;
        this.installmentsPercent = 30;
      } else if (this.installmentsNumber == 72) {
        this.prePayment1Percent = 20;
        this.prePayment1Percent = 20;
        this.deliveryKeyPercent = 25;
        this.installmentsPercent = 35;
      } // `this.installmentsNumber == 180
      else {
        this.prePayment1Percent = 20;
        this.prePayment2Percent = 10;
        this.deliveryKeyPercent = 10;
        this.installmentsPercent = 60;
      }
      this.calculateInstallments();
    },
    calculateInstallments() {
      let customInstallments = [];
      let percent =
        parseFloat(this.deliveryKeyPercent) +
        parseFloat(this.prePayment1Percent) +
        parseFloat(this.prePayment2Percent) +
        parseFloat(this.percentDiscount);

      this.currentInstallmentsPercent = 100 - percent;
      let propertyInitial =
        ((this.property.initialPrice - this.discount) *
          (this.currentInstallmentsPercent / 100)) /
        this.installmentsNumber;
      for (let i = 1; i <= this.installmentsNumber; i++) {
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Installment",
          index: i,
          currentPayPrice: propertyInitial,
          currentPayDate: moment(this.deliveryKeyDate)
            .add(this.installmentPeriod * i, "M")
            .format("YYYY-MM-DD"),
        });
      }
      this.installments = customInstallments;
      if (this.prePayment1IsPercent) {
        this.prePayment1 =
          (this.prePayment1Percent / 100) *
          (this.property.initialPrice - this.discount);
      } else {
        this.prePayment1Percent =
          (this.prePayment1 / (this.property.initialPrice - this.discount)) *
          100;
      }
      if (this.prePayment2IsPercent) {
        this.prePayment2 =
          (this.prePayment2Percent / 100) *
          (this.property.initialPrice - this.discount);
      } else {
        this.prePayment2Percent =
          (this.prePayment2 / (this.property.initialPrice - this.discount)) *
          100;
      }
      if (this.deliveryKeyIsPercent) {
        this.deliveryKey =
          (this.deliveryKeyPercent / 100) *
          (this.property.initialPrice - this.discount);
      } else {
        this.deliveryKeyPercent =
          (this.deliveryKey / (this.property.initialPrice - this.discount)) *
          100;
      }
      this.finalPrice = this.property.initialPrice - this.discount;
    },
    onSubmit() {
      let customInstallments = [];
      if (this.isCash) {
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.property.finalPrice - this.discount,
          currentPayDate: this.prePayment1Date,
        });
      } else {
        customInstallments = this._.clone(this.installments);
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.prePayment1,
          currentPayDate: this.prePayment1Date,
        });
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.prePayment2,
          currentPayDate: this.prePayment2Date,
        });
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: true,
          isPayed: false,
          paymentProcessType: "DeliveryKey",
          currentPayPrice: this.deliveryKey,
          currentPayDate: this.deliveryKeyDate,
        });
      }
      let sum = this._.sumBy(customInstallments, (val) => {
        return parseFloat(val.currentPayPrice);
      });
      if (
        parseFloat(sum.toFixed(3)) + parseFloat(this.discount) !==
        this.property.initialPrice
      ) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: this.$t(
            "pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice"
          ),
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
      let propertyExpense;
      if (this.expenseTitle) {
        propertyExpense = {
          title: this.expenseTitle,
          byMain: true,
          mcPrice: this.expensePrice,
          qty: 1,
          mcTotalPrice: this.expensePrice,
          date: this.prePayment1Date,
          propertyExpenseTypeId: this.expenseTypeId,
        };
      } else {
        propertyExpense = null;
      }
      let data = {
        buyContract: {
          contractNumber: this.contractNumber,
          buyingDate: this.prePayment1Date,
          buyingPrice: this.property.initialPrice,
          buyType: this.isCash ? "Cash" : "Installment",
          isCancelled: false,
          isPrePaymentPayed: false,
          discount: this.discount,
          showDiscount: this.showDiscount,
          propertyId: this.property.id,
          customerId: this.customer,
          paymentProcess: customInstallments,
          note: this.buyContractNote,
        },
        propertyExpense: propertyExpense,
      };
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/CREATE", data)
        .then(() => {
          this.$router.push({ name: "buyContracts" });
        })
        .catch(() => (this.isLoading = false));
    },
    calculateDiscount() {
      this.percentDiscount = parseFloat(
        (this.discount / this.property.initialPrice) * 100
      ).toFixed(4);
      this.calculateInstallments();
    },
    calculateDiscountPercent() {
      this.discount = (this.percentDiscount / 100) * this.property.initialPrice;
      this.calculateInstallments();
    },
  },
};
</script>
