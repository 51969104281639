import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const cancelContracts = {
    namespaced: true,
    state: {
        cancelContracts: {},
        createdCancelContract: {},
        getOneCancelContract: {},
        updatedCancelContract: {},
    },
    mutations: {
        SET_CANCEL_CONTRACTS (state, value) { state.cancelContracts = value },
        SET_CREATED_CANCEL_CONTRACT (state, value) {state.createdCancelContract = value},
        SET_GET_ONE_CANCEL_CONTRACT (state, value) {state.getOneCancelContract = value},
        SET_UPDATED_CANCEL_CONTRACT (state, value) {state.updatedCancelContract = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/CancelContracts` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_CANCEL_CONTRACTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CANCEL_CONTRACTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/CancelContracts/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_CANCEL_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_CANCEL_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/CancelContracts`, form)
                    .then((response) => {
                        commit('SET_CREATED_CANCEL_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CANCEL_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/CancelContracts/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_CANCEL_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_CANCEL_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/CancelContracts/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        UPDATE_ATTACHMENT(commit, form) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/CancelContracts/UploadAttachment`, form)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        cancelContracts: state => state.cancelContracts,
        createdCancelContract: state => state.createdCancelContract,
        getOneCancelContract: state => state.getOneCancelContract,
        updatedCancelContract: state => state.updatedCancelContract,
    }
}

export default cancelContracts;
