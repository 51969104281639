import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import methods from "./mixins";
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueToggles from 'vue-toggles';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'

Vue.use(VueSweetalert2);

Vue.mixin({
  methods: methods
});

const plugin = {
  install () {
    Vue.moment = moment
    Vue.prototype.moment = moment
    Vue.helpers = methods
    Vue.prototype.$helpers = methods
    Vue.prototype._ = lodash
    Vue.prototype.trans = Vue.prototype.$t
    Vue.prototype.writtenNumber = require('written-number')
  }
}

Vue.use(plugin)

Vue.use(Vuelidate);

Vue.component('v-select', vSelect)

Vue.use(VueLodash, { lodash: lodash })

Vue.component('VueToggles', VueToggles);

import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('multiselect', Multiselect)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
