<template>
  <div v-if="hasPermission(permissions.reportsExpenses)">
    <content-header>{{ $t("expensesReport") }}</content-header>
    <v-content>
      <b-row class="mb-3">
        <b-col sm="12" md="6" lg="3">
          <label for="totalAmount">{{ $t("total") }}</label>
          <div class="col-sm-12">
            <input
              id="totalAmount"
              :value="
                projectCurrencySymbol() +
                totalExpenses +
                projectCurrencySymbol(true)
              "
              disabled
              :placeholder="$t('total')"
              type="text"
              autocomplete="off"
              class="form-control"
              @focus="$event.target.select()"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="totalExpenseCount">{{ $t("totalExpenseCount") }}</label>
          <div class="col-sm-12">
            <input
              id="totalExpenseCount"
              v-model.trim="totalExpenseCount"
              disabled
              :placeholder="$t('totalExpenseCount')"
              type="text"
              autocomplete="off"
              class="form-control"
              @focus="$event.target.select()"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="fromDate">{{ $t("fromDate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="fromDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <label for="toDate">{{ $t("toDate") }}</label>
          <flat-pickr
            id="toDate"
            v-model="toDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
      </b-row>

      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcTotal" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.mcTotal.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { ExpenseReport } from "@/models/ExpenseReport";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "Expenses",
  components: {
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      fromDate: this.$helpers.getCurrentDate(-1),
      toDate: this.$helpers.getCurrentDate(),
      isLoading: false,
      model: new ExpenseReport(),
      columns: [],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t("expensesReport"), true, false),
      serverParams: serverParams,
      selectedRows: [],
      selectedTypes: [],
      permissions: permissions,
      totalExpenses: 0,
      totalExpenseCount: 0,
    };
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store
        .dispatch("expenseTypes/GET_ALL_REPORT", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.totalExpenses = parseFloat(
            response.headers["mctotal"]
          ).toLocaleString();
          this.totalExpenseCount = parseFloat(
            response.headers["totalcount"]
          ).toLocaleString();
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
      this.loadData();
    },
    resetServerParams() {
      serverParams._start = 0;
      serverParams._end = 10;
      serverParams._sort = "";
      serverParams._order = "";
      serverParams._search = "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
    },
  },
};
</script>
