import { i18n } from "@/plugins/i18n";

import Dashboard from "@/views/Dashboard";
import MainDashboard from "@/views/MainDashboard";
import FinancialDashboard from "@/views/FinancialDashboard";
import Profile from "@/views/Profile";

import ExpenseTypeList from "@/views/expenseTypes/List";
import ExpenseTypeCreate from "@/views/expenseTypes/Create";
import ExpenseTypeEdit from "@/views/expenseTypes/Edit";

import DepositTypeList from "@/views/depositTypes/List";
import DepositTypeCreate from "@/views/depositTypes/Create";
import DepositTypeEdit from "@/views/depositTypes/Edit";

import WithdrawTypeList from "@/views/withdrawTypes/List";
import WithdrawTypeCreate from "@/views/withdrawTypes/Create";
import WithdrawTypeEdit from "@/views/withdrawTypes/Edit";

import PropertiesList from "@/views/properties/List";
import PropertiesCreate from "@/views/properties/Create";
import PropertiesEdit from "@/views/properties/Edit";

import CustomerList from "@/views/customers/List";
import CustomerCreate from "@/views/customers/Create";
import CustomerEdit from "@/views/customers/Edit";

import ExpenseList from "@/views/expenses/List";
import ExpenseCreate from "@/views/expenses/Create";
import ExpenseEdit from "@/views/expenses/Edit";

import DepositList from "@/views/deposits/List";
import DepositCreate from "@/views/deposits/Create";
import DepositEdit from "@/views/deposits/Edit";

import SmsMessageList from "@/views/smsMessages/List";
import SmsMessageCreate from "@/views/smsMessages/Create";

import WithdrawList from "@/views/withdraws/List";
import WithdrawCreate from "@/views/withdraws/Create";
import WithdrawEdit from "@/views/withdraws/Edit";

import TransferList from "@/views/transfers/List";
import TransferCreate from "@/views/transfers/Create";

import CustomerAttachmentCreate from "@/views/customers/attachments/Create";

import UpcomingPaymentList from "@/views/upcomingPayments/List";

import PropertyAttachmentCreate from "@/views/properties/attachments/Create";
import PropertyExpenseCreate from "@/views/properties/expenses/Create";
import PropertyFollowUpCreate from "@/views/properties/followup/Create";
import PropertyFeatureCreate from "@/views/properties/features/Create";

import LeadSourceList from "@/views/leadSources/List";
import LeadSourceCreate from "@/views/leadSources/Create";
import LeadSourceEdit from "@/views/leadSources/Edit";

import LeadSubSourceList from "@/views/leadSubSources/List";
import LeadSubSourceCreate from "@/views/leadSubSources/Create";
import LeadSubSourceEdit from "@/views/leadSubSources/Edit";

import LeadCustomerList from "@/views/leadCustomers/List";
import LeadCustomerCreate from "@/views/leadCustomers/Create";
import LeadCustomerEdit from "@/views/leadCustomers/Edit";

import LeadVisitList from "@/views/leadVisits/List";
import LeadVisitCreate from "@/views/leadVisits/Create";

import LeadReports from "@/views/reports/LeadReports";

import BuyContractList from "@/views/buyContracts/List";
import BuyContractsCreate from "@/views/buyContracts/Create";
import BuyContractsCreateDiplomatiSafiran from "@/views/buyContracts/CreateDiplomatiSafiran";
import BuyContractsCreateCandle from "@/views/buyContracts/CreateCandle";
import BuyContractsCreateRoyalTower from "@/views/buyContracts/CreateRoyalTower";
import BuyContractsCreateZaitonPlus from "@/views/buyContracts/CreateZaitonPlusContract";
import BuyContractsCreateMassHills from "@/views/buyContracts/CreateMassHills";
import BuyContractsShow from "@/views/buyContracts/Show";
import ShowMassHills from "@/views/buyContracts/ShowMassHills";
import ShowZaitonPlusCity from "@/views/buyContracts/ShowZaitonPlusCity";
import BuyContractAttachmentList from "@/views/buyContracts/attachments/List";
import BuyContractAttachmentCreate from "@/views/buyContracts/attachments/Create";
import BuyContractExpenseList from "@/views/buyContracts/contractExpenses/List";
import BuyContractExpenseCreate from "@/views/buyContracts/contractExpenses/Create";

import VoucherReceipt from "@/views/contractDesigns/VoucherReceipt";

import FeatureList from "@/views/features/List";
import FeatureCreate from "@/views/features/Create";
import FeatureEdit from "@/views/features/Edit";

import PropertyTypeList from "@/views/propertyTypes/List";
import PropertyTypeCreate from "@/views/propertyTypes/Create";
import PropertyTypeEdit from "@/views/propertyTypes/Edit";

import ProjectList from "@/views/projects/List";
import ProjectEdit from "@/views/projects/Edit";

import PropertyHoldList from "@/views/propertyHolds/List";
import PropertyHoldCreate from "@/views/propertyHolds/Create";
import PropertyHoldEdit from "@/views/propertyHolds/Edit";
import PropertyHoldShow from "@/views/propertyHolds/Show";

import Transactions from "@/views/reports/Transactions";

import TransferContractList from "@/views/transferContracts/List";
import TransferContractShow from "@/views/transferContracts/Show";

import CancelContractList from "@/views/canceledContracts/List";
import CancelContractShow from "@/views/canceledContracts/Show";

import PropertyExpenseTypeList from "@/views/propertyExpenseTypes/List";
import PropertyExpenseTypeCreate from "@/views/propertyExpenseTypes/Create";
import PropertyExpenseTypeEdit from "@/views/propertyExpenseTypes/Edit";

import PropertyReport from "@/views/reports/PropertyReport";

import ExpensesByType from "@/views/reports/ExpenseByType";

import ExpensesReport from "@/views/reports/Expense";
import DepositsReport from "@/views/reports/DepositReport";
import WithdrawsReport from "@/views/reports/WithdrawReport";

import UserList from "@/views/users/List";
import UserShow from "@/views/users/Show";

import Settings from "@/views/Settings";
import BuyContractReport from "@/views/reports/BuyContractReport";
import PaymentProcessReport from "@/views/reports/PaymentProcessReport";
import UpcommingPaymentReport from "@/views/reports/UpcommingPaymentReport";
import PartialPaymentReport from "@/views/reports/PartialPaymentReport";
import SafeBoxTransaction from "@/views/reports/SafeBoxTransaction";
import BuyContractDetail from "@/views/reports/BuyContractDetail";

import UserTaskList from "@/views/userTasks/List";
import UserTaskCreate from "@/views/userTasks/Create";

import { permissions } from "@/plugins/constants";

import InvestorList from "@/views/investors/List";
import InvestorCreate from "@/views/investors/Create";
import InvestorEdit from "@/views/investors/Edit";
import ForgivePriceReport from "@/views/reports/ForgivePriceReport";
import PropertyExpenseReport from "@/views/reports/PropertyExpenseReport";

export default [
  {
    path: "",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
      permissions: [
        permissions.dashboardCard,
        permissions.dashboardTodaySummary,
        permissions.dashboardGeneralData,
        permissions.dashboardPayments,
        permissions.dashboardMap,
      ],
    },
  },
  {
    path: "dashboard",
    name: "dashboard.page",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
      permissions: [
        permissions.dashboardCard,
        permissions.dashboardTodaySummary,
        permissions.dashboardGeneralData,
        permissions.dashboardPayments,
        permissions.dashboardMap,
      ],
    },
  },
  {
    path: "admin",
    name: "admin",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
      permissions: [
        permissions.dashboardCard,
        permissions.dashboardTodaySummary,
        permissions.dashboardGeneralData,
        permissions.dashboardPayments,
        permissions.dashboardMap,
      ],
    },
  },
  {
    path: "/main-dashboard",
    name: "mainDashboard",
    component: MainDashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("mainDashboard"),
    },
  },
  {
    path: "/financial-dashboard",
    name: "financialDashboard",
    component: FinancialDashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("financialDashboard"),
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      title: i18n.t("profile"),
    },
  },

  {
    path: "expense-types",
    name: "expenseTypes",
    component: ExpenseTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("expenseTypes"),
      permissions: [permissions.expenseTypesList],
    },
  },
  {
    path: "/expense-types/create",
    name: "expenseTypes.create",
    component: ExpenseTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createExpenseType"),
      permissions: [permissions.expenseTypesCreate],
    },
  },
  {
    path: "/expense-types/:id/edit",
    name: "expenseTypes.edit",
    component: ExpenseTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editExpenseType"),
      permissions: [permissions.expenseTypesEdit],
    },
  },

  {
    path: "deposit-types",
    name: "depositTypes",
    component: DepositTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("depositTypes"),
      permissions: [permissions.depositTypesList],
    },
  },
  {
    path: "/deposit-types/create",
    name: "depositTypes.create",
    component: DepositTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createDepositType"),
      permissions: [permissions.depositTypesCreate],
    },
  },
  {
    path: "/deposit-types/:id/edit",
    name: "depositTypes.edit",
    component: DepositTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editDepositType"),
      permissions: [permissions.depositsEdit],
    },
  },

  {
    path: "withdraw-types",
    name: "withdrawTypes",
    component: WithdrawTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("withdrawTypes"),
      permissions: [permissions.withdrawTypesList],
    },
  },
  {
    path: "/withdraw-types/create",
    name: "withdrawTypes.create",
    component: WithdrawTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createWithdrawType"),
      permissions: [permissions.withdrawTypesCreate],
    },
  },
  {
    path: "/withdraw-types/:id/edit",
    name: "withdrawTypes.edit",
    component: WithdrawTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editWithdrawType"),
      permissions: [permissions.withdrawTypesEdit],
    },
  },

  {
    path: "properties",
    name: "properties",
    component: PropertiesList,
    meta: {
      requiresAuth: true,
      title: i18n.t("properties"),
      permissions: [permissions.propertiesList],
    },
  },
  {
    path: "/properties/create",
    name: "properties.create",
    component: PropertiesCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProperties"),
      permissions: [permissions.propertiesCreate],
    },
  },
  {
    path: "/properties/:id/edit",
    name: "properties.edit",
    component: PropertiesEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProperties"),
      permissions: [permissions.propertiesEdit, permissions.propertiesShow],
    },
  },

  {
    path: "customers",
    name: "customers",
    component: CustomerList,
    meta: {
      requiresAuth: true,
      title: i18n.t("customers"),
      permissions: [permissions.customersList],
    },
  },
  {
    path: "/customers/create",
    name: "customers.create",
    component: CustomerCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createCustomer"),
      permissions: [permissions.customersCreate],
    },
  },
  {
    path: "/customers/:id/edit",
    name: "customers.edit",
    component: CustomerEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editCustomer"),
      permissions: [
        permissions.customersEdit,
        permissions.customersShowAttachments,
      ],
    },
  },

  {
    path: "expenses",
    name: "expenses",
    component: ExpenseList,
    meta: {
      requiresAuth: true,
      title: i18n.t("expenses"),
      permissions: [permissions.expensesList],
    },
  },
  {
    path: "/expenses/create",
    name: "expenses.create",
    component: ExpenseCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createExpense"),
      permissions: [permissions.expensesCreate],
    },
  },
  {
    path: "/expenses/:id/edit",
    name: "expenses.edit",
    component: ExpenseEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editExpense"),
      permissions: [permissions.expensesEdit],
    },
  },

  {
    path: "deposits",
    name: "deposits",
    component: DepositList,
    meta: {
      requiresAuth: true,
      title: i18n.t("deposits"),
      permissions: [permissions.depositsList],
    },
  },
  {
    path: "/deposits/create",
    name: "deposits.create",
    component: DepositCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createDeposit"),
      permissions: [permissions.depositsCreate],
    },
  },
  {
    path: "/deposits/:id/edit",
    name: "deposits.edit",
    component: DepositEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editDeposit"),
      permissions: [permissions.depositsEdit],
    },
  },
  {
    path: "smsMessages",
    name: "smsMessages",
    component: SmsMessageList,
    meta: {
      requiresAuth: true,
      title: i18n.t("smsMessages"),
      permissions: [permissions.smsMessageList],
    },
  },
  {
    path: "/smsMessages/create",
    name: "smsMessages.create",
    component: SmsMessageCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createSmsMessage"),
      permissions: [permissions.smsMessageCreate],
    },
  },

  {
    path: "withdraws",
    name: "withdraws",
    component: WithdrawList,
    meta: {
      requiresAuth: true,
      title: i18n.t("withdraws"),
      permissions: [permissions.withdrawsList],
    },
  },
  {
    path: "/withdraws/create",
    name: "withdraws.create",
    component: WithdrawCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createWithdraw"),
      permissions: [permissions.withdrawsCreate],
    },
  },
  {
    path: "/withdraws/:id/edit",
    name: "withdraws.edit",
    component: WithdrawEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editWithdraw"),
      permissions: [permissions.withdrawsEdit],
    },
  },

  {
    path: "transfers",
    name: "transfers",
    component: TransferList,
    meta: {
      requiresAuth: true,
      title: i18n.t("transfers"),
      permissions: [permissions.transfersList],
    },
  },
  {
    path: "/transfers/create",
    name: "transfers.create",
    component: TransferCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTransfer"),
      permissions: [permissions.transfersCreate],
    },
  },

  {
    path: "/customer-attachment/:id/create",
    name: "customer.attachment.create",
    component: CustomerAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createCustomerAttachment"),
      permissions: [permissions.customersShowAttachments],
    },
  },

  {
    path: "/property-attachment/:id/create",
    name: "property.attachment.create",
    component: PropertyAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyAttachment"),
      permissions: [permissions.propertiesShowAttachments],
    },
  },
  {
    path: "/property-expense/:id/create",
    name: "property.expense.create",
    component: PropertyExpenseCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createExpense"),
      permissions: [permissions.propertiesShowExpenses],
    },
  },
  {
    path: "/property-follow-up/:id/create",
    name: "property.follow.up.create",
    component: PropertyFollowUpCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyFollowUp"),
      permissions: [permissions.propertiesShowFollowups],
    },
  },
  {
    path: "/property-feature/:id/create",
    name: "property.feature.create",
    component: PropertyFeatureCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyFeature"),
      permissions: [permissions.propertiesShowFeatures],
    },
  },

  {
    path: "lead-sources",
    name: "leadSources",
    component: LeadSourceList,
    meta: {
      requiresAuth: true,
      title: i18n.t("leadSources"),
      permissions: [permissions.leadSourcesList],
    },
  },
  {
    path: "/lead-sources/create",
    name: "leadSources.create",
    component: LeadSourceCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createLeadSource"),
      permissions: [permissions.leadSourcesCreate],
    },
  },
  {
    path: "/lead-sources/:id/edit",
    name: "leadSources.edit",
    component: LeadSourceEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editLeadSource"),
      permissions: [permissions.leadSourcesEdit],
    },
  },

  {
    path: "lead-sub-sources",
    name: "leadSubSources",
    component: LeadSubSourceList,
    meta: {
      requiresAuth: true,
      title: i18n.t("leadSubSources"),
      permissions: [permissions.leadSubSourcesList],
    },
  },
  {
    path: "/lead-sub-sources/create",
    name: "leadSubSources.create",
    component: LeadSubSourceCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createLeadSubSource"),
      permissions: [permissions.leadSubSourcesCreate],
    },
  },
  {
    path: "/lead-sub-sources/:id/edit",
    name: "leadSubSources.edit",
    component: LeadSubSourceEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editLeadSubSource"),
      permissions: [permissions.leadSubSourcesEdit],
    },
  },

  {
    path: "lead-customers",
    name: "leadCustomers",
    component: LeadCustomerList,
    meta: {
      requiresAuth: true,
      title: i18n.t("leadCustomers"),
      permissions: [permissions.leadCustomersList],
    },
  },
  {
    path: "/lead-customers/create",
    name: "leadCustomers.create",
    component: LeadCustomerCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createLeadCustomer"),
      permissions: [permissions.leadCustomersCreate],
    },
  },
  {
    path: "/lead-customers/:id/edit",
    name: "leadCustomers.edit",
    component: LeadCustomerEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editLeadCustomer"),
      permissions: [permissions.leadCustomersEdit],
    },
  },

  {
    path: "lead-visits",
    name: "leadVisits",
    component: LeadVisitList,
    meta: {
      requiresAuth: true,
      title: i18n.t("leadVisits"),
      permissions: [permissions.leadVisitsList],
    },
  },
  {
    path: "/lead-visits/create",
    name: "leadVisits.create",
    component: LeadVisitCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createLeadVisit"),
      permissions: [permissions.leadVisitsCreate],
    },
  },

  {
    path: "lead-reports",
    name: "leadReports",
    component: LeadReports,
    meta: {
      requiresAuth: true,
      title: i18n.t("leadReports"),
      permissions: [permissions.reportsLeadVisits],
    },
  },

  {
    path: "upcoming-payments",
    name: "upcomingPayments",
    component: UpcomingPaymentList,
    meta: {
      requiresAuth: true,
      title: i18n.t("upcomingPayments"),
      permissions: [permissions.upcomingPaymentsList],
    },
  },

  {
    path: "buy-contracts",
    name: "buyContracts",
    component: BuyContractList,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContracts"),
      permissions: [permissions.buyContractsList],
    },
  },
  {
    path: "buy-contracts/create",
    name: "buyContracts.create",
    component:
      process.env.VUE_APP_PROJECT_NAME === "DiplomatiSafiran"
        ? BuyContractsCreateDiplomatiSafiran
        : process.env.VUE_APP_PROJECT_NAME === "Candle"
        ? BuyContractsCreateCandle
        : process.env.VUE_APP_PROJECT_NAME === "RoyalTower"
        ? BuyContractsCreateRoyalTower
        : process.env.VUE_APP_PROJECT_NAME === "MassHills"
        ? BuyContractsCreateMassHills
        : process.env.VUE_APP_PROJECT_NAME === "ZaitonPlusCity"
        ? BuyContractsCreateZaitonPlus
        : BuyContractsCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContractCreate"),
      permissions: [permissions.buyContractsCreate],
    },
  },
  {
    path: "/buy-contracts/:id/show",
    name: "buyContracts.show",
    component:
      process.env.VUE_APP_PROJECT_NAME === "MassHills"
        ? ShowMassHills
        : process.env.VUE_APP_PROJECT_NAME === "ZaitonPlusCity"
        ? ShowZaitonPlusCity
        : BuyContractsShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showBuyContracts"),
      permissions: [permissions.buyContractsShow],
    },
  },
  {
    path: "/buy-contracts/:id/list",
    name: "buyContract.attachments",
    component: BuyContractAttachmentList,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContractAttachmentsList"),
    },
  },
  {
    path: "/buy-contracts/:id/create",
    name: "buyContract.attachment.create",
    component: BuyContractAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createBuyContractAttachment"),
    },
  },
  {
    path: "/buy-contracts/:id/expenses/list",
    name: "buyContract.expenses",
    component: BuyContractExpenseList,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContractExpensesList"),
    },
  },
  {
    path: "/buy-contracts/:id/expenses/create",
    name: "buyContract.expenses.create",
    component: BuyContractExpenseCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createBuyContractExpense"),
    },
  },

  {
    path: "voucher-receipt",
    name: "VoucherReceipt",
    component: VoucherReceipt,
    meta: {
      requiresAuth: true,
      title: i18n.t("voucherReceipt"),
    },
  },

  {
    path: "features",
    name: "features",
    component: FeatureList,
    meta: {
      requiresAuth: true,
      title: i18n.t("features"),
      permissions: [permissions.featuresList],
    },
  },
  {
    path: "/features/create",
    name: "features.create",
    component: FeatureCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createFeature"),
      permissions: [permissions.featuresCreate],
    },
  },
  {
    path: "/features/:id/edit",
    name: "features.edit",
    component: FeatureEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editFeature"),
      permissions: [permissions.featuresEdit],
    },
  },

  {
    path: "property-types",
    name: "propertyTypes",
    component: PropertyTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("propertyTypes"),
      permissions: [permissions.propertyTypesList],
    },
  },
  {
    path: "/property-types/create",
    name: "propertyTypes.create",
    component: PropertyTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyType"),
      permissions: [permissions.propertyTypesCreate],
    },
  },
  {
    path: "/property-types/:id/edit",
    name: "propertyTypes.edit",
    component: PropertyTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editPropertyType"),
      permissions: [permissions.propertyTypesEdit],
    },
  },

  {
    path: "projects",
    name: "projects",
    component: ProjectList,
    meta: {
      requiresAuth: true,
      title: i18n.t("projects"),
      permissions: [permissions.projectsList],
    },
  },
  {
    path: "/projects/:id/edit",
    name: "projects.edit",
    component: ProjectEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProject"),
    },
  },

  {
    path: "transactions",
    name: "transactions",
    component: Transactions,
    meta: {
      requiresAuth: true,
      title: i18n.t("transactions"),
      permissions: [permissions.reportsTransactions],
    },
  },

  {
    path: "property-holds",
    name: "propertyHolds",
    component: PropertyHoldList,
    meta: {
      requiresAuth: true,
      title: i18n.t("propertyHolds"),
      permissions: [permissions.propertyHoldList],
    },
  },
  {
    path: "/property-holds/create",
    name: "propertyHolds.create",
    component: PropertyHoldCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyHold"),
      permissions: [permissions.propertyHoldCreate],
    },
  },
  {
    path: "/property-holds/:id/edit",
    name: "propertyHolds.edit",
    component: PropertyHoldEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editPropertyHold"),
      permissions: [permissions.propertyHoldEdit],
    },
  },
  {
    path: "/property-holds/:id/show",
    name: "propertyHolds.show",
    component: PropertyHoldShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showPropertyHold"),
      permissions: [permissions.propertyHoldShow],
    },
  },

  {
    path: "transfer-contracts",
    name: "transferContracts",
    component: TransferContractList,
    meta: {
      requiresAuth: true,
      title: i18n.t("transferContracts"),
      permissions: [permissions.transferContractsList],
    },
  },
  {
    path: "/transfer-contracts/:id/show",
    name: "transferContracts.show",
    component: TransferContractShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showTransferContract"),
      permissions: [permissions.transferContractsShow],
    },
  },

  {
    path: "cancel-contracts",
    name: "cancelContracts",
    component: CancelContractList,
    meta: {
      requiresAuth: true,
      title: i18n.t("cancelContracts"),
      permissions: [permissions.cancelContractsList],
    },
  },
  {
    path: "/cancel-contracts/:id/show",
    name: "cancelContracts.show",
    component: CancelContractShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showCancelContract"),
      permissions: [permissions.cancelContractsShow],
    },
  },

  {
    path: "property-expense-types",
    name: "propertyExpenseTypes",
    component: PropertyExpenseTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("propertyExpenseTypes"),
      permissions: [permissions.propertyExpenseTypesList],
    },
  },
  {
    path: "/property-expense-types/create",
    name: "propertyExpenseTypes.create",
    component: PropertyExpenseTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createPropertyExpenseType"),
      permissions: [permissions.propertyExpenseTypesCreate],
    },
  },
  {
    path: "/property-expense-types/:id/edit",
    name: "propertyExpenseTypes.edit",
    component: PropertyExpenseTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editPropertyExpenseType"),
      permissions: [permissions.propertyExpenseTypesEdit],
    },
  },

  {
    path: "/reports/property-report",
    name: "propertyReport",
    component: PropertyReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("propertyReport"),
      permissions: [permissions.reportsProperty],
    },
  },

  {
    path: "/reports/expenses-by-type",
    name: "expensesByType",
    component: ExpensesByType,
    meta: {
      requiresAuth: true,
      title: i18n.t("expensesByType"),
      permissions: [permissions.reportsExpensesByType],
    },
  },

  {
    path: "/reports/expenses",
    name: "expensesReport",
    component: ExpensesReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("expensesReport"),
      permissions: [permissions.reportsExpenses],
    },
  },
  {
    path: "/reports/deposits",
    name: "depositsReport",
    component: DepositsReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("depositsReport"),
      permissions: [permissions.reportsDeposits],
    },
  },
  {
    path: "/reports/withdraws",
    name: "withdrawsReport",
    component: WithdrawsReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("withdrawsReport"),
      permissions: [permissions.reportsWithdraws],
    },
  },

  {
    path: "/reports/buy-contract",
    name: "buyContractReport",
    component: BuyContractReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContractReport"),
      permissions: [permissions.reportsBuyContracts],
    },
  },

  {
    path: "/reports/payment-report",
    name: "paymentProcessReport",
    component: PaymentProcessReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("paymentReport"),
      permissions: [permissions.reportsPayment],
    },
  },

  {
    path: "/reports/upcomming-payment-report",
    name: "upcommingPaymentReport",
    component: UpcommingPaymentReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("upcomingPayments"),
      permissions: [permissions.reportsPayment],
    },
  },

  {
    path: "/reports/partial-payment-report",
    name: "partialPaymentReport",
    component: PartialPaymentReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("partialPaymentReport"),
      permissions: [permissions.reportsPayment],
    },
  },

  {
    path: "/reports/safe-box-transaction",
    name: "safeBoxTransactionReport",
    component: SafeBoxTransaction,
    meta: {
      requiresAuth: true,
      title: i18n.t("safeBoxTransactionReport"),
      permissions: [permissions.reportsSafeBoxTransactions],
    },
  },

  {
    path: "/reports/buy-contract-details",
    name: "buyContractDetail",
    component: BuyContractDetail,
    meta: {
      requiresAuth: true,
      title: i18n.t("buyContractDetail"),
      permissions: [permissions.reportsBuyContractsDetails],
    },
  },

  {
    path: "/reports/forgive-price",
    name: "forgivePriceReport",
    component: ForgivePriceReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("forgivePriceReport"),
      permissions: [permissions.reportsForgivePrice],
    },
  },

  {
    path: "/reports/property-expense-report",
    name: "propertyExpenseReportList",
    component: PropertyExpenseReport,
    meta: {
      requiresAuth: true,
      title: i18n.t("propertyExpenseReportList"),
      permissions: [permissions.reportsPropertyExpense],
    },
  },

  {
    path: "users",
    name: "users",
    component: UserList,
    meta: {
      requiresAuth: true,
      title: i18n.t("users"),
      permissions: [permissions.usersList],
    },
  },
  {
    path: "/users/:id/show",
    name: "users.show",
    component: UserShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showUser"),
      permissions: [permissions.usersShow],
    },
  },

  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: i18n.t("settings"),
    },
  },

  {
    path: "user-tasks",
    name: "userTasks",
    component: UserTaskList,
    meta: {
      requiresAuth: true,
      title: i18n.t("userTasks"),
      permissions: [permissions.userTasksList],
    },
  },
  {
    path: "/user-tasks/create",
    name: "userTasks.create",
    component: UserTaskCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTransfer"),
      permissions: [permissions.userTasksCreate],
    },
  },

  {
    path: "investors",
    name: "investors",
    component: InvestorList,
    meta: {
      requiresAuth: true,
      title: i18n.t("investors"),
      permissions: [permissions.investorsList],
    },
  },
  {
    path: "/investors/create",
    name: "investors.create",
    component: InvestorCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createInvestor"),
      permissions: [permissions.investorsCreate],
    },
  },
  {
    path: "/investors/:id/edit",
    name: "investors.edit",
    component: InvestorEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editInvestor"),
      permissions: [permissions.investorsEdit],
    },
  },
];
