<template>
  <div
    v-if="
      hasPermission(permissions.reportsLeadVisits) ||
      hasPermission(permissions.reportsLeadVisitsByDayOfWeek) ||
      hasPermission(permissions.reportsDailyLeadVisits) ||
      hasPermission(permissions.reportsPerSources)
    "
  >
    <content-header>{{ $t("leadReports") }}</content-header>
    <v-content>
      <b-card no-body v-if="!isLoading">
        <b-card-header>
          <b-row class="mb-3 justify-content-center align-items-end">
            <b-col sm="12" md="6">
              <label for="fromDate">{{ $t("fromDate") }}</label>
              <flat-pickr
                id="fromDate"
                v-model="fromDate"
                class="form-control"
                :placeholder="$t('selectDate')"
                name="date"
              />
            </b-col>
            <b-col sm="12" md="5">
              <label for="toDate">{{ $t("toDate") }}</label>
              <flat-pickr
                id="toDate"
                v-model="toDate"
                class="form-control"
                :placeholder="$t('selectDate')"
                name="date"
              />
            </b-col>
            <!-- <b-col sm="12" md="1">
              <b-button
                class="btn-secondary my-2 my-md-0"
                @click="tocsv('maintable', 'Visitor Reports')"
                >{{ $t("ExportCSV") }}</b-button
              >
            </b-col> -->
          </b-row>
        </b-card-header>
        <b-tabs card>
          <b-tab
            v-if="hasPermission(permissions.reportsLeadVisits)"
            :title="$t('leadVisits')"
            @click.prevent="currentTab = 1"
          >
            <lead-visits-report
              :from-date="fromDate"
              :to-date="toDate"
              :current-tab="currentTab"
              active
            />
          </b-tab>
          <b-tab
            v-if="hasPermission(permissions.reportsLeadVisitsByDayOfWeek)"
            :title="$t('leadVisitByDayOfWeek')"
            @click.prevent="currentTab = 2"
          >
            <lead-visit-by-day-of-week
              :from-date="fromDate"
              :to-date="toDate"
              :current-tab="currentTab"
            />
          </b-tab>
          <b-tab
            v-if="hasPermission(permissions.reportsDailyLeadVisits)"
            :title="$t('dailyLeadVisits')"
            @click.prevent="currentTab = 3"
          >
            <lead-visit-by-date
              :from-date="fromDate"
              :to-date="toDate"
              :current-tab="currentTab"
            />
          </b-tab>
          <b-tab
            v-if="hasPermission(permissions.reportsPerSources)"
            :title="$t('perSources')"
            @click.prevent="currentTab = 4"
          >
            <per-sources
              :from-date="fromDate"
              :to-date="toDate"
              :current-tab="currentTab"
            />
          </b-tab>
        </b-tabs>
      </b-card>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import IsLoading from "../../components/IsLoading";
import PerSources from "./LeadReports/PerSources";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import LeadVisitByDate from "./LeadReports/LeadVisitByDate";
import LeadVisitsReport from "./LeadReports/LeadVisitsReport";
import LeadVisitByDayOfWeek from "./LeadReports/LeadVisitByDayOfWeek";
import { permissions } from "@/plugins/constants";

export default {
  name: "LeadReports",
  components: {
    LeadVisitByDayOfWeek,
    LeadVisitsReport,
    LeadVisitByDate,
    PerSources,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(1),
      currentTab: 1,
      permissions: permissions,
    };
  },
};
</script>
