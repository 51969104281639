<template>
  <div v-if="hasPermission(permissions.usersShow)">
    <content-header
      >User / {{ user.firstName }} {{ user.middleName }}
      {{ user.lastName }}</content-header
    >
    <v-content>
      <b-card>
        <is-loading v-if="isLoading" />
        <b-card-body v-else>
          <b-tabs card>
            <b-tab :title="$t('projects')" active>
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Logo</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="project in user.projects" :key="project.id">
                    <td>{{ project.name }}</td>
                    <td>{{ project.address }}</td>
                    <td>
                      <img
                        :src="project.logo"
                        alt="Logo"
                        width="100"
                        height="100"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeProject(project.id)"
                      >
                        <b-icon icon="trash" size="is-small"></b-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab :title="$t('addToProject')">
              <form @submit.prevent="addProjectToUser" class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{
                    $t("project")
                  }}</label>
                  <div class="col-sm-10">
                    <select class="form-control" v-model="selectedProject">
                      <option
                        v-for="project in projects"
                        :key="project.id"
                        :value="project.id"
                      >
                        {{ project.name }} - {{ project.address }}
                      </option>
                    </select>
                  </div>
                </div>
                <submit-input :name="$t('add')" :disabled="isLoading" />
              </form>
            </b-tab>
            <b-tab :title="$t('permissions')">
              <h3>Permissions List</h3>
              <b-row>
                <b-col
                  v-for="(permission, index) in permissions"
                  cols="3"
                  :key="index"
                >
                  <b-form-checkbox
                    v-model="permission.setValue"
                    name="check-button"
                    switch
                  >
                    {{ permission.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="3">
                  <b-button
                    block
                    squared
                    variant="primary"
                    @click="savePermissions"
                    >Save Changes</b-button
                  >
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import Swal from "sweetalert2";
import { i18n } from "@/plugins/i18n";
import SubmitInput from "@/components/Inputs/SubmitInput";
import { notify } from "@/plugins/constants";

export default {
  name: "UserShow",
  components: { SubmitInput, ContentHeader, "v-content": Content, IsLoading },
  data() {
    return {
      user: {},
      projects: [],
      selectedProject: null,
      isLoading: false,
      permissions: [],
    };
  },
  mounted() {
    this.loadUser();
    this.loadProjects();
    this.loadPermissions();
  },
  methods: {
    loadUser() {
      this.isLoading = true;
      this.$store
        .dispatch("users/GET_ONE", this.$route.params.id)
        .then((response) => {
          this.user = response.data;
          this.isLoading = false;
        });
    },
    loadProjects() {
      this.isLoading = true;
      this.$store.dispatch("projects/GET_ALL").then((response) => {
        this.projects = response.data;
        this.isLoading = false;
      });
    },
    async removeProject(projectId) {
      await Swal.fire({
        title: i18n.t("areYouSure"),
        text: i18n.t(`youWantToDeletePermanentlySelectedRows`),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: i18n.t("noCancel"),
        confirmButtonText: i18n.t(`yesDelete`),
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("users/REMOVE_PROJECT_FROM_USER", {
              userId: this.$route.params.id,
              projectId: projectId,
            })
            .then(() => {
              this.loadUser();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    async addProjectToUser() {
      this.isLoading = true;
      await this.$store
        .dispatch("users/ADD_PROJECT_TO_USER", {
          userId: this.$route.params.id,
          projectId: this.selectedProject,
        })
        .then(() => {
          this.loadUser();
        })
        .catch(() => (this.isLoading = false));
    },
    loadPermissions() {
      this.isLoading = true;
      this.$store
        .dispatch("permissions/GET_ALL", this.$route.params.id)
        .then((response) => {
          this.permissions = [
            {
              name: this.$t("canAddContract"),
              value: "CanAddContract",
              setValue: response.data.CanAddContract === "true",
              type: "bool",
            },
            {
              name: this.$t("canPayContractPayment"),
              value: "CanPayContractPayment",
              setValue: response.data.CanPayContractPayment === "true",
              type: "bool",
            },
            {
              name: this.$t("canAddProperty"),
              value: "CanAddProperty",
              setValue: response.data.CanAddProperty === "true",
              type: "bool",
            },
            {
              name: this.$t("canAddHoldProperty"),
              value: "CanAddHoldProperty",
              setValue: response.data.CanAddHoldProperty === "true",
              type: "bool",
            },
            {
              name: this.$t("canDeleteHoldProperty"),
              value: "CanDeleteHoldProperty",
              setValue: response.data.CanDeleteHoldProperty === "true",
              type: "bool",
            },
            {
              name: this.$t("canCreateExpense"),
              value: "CanCreateExpense",
              setValue: response.data.CanCreateExpense === "true",
              type: "bool",
            },
            {
              name: this.$t("canViewTransaction"),
              value: "CanViewTransaction",
              setValue: response.data.CanViewTransaction === "true",
              type: "bool",
            },
            {
              name: this.$t("canViewExpenseByTypeReport"),
              value: "CanViewExpenseByTypeReport",
              setValue: response.data.CanViewExpenseByTypeReport === "true",
              type: "bool",
            },
            {
              name: this.$t("canTransferContract"),
              value: "CanTransferContract",
              setValue: response.data.CanTransferContract === "true",
              type: "bool",
            },
            {
              name: this.$t("canCancelContract"),
              value: "CanCancelContract",
              setValue: response.data.CanCancelContract === "true",
              type: "bool",
            },
          ];
          this.isLoading = false;
        });
    },
    savePermissions() {
      this.permissions.forEach((v) => delete v.name);
      let filtered = this.permissions;
      let newObj = {};
      filtered.map((x) => {
        newObj[x.value] = x.setValue + "";
      });
      this.isLoading = true;
      this.$store
        .dispatch("permissions/STORE_CLAIMS", {
          userId: this.$route.params.id,
          claims: newObj,
        })
        .then(() => {
          notify("success", i18n.t("permissionsSaved"));
        })
        .catch(() => notify("error", i18n.t("errorInSavingPermissions")))
        .finally(() => {
          this.isLoading = false;
          this.loadPermissions();
        });
    },
  },
};
</script>
