import {i18n} from "@/plugins/i18n";
import mixins from "@/mixins";

export class LeadCustomer {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'createdAt',
            i18n.t('date'),
            true
        ],
        [
            'name',
            i18n.t('name'),
            true
        ],
        [
            'phone',
            i18n.t('phone'),
            true
        ],
        [
            'nationality',
            i18n.t('nationality'),
            true
        ],
        [
            'address',
            i18n.t('address'),
            true
        ],
        [
            'isMale',
            i18n.t('gender'),
            true
        ],
        [
            'leadSubSource.name',
            i18n.t('leadSubSourceName'),
            true
        ],
        [
            'state',
            i18n.t('state'),
            true,
            false
        ],
        [
            'males',
            i18n.t('males'),
            true,
            false
        ],
        [
            'females',
            i18n.t('females'),
            true,
            false
        ],
        [
            'extraField3',
            i18n.t('type'),
            true
        ],
        [
            'extraField2',
            i18n.t('villaNo'),
            true
        ],
        [
            'extraField1',
            i18n.t('heardAboutUsFrom'),
            true
        ],
        [
            'feedback',
            i18n.t('feedback'),
            true
        ],
        [
            'note',
            i18n.t('note'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: i18n.t('id'),
                name: 'id',
                sort: false,
                visibility: mixins.projectTenant() !== 'MassHills',
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
