var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission(_vm.permissions.propertyHoldList))?_c('div',[_c('content-header',[_vm._v(_vm._s(_vm.$t("propertyHolds")))]),_c('v-content',[_c('BootstrapTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"config":_vm.config,"total-rows":_vm.totalRecords,"actions":_vm.actions},on:{"on-change-query":_vm.onChangeQuery,"on-create":function($event){return _vm.$router.push({ name: 'propertyHolds.create' })},"on-multiple-delete":_vm.onMultipleDelete},scopedSlots:_vm._u([{key:"pagination-info",fn:function(props){return [(props.currentPageRowsLength !== 0)?[_vm._v(_vm._s(_vm.totalRecords)+" "+_vm._s(_vm.$t("totalRecords")))]:[_vm._v(_vm._s(_vm.$t("noResultsFound")))]]}},{key:"controls",fn:function(props){return (
          _vm.hasPermission(_vm.permissions.propertyHoldDelete) ||
          _vm.hasPermission(_vm.permissions.propertyHoldShow) ||
          _vm.hasPermission(_vm.permissions.propertyHoldEdit)
        )?[_c('control-buttons',{attrs:{"props":props,"edit-link":_vm.hasPermission(_vm.permissions.propertyHoldEdit) &&
            'propertyHolds.edit',"delete-link":_vm.hasPermission(_vm.permissions.propertyHoldDelete) &&
            'propertyHolds/DELETE',"show-link":_vm.hasPermission(_vm.permissions.propertyHoldShow) &&
            'propertyHolds.show'},on:{"successAction":function($event){return _vm.loadData(true)}}})]:undefined}},{key:"holdPayment",fn:function(props){return [_vm._v(" "+_vm._s(_vm.projectCurrencySymbol() + props.row.holdPayment.toLocaleString() + _vm.projectCurrencySymbol(true))+" ")]}},{key:"date",fn:function(props){return [_vm._v(" "+_vm._s(_vm.moment(props.row.date).format("YYYY-MM-DD"))+" ")]}},{key:"deadline",fn:function(props){return [_vm._v(" "+_vm._s(props.row.deadline && _vm.moment(props.row.deadline).format("YYYY-MM-DD"))+" ")]}},{key:"note",fn:function(props){return [(props.row.note)?[_vm._v(_vm._s(props.row.note))]:[_vm._v("-")]]}},{key:"attachment",fn:function(props){return [_c('show-attachment',{attrs:{"attachment":props.row.attachment,"title":props.row.name}})]}}],null,true)},[_c('template',{slot:"empty-results"},[_vm._v(_vm._s(_vm.$t("noResultsFound")))])],2),(_vm.isLoading)?_c('is-loading'):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }