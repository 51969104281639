<template>
  <div id="transfer-contract-voucher-receipt-zaiton-kurdish" class="d-none" >
    <div style="line-height: 35px;">
      <h3 style="text-align: center; font-weight: bold">
        عقد التنازل <br />
        گرێبەستی دەستبەرداربوون
      </h3>
      <br />
      <div style="direction: rtl !importantl" >
        <p>
          لایەنی یەکەم (دەستبەرداربوو)(المتنازل): {{data.oldCustomer && data.oldCustomer.fullName }}
          <br />
          لایەنی دووەم (بۆی دەستبەرداربوو)(المتنازل اليه): {{data.oldCustomer && data.newCustomer.fullName }}.
        </p>

        <p>
          ئاماژە بە گرێبەستی واژووکراوی ژمارە ({{ data.contractNumber }}) لە ڕێکەوتی ( {{ moment(data.buyingDate).format('YYYY-MM-DD')  }})
          لەنێوان لایەنی یەکەم <br />
          (دەستبەرداربوو) (المتنازل) لەم گرێبەستەدا لەگەڵ کۆمپانیای شجره‌
          الزیتون.
          <br />
          <br />
          هەردوو لایەن ڕێککەوتن لەسەر ئەم خاڵانەی خوارەوە: <br />
          <br />
          1. لایەنی یەکەم (دەستبەرداربوو)(المتنازل) ڕەزامەند بوو لەسەر
          دەستبەرداربوون لە یەکەی نیشتەجێبوونی ژمارە ({{data.property && data.property.name }} ) لە زونی ( {{ data.property && data.property.zone }} )
          
          <span v-if="data.property && data.property.zone === 'L'" >
            لە
            جادەی ({{data.property && data.property.streetNumber }}) لە بلوکی ({{data.property && data.property.block }})
          </span>
          
          
          بۆ لایەنی دووەم (بۆی دەستبەرداربوو)
          (المتنازل الیه) لە گرێبەستی ئاماژەپێکراوی سەرەوە.
          <br />
          2. دوای واژووکردنی گرێبەستی دەستبەرداربوون تنازلکردن ، لایەنی دووەم
          (بۆی دەستبەرداربوو) (المتنازل الیه) پابەند دەبێت بە گشت ئەرکەکانەوە
          بەرامبەر بە کۆمپانیای شجره‌ الزیتون بەپێی گرێبەستی ئاماژەپێکراو،
          پابەند دەبێت بە دانی گشت خەرجیەکان و خزمەتگوزاریەکانى پڕۆژەکە بەشێوەی
          مانگانە.

          <br />
          3. لایەنی یەکەم (دەستبەرداربوو) (المتنازل) گشت مافەکانی وەرگرت لە
          لایەنی دووەم (بۆی دەستبەرداربوو)(المتنازل الیه)، وە هیچ مافێکی لەسەر
          یەکەی نیشتەجێبوون و کۆمپانیای ( شجره‌ الزیتون ) نەماوە.
          <br />
          4. لایەنی یەکەم (دەستبەرداربوو)(المتنازل) پابەند دەبێت بە گشت ئەو
          قەرزانەی کە کەوتوونەتە سەری لەبری ئەو خزمەتگوزاریانەی کە بۆی پێشکەش
          کراون لە پڕۆژەکەدا و لەدوای پاکانەی ئەستۆی لایەنی یەکەم
          (دەستبەرداربوو) (المتنازل) لەبری گشت ئەو قەرزانەی کە کەوتوونەتە سەری،
          ئەوا گرێبەستی کڕین و فروشتن واژوو دەکرێت بۆ لایەنی دووەم (بۆی
          دەستبەرداربوو) (المتنازل الیه) لەگەڵ کۆمپانیای ( شجره‌ الزیتون ) ،
          دوای ئەوەش لایەنی دووەم (بۆی دەستبەرداربوو) (المتنازل الیه) پابەند
          دەبێت بە دانی خەرجیەکانی ئەو خزمەتگوزاریانەی کە پێشکەشی دەکرێن لە
          پڕۆژەکەدا.
          <br />
          5. لایەنی یەکەم (دەستبەرداربوو) (المتنازل) رەزامەندە و دانی پێدادەنێت
          کەوا دەستبەرداری یەکەی نیشتەجێبوون بووە واتە ( تنازلی ) لە یەکەی
          نیشتەجێبوون کردووە کە ئاماژەی پێکراوە لە گرێبەستی واژوو کراوی ژمارە
          ({{ data.contractNumber }}) لە رێکەوتی({{ moment(data.buyingDate).format('YYYY-MM-DD') }}) بۆ لایەنی دووەم (بۆی دەستبەرداربوو)
          (المتنازل الیه) , وه لایەنی یەکەم هیچ مافێکى نامێنێت لەسەر کۆمپانیای (
          شجره‌ الزیتون ).
          <br />
          6. لایەنی یەکەم (دەستبەرداربوو)(المتنازل) بەلێن دەدات کەوا هیچ
          وێنەیەکی گرێبەستی فەرمی واژوو کراوی لە لا نەماوە و وێنەی رەسەنی بۆ
          کومپانیا گەراندوتەوە وە بە هیچ شێوەیەک بۆ هیچ مەبەستێکی ئیداری و یاسای
          و کارگێری و مالی وە هەروەها بو کارو باری دادگاکان بە کاری ناهێنێت لە
          هیچ شوێنێک.
          <br />
          7. ئەم گرێبەستە واژووکرا لە هەولێر لە ڕێکەوتی ({{ moment(data.transferDate).format('YYYY-MM-DD') }}) ئەم
          گرێبەستە لە دوو لاپەڕە و ( ٧ ) خاڵ پێکهاتووە.
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>

      <br />
      <br />
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div style="font-weight: bold; text-align: right">
          <p>
            لایەنی یەکەم (دەستبەرداربوو) (المتنازل) <br />
            ناو: {{ data.oldCustomer && data.oldCustomer.fullName }} <br />
            ژماره‌ی كارتی نیشتمانی: {{ data.oldCustomer && data.oldCustomer.idCard }} <br />
            ژمارەی کارتی زانیاری: {{data.oldCustomer && data.oldCustomer.nationalityCard }} <br />
            ناونیشان: {{data.oldCustomer && data.oldCustomer.address }} <br />
            مۆبایل:  {{ data.oldCustomer && data.oldCustomer.phone && data.oldCustomer.phone.replace('+964','0') }}  –  {{data.oldCustomer && data.oldCustomer.phone2&& data.oldCustomer.phone2.replace('+964','0') }} 
            <br />
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>

        <div style="font-weight: bold; text-align: right">
          <p>
            لایەنی دووەم (بۆی دەستبەرداربوو) (المتنازل اليه) <br />
            ناو: {{ data.newCustomer && data.newCustomer.fullName }} <br />
            ژماره‌ی كارتی نیشتمانی:{{ data.newCustomer && data.newCustomer.idCard }} <br />
            ژمارەی کارتی زانیاری: {{ data.newCustomer && data.newCustomer.nationalityCard }} <br />
            ناونیشان: {{data.newCustomer && data.newCustomer.address }} <br />
            مۆبایل: {{ data.newCustomer && data.newCustomer.phone && data.newCustomer.phone.replace('+964','0')}}  –  {{data.newCustomer && data.newCustomer.phone2&& data.newCustomer.phone2.replace('+964','0') }}
          </p>
        </div>
      </div>

      <div class="row" dir="rtl">

        <div class="col-5 text-right ">
          <span style="font-weight: normal">
            وێنەیەک بۆ /<br />
            بەرێوەبەرایەتی گشتی وەبەرهێنان./ بو ئەنجامدانی کاری پێویست
          </span>
        </div>

        <div class="col-3" />

        <div class="col-4 d-inline-block" style="font-weight: normal">
          <div class="text-center">
            پەسەندی کومپانیا

            <br />

            کۆمپانیای شجرة زەیتون بۆ بازرگانی گشتی و <br />
            وەبەرهێنانی خانووبەرە و پیشەسازی سنوردار <br />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZaitonTransferContractDesignKurdish",
  props: {
    data: {},
  },
  mounted() {
  },
};
</script>

<style scoped>
p {
  font-size: 19px !important;
  direction: rtl !important;
  text-align: start;
  font-family: Noto, Speda, sans-serif !important;
}

@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
