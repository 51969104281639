<template>
  <div class="contract" dir="rtl">
    <div :style="{'padding-top': contractTopSpaces}"></div>
    <h4 class="text-center">بسم الله الرحمن الرحيم</h4>
    <h4 class="text-center">عقد بيع للفلل السكنية في (مدينة دیپلۆمات السفراء)</h4>
    <h5 class="text-center">{{ buyType === 'Cash' ? '(بشكل نقدي)' : '(بشكل أقساط)' }}</h5>
    <p class="lead" style="text-align: right; font-size: 1.2rem !important"><b>رقم العقد:</b> {{ contractNo }}</p>
    <p class="lead" style="text-align: right; font-size: 1.2rem !important"><b>التاريخ:</b> {{ getCurrentDate() }}</p>
    <br />
    <p class="lead" style="text-align: right"><b>الطرف الأول (البائع):</b> شرکة گولوان للاستثمار العقاری / المحدودە مدیرها المفوض السید (احمد رمزی قرەنی) </p>
    <p class="lead" style="text-align: right"><b>الطرف الثاني (المشتري)</b> السيد/السيدة : {{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }} / <b>العنوان:</b> {{ customer.address }}</p>
    <p class="lead" style="text-align: right"><b>رقم الهاتف:</b> {{ replacePhone(customer.phone) }} ، البريد الإلكتروني: {{ customer.email }}</p>
    <br />
    <h5 style="text-align: right">مقدمة العقد:</h5>
    <p class="lead" style="text-align: right">استحصل الطرف الأول على الإجازة الاستثمارية المرقمة &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; الصادرة بتاريخ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/2021 من هيئة استثمار أربيل بخصوص بناء مشروع سكني الذي يحتوي على عدد من الدور السكنية بمساحات مختلفة و الذي يتكون من أربعة تصنيفات و يمثل (زون A) بفلل (بدور سكنية)بمساحات (1000)م<sup>2</sup> تبدأ من رقم (1 – 23) ، و يمثل (زون B) بفلل بمساحات (500)م<sup>2</sup> تبدا من (1 – 70) ، و يمثل (زون C) بفلل بمساحات (300)م<sup>2</sup> تبدا من (1 – 91) ، و يمثل (زون D) بفلل بمساحات (240)م<sup>2</sup> و تبدأ من (1 – 98) على القطع المرقمات (10/1 م 46 وارش) و (21/1 م 46 وارش) بأسم مشروع (مدينة دیپلۆماتی سەفیران) بموجب الخرائط و التصاميم (ماستر بلان) و المصدقة من الجهات المختصة ، و عليه تم الأتفاق بين الطرفين على ما يلي: </p>
    <p class="lead" style="text-align: right"><b>١.</b> تعتبر مقدمة العقد جزءا لا يتجزأ من هذا العقد.</p>
    <p class="lead" style="text-align: right"><b>٢.</b> باع الطرف الأول إلی الطرف الثانی الفيلا المرقمة (<b>{{ property.name }}</b>) والمصنفة <b>({{ property.zone }})</b> بمساحة <b>({{ property.plotArea }})</b> م<sup>2</sup> بسعر <b>({{ property.initialPrice / property.area }}$)</b> {{ number2Text(parseFloat(property.initialPrice / property.area), 'ar') }} للمتر المربع الواحد للبناء ببدل بيع كلي وقدره <b>({{ property.initialPrice.toLocaleString() }})</b> {{ number2Text(property.initialPrice, 'ar') }} والذي حدد مساحة البناء ب <b>({{ property.area }})</b> م<sup>2</sup> للبناء حسب الخرائط المرفقة مع هذا العقد</p>
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الأول ( البائع ) شركة</b></p>
        <p class="lead" style="text-align: center">گولوان للأستثمار العقاري</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>ممثل الشرکة</b></p>
        <p class="lead" style="text-align: center">مصطفی رمزی قرنی</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الثاني ( المشتري )</b></p>
        <p class="lead" style="text-align: center">{{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}</p>
      </b-col>
    </b-row>
    <br />
    <br />
    <br />
    <div class="text-center">
      <p class="lead" style="text-align: center"><b>المستشار القانوني للشركة</b></p>
      <p class="lead" style="text-align: center">المستشار/ احمد عثمان الأطرقجي</p>
    </div>

    <div :style="{'padding-top': contractTopSpaces}"></div>
    <div v-if="buyType === 'Installment'">
      <p class="lead" style="text-align: right">واتفق الطرفان على ان يكون تسديد الأقساط بالشكل التالي:</p>
      <p class="lead" style="text-align: right"><b>أ‌-</b> يدفع الطرف الثاني نسبة <b>(20%)</b> من المبلغ الكلي مع التوقيع على هذا العقد.</p>
      <p class="lead" style="text-align: right"><b>ب‌-</b> يدفع الطرف الثاني نسبة <b>(20%)</b> من المبلغ الكلي مع إتمام الهيكل للفيلا موضوع هذا العقد.</p>
      <p class="lead" style="text-align: right"><b>ج-</b> يدفع الطرف الثاني نسبة <b>(20%)</b> من المبلغ الكلي بعد إكمال أعمال اللبغ للفيلا موضوع هذا العقد.</p>
      <p class="lead" style="text-align: right"><b>د-</b> يدفع الطرف الثاني نسبة <b>(15%)</b> من المبلغ الكلي بعد إنجاز أعمال البياض للفيلا موضوع هذا العقد.</p>
      <p class="lead" style="text-align: right"><b>ھ-</b> يدفع الطرف الثاني نسبة <b>(15%)</b> من المبلغ الكلي بعد تركيب الأبواب والشبابيك للفيلا موضوع هذا العقد.</p>
      <p class="lead" style="text-align: right"><b>و-</b> يدفع الطرف الثاني نسبة <b>(10%)</b> من المبلغ الكلي مع استلام المفاتيح للفيلا موضوع هذا العقد.</p>
    </div>
    <p v-if="buyType === 'Installment'" class="lead" style="text-align: right"><b>3.</b> اذا اخل الطرف الثاني بعدم دفع الأقساط المترتبة في ذمته في مواعيدها المحددة أعلاه فبعد مرور ( 3 ) ثلاثة أيام من تاريخ الاستحقاق لدفع الأقساط ، يتم توجيه إنذار رسمي له عن طريق كاتب العدل يمهله ( 7 ) أيام من تاريخ اليوم التالي لتبلغه بالإنذار لتنفيذ التزامه ، و بخلافه يحق للطرف الأول بيع الفيلا المذكورة أعلاه الى مشتر اخر و يتحمل الطرف الثاني الضرر الذي لحق بالطرف الأول و يستقطع من الأقساط المدفوعة و ذلك كتعويض للطرف الأول عن ما فاته من كسب و عن الأضرار الناتجة عن عدم التزام الطرف الثاني بمضمون هذا العقد ، و تسري هذه الفقرة على جميع مدد الأقساط المذكورة أعلاه و يعتبر هذا العقد مفسوخا دون الحاجة الى الرجوع الى المحاكم المختصة.</p>
    <p v-else class="lead" style="text-align: right"><b>٣.</b> اذا نكل الطرف الثاني او تراجع عن رغبته في إتمام العقد في أي مرحلة كانت من مراحل البناء ، لا يحق له طلب المبالغ التي تم تسديدها مطلقا لا عن طريق المحاكم و لا خارجها و يكون الفيلا موضوع العقد ملكا له بموجب هذا العقد و عند ذاك و بتراضي الطرفين يقوم الطرف الأول ببيع الفيلا الى مشتر اخر اذا امكن و بسعر السوق السائد و يتحمل الطرف الثاني الأضرار الناتجة عن عدم رغبته في إتمام العقد.</p>
    <p class="lead" style="text-align: right"><b>٤.</b> يلتزم الطرف الأول بأنهاء المشروع وتسليم المفاتيح الى الطرف الثاني بعد مرور (34) شهر ميلادي (شهر عمل) حسب قانون المقاولات العراقي النافذ أي يستخرج منها (العطل الرسمية وسوء الأحوال الجوية) وتطبق نظرية الظروف الطارئة المنصوص عليها في القانون المدني العراقي على هذا العقد، وتحتسب هذه المدة من تاريخ التوقيع على هذا العقد.</p>
    <p class="lead" style="text-align: right"><b>٥.</b> يدفع الطرف الأول إلى الطرف الثاني غرامة تأخيريه عن كل شهر تأخير و قدره ( 400$ ) أربعمائة دولار أمريكي اذا لم يلتزم الطرف الأول بتسليم المفاتيح خلال المدة المتفقة بين الطرفين والمذكورة أعلاه ، و على الطرف الأول تزويد الطرف الثاني بوصولات قبض قانونية للمبالغ المدفوعة و لا يعتد بالمدفوعات غير المثبتة في وصولات.</p>
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الأول ( البائع ) شركة</b></p>
        <p class="lead" style="text-align: center">گولوان للأستثمار العقاري</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>ممثل الشرکة</b></p>
        <p class="lead" style="text-align: center">مصطفی رمزی قرنی</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الثاني ( المشتري )</b></p>
        <p class="lead" style="text-align: center">{{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}</p>
      </b-col>
    </b-row>
    <br />
    <br />
    <br />
    <div class="text-center">
      <p class="lead" style="text-align: center"><b>المستشار القانوني للشركة</b></p>
      <p class="lead" style="text-align: center">المستشار/ احمد عثمان الأطرقجي</p>
    </div>

    <div :style="{'padding-top': contractTopSpaces}"></div>
    <p class="lead" style="text-align: right"><b>٦.</b> يلتزم الطرف الأول بتسليم الفيلا موضوع هذا العقد الى الطرف الثاني حسب الخرائط و التصاميم الموقعة بين الطرفين و المصادقة عليها من الجهات الرسمية ، و عند رغبة الطرف الثاني أجراء أية تغييرات أو إضافة فقرات في الخرائط ، يقوم الطرف الثاني بتقديم طلب إلى الطرف الأول  يطلب فيه تنفيذ تلك الإضافات أو التعديلات ويستجيب الطرف الأول لهذا الطلب بشرط ان لا يكون مؤثرا من الناحية الهندسية ، وان الجهة التي تنفذ هذه التعديلات او الإضافات هو الطرف الأول فقط و لا يحق للطرف الثاني القيام بأجراء تلك التعديلات بنفسه و تكون تكاليف تلك الإضافات أو التعديلات على نفقة الطرف الثاني و لا تشمل هذه التغييرات الواجهات بشكل عام للفلل ، لا أثناء التنفيذ و لا بعد استلام المفاتيح.</p>
    <p class="lead" style="text-align: right"><b>٧.</b> يتم تسجيل الفيلا موضوع هذا العقد باسم الطرف الثاني حسب قانون الاستثمار النافذ في الإقليم بعد تسديد الطرف الثاني كافة الأقساط المترتبة في ذمته عندما يكون ذلك ممكنا، و يتحمل الطرف الثاني كافة الرسومات القانونية ( رسومات التسجيل و غيرها ).</p>
    <p class="lead" style="text-align: right"><b>٨.</b> يلتزم الطرف الثاني بدفع المبالغ التي تخصصها الدولة بعد أشغاله للفيلا المذكورة أعلاه من رسوما  ( الماء و الكهرباء الوطنية و كذلك تكاليف الكهرباء و الماء و الخدمات ).</p>
    <p class="lead" style="text-align: right"><b>٩.</b> تكون فترة الصيانة للفيلا موضوع هذا العقد ( سنة واحدة ) تبدأ من تاريخ استلام المفاتيح و لا تدخل ضمن هذه الصيانة الأضرار التي تنتج عن سوء استخدام الطرف الثاني للفيلا المذكورة.</p>
    <p class="lead" style="text-align: right"><b>١٠.</b> لا يحق للطرف الثاني التنازل عن هذا العقد الا بعد استحصال موافقة تحريرية من الطرف الأول و عند إبداء الطرف الأول الموافقة على هذا التنازل يقع على عاتق الطرف الثاني ( المتنازل له ) جميع الالتزامات المذكورة في هذا العقد.</p>
    <p class="lead" style="text-align: right"><b>١١.</b> يلتزم الطرف الأول بتزويد الطرف الثاني بقائمة المواد المستخدمة في البناء من ( سيراميكات و البلاط ( الكاشي ) و الأبواب و النوافذ و المواد الصحية) و عدد المكيفات (   ) التي تثبت في الفلل ، و كذلك تزويد الطرف الثاني بخارطة البناء الداخلية و الخارجية و الماستر پلان للمشروع و يوقع عليها الطرفان و يكون مرفقا بهذا العقد.</p>
    <p class="lead" style="text-align: right"><b>١٢.</b> يحق للطرف الثاني الطلب من الطرف الأول بتركيب نظام ( الشوڤاز ) في الفيلا حسب طلبه و تكون تكاليف التركيب على نفقة الطرف الثاني بموجب اتفاق الطرفين.</p>
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الأول ( البائع ) شركة</b></p>
        <p class="lead" style="text-align: center">گولوان للأستثمار العقاري</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>ممثل الشرکة</b></p>
        <p class="lead" style="text-align: center">مصطفی رمزی قرنی</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الثاني ( المشتري )</b></p>
        <p class="lead" style="text-align: center">{{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}</p>
      </b-col>
    </b-row>
    <br />
    <br />
    <br />
    <div class="text-center">
      <p class="lead" style="text-align: center"><b>المستشار القانوني للشركة</b></p>
      <p class="lead" style="text-align: center">المستشار/ احمد عثمان الأطرقجي</p>
    </div>

    <div :style="{'padding-top': contractTopSpaces}"></div>
    <p class="lead" style="text-align: right"><b>١٣.</b> على الطرف الثاني تزويد الطرف الأول بمحل للتبليغات القانونية بموجب القانون او اعطاء عنوان اخر يكون محلا للتبليغ ، و تعتمد أرقام الهواتف و البريد الإلكتروني المذكور في مقدمة العقد في الأخطار و المراسلات بينهم.</p>
    <p class="lead" style="text-align: right"><b>١٤.</b> أية تغييرات في فقرات هذا العقد يجب ان تكون خطيا و يوقع عليها الطرفان.</p>
    <p class="lead" style="text-align: right"><b>١٥.</b> يلتزم الطرف الثاني بتنفيذ جميع التعليمات الصادرة من الطرف الأول و من هيئة الاستثمار في مرحلة البناء و ما بعد التسليم و عليه تنفيذ تلك التعليمات.</p>
    <p class="lead" style="text-align: right"><b>١٦.</b> وأتفق الطرفان على أن يقوم الطرف الأول بتثبيت رقم الإجازة الاستثمارية على العقد لاحقا عند صدورها.</p>
    <p class="lead" style="text-align: right"><b>١٧.</b> في حال نشوب أي خلاف بين الطرفين تعتبر محاكم بداءة أربيل هي المختصة لفض هذا الخلاف.</p>
    <p class="lead" style="text-align: right"><b>١٨.</b> حرر هذا العقد في أربيل بتاريخ <b>{{ getCurrentDate() }}</b> و بثلاث نسخ أصلية و وقع عليه الطرفان بثمانية عشرة فقرة.</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <b-row class="text-center">
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الأول ( البائع ) شركة</b></p>
        <p class="lead" style="text-align: center">گولوان للأستثمار العقاري</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>ممثل الشرکة</b></p>
        <p class="lead" style="text-align: center">مصطفی رمزی قرنی</p>
      </b-col>
      <b-col cols="4">
        <p class="lead" style="text-align: center"><b>الطرف الثاني ( المشتري )</b></p>
        <p class="lead" style="text-align: center">{{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}</p>
      </b-col>
    </b-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="text-center">
      <p class="lead" style="text-align: center"><b>المستشار القانوني للشركة</b></p>
      <p class="lead" style="text-align: center">المستشار/ احمد عثمان الأطرقجي</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArabicContractDiplomatiSafiran",
  props: {
    accountant: {
      type: String,
      default: 'عمر اسماعیل صالح'
    },
    accountantPhone: {
      type: String,
      default: '07504452330'
    },
    customer: {
      type: Object
    },
    property: {
      type: Object
    },
    buyType: {},
    contractNo: {}
  },
  data() {
    return {
      contractTopSpaces: localStorage.getItem('contractTopSpaces') + 'px' ?? 100 + 'px'
    }
  }
}
</script>

<style scoped>
.lead {
  font-size: 1.45rem;
  font-weight: 300;
}
</style>
