import { HTTP } from "@/plugins/axios";

const permissions = {
  namespaced: true,
  state: {
    permissions: {},
    reAddMyClaims: {},
  },
  mutations: {
    SET_USER_CLAIMS(state, value) {
      state.permissions = value;
    },
    SET_RE_ADD_MY_CLAIMS(state, value) {
      state.reAddMyClaims = value;
    },
  },
  actions: {
    GET_ALL({ commit }, userId) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/Authentication/GetClaims?userId=${userId}`)
          .then((response) => {
            commit("SET_USER_CLAIMS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_USER_CLAIMS", {});
            reject(error);
          });
      });
    },
    STORE_CLAIMS({ commit }, { userId, claims }) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/Authentication/ReAddMyClaims?userId=${userId}`, claims)
          .then((response) => {
            commit("SET_RE_ADD_MY_CLAIMS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_RE_ADD_MY_CLAIMS", {});
            reject(error);
          });
      });
    },
  },
  getters: {
    permissions: (state) => state.permissions,
    reAddMyClaims: (state) => state.reAddMyClaims,
  },
};

export default permissions;
