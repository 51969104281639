<template>
  <div v-if="hasPermission(permissions.leadCustomersCreate)">
    <content-header>{{ $t("createLeadCustomer") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input
              id="name"
              :name="$t('name')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <text-input
              id="phone"
              :name="$t('phone')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <text-input
              id="nationality"
              :name="$t('nationality')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-input
              id="address"
              :name="$t('address')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <div class="col-6">
              <div class="d-flex justify-content-start">
                <label class="col-sm-4 px-3 col-form-label text-right"
                  >Gender:
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="maleLabel"
                    :checked="form.isMale"
                    @change="(e) => (form.isMale = e.target.checked)"
                  />
                  <label class="form-check-label" for="maleLabel"> Male </label>
                </div>
                <div class="mx-2"></div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="femaleLabel"
                    :checked="!form.isMale"
                    @change="(e) => (form.isMale = !e.target.checked)"
                  />
                  <label class="form-check-label" for="femaleLabel">
                    Female
                  </label>
                </div>
              </div>
            </div>
            <!-- <boolean-input
              id="male"
              :name="$t('gender')"
              :default-value="true"
              :checked-label="$t('male')"
              :un-checked-label="$t('female')"
              @switched="(e) => (form.isMale = e)"
            /> -->
            <select-input
              id="leadSubSource"
              :name="$t('leadSubSource')"
              label="name"
              :validate="$v"
              :required="true"
              api-link="leadSubSources/GET_ALL"
              @itemSelected="(e) => (form.leadSubSourceId = e)"
            />
            <text-input
              id="extraField3"
              :name="$t('type')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-input
              v-if="projectTenant() === 'UniqueTowers'"
              id="extraField2"
              :name="$t('apartmentNo')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-input
              v-else
              id="extraField2"
              :name="$t('villaNo')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-input
              id="extraField1"
              :name="$t('heardAboutUsFrom')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-input
              id="feedback"
              :name="$t('feedback')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <number-input
              v-if="projectTenant() !== 'MassHills'"
              id="males"
              :name="$t('males')"
              :validate="$v"
              :default-value="0"
              :is-loading="isLoading"
            />
            <number-input
              v-if="projectTenant() !== 'MassHills'"
              id="females"
              :name="$t('females')"
              :validate="$v"
              :default-value="0"
              :is-loading="isLoading"
            />
            <text-area-input
              id="note"
              :name="$t('customerNote')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <text-area-input
              id="visitNote"
              :name="$t('visitNote')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import { LeadCustomer } from "@/models/LeadCustomer";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";
// import BooleanInput from "@/components/Inputs/BooleanInput";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import { permissions } from "@/plugins/constants";

export default {
  name: "LeadCustomerCreate",
  components: {
    TextAreaInput,
    NumberInput,
    TextInput,
    // BooleanInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
    SelectInput,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new LeadCustomer().fillAbles),
      isLoading: false,
      permissions: permissions,
    };
  },
  validations: {
    form: {
      name: { required },
      phone: { required },
      nationality: {},
      address: {},
      note: {},
      isMale: {},
      leadSubSourceId: { required },
      extraField3: {},
      extraField2: {},
      extraField1: {},
      feedback: {},
      males: {},
      females: {},
      visitNote: {},
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.isMale = this.form.isMale === true || this.form.isMale === "";
      delete this.form.leadSubSource;
      this.isLoading = true;
      delete this.form.state;
      this.$store
        .dispatch("leadCustomers/CREATE", this.form)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "leadCustomers" });
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
