<template>
  <div v-if="hasPermission(permissions.buyContractsCreate)" class="buy-contract">
    <content-header>{{ $t('createBuyContract') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col>
              <select-input
                  id="property"
                  :name="$t('property')"
                  label="name"
                  api-link="properties/GET_ALL"
                  :api-params="{OnlyAvailable: true}"
                  @itemSelected="propertySelected"
                  :return-object="true"
              />
              <select-input
                  id="customers"
                  :name="$t('customers')"
                  label='firstName,middleName,lastName,+phone'
                  api-link="customers/GET_ALL"
                  @itemSelected="customerSelected"
              />
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group :prepend="$t('area')" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input :placeholder="$t('area')" v-model="property.area"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group :prepend="$t('street')" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input :placeholder="$t('streetWidth')" v-model="property.streetWidth"></b-form-input>
                  <b-form-input :placeholder="$t('streetNumber')" v-model="property.streetNumber"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group :prepend="$t('zone')" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input :placeholder="$t('zone')" v-model="property.zone"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group :prepend="$t('contractNumber')" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input :placeholder="$t('contractNumber')" v-model="contractNumber"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-input-group :prepend="$t('propertyPrice')" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-form-input disabled :placeholder="$t('propertyPrice')" v-model="property.initialPrice"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-input-group :prepend="$t('finalPrice')" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-form-input disabled :placeholder="$t('finalPrice')" v-model="finalPrice"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                      <b-form-checkbox switch size="lg" v-model="isCash">{{ $t('isCash') }}</b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="!isCash">
            <b-col>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('installment') }}</th>
                  <th>{{ $t('percentage') }}</th>
                  <th>{{ $t('percentageOfInstallments') }}</th>
                  <th>{{ $t('date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>{{ $t('prepayment') }}</th>
                  <th>
                    <b-input-group prepend="%">
                      <b-form-input type="number" v-model="prePayment1Percent" @change="calculateInstallments" :disabled="true"></b-form-input>
                    </b-input-group>
                  </th>
                  <th>
                    <b-input-group prepend="$">
                      <b-form-input type="number" v-model="prePayment1" @change="calculateInstallments" :disabled="true"></b-form-input>
                    </b-input-group>
                  </th>
                  <th>
                    <b-input-group :prepend="$t('date')">
                      <b-form-input type="date" v-model="prePayment1Date" @change="calculateInstallments"></b-form-input>
                    </b-input-group>
                  </th>
                </tr>
                <tr>
                  <th>{{ $t('installments') }}</th>
                  <th>
                    <b-input-group prepend="%">
                      <b-form-input type="number" v-model="currentInstallmentsPercent" disabled></b-form-input>
                    </b-input-group>
                  </th>
                  <th colspan="2">
                    <b-input-group :prepend="$t('note')">
                      <b-form-textarea id="note" v-model="installmentsNote"></b-form-textarea>
                    </b-input-group>
                  </th>
                </tr>
                <!-- Loop Here -->
                <tr v-for="installment in installments" v-bind:key="installment.index">
                  <th>{{ installment.index }}</th>
                  <th>
                    <b-input-group prepend="$">
                      <b-form-input type="number" v-model="installment.currentPayPrice" :disabled="true"></b-form-input>
                    </b-input-group>
                  </th>
                  <th colspan="2">
                    <b-input-group :prepend="$t('date')">
                      <b-form-input type="date" v-model="installment.currentPayDate"></b-form-input>
                    </b-input-group>
                  </th>
                </tr>
                <!-- Loop Here -->
                <tr>
                  <th>{{ $t('deliveringKeys') }}</th>
                  <th>
                    <b-input-group prepend="%">
                      <b-form-input type="number" v-model="deliveryKeyPercent" @change="calculateInstallments" :disabled="true"></b-form-input>
                    </b-input-group>
                  </th>
                  <th>
                    <b-input-group prepend="$">
                      <b-form-input type="number" v-model="deliveryKey" @change="calculateInstallments" :disabled="true"></b-form-input>
                    </b-input-group>
                  </th>
                  <th>
                    <b-input-group :prepend="$t('date')">
                      <b-form-input type="date" v-model="deliveryKeyDate"></b-form-input>
                    </b-input-group>
                  </th>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-form-group>
            <b-input-group :prepend="$t('discount')" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input :placeholder="$t('propertyPrice')" v-model="discount" @change="calculateInstallments"></b-form-input>
              <b-form-checkbox v-model="showDiscount" name="check-button" switch>
                {{ $t('showDiscount') }}
              </b-form-checkbox>
            </b-input-group>
          </b-form-group>
          <button class="btn btn-outline-primary col-md-3" type="submit" :disabled="isLoading" @click="onSubmit">
            <i class="fas fa-plus"></i> {{ $t('create') }}
          </button>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import SelectInput from "../../components/Inputs/SelectInput";
import moment from "moment";
import Swal from "sweetalert2";
import {permissions} from "@/plugins/constants";

export default {
  name: "BuyContractsCreateDiplomatiSafiran",
  components: {ContentHeader, 'v-content': Content, SelectInput},
  data() {
    return {
      isLoading: false,
      installments: [],
      property: '',
      customer: 0,
      contractNumber: '0',
      installmentsNumber: 4,
      installmentPeriod: 4,
      prePayment1: 0,
      prePayment1Percent: 20,
      prePayment1IsPercent: true,
      prePayment1Date: this.$helpers.getCurrentDate(),
      installmentsPercent: 70,
      installmentsNote: '',
      deliveryKey: 0,
      deliveryKeyPercent: 10,
      deliveryKeyIsPercent: true,
      deliveryKeyDate: this.$helpers.getCurrentDate(18),
      currentInstallmentsPercent: 70,
      discount: 0,
      finalPrice: 0,
      isCash: false,
      showDiscount: false,
      permissions: permissions
    }
  },
  mounted() {
    this.calculateInstallments();
  },
  methods: {
    customerSelected(customerId) {
      this.customer = customerId
    },
    propertySelected(property) {
      this.property = property;
      this.calculateInstallments();
    },
    calculateInstallments() {
      let customInstallments = [];
      customInstallments.push({
        isItPrePayment: false,
        isItKeyPayment: false,
        isPayed: false,
        paymentProcessType: "Installment",
        index: 1,
        currentPayPrice: 0.2 * (this.property.initialPrice - this.discount),
        currentPayDate: moment(this.prePayment1Date).add(this.installmentPeriod, 'M').format('YYYY-MM-DD')
      });
      customInstallments.push({
        isItPrePayment: false,
        isItKeyPayment: false,
        isPayed: false,
        paymentProcessType: "Installment",
        index: 2,
        currentPayPrice: 0.2 * (this.property.initialPrice - this.discount),
        currentPayDate: moment(this.prePayment1Date).add(this.installmentPeriod * 2, 'M').format('YYYY-MM-DD')
      });
      customInstallments.push({
        isItPrePayment: false,
        isItKeyPayment: false,
        isPayed: false,
        paymentProcessType: "Installment",
        index: 3,
        currentPayPrice: 0.15 * (this.property.initialPrice - this.discount),
        currentPayDate: moment(this.prePayment1Date).add(this.installmentPeriod * 3, 'M').format('YYYY-MM-DD')
      });
      customInstallments.push({
        isItPrePayment: false,
        isItKeyPayment: false,
        isPayed: false,
        paymentProcessType: "Installment",
        index: 4,
        currentPayPrice: 0.15 * (this.property.initialPrice - this.discount),
        currentPayDate: moment(this.prePayment1Date).add(this.installmentPeriod * 4, 'M').format('YYYY-MM-DD')
      });
      this.installments = customInstallments;
      if (this.prePayment1IsPercent) {
        this.prePayment1 = (this.prePayment1Percent / 100) * (this.property.initialPrice - this.discount);
      } else {
        this.prePayment1Percent = (this.prePayment1 / (this.property.initialPrice - this.discount)) * 100;
      }
      if (this.deliveryKeyIsPercent) {
        this.deliveryKey  = (this.deliveryKeyPercent / 100) * (this.property.initialPrice - this.discount);
      } else {
        this.deliveryKeyPercent = (this.deliveryKey / (this.property.initialPrice - this.discount)) * 100;
      }
      this.finalPrice = (this.property.initialPrice ?? 0) - this.discount;
    },
    onSubmit() {
      let customInstallments = [];
      if (this.isCash) {
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.property.initialPrice - this.discount,
          currentPayDate: this.prePayment1Date
        });
      } else {
        customInstallments = this._.clone(this.installments);
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Installment",
          index: 1,
          currentPayPrice: this.prePayment1,
          currentPayDate: this.prePayment1Date
        });
      }
      if (!this.isCash) {
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: true,
          isPayed: false,
          paymentProcessType: "DeliveryKey",
          currentPayPrice: this.deliveryKey,
          currentPayDate: this.deliveryKeyDate
        });
        let sum = parseFloat(this._.sumBy(customInstallments, (val) => {
          return parseFloat(val.currentPayPrice);
        }))
        if ((sum + parseFloat(this.discount)) !== this.property.initialPrice) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: this.$t('pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice'),
            showConfirmButton: false,
            timer: 1500
          });
          return false;
        }
      }
      let data = {
        buyContract: {
          contractNumber: this.contractNumber,
          buyingDate: this.prePayment1Date,
          buyingPrice: this.property.initialPrice,
          buyType: this.isCash ? "Cash" : "Installment",
          isCancelled: false,
          isPrePaymentPayed: false,
          discount: this.discount,
          showDiscount: this.showDiscount,
          propertyId: this.property.id,
          customerId: this.customer,
          paymentProcess: customInstallments
        },
        propertyExpense: null
      }

      this.isLoading = true;
      this.$store.dispatch('buyContracts/CREATE', data).then(() => {
        this.$router.push({name: "buyContracts"})
      }).catch(() => this.isLoading = false)
    }
  }
}
</script>
