import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./../translations";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  lazy: true,
  silentTranslationWarn: process.env.NODE_ENV === "development",
  locale:
    localStorage.getItem("locale") ?? process.env.VUE_APP_I18N_LOCALE_DEFAULT,
  fallbackLocale: ["ku", "ku_badini", "ar", "en"],
  messages,
});
