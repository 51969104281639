<template>
  <div>
    <content-header>{{ $t("financialDashboard") }}</content-header>
    <v-content>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-green-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalPriceOfContracts &&
                  projectCurrencySymbol() +
                    allNumbers.totalPriceOfContracts.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>
              <p>{{ $t("totalSells") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-money-bill-wave"></i>
            </div>
            <router-link
              :to="{ name: 'buyContractDetail' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-blue-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalReceviedPayments &&
                  projectCurrencySymbol() +
                    allNumbers.totalReceviedPayments.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>
              <p>{{ $t("totalIncomeFromSell") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-dollar-sign"></i>
            </div>
            <router-link
              :to="{ name: 'paymentProcessReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-yellow-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalPendingPayments &&
                  projectCurrencySymbol() +
                    allNumbers.totalPendingPayments.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>
              <p>{{ $t("remainToCollect") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-money-check-edit-alt"></i>
            </div>
            <router-link
              :to="{ name: 'paymentProcessReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-gray-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalReceviedPayments &&
                  allNumbers.totalDeposits &&
                  projectCurrencySymbol() +
                    (
                      allNumbers.totalReceviedPayments +
                      allNumbers.totalDeposits
                    ).toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>
              <p>{{ $t("totalIncomes") }}</p>
            </div>
            <div class="icon"><i class="fal fa-envelope-open-dollar"></i></div>
            <router-link :to="{ name: 'transactions' }" class="small-box-footer"
              >{{ $t("moreInfo") }}
              <i class="fas fa-arrow-circle-right text-light"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-info-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalDeposits &&
                  projectCurrencySymbol() +
                    allNumbers.totalDeposits.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t("totalDeposits") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-sack-dollar"></i>
            </div>
            <router-link
              :to="{ name: 'depositsReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-red-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalWithdraws &&
                  projectCurrencySymbol() +
                    allNumbers.totalWithdraws.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t("totalWithdraws") }}</p>
            </div>
            <div class="icon">
              <i class="fal fa-file-invoice-dollar"></i>
            </div>
            <router-link
              :to="{ name: 'withdrawsReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-green-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalForgivePrice &&
                  projectCurrencySymbol() +
                    allNumbers.totalForgivePrice.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t("totalForgivePrice") }}</p>
            </div>
            <div class="icon"><i class="fal fa-piggy-bank"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-6">
          <div class="small-box bg-secondary">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalDiscount &&
                  projectCurrencySymbol() +
                    allNumbers.totalDiscount.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t("totalDiscount") }}</p>
            </div>
            <div class="icon"><i class="fal fa-piggy-bank"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div> -->
        <div class="col-lg-3 col-md-4 col-6 text-light">
          <div class="small-box bg-blue-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalHoldPrice &&
                  projectCurrencySymbol() +
                    allNumbers.totalHoldPrice.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t("TotalHoldPrice") }}</p>
            </div>
            <div class="icon"><i class="fal fa-piggy-bank"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-4 col-6 text-light"
          v-for="safeBox in allNumbers.safeboxes"
          :key="safeBox.id"
        >
          <div class="small-box bg-purple-g">
            <div class="inner">
              <h3>
                {{
                  safeBox.mcCurrentBalance &&
                  projectCurrencySymbol() +
                    safeBox.mcCurrentBalance.toLocaleString() +
                    projectCurrencySymbol(true)
                }}
              </h3>

              <p>{{ $t(safeBox.name) }}</p>
            </div>
            <div class="icon"><i class="fal fa-box-usd"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }}
              <i class="fas fa-arrow-circle-right text-light"></i
            ></router-link>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-6">
          <div class="small-box bg-green-g">
            <div class="inner">
              <h3>
                {{
                  allNumbers.safeBox1Total &&
                   projectCurrencySymbol() +allNumbers.safeBox1Total.toLocaleString() + projectCurrencySymbol(true) 
                }}
              </h3>

              <p>{{ $t("defaultSafeBox") }}</p>
            </div>
            <div class="icon"><i class="fal fa-box-usd"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <div class="small-box bg-info">
            <div class="inner">
              <h3>
                {{
                  allNumbers.safeBox3Total &&
                   projectCurrencySymbol() +allNumbers.safeBox3Total.toLocaleString() + projectCurrencySymbol(true) 
                }}
              </h3>
           
              <p>{{ $t("otherExpenseSafeBox") }}</p>
            </div>
            <div class="icon"><i class="fal fa-box-open"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <div class="small-box bg-secondary">
            <div class="inner">
              <h3>
                {{
                  allNumbers.safeBox2Total &&
                   projectCurrencySymbol() +allNumbers.safeBox2Total.toLocaleString() + projectCurrencySymbol(true) 
                }}
              </h3>
             
              <p>{{ $t("bankSafeBox") }}</p>
            </div>
            <div class="icon"><i class="fal fa-piggy-bank"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-6">
          <div class="small-box bg-warning">
            <div class="inner">
              <h3>
                {{
                  allNumbers.totalExpense &&
                   projectCurrencySymbol() +allNumbers.totalExpense.toLocaleString() + projectCurrencySymbol(true) 
                }}
              </h3>
              
              <p>{{ $t("totalExpenses") }}</p>
            </div>
            <div class="icon"><i class="fal fa-box-popen"></i></div>
            <router-link
              :to="{ name: 'safeBoxTransactionReport' }"
              class="small-box-footer"
              >{{ $t("moreInfo") }} <i class="fas fa-arrow-circle-right"></i
            ></router-link>
          </div>
        </div> -->
      </div>

      <hr class="hr-text" data-content="" />

      <div class="row">
        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header bg-blue-g text-light">
              <h3 class="card-title">{{ $t("latestDeposits") }}</h3>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("title") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="deposit in deposits" :key="deposit.id">
                    <td>
                      <span class="asLink" @click="showDepositModal(deposit)">
                        {{ deposit.name }}
                      </span>
                    </td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        deposit.mcPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(deposit.date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-modal id="deposit-modal" :title="$t('deposits')" size="xl">
              <div class="d-block">
                <h5>
                  {{ $t("name") }}: {{ oneDeposit.name && oneDeposit.name }}
                </h5>
                <h5>
                  {{ $t("depositType") }}:
                  {{ oneDeposit.depositType && oneDeposit.depositType.name }}
                </h5>
                <h5>
                  {{ $t("invoiceNumber") }}:
                  {{ oneDeposit.invoiceNumber && oneDeposit.invoiceNumber }}
                </h5>
                <h5>
                  {{ $t("mcPrice") }}:
                  {{
                    oneDeposit.mcPrice &&
                    projectCurrencySymbol() +
                      oneDeposit.mcPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                  }}
                </h5>
                <h5>
                  {{ $t("accountant") }}:
                  {{ oneDeposit.accountant && oneDeposit.accountant }}
                </h5>
                <h5>
                  {{ $t("depositer") }}:
                  {{ oneDeposit.depositer && oneDeposit.depositer }}
                </h5>
                <h5>
                  {{ $t("date") }}:
                  {{ oneDeposit.date && formatDate(oneDeposit.date) }}
                </h5>
                <h5>
                  {{ $t("note") }}: {{ oneDeposit.note && oneDeposit.note }}
                </h5>
                <show-attachment
                  v-if="oneDeposit.attachment"
                  :attachment="oneDeposit.attachment"
                  :title="oneDeposit.id"
                />
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{
                  $t("close")
                }}</b-button>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header bg-green-g text-light">
              <h3 class="card-title">{{ $t("latestWithdraws") }}</h3>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("title") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="withdraw in withdraws" :key="withdraw.id">
                    <td>
                      <span class="asLink" @click="showWithdrawModal(withdraw)">
                        {{ withdraw.name }}
                      </span>
                    </td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        withdraw.mcPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(withdraw.date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-modal id="withdraw-modal" :title="$t('withdraws')" size="xl">
              <div class="d-block">
                <h5>
                  {{ $t("name") }}: {{ oneWithdraw.name && oneWithdraw.name }}
                </h5>
                <h5>
                  {{ $t("withdrawType") }}:
                  {{
                    oneWithdraw.withdrawType && oneWithdraw.withdrawType.name
                  }}
                </h5>
                <h5>
                  {{ $t("invoiceNumber") }}:
                  {{ oneWithdraw.invoiceNumber && oneWithdraw.invoiceNumber }}
                </h5>
                <h5>
                  {{ $t("mcPrice") }}:
                  {{
                    oneWithdraw.mcPrice &&
                    projectCurrencySymbol() +
                      oneWithdraw.mcPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                  }}
                </h5>
                <h5>
                  {{ $t("accountant") }}:
                  {{ oneWithdraw.accountant && oneWithdraw.accountant }}
                </h5>
                <h5>
                  {{ $t("withdrawer") }}:
                  {{ oneWithdraw.withdrawer && oneWithdraw.withdrawer }}
                </h5>
                <h5>
                  {{ $t("date") }}:
                  {{ oneWithdraw.date && formatDate(oneWithdraw.date) }}
                </h5>
                <h5>
                  {{ $t("note") }}: {{ oneWithdraw.note && oneWithdraw.note }}
                </h5>
                <show-attachment
                  v-if="oneWithdraw.attachment"
                  :attachment="oneWithdraw.attachment"
                  :title="oneWithdraw.id"
                />
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{
                  $t("close")
                }}</b-button>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="card">
            <div class="card-header bg-red-g text-light">
              <h3 class="card-title">{{ $t("latestExpenses") }}</h3>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("title") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="expense in expenses" :key="expense.id">
                    <td>
                      <span class="asLink" @click="showExpenseModal(expense)">
                        {{ expense.title }}
                      </span>
                    </td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        expense.mcTotalPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(expense.date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-modal id="expense-modal" :title="$t('expenses')" size="xl">
              <div class="d-block">
                <h5>
                  {{ $t("name") }}: {{ oneExpense.title && oneExpense.title }}
                </h5>
                <h5>
                  {{ $t("expenseType") }}:
                  {{ oneExpense.expenseType && oneExpense.expenseType.name }}
                </h5>
                <h5>
                  {{ $t("invoiceNumber") }}:
                  {{ oneExpense.invoiceNumber && oneExpense.invoiceNumber }}
                </h5>
                <h5>
                  {{ $t("mcPrice") }}:
                  {{
                    oneExpense.mcTotalPrice &&
                    projectCurrencySymbol() +
                      oneExpense.mcTotalPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                  }}
                </h5>
                <h5>
                  {{ $t("date") }}:
                  {{ oneExpense.date && formatDate(oneExpense.date) }}
                </h5>
                <h5>
                  {{ $t("note") }}: {{ oneExpense.note && oneExpense.note }}
                </h5>
                <show-attachment
                  v-if="oneExpense.attachment"
                  :attachment="oneExpense.attachment"
                  :title="oneExpense.id"
                />
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{
                  $t("close")
                }}</b-button>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header bg-info-g text-light">
              <h3 class="card-title">{{ $t("latestTransfers") }}</h3>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("title") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in transactions" :key="transaction.id">
                    <td>
                      <span
                        class="asLink"
                        @click="showTransactionModal(transaction)"
                      >
                        {{ transaction.type }}
                      </span>
                    </td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        transaction.mcPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(transaction.date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-modal
              id="transaction-modal"
              :title="$t('transactions')"
              size="xl"
            >
              <div class="d-block">
                <h5>
                  {{ $t("name") }}:
                  {{ oneTransaction.title && oneTransaction.title }}
                </h5>
                <h5>
                  {{ $t("detail") }}:
                  {{ oneTransaction.detail && oneTransaction.detail }}
                </h5>
                <h5>
                  {{ $t("contractNumber") }}:
                  {{
                    oneTransaction.contractNumber &&
                    oneTransaction.contractNumber
                  }}
                </h5>
                <h5>
                  {{ $t("mcPrice") }}:
                  {{
                    oneTransaction.mcTotalPrice &&
                    projectCurrencySymbol() +
                      oneTransaction.mcTotalPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                  }}
                </h5>
                <h5>
                  {{ $t("date") }}:
                  {{ oneTransaction.date && formatDate(oneTransaction.date) }}
                </h5>
                <h5>
                  {{ $t("note") }}:
                  {{ oneTransaction.note && oneTransaction.note }}
                </h5>
                <show-attachment
                  v-if="oneTransaction.attachment"
                  :attachment="oneTransaction.attachment"
                  :title="oneTransaction.id"
                />
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{
                  $t("close")
                }}</b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>

      <hr class="hr-text" data-content="" />

      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="row mb-4">
            <div class="col-10">
              <select-input
                :show-label="false"
                label="name"
                id="expenseTypes"
                :name="$t('expenseTypes')"
                api-link="expenseTypes/GET_ALL"
                @itemSelected="expenseTypeSelected"
                :multiple="true"
              />
            </div>
            <div class="col-2">
              <select class="form-control" v-model="selectedYear">
                <option v-for="year in years" :key="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <chart
            :chart-fields="chartFields"
            :chart-data="chartData"
            :title="$t('totalOfExpenses')"
          />
        </div>
        <div class="col-lg-4 col-12">
          <div class="card card-widget widget-user-2">
            <div class="widget-user-header bg-yellow-g text-light">
              <h3 class="widget-user-username">
                {{ $t("paymentsFromAgents") }}
              </h3>
            </div>
            <div class="card-footer p-0">
              <ul class="nav flex-column">
                <li
                  v-for="investor in investors"
                  :key="investor.id"
                  class="nav-item"
                >
                  <h6 class="nav-link mb-0">
                    {{ investor.name }}
                    <span class="float-right ml-2 badge bg-danger badgeWidth">{{
                      projectCurrencySymbol() +
                      (
                        investor.mcNeedToPay - investor.mcPaidPrice
                      ).toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                    <span class="float-right ml-2 badge bg-dark badgeWidth">{{
                      projectCurrencySymbol() +
                      investor.mcPaidPrice.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                    <span class="float-right ml-2 badge bg-blue-g badgeWidth">{{
                      projectCurrencySymbol() +
                      investor.mcNeedToPay.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                    <span
                      data-v-7eab81f9=""
                      class="float-right ml-2 badge bg-gray badgeWidth"
                      >{{ investor.meter.toLocaleString() }}m<sup>2</sup></span
                    >
                  </h6>
                </li>
                <li class="nav-item">
                  <h6 class="nav-link mb-0">
                    {{ $t("total") }}
                    <span class="float-right ml-2 badge text-light bg-red-g">{{
                      projectCurrencySymbol() +
                      (sumNeedToPay - sumPaidPrice).toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                    <span
                      class="float-right ml-2 badge text-light bg-green-g"
                      >{{
                        projectCurrencySymbol() +
                        sumPaidPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}</span
                    >
                    <span class="float-right ml-2 badge text-light bg-blue-g">{{
                      projectCurrencySymbol() +
                      sumNeedToPay.toLocaleString() +
                      projectCurrencySymbol(true)
                    }}</span>
                    <span
                      data-v-7eab81f9=""
                      class="float-right ml-2 badge text-light bg-yellow-g"
                      >{{ sumMeter.toLocaleString() }}m<sup>2</sup></span
                    >
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr class="hr-text" data-content="" />

      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <h3 class="card-header bg-blue-g text-light">
              {{ $t("upcomingPayments") }}
            </h3>
            <div class="card-body">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("name") }} ({{ $t("contractNumber") }})</th>
                    <th>{{ $t("phone") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                    <th>{{ $t("property") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="upcomingPayment in upcomingPayments"
                    :key="upcomingPayment.id"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'customers.edit',
                          params: { id: upcomingPayment.customerId },
                        }"
                        >{{ upcomingPayment.name }}</router-link
                      >
                      (<router-link
                        :to="{
                          name: 'buyContracts.show',
                          params: { id: upcomingPayment.contractId },
                        }"
                        >{{ upcomingPayment.contractNumber }}</router-link
                      >)
                    </td>
                    <td>{{ replacePhone(upcomingPayment.phone) }}</td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        upcomingPayment.mcPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(upcomingPayment.date) }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'properties.edit',
                          params: { id: upcomingPayment.propertyId },
                        }"
                        >{{ upcomingPayment.type }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="card">
            <h3 class="card-header bg-red-g text-light">
              {{ $t("passedPayments") }}
            </h3>
            <div class="card-body">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("name") }} ({{ $t("contractNumber") }})</th>
                    <th>{{ $t("phone") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                    <th>{{ $t("property") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="passedPayment in passedPayments"
                    :key="passedPayment.id"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'customers.edit',
                          params: { id: passedPayment.customerId },
                        }"
                        >{{ passedPayment.name }}</router-link
                      >
                      (<router-link
                        :to="{
                          name: 'buyContracts.show',
                          params: { id: passedPayment.contractId },
                        }"
                        >{{ passedPayment.contractNumber }}</router-link
                      >)
                    </td>
                    <td>{{ replacePhone(passedPayment.phone) }}</td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        passedPayment.mcPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>{{ formatDate(passedPayment.date) }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'properties.edit',
                          params: { id: passedPayment.propertyId },
                        }"
                        >{{ passedPayment.type }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <h3 class="card-header bg-purple-g text-light">
              {{ $t("unpaidContracts") }}
            </h3>
            <div class="card-body">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("property") }} ({{ $t("contractNumber") }})</th>
                    <th>{{ $t("customer") }}</th>
                    <th>{{ $t("buyPrice") }}</th>
                    <th>{{ $t("prepayment") }} 1</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="unpaidContract in unpaidContracts"
                    :key="unpaidContract.id"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'properties.edit',
                          params: { id: unpaidContract.propertyId },
                        }"
                        >{{ unpaidContract.propertyName }}</router-link
                      >
                      (<router-link
                        :to="{
                          name: 'buyContracts.show',
                          params: { id: unpaidContract.id },
                        }"
                        >{{ unpaidContract.id }}</router-link
                      >)
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'customers.edit',
                          params: { id: unpaidContract.customerId },
                        }"
                        >{{ unpaidContract.customerName }}</router-link
                      >
                    </td>
                    <td>
                      {{
                        projectCurrencySymbol() +
                        unpaidContract.buyingPrice.toLocaleString() +
                        projectCurrencySymbol(true)
                      }}
                    </td>
                    <td>0</td>
                    <td>{{ formatDate(unpaidContract.buyingDate) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-content>
  </div>
</template>

<script>
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import SelectInput from "@/components/Inputs/SelectInput";
import Chart from "../components/charts/Chart";
import ShowAttachment from "@/components/ShowAttachment";

export default {
  name: "FinancialDashboard",
  components: {
    ShowAttachment,
    SelectInput,
    "v-content": Content,
    ContentHeader,
    Chart,
  },
  data() {
    return {
      deposits: [],
      oneDeposit: {},
      withdraws: [],
      oneWithdraw: {},
      expenses: [],
      oneExpense: {},
      transactions: [],
      oneTransaction: {},
      allNumbers: [],
      chartFields: [
        this.$t("january"),
        this.$t("february"),
        this.$t("march"),
        this.$t("april"),
        this.$t("may"),
        this.$t("june"),
        this.$t("july"),
        this.$t("august"),
        this.$t("september"),
        this.$t("october"),
        this.$t("november"),
        this.$t("december"),
      ],
      chartData: [],
      selectedExpenseType: null,
      years: this.generateYear(),
      selectedYear: new Date().getFullYear(),
      upcomingPayments: [],
      passedPayments: [],
      unpaidContracts: [],
      investors: [],
      sumPaidPrice: 0,
      sumNeedToPay: 0,
      sumMeter: 0,
    };
  },
  watch: {
    selectedYear() {
      this.getChartData();
    },
  },
  mounted() {
    this.getDeposits();
    this.getWithdraws();
    this.getExpenses();
    this.getTransactions();
    this.loadData();
    this.getChartData();
    this.getUpcomingPayments();
    this.getPassedPayments();
    this.getUnpaidContracts();
    this.getInvestors();
  },
  methods: {
    getDeposits() {
      this.$store
        .dispatch("deposits/GET_ALL", {
          _start: 0,
          _end: 10,
          _sort: "id",
          _order: "desc",
          _search: "",
        })
        .then((response) => {
          this.deposits = response.data;
        })
        .catch(() => {});
    },
    showDepositModal(deposit) {
      this.oneDeposit = deposit;
      this.$bvModal.show("deposit-modal");
    },
    getWithdraws() {
      this.$store
        .dispatch("withdraws/GET_ALL", {
          _start: 0,
          _end: 10,
          _sort: "id",
          _order: "desc",
          _search: "",
        })
        .then((response) => {
          this.withdraws = response.data;
        })
        .catch(() => {});
    },
    showWithdrawModal(withdraw) {
      this.oneWithdraw = withdraw;
      this.$bvModal.show("withdraw-modal");
    },
    getExpenses() {
      this.$store
        .dispatch("expenses/GET_ALL", {
          _start: 0,
          _end: 10,
          _sort: "id",
          _order: "desc",
          _search: "",
        })
        .then((response) => {
          this.expenses = response.data;
        })
        .catch(() => {});
    },
    showExpenseModal(expense) {
      this.oneExpense = expense;
      this.$bvModal.show("expense-modal");
    },
    getTransactions() {
      this.$store
        .dispatch("reports/GET_TRANSACTIONS", {
          _start: 0,
          _end: 10,
          _sort: "id",
          _order: "desc",
          _search: "",
          fromDate: this.$helpers.getCurrentDate(-1000, "d"),
          toDate: this.$helpers.getCurrentDate(0, "d"),
          type: 0,
        })
        .then((response) => {
          this.transactions = response.data;
        })
        .catch(() => {});
    },
    showTransactionModal(transaction) {
      this.oneTransaction = transaction;
      this.$bvModal.show("transaction-modal");
    },
    loadData() {
      this.$store
        .dispatch("financialDashboard/GET_ACCOUNTANT_DASHBOARD")
        .then((response) => {
          this.allNumbers = response.data;
        });
    },
    getChartData() {
      this.$store
        .dispatch("financialDashboard/GET_EXPENSE_CHART", {
          year: this.selectedYear,
          expenseType: this.selectedExpenseType,
        })
        .then((response) => {
          let data = [];
          let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          for (let i = 0; i < months.length; i++) {
            let check = false;
            for (let j = 0; j < response.data.length; j++) {
              if (response.data[j].month === months[i]) {
                data.push({
                  month: months[i],
                  amount: response.data[j].amount,
                });
                check = true;
                break;
              }
            }
            if (!check) {
              data.push({
                month: months[i],
                amount: 0,
              });
            }
          }
          this.chartData = this.$helpers.pluck(data, "amount");
        });
    },
    expenseTypeSelected(item) {
      this.selectedExpenseType = item;
      this.getChartData();
    },
    generateYear() {
      let currentYear = new Date().getFullYear();
      return [
        currentYear - 5,
        currentYear - 4,
        currentYear - 3,
        currentYear - 2,
        currentYear - 1,
        currentYear,
      ];
    },
    getUpcomingPayments() {
      let fromDate = this.$helpers.getCurrentDate(0, "d");
      let toDate = this.$helpers.getCurrentDate(20, "d");
      this.$store
        .dispatch("dashboard/GET_UPCOMING_PAYMENTS", {
          fromDate,
          toDate,
          serverParams: {
            _start: 0,
            _end: this.next10DaysLoadMore,
            _sort: "date",
            _order: "desc",
            _search: "",
          },
        })
        .then((response) => {
          this.upcomingPayments = response.data.slice(0, 10);
        });
    },
    getPassedPayments() {
      this.$store
        .dispatch("dashboard/GET_PASSED_PAYMENT", { _end: 10 })
        .then((response) => {
          this.passedPayments = response.data.slice(0, 10);
        });
    },
    getUnpaidContracts() {
      let serverParams = {
        _start: 0,
        _end: 10,
        _sort: "id",
        _order: "desc",
        _search: "",
        custom_params: {
          unpaid: true,
        },
      };
      this.$store
        .dispatch("buyContracts/GET_ALL", serverParams)
        .then((response) => {
          this.unpaidContracts = response.data;
        });
    },
    getInvestors() {
      let serverParams = {
        _start: 0,
        _end: 30000000,
        _sort: "id",
        _order: "desc",
        _search: "",
        custom_params: {
          unpaid: true,
        },
      };
      this.$store
        .dispatch("investors/GET_ALL", serverParams)
        .then((response) => {
          this.investors = response.data;
          this.sumPaidPrice = this.investors.reduce((sum, investor) => {
            return sum + investor.mcPaidPrice;
          }, 0);
          this.sumNeedToPay = this.investors.reduce((sum, investor) => {
            return sum + investor.mcNeedToPay;
          }, 0);
          this.sumMeter = this.investors.reduce((sum, investor) => {
            return sum + investor.meter;
          }, 0);
        });
    },
  },
};
</script>

<style>
.bg-red-g {
  background: rgb(250, 68, 12);
  background: linear-gradient(
    313deg,
    rgb(219, 59, 11) 0%,
    rgb(175, 4, 15) 100%
  );
}
.bg-blue-g {
  background: linear-gradient(
    313deg,
    rgba(46, 149, 210, 1) 0%,
    rgba(1, 92, 146, 1) 100%
  );
}
.bg-green-g {
  background: linear-gradient(
    313deg,
    rgba(40, 198, 100, 1) 0%,
    rgba(17, 128, 59, 1) 100%
  );
}
.bg-gray-g {
  background: linear-gradient(
    313deg,
    rgba(121, 120, 120, 1) 0%,
    rgb(55, 55, 55) 100%
  );
}
.bg-purple-g {
  background: linear-gradient(
    313deg,
    rgba(124, 73, 177, 1) 0%,
    rgba(85, 37, 134, 1) 100%
  );
}
.bg-yellow-g {
  background: linear-gradient(
    313deg,
    rgba(190, 172, 0, 1) 0%,
    rgba(152, 109, 0, 1) 100%
  );
}
.bg-info-g {
  background: linear-gradient(
    313deg,
    rgb(7, 213, 202) 0%,
    rgb(10, 176, 154) 100%
  );
}

.asLink {
  cursor: pointer;
  color: #0a53be;
}
.badgeWidth {
  width: 69px !important;
}

.hr-text {
  color: #818078;
  line-height: 2em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: "";

    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #f5f6fa;
  }
}
</style>
