<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right" :class="{'required-field': required}">{{ name }}</label>
    <div :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}">
      <multiselect
          v-if="!isLoaded"
          :id="id"
          v-model="selectedOptions"
          :options="options"
          :placeholder="$t('pleaseSelect')"
          :multiple="multiple"
          :loading="isLoading"
          :allow-empty="!required"
          select-label=""
          :custom-label="customLabel"
          @open="onOpen"
          @select="itemSelected"
          @search-change="asyncFind"
          @remove="clearSelected"
          open-direction="bottom"
          :hide-selected="true"
          :internal-search="false"
          :clear-on-select="false"
      >
        <template slot="tag" slot-scope="{ option }">
          <span class="badge bg-secondary mr-1" style="font-size: 0.9rem">
            <span style="display: block;text-align: start">{{ option[label] }}</span>
            <span class="custom__remove" style="cursor: pointer;" @click="deleteSelect(option)">❌</span>
          </span>
        </template>
        <template slot="clear" slot-scope="props">
          <div :class="showClearButton ? 'multiselect__clear' : ''" id="clearSelected" v-if="items.length" @click="clearSelected(props.search)"></div>
        </template>
        <span slot="noOptions" style="text-align: start !important;display: block">{{ $t('listIsEmpty') }}</span>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {serverParams} from "@/plugins/constants";
import lodash from "lodash";

export default {
  name: "SelectInput",
  props: {
    id: {
      type: String
    },
    name: {},
    label: {
      type: String
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {},
    apiLink: {},
    apiParams: {},
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isLoaded: {
      type: Boolean
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    showClearButton: {
      type: Boolean,
      default: true
    }
  },
  components: { Multiselect },
  watch: {
    defaultValue: async function (value) {
      this.resetServerParams();
      await this.loadData();
      if (this.multiple) {
        for (let i = 0; i < value.length; i++) {
          this.selectedOptions.push(value[i]);
          this.items.push(value[i].id);
        }
      } else {
        let sel = lodash.find(this.options, {id: value});
        this.selectedOptions.push(sel);
        this.itemSelected(sel);
      }
    }
  },
  data() {
    return {
      items: [],
      selectedOptions: [],
      options: [],
      isLoading: false
    }
  },
  mounted() {
    this.resetServerParams();
    if (typeof this.apiLink === 'object') {
      this.options = this.apiLink;
      this.selectedOptions.push(this.defaultValue);
      this.itemSelected(this.defaultValue);
    }
  },
  methods: {
    async loadData() {
      if (typeof this.apiLink !== 'object') {
        await this.$store.dispatch(this.apiLink, serverParams)
            .then((response) => {
              if (this.multiple) {
                this.options = response.data.filter(o1 => !this.items.some(o2 => o1.id === o2));
              } else {
                this.options = lodash.filter(response.data, (x) => {
                  return !this.items.includes(x.id)
                })
              }
              this.isLoading = false
            })
            .catch(() => this.isLoading = false);
      } else {
        this.options = await this.apiLink;
      }
    },
    customLabel(data) {
      let label = this.label;
      let splitArray = label.split(',');
      let returnString = '';
      splitArray.map((x) => {
        if (!x.includes('.')) {
          if (!x.includes('+')) {
            returnString += data[x] + ' ';
          } else {
            let split = data[x.replace('+', '')];
            if (typeof split === 'number') {
              returnString += '(' + split.toLocaleString() + ')';
            } else {
              returnString += '(' + data[x.replace('+', '')] + ')';
            }
          }
        } else {
          let relationName = x.split('.')
          returnString += ' | ' + data[relationName[0]][relationName[1]]
        }
      });
      return returnString;
    },
    asyncFind(query) {
      this.isLoading        = true;
      this.resetServerParams();
      serverParams._start   = 0;
      serverParams._end     = 50;
      serverParams._search  = query;
      if (this.apiParams !== '' && this.apiParams !== undefined) {
        serverParams.custom_params = this.apiParams;
      } else {
        serverParams.custom_params = ''
      }
      this.loadData()
    },
    onOpen() {
      this.resetServerParams();
      this.loadData();
    },
    itemSelected(selectedOption) {
      if (selectedOption !== undefined && selectedOption !== '' && selectedOption !== null) {
        this.items.push(selectedOption.id ?? selectedOption);
        if (!this.multiple) {
          this.$emit('itemSelected', this.returnObject ? selectedOption : lodash.last(this.items) ?? null)
        } else {
          this.$emit('itemSelected', this.items ?? [])
        }
      }
    },
    resetServerParams() {
      serverParams._start   = 0;
      serverParams._end     = 50;
      serverParams._sort    = 'id';
      serverParams._order   = 'DESC';
      serverParams._search  = '';
      if (this.apiParams !== '' && this.apiParams !== undefined) {
        serverParams.custom_params = this.apiParams;
      } else {
        serverParams.custom_params = ''
      }
    },
    deleteSelect(option) {
      this.items.splice(this.items.findIndex(x => x === option.id), 1);
      this.selectedOptions.splice(this.selectedOptions.findIndex(x => x.id === option.id), 1);
      if (!this.multiple) {
        this.$emit('itemSelected', lodash.last(this.items) ?? null)
      } else {
        this.$emit('itemSelected', this.items ?? [])
      }
    },
    clearSelected() {
      this.items = []
      this.selectedOptions = []
    }
  }
}
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__clear {
  position: absolute;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.multiselect__clear:after, .multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
  cursor: pointer;
}

.multiselect__clear:before {
  transform: rotate(45deg);
}

.multiselect__clear:after {
  transform: rotate(-45deg);
}
</style>
