import {i18n} from "@/plugins/i18n";
import mixins from "@/mixins";

export class LeadVisit {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'leadCustomerName',
            i18n.t('leadCustomer'),
            true
        ],
        [
            'leadCustomerPhone',
            i18n.t('leadCustomerPhone'),
            true
        ],
        [
            'visitDate',
            i18n.t('visitDate'),
            true
        ],
        [
            'note',
            i18n.t('note'),
            true
        ],
        [
            'newState',
            i18n.t('newState'),
            true
        ],
        [
            'oldState',
            i18n.t('oldState'),
            true
        ],
        [
            'males',
            i18n.t('males'),
            true
        ],
        [
            'females',
            i18n.t('females'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: i18n.t('id'),
                name: 'id',
                sort: false,
                visibility: mixins.projectTenant() !== 'MassHills',
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
