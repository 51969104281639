<template>
  <div id="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <!-- <b-row class="text-center" style="margin: 0; font-size: 28px">
        <img
          height="250px"
          src="../../../assets/images/ZaitonDiscountHeader.png"
          alt=""
        />
      </b-row> -->
      <br />
      <br />
      <br />
      <b-row>


        <p style="text-align: right; font-size: 20px; margin-right: 100px;">
          بەروار: {{ contractDate }}
        </p>
      </b-row>

      <br /><br /><br /><br /><br />

      <h3 style="line-height: 70px; text-align: justify;" class="text-center;">  
        
        ڤیلاى ژماره‌ 
        ( {{ propertyName }} ) 
        فروشراوه‌ بو به‌رێز 
        ( {{ customerName }} ) 
        به‌ پێ گرێبه‌ستی واژووكراوى ژماره‌
         ( {{ contractNumber }} ) 
         له‌ به‌روارى
         ( {{ contractDate }} )
         .واته‌ كوى گشتى پاره‌ى ڤیللا بریتیه‌ له‌
         ($ {{ parseFloat(contractTotalPrice).toLocaleString() }})
         {{ buyingPriceText }}
         , وبه‌ڵام كومپانیا دیاریه‌ك به‌ برى  گوژمه‌ى
         ($ {{ parseFloat(discount).toLocaleString() }} )
         {{ discountPriceText }}
         هه‌ژماركرده‌ووه‌ له‌ قستی كۆتاى
      </h3>
      
      <br /><br /><br /><br /><br />
      <h3 >كۆمپانیاى شجره‌ الزیتون</h3>
    </div>
  </div>
</template>


<script>
import { HTTP } from "@/plugins/axios";

export default {
  name: "KurdishZaitonPlusCityDiscountReceipt",
  props: {
    propertyName: {
      type: String,
    },
    customerName: {
      type: String,
    },
    contractNumber: {
      type: String,
    },
    contractDate: {
      type: String,
    },
    contractTotalPrice: {
      type: String,
    },
    discount: {
      type: String,
    },
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      buyingPriceText: 0,
      discountPriceText: 0,
    };
  },
  async mounted(){
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    if(parseFloat(this.contractTotalPrice) > 0){
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.contractTotalPrice}`
    )
      .then((resp) => {
        this.buyingPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    }
if(parseFloat(this.discount) > 0){
      await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.discount}`
    )
      .then((resp) => {
        this.discountPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    }

  },
};
</script>
