import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const leadSubSources = {
    namespaced: true,
    state: {
        leadSubSources: {},
        createdLeadSubSource: {},
        getOneLeadSubSource: {},
        updatedLeadSubSource: {},
    },
    mutations: {
        SET_LEAD_SUB_SOURCES (state, value) { state.leadSubSources = value },
        SET_CREATED_LEAD_SUB_SOURCE (state, value) {state.createdLeadSubSource = value},
        SET_GET_ONE_LEAD_SUB_SOURCE (state, value) {state.getOneLeadSubSource = value},
        SET_UPDATED_LEAD_SUB_SOURCE (state, value) {state.updatedLeadSubSource = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/LeadSubSources` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_LEAD_SUB_SOURCES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LEAD_SUB_SOURCES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/LeadSubSources/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_LEAD_SUB_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_LEAD_SUB_SOURCE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.post(`/LeadSubSources`, form)
                    .then((response) => {
                        commit('SET_CREATED_LEAD_SUB_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_LEAD_SUB_SOURCE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls;
                HTTP.put(`/LeadSubSources/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_LEAD_SUB_SOURCE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_LEAD_SUB_SOURCE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/LeadSubSources/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        leadSubSources: state => state.leadSubSources,
        createdLeadSubSource: state => state.createdLeadSubSource,
        getOneLeadSubSource: state => state.getOneLeadSubSource,
        updatedLeadSubSource: state => state.updatedLeadSubSource,
    }
}

export default leadSubSources;
