<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row class="text-center" style="margin: 0;font-size: 28px;border-bottom: 3px solid #B58151">
        <b-col cols="4">
          <h2 class="text-left text-bold pt-4">پڕۆژەی کاندڵ ریزدینس</h2>
          <h2 class="text-left text-bold">مشروع کاندل السکنی</h2>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-img :src="systemImage" fluid rounded="circle" alt="Responsive image" style="width: 65%;"></b-img>
        </b-col>
        <b-col cols="5" class="p-2 pt-1 text-right" style="text-align: end">
          <h5 class="text-bold">law@investincandle.com <i class="fal fa-envelope" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">sales@investincandle.com <i class="fal fa-envelope" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">07504211100 - 07504211122 <i class="fal fa-phone fa-rotate-270" style="color: #9E7F49"></i></h5>
          <h5 class="text-bold">Iraq - Erbil - Gulan Str, A.B. Center, Floor 2 <i class="fal fa-map-marker" style="color: #9E7F49"></i></h5>
        </b-col>
      </b-row>
      <b-row class="m-2" style="margin: 0;font-size: 20px">
        <b-col class="text-center" style="font-size: 25px" cols="8">
          <h3 class="text-center text-bold mt-3">پسوولەی پارە وەرگرتن</h3>
          <h3 class="text-center text-bold mt-3">قبض - Deposit</h3>
        </b-col>
        <b-col cols="4">
          <h5>
            <span class="text-bold">ژمـــــارە:&nbsp;</span>
            <span class="underLine width-200">{{ voucherNo && voucherNo }}</span>
          </h5>
          <h5>
            <span class="text-bold">کــــــــات:&nbsp;</span>
            <span class="underLine width-200">{{ formatDate(datetime, 'hh:mm A') }}</span>
          </h5>
          <h5>
            <span class="text-bold">رێکەوت:&nbsp;</span>
            <span class="underLine width-200">{{ voucherDate && formatDate(voucherDate) }}</span>
          </h5>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          وەرگیرا لە
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ receivedFrom && receivedFrom }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          بڕی پارە
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700 text-bold">(${{ amount && numberFormat(amount) }}) {{ amountText }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row v-if="reason" style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          بۆ مەبەستی
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ reason !== null ? reason : '-' }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px">
        <b-col class="text-right" cols="3">
          تێبینی
        </b-col>
        <b-col cols="8" style="text-align: start">
          <span class="underLine width-700">{{ note !== null ? note : '-' }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0;font-size: 25px" class="text-center mt-5">
        <b-col cols="6">
          <strong>واژووی وەرگر</strong>
          <br />
          <strong>{{ getAuthUser() }}</strong>
        </b-col>
        <b-col cols="6">
          <strong>واژووی پێدەر</strong>
          <br />
          <strong>{{ receivedFrom }}</strong>
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #B58151;height: 0; margin: 20px 0"/>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "DepositVoucherCandle",
  props: {
    voucherNo: {},
    voucherDate: {
      type: String
    },
    receivedFrom: {
      type: String
    },
    amount: {
      type: Number
    },
    reason: {
      type: String
    },
    note: {
      type: String
    },
  },
  mounted() {
    mixins.getCurrentDateTimeServer().then((response) => {
      this.datetime = response.datetime;
    })
    mixins.getAmountInText(this.amount, 'ku').then((response) => {
      this.amountText = response;
    })
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      datetime: null,
      amountText: ''
    }
  },
}
</script>

<style>
.underLine {
  display: inline-block;
  border-bottom: 5px dotted #9E7F49;
  padding: 0 20px
}
.width-200 {
  width: 200px;
}
.width-700 {
  width: 700px;
}
</style>