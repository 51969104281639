import { i18n } from "@/plugins/i18n";

export class UpcomingPayment {
  columns = [];
  id = "id";

  fillAbles = [
    ["contractNumber", i18n.t("contractNumber"), true],
    ["mcPrice", i18n.t("mcPrice"), true],
    ["type", i18n.t("type"), true],
    ["detail", i18n.t("detail"), true],
    ["date", i18n.t("date"), false],
    ["phone", i18n.t("phone"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
