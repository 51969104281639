<template>
  <div>
    <content-header>{{ $t('settings') }}</content-header>
    <v-content>
      <b-card
          border-variant="primary"
          :header="$t('propertySettings')"
          header-bg-variant="primary"
          header-text-variant="white"
      >
        <b-row>
          <b-col>
            <b-form-group>
              <b-input-group :prepend="$t('variance')" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input type="number" :placeholder="$t('variance')" v-model="variance" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <b-input-group :prepend="$t('buildUpArea')" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input type="number" :placeholder="$t('buildUpArea')" v-model="buildUpArea" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
          border-variant="primary"
          :header="$t('contractSettings')"
          header-bg-variant="primary"
          header-text-variant="white"
      >
        <b-row>
          <b-col>
            <b-form-group>
              <b-input-group :prepend="$t('contractTopSpaces')" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input type="number" :placeholder="$t('contractTopSpaces')" v-model="contractTopSpaces" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
          border-variant="primary"
          :header="$t('safeBoxSettings')"
          header-bg-variant="primary"
          header-text-variant="white"
      >
        <b-row>
          <b-col>
            <b-form-group>
              <b-input-group :prepend="$t('defaultExpenseSafeBox')" class="mb-2 mr-sm-2 mb-sm-0">
                <select class="form-control" v-model="defaultExpenseSafeBox">
                  <option v-for="safeBox in safeBoxes" :key="safeBox.id" :value="safeBox.id">{{ safeBox.name }}</option>
                </select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../components/Content";
import ContentHeader from "../components/ContentHeader";
import {serverParams} from "@/plugins/constants";

export default {
  name: "Settings",
  components: {'v-content': Content, ContentHeader},
  data() {
    return {
      variance: localStorage.getItem('variance') ?? 10,
      buildUpArea: localStorage.getItem('buildUpArea') ?? 300,
      contractTopSpaces: localStorage.getItem('contractTopSpaces') ?? 200,
      safeBoxes: [],
      serverParams: serverParams,
      defaultExpenseSafeBox: localStorage.getItem('defaultExpenseSafeBox') ?? 1,
    }
  },
  mounted() {
    this.getSafeBoxes();
  },
  watch: {
    variance: (value) => {
      localStorage.setItem('variance', value)
    },
    buildUpArea: (value) => {
      localStorage.setItem('buildUpArea', value)
    },
    contractTopSpaces: (value) => {
      localStorage.setItem('contractTopSpaces', value)
    },
    defaultExpenseSafeBox: (value) => {
      localStorage.setItem('defaultExpenseSafeBox', value)
    },
  },
  methods: {
    getSafeBoxes() {
      this.$store.dispatch('safeBoxes/GET_ALL', this.serverParams)
          .then((response) => {
            this.safeBoxes = response.data;
          })
          .catch(() => {});
    },
  }
}
</script>
