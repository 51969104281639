export default {
  // Start Generals
  kurdish: "کوردی",
  kurdisbadini: "کوردی بادینی",
  arabic: "عربی",
  english: "English",
  language: "زمان",
  controls: "دەستکاری",
  dashboard: "داشبۆرد",
  kurdishName: "ناوی کوردی",
  arabicName: "ناوی عەرەبی",
  englishName: "ناوی ئینگلیزی",
  isoCode: "کۆدی نێودەوڵەتی",
  postalCode: "پۆستال كۆد",
  city: "شار",
  noResultsFound: "هیچ ئەنجامێک نەدۆزرایەوە",
  totalRecords: "کۆی تۆمارەکان",
  save: "پاشەکەوت بکە",
  yes: "بەڵێ",
  no: "نەخێر",
  delete: "سڕینەوو",
  someThingWentWrong: "هەڵەیەک ڕوویدا",
  settings: "ڕێکخستنەکان",
  listIsEmpty: "لیستەکە بەتاڵە",
  id: "ناسنامە",
  addedSuccessfully: "بەسەرکەوتوویی زیادکرا",
  // End Generals

  // Start Custom Components
  isRequired: "داواکراوە",
  remove: "سڕینەوە",
  fileIsTooBig: "قەبارەی فایلەکە زۆرە",
  typeNotSupported: "جۆر پشتگیری نەکراوە",
  maxFileUploadReach: "فایلەکان زۆرە",
  pleaseSelect: "تکایە هەڵبژێرە",
  mustHaveAtLeast: "دەبێت لانی کەم هەبێت",
  letters: "پیت",
  mustNotHaveGreaterThen: "نابێت گەورەتر بێت",
  attachments: "هاوپێچەکان",
  close: "داخستن",
  search: "گەڕان",
  multipleDelete: "فرە سڕینەوە",
  multipleRestore: "فرە گەڕاندنەوە",
  multipleForceDelete: "فرە سڕینەوەی هەتایی",
  export: "ناردن",
  noRowsSelected: "هیچ ڕیزێک دەستنیشان نەکراوە",
  youWantToDeletePermanentlySelectedRows:
    "دەتەوێت ڕیزە دیاریکراوە  بۆ هەمیشەییەکان بسڕیتەوە",
  youWantToRestorePermanentlySelectedRows:
    "دەتەوێت ڕیزە دیاریکراوە بۆ هەمیشەییەکان بگەڕێنیتەوە",
  yesDelete: "بەڵێ، بسڕەوە",
  yesRestore: "بەڵێ، بگەڕێنەوە",
  yourDataHasBeenDeletedPermanently: "داتاکەت بە هەمیشەیی سڕاوەتەوە",
  yourDataHasBeenRestoredPermanently: "داتاکەت بە هەمیشەیی گەڕاوەتەوە",
  // End Custom Components

  // Start Routes
  editProperties: "دەستکاری موڵکەکان",
  createCustomer: "دروستکردنی کڕیار",
  editCustomer: "دەستکاری کڕیار",
  createExpenseAttachment: "دروستکردنی هاوپێچی خەرجی",
  createPropertyFollowUp: "دروستکردنی شوێنپێدانی موڵک",
  editLeadSubSource: "بژارکردنی سەرچاوەی لاوەکی سەرەکی",
  buyContractCreate: "دروستکردنی گرێبەستی کڕین",
  showBuyContracts: "پیشاندانی گرێبەستی کڕین",
  voucherReceipt: "پسووڵە",
  holds: "ڕاگیراو",
  // End Routes

  // Start 404
  pageNotFound: "پەیج نەدۆزراوە",
  oopsThePageYouWereLookingForDoesntExist:
    "ببورە ئەو پەیجەی لەدوای دەگەڕێی نەدۆزراوە",
  backToDashboard: "گەڕانەوە بۆ داشبۆرد",
  notFound: "نەدۆزراوە",
  // End 404

  // Start Login
  username: "ناوی بەکارهێنەر",
  password: "ووشەی نهێنی",
  login: "چوونەژوورەوە",
  // End Login

  // Start Sidebar
  customers: "کڕیارەکان",
  buyers: "کڕیارەکان",
  list: "لیست",
  create: "زیادکردن",
  additionalPlotArea: "ڕووبەری زیادە",
  createAndNew: "زیادکردن و نوێ",
  properties: "موڵکەکان",
  propertyHolds: "موڵکە ڕاگیراو",
  propertyTypes: "جۆرەکانی موڵک",
  villaTypes: "جۆری موڵکەکان",
  propertyExpenseTypes: "جۆرەکانی خەرجی موڵک",
  features: "تایبەتمەندیەکان",
  buyContracts: "گرێبەستی کڕین",
  transferContracts: "گڕێبەستە گواستراوەکان",
  cancelContracts: "گرێبەستە هەڵوەشێندراوەکان",
  isCash: "بە کاش؟",
  leadSources: "سەرچاوە سەرەکیەکان",
  leadSubSources: "سەرچاوە لاوەکیەکان",
  leadCustomers: "سەرچاوەی کڕیارەکان",
  leadVisits: "سەرچاوەی سەردانەکان",
  expenses: "خەرجیەکان",
  expenseTypes: "جۆرەکانی خەرجی",
  reports: "ڕاپۆرتەکان",
  leadReports: "راپۆرتەی سەرچاوەکان",
  transactions: "مامەڵەکان",
  logout: "چوونەدەرەوە",
  initials: "سەرەتایەکان",
  contracts: "گرێبەستەکان",
  leads: "سەرچاوەکان",
  createLeadVisits: "زیادکردنی سەرچاوەی سەردان",
  createLeadCustomers: "زیادکردنی سەرچاوەی کڕیار",
  accounting: "ژمێریاری",
  // End Sidebar

  // Start Main Dashboard
  mainDashboard: "داشبۆردی سەرەکی",
  projectDashboard: "داشبۆردی پڕۆژەکان",
  totalProperties: "کۆی موڵکەکان",
  totalExpenses: "کۆی خەرجیەکان",
  remainingPayableAmount: "پاشماوەی بڕی پارەپێدراو",
  // End Main Dashboard

  // Start Dashboard
  todayVisits: "سەردانەکانی ئەمڕۆ",
  totalVisits: "کۆی سەردانەکان",
  todayContracts: "گرێبەستەکانی ئەمڕۆ",
  totalContracts: "کۆی گرێبەستەکان",
  todaySummary: "پوختەی ئەمڕۆ",
  generalData: "داتای گشتی",
  invoiceNumber: "ژمارەی پسوولە",
  referenceNumber: "ژمارەی پسوولە",
  withdraw: "ڕاکێشان",
  reason: "هۆکار",
  expiredPropertyHolds: "موڵکە ڕاگیراوە بەسەرچووەکان",
  fromDate: "لە بەرواری",
  toDate: "بۆ بەرواری",
  upcomingPayments: "پارەدانەکانی داهاتوو",
  upcomingPaymentsList: "لیستی پارەدانەکانی داهاتوو",
  payments: "پارەدانەکان",
  next10Days: "10 ڕۆژی داهاتوو",
  next20Days: "20 ڕۆژی داهاتوو",
  delayed: "دواکەوت",
  total: "کۆی گشتی",
  remain: "ماوە",
  remain2: "ماوە",
  payableAmount: "بڕی پارە",
  sold: "فرۆشراو",
  notSold: "نە فرۆشراوەکان",
  hold: "ڕاگیراو",
  back: "گەڕانەوە",
  soldProperties: "فرۆشراوەکان",
  remainedProperties: "ماوەکان",
  onHoldProperties: "ڕاگیراوەکان",
  reset: "دووبارە ڕێک بخەوە",
  cancelledContracts: "گرێبەستە هەڵوەشێنراوەکان",
  collectedMoney: "پارە کۆکراوەکان",
  currentContractCount: "ژمارەی گرێبەستی ئێستا",
  propertyExpenses: "خەرجی موڵکەکان",
  remainPrices: "نرخی ماوەکان",
  returnedPrices: "نرخی گەڕاوەکان",
  safeBox: "حیسابی بەردەست",
  remainToCollect: "ماوە بۆ کۆکردنەوە",
  price: "نرخ",
  holdInfo: "زانیاری ڕاگیراو",
  // End Dashboard

  // Start Customers
  customersList: "لیستی کڕیارەکان",
  firstName: "ناوی یەکەم",
  middleName: "ناوی ناوەڕاست",
  lastName: "ناوی کۆتایی",
  gender: "ڕەگەز",
  birthdate: "لەدایکبوون",
  address: "ناونیشان",
  email: "ئیمەیڵ",
  phone: "تەلەفۆن",
  phone2: "تەلەفۆن 2",
  nationality: "نەتەوە",
  idCard: "کارتی ناسنامە",
  foodCard: "کارتی خۆراک",
  informationCard: "کارتی زانیاری",
  residencyCard: "کارتی نشینگە",
  placementCard: "کارتی دانانی",
  securitySupport: "پشتگیری ئاسایش",
  note: "تێبینی",
  job: "کار",
  jnsia: "جنسیە",
  fullName: "ناوی تەواو",
  male: "نێر",
  hasAccount: "ئەژماری هەیە",
  edit: "بژارکردن",
  attachment: "هاوپێچ",
  createCustomerAttachment: "دروستکردنی هاوپێچی کڕیار",
  sharable: "هاوبەشکراوە",
  is360: "ئایا 360",
  title: "ناونیشان",
  shareableForCustomer: "هاوبەشکراو بۆ کڕیار",
  share: "هاوبەشکردن",
  deletedSuccessfully: "بە سەرکەوتوویی سڕاوە",
  // End Customers

  // Start Properties
  createProperties: "زیادکردنی موڵک",
  villaNumber: "ژمارەی ڤیلا",
  propertyNumber: "ژمارەی موڵک",
  villaType: "جۆری ڤیلا",
  name: "ناو",
  floor: "نهۆم",
  priceMeter: "نرخی یەک مەتر",
  additionalInfo: "زانیاری زیاتر",
  zone: "ناوچە",
  tower: "تاوەر",
  extraProperty1: "ڕووکار",
  extraProperty2: "ڕوو",
  apartmentType: "جۆری شوقە",
  apartment: "شوقە",
  apartmentPrice: "نرخی شوقە",
  apartmentNo: "ژمارەی ئەپارتمانت",
  block: "بلۆک",
  streetNumber: "ژمارەی شەقام",
  streetWidth: "پانی شەقام",
  rooms: "ژوورەکان",
  floors: "نهۆمەکان",
  realEstateAgent: "بریکاری خانووبەرە",
  propertyType: "جۆری موڵک",
  plotArea: "ڕووبەری نەخشە",
  buildUpArea: "ڕووبەری دروستکراو",
  holdArea: "ڕووبەری هەڵگیراو",
  soldArea: "ڕووبەری فرۆشراو",
  meterPrice: "نرخی مەتر",
  amount: "بڕ",
  area: "ڕووبەر",
  variance: "جیاوازی ڕووبەر",
  extraCost: "تێچووی زیادە",
  initialPrice: "نرخی سەرەتا",
  feature: "تایبەتمەندی",
  finalPrice: "نرخی کۆتایی",
  numberOfRooms: "ژمارەی ژوورەکان",
  followUp: "شوێن کەوتن",
  createPropertyAttachment: "دروستکردنی هاوپێچی موڵک",
  date: "بەروار",
  deadline: "دوا وادە",
  done: "کراوە",
  propertiesList: "لیستی موڵکەکان",
  area2: "ڕوبەر",
  meterPrice2: "نرخی مەتر",
  meterPriceAfterDiscount: "س.م.خ",
  buyPriceAfterDiscount: "س.ب.خ.",
  totalPaidPrice: "م.م.ك",
  purchase2: "كرين",
  // End Properties

  // Start Property Hold
  propertyName: "ناوی موڵک",
  holdPayment: "پارەی هەڵگیراو",
  propertyHoldsList: "لیستی موڵکە هەڵواسراوەکان",
  createPropertyHold: "دروستکردنی موڵکی هەڵواسراو",
  showPropertyHold: "پیشاندانی موڵکی هەڵواسراو",
  property: "موڵک",
  transferred: "گواستراوەتەوە",
  cancelled: "هەڵوەشایەوە",
  isCustomer: "ئایا کڕیارە؟",
  payType: "جۆری پارەدان",
  bankName: "ناوی بانک",
  bankSlipNumber: "ژمارەی پسووڵە بانک",
  bankSlipDate: "بەرواری پسووڵە بانک",
  bankSlipNote: "تێبینی پسووڵە بانک",
  isBankPayment: "ئایا پارەدانی بانکە؟",
  bankSlipAttachment: "هاوپێچی پسووڵە بانک",
  editPropertyHold:"گۆڕانکاری موڵکی ڕاگیراو",
  // End Property Hold

  // Start Property Type
  propertyTypesList: "لیستی جۆری موڵک",
  createPropertyType: "دروستکردنی جۆری موڵک",
  editPropertyType: "گۆڕینی جۆری موڵک",
  // End Property Type

  // Start Feature
  extraPrice: "نرخی زیادە",
  featuresList: "لیستی تایبەتمەندیەکان",
  createFeature: "دروستکردنی تایبەتمەندی",
  editFeature: "دەستکاری تایبەتمەندی",
  // End Feature

  // Start Buy Contract
  contractNumber: "ژمارەی گرێبەست",
  contractNumber2: "ژمارە",
  customerName: "ناوی کڕیار",
  buyingDate: "بەرواری کڕین",
  buyingPrice: "نرخی کڕین",
  buyContractsList: "لیستی گرێبەستەکانی کڕین",
  createBuyContract: "دروستکردنی گرێبەستی کڕین",
  street: "شەقام",
  propertyPrice: "نرخی موڵک",
  installments: "قیستەکان",
  noOfInstallments: "ژمارەی قیستەکان",
  periodOfInstallments: "ماوەی قیستەکان",
  installment: "قیست",
  percentage: "ڕێژەی سەدی",
  percentageOfInstallments: "ڕێژەی سەدی قیستەکان",
  prepayment: "پارەدان",
  percent: "ڕێژە",
  paidPrice: "پارەدراوەکان",
  deliveringKeys: "وەرگرتنی کلیل",
  discount: "داشکاندن",
  showDiscount: "پیشاندانی داشکان",
  pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice:
    "تکایە قیستەکان پێدابچۆوە چونکە یەکسان نیە لەگەڵ نرخی سەرەتای موڵک",
  showContract: "پیشاندانی گرێبەست",
  print: "چاپکردن",
  withExpense: "خەرجی لەگەڵە",
  printKurdishContract: "چاپکردنی گرێبەستی کڕینی کوردی",
  printArabicContract: "چاپکردنی گرێبەستی کڕینی عەرەبی",
  printArabicContractIQD: "چاپکردنی گرێبەستی کڕینی عەرەبی بە دینار",
  isPaid: "پارە دراوە",
  action: "کردار",
  paidBy: "دراوە لەلایەن",
  pay: "پارەدان",
  partialPaymentPrice: "نرخی بەشێکی پارە",
  partialPaymentNote: "تێبینی بەشێکی پارە",
  partialPaymentDate: "بەرواری بەشێکی پارە",
  partialPaymentDeadline: "کۆتا بەرواری بەشێکی پارە",
  extraNote: "تێبینی زیاتر",
  partialPay: "پارەدانی بەشێک",
  areYouSure: "ئایا دڵنیای؟",
  youWantToMakePayment: "دەتەوێ پارەدانەکە بکەی",
  noCancel: "نەخێر",
  paymentSuccessfullyDone: "پارەدان بە سەرکەوتوویی ئەنجام درا",
  payment: "پارەدان",
  printPayments: "چاپکردنی پارەدانەکان",
  transferContract: "گواستنەوەی گرێبەست",
  customer: "کڕیار",
  cancelContract: "هەڵوەشاندنەوەی گرێبەست",
  changeInstallments: "گۆڕینی قستەکان",
  difference: "جیاوازی",
  addAttachments: "زیادکردنی هاوپێچ",
  paymentToTransfer: "گواستنەوەی پارەدان",
  transferPayment: "گواستنەوەی پارەدان",
  paymentPriceAfter: "پارەدان دوای گۆڕین",
  discountAr: "داشكان ع",
  discountKu: "داشكان ك",
  // End Buy Contract

  // Start Lead Sources
  leadSourcesList: "لیستی سەرچاوەکان",
  createLeadSource: "دروستکردنی سەرچاوە",
  editLeadSource: "دەستکاری کردنی سەرچاوە",
  // End Lead Sources

  // Start Lead Sub Sources
  leadSourceName: "ناوی سەرچاوە",
  leadSubSourcesList: "لیستی سەرچاوە لاوەکیەکان",
  createLeadSubSource: "دروستکردنی سەرچاوەی لاوەکی",
  leadSource: "سەرچاوە",
  editSubLeadSource: "دەستکاری سەرچاوەی لاوەکی",
  // End Lead Sub Sources

  // Start Lead Customers
  isMale: "نێر؟",
  leadSubSourceName: "ناوی سەرچاوەی لاوەکی",
  state: "بارودۆخ",
  males: "نێرەکان",
  females: "مێیەکان",
  visitNote: "تێبینی سەردانی",
  leadCustomersList: "لیستی کڕیارە پێشبینیکراوەکان",
  createLeadCustomer: "دروستکردنی کڕیاری پێشبینیکراوە",
  female: "مێ",
  leadSubSource: "سەرچاوەی لاوەکی پێشبینیکراو",
  customerNote: "تێبینی کڕیار",
  editLeadCustomer: "دەستکاری کڕیاری چاوەڕوانکراو",
  // End Lead Customers

  // Start Lead Visits
  leadCustomer: "کڕیاری پێشبینیکراو",
  leadCustomerPhone: "ژمارە مۆبایلی کڕیاری پێشبینیکراو",
  newState: "بارودۆخی نوێ",
  oldState: "بارودۆخی کۆن",
  visitDate: "بەرواری سەردان",
  leadVisitsList: "لیستی سەردانی پێشبینیکراو",
  createLeadVisit: "دروستکردنی سەردانی پێشبینیکراو",
  // End Lead Visits

  // Start Expenses
  expenseType: "جۆری خەرجی",
  mcPrice: "نرخ",
  qty: "بڕ",
  mcTotalPrice: "کۆی نرخ",
  expenseList: "لیستی خەرجی",
  createExpense: "دروستکردنی خەرجی",
  editExpense: "دەستکاری خەرجی",
  safeBoxBalanceError: "خەرجی زیاترە لە بەڵانسی بەردەست",
  notEnoughMoney: "پارەی بەردەست نیە",
  yourCurrentSafeBoxBalanceLowerThanExpense:
    "پارەی بەردەست لە قاصە کەمترە لە خەرجی",
  safeBoxBalance: "بڕی پارەی بەردەست",
  expense: "خەرجی",
  areYouSureToContinue: "دڵنیای لە زیادکردنی ئەم خەرجیە؟",
  // End Expenses

  // Start Expense Types
  expenseTypeList: "لیستی جۆری خەرجی",
  createExpenseType: "دروستکردنی جۆری خەرجی",
  description: "وەسف",
  editExpenseType: "دەستکاری جۆری خەرجی",
  // End Expense Types

  // Start Lead Reports
  selectDate: "بەروار هەڵبژێرە",
  leadVisitByDayOfWeek: "سەردانی پێشبینیکراو بە پێی ڕۆژی هەفتە",
  dailyLeadVisits: "سەردانی پێشبینیکراوی ڕۆژانە",
  perSources: "بۆ هەر سەرچاوەکان",
  women: "مێ",
  men: "نێر",
  day: "ڕۆژ",
  noOfGroups: "ژمارەی گروپەکان",
  visitsByDate: "سەردانیەکان بەگوێرەی بەروار",
  type: "جۆر",
  detail: "وردەکاری",
  // End Expense Types

  // Start Transfer Contracts
  transferContractList: "لیستی گرێبەستە گواستراوەکان",
  fromCustomer: "لە کڕیار",
  toCustomer: "بۆ کڕیار",
  oldContract: "گرێبەستی کۆن",
  newContract: "گرێبەستی نوێ",
  showTransferContract: "پیشاندانی گرێبەستی گواستراوە",
  printArabic: "چاپی عربي",
  printKurdish: "چاپی کوردی",
  printArabicOrder: "چاپی عربي (داواکردن)",
  printKurdishOrder: "چاپی کوردی(داواکردن)",
  // End Transfer Contracts

  // Start Cancel Contracts
  cancelContractList: "لیستی گرێبەستە هەڵوەشاوەکان",
  returnedPrice: "نرخی گەڕاو",
  remainPrice: "نرخی ماوە",
  buyContract: "گرێبەستی کڕین",
  showCancelContract: "پیشاندانی گرێبەستی هەڵوەشاوە",
  // End Cancel Contracts

  // Start Property Expense Types
  propertyExpenseTypesList: "لیستی جۆری خەرجی موڵک",
  createPropertyExpenseType: "زیادکردنی جۆری خەرجی موڵک",
  editPropertyExpenseType: "دەستکاریکردنی جۆری خەرجی موڵک",
  propertyExpenseType: "جۆری خەرجی موڵک",
  // End Property Expense Types

  // Start Property Report
  propertyReport: "ڕاپۆرتی موڵکەکان",
  partialPaymentReport: "راپۆرتی پارەی بەش",
  // End Property Report

  // Start Expense By Type
  expensesByType: "خەرجی بەگوێرەی جۆر",
  // End Expense By Type

  // Start Settings
  propertySettings: "ڕێکخستنەکانی موڵک",
  contractSettings: "ڕێکخستنەکانی گرێبەست",
  contractTopSpaces: "بۆشایی سەرەوەی گرێنەست",
  safeBoxSettings: "ڕێکخستنەکانی قاصە",
  defaultExpenseSafeBox: "قاصەی سەرەکی خەرجی",
  // End Settings

  // Start Projects
  logo: "لۆگۆ",
  projectsList: "لیستی پڕۆژەکان",
  editProject: "دەستکاریکردنی پرۆژە",
  // End Projects

  // Start Users
  users: "بەکارهێنەران",
  usersList: "لیستی بەکارهێنەران",
  phoneNumber: "ژمارە مۆبایل",
  showUser: "پیشاندانی بەکارهێنەر",
  project: "پڕۆژە",
  projects: "پڕۆژەکان",
  addProjectToUser: "زیادکردنی پڕۆژە بۆ بەکارهێنەر",
  addProjectToCustomer: "زیادکردنی پڕۆژە بۆ کڕیار",
  addToProject: "زیادکردنی بۆ پڕۆژە",
  // End Users

  // Start Permissions
  permissions: "مۆڵەتەکان",
  permissionsList: "لیستی مۆڵەتەکان",
  editPermission: "بژارکردنی مۆڵەت",
  permissionsSaved: "ڕێپێدانەکان هەڵگیراون",
  errorInSavingPermissions: "هەڵە لە پاشەکەوتکردنی مۆڵەتەکان",
  canPayContractPayment: "دەتوانێت پارەدانی گرێبەست بدات",
  canAddContract: "دەتوانێت گرێبەست زیاد بکات",
  canAddProperty: "دەتوانێت تایبەتمەندی زیاد بکات",
  canAddHoldProperty: "ئەتوانێت تایبەتمەندی ڕاگرتن زیاد بکات",
  canDeleteHoldProperty: "دەتوانێت تایبەتمەندی ڕاگرتن بسڕێتەوە",
  canCreateExpense: "دەتوانێت خەرجی دروست بکات",
  canViewTransaction: "ئەتوانێت مامەڵەکە ببینیت",
  canViewExpenseByTypeReport:
    "دەتوانێت تێچوونەکە بە گوێرەی راپۆرتی جۆر نیشان بدە",
  canTransferContract: "دەتوانێت گرێبەستەکە بگوازێتەوە",
  canCancelContract: "ئەتوانێت گرێبەستەکە هەڵبوەشێنێتەوە",
  // End Permissions

  // Start Profile
  accountInfo: "زانیاری بەکارهێنەر",
  profile: "پرۆفایلی کەسی",
  updateAvatar: "گۆڕینی وێنە",
  updatePassword: "گۆڕینی وشەی نهێنی",
  oldPassword: "وشەی نهێنی کۆن",
  newPassword: "وشەی نهێنی نوێ",
  confirmNewPassword: "دوپاتکردنەوەی وشەی نهێنی",
  pleaseWriteFullName: "تکایە ناوی تەواو بنووسە",
  namesMustBeMoreThan3Chars: "ناو نابێ لە ٣ پیت کەمتر بێت",
  updated: "گۆڕدرا",
  pleaseWriteAllPasswords: "تکایە هەموو وشەی نهێنیەکان بنووسە",
  passwordsAreNotMatch: "وشەی نهێنی وەکو یەک نین",
  updateInfo: "گۆڕینی زانیاری",
  // End Profile

  // Start Transactions
  totalIncomes: "کۆی داهات",
  totalOutcomes: "کۆی خەرجی",
  all: "هەموو",
  incomes: "هاتوو",
  outcomes: "ڕۆیشتوو",
  types: "جۆرەکان",
  // End Transactions

  // Start Expense Report
  expenseReport: "ڕاپۆرتی خەری",
  expensesReport: "ڕاپۆرتی خەرجیەکان",
  mcTotal: "کۆی گشتی",
  expenseCount: "ژمارەی خەرجیەکان",
  // End Expense Report

  // Start Withdraw Report
  depositReport: "ڕاپۆرتی دانان",
  depositsReport: "ڕاپۆرتی دانانەکان",
  depositCount: "ژمارەی دانانەکان",
  // End Deposit Report

  // Start Deposit Report
  withdrawReport: "ڕاپۆرتی ڕاکێشان",
  withdrawsReport: "ڕاپۆرتی ڕاکێشانەکان",
  withdrawCount: "ژمارەی ڕاکێشانەکان",
  // End Deposit Report

  // Start Property Features
  propertyFeatures: "تایبەتمەندیەکانی یەکە",
  propertyFeaturesList: "لیستی تایبەتمەندیەکانی یەکە",
  createPropertyFeature: "زیادکردنی تایبەتمەندیەکانی یەکە",
  editPropertyFeature: "گۆڕینی تایبەتمەندیەکانی یەکە",
  // End Property Features

  // Start Deposit Types
  depositTypes: "جۆرەکانی دانان",
  depositTypesList: "لیستی جۆرەکانی دانان",
  depositTypeList: "لیستی جۆرەکانی دانان",
  createDepositType: "زیادکردنی جۆری دانان",
  editDepositType: "گۆڕینی جۆری دانان",
  // End Deposit Types

  // Start Withdraw Types
  withdrawTypes: "جۆرەکانی ڕاکێشان",
  withdrawTypesList: "لیستی جۆرەکانی ڕاکێشان",
  withdrawTypeList: "لیستی جۆرەکانی ڕاکێشان",
  createWithdrawType: "زیادکردنی جۆری ڕاکێشان",
  editWithdrawType: "گۆڕینی جۆری ڕاکێشان",
  // End Withdraw Types

  // Start Deposits
  deposits: "دانانەکان",
  depositsList: "لیستی دانانەکان",
  depositList: "لیستی دانانەکان",
  createDeposit: "زیادکرنی دانان",
  editDeposit: "گۆڕینی دانان",
  depositer: "دانەر",
  accountant: "ژمێریار",
  depositType: "جۆری دانان",
  // End Deposits

  // Start Withdraws
  withdraws: "ڕاکێشان",
  withdrawsList: "لیستی ڕاکێشان",
  withdrawList: "لیستی ڕاکێشان",
  createWithdraw: "زیادکرنی ڕاکێشان",
  editWithdraw: "گۆڕینی ڕاکێشان",
  withdrawer: "ڕاکێشەر",
  withdrawType: "جۆری ڕاکێشان",
  // End Withdraws

  // Start Buy Contract Report
  buyContractReport: "ڕاپۆرتی گرێبەست",
  buyContractsReport: "ڕاپۆرتی گرێبەستەکان",
  buyContractCount: "ژمارەی گرێبەستەکان",
  // End Buy Contract Report

  // Start Buy Contract Details
  buyContractDetail: "ڕاپۆرتی گرێبەستەکان",
  buyContractsDetails: "ڕاپۆرتی گرێبەستەکان",
  unitSolds: "تەواوی گرێبەستەکان و زانیاری کڕیارەکان",
  startDateToToday: "لە بەرواری دەستپێکردنی فرۆشتنەوە تا ئەمڕۆ",
  building: "باڵەخانەکە",
  property2: "موڵك",
  paid: "دراوە",
  remaining: "ماوە",
  dateOfSale: "بەرواری فرۆشتن",
  identifier: "پێناس",
  bondNumber: "ژمارەی بۆند",
  nOfTheBatch: "ناوی پارەدان",
  amountPrice: "بڕ",
  paymentDate: "بەرواری پارەدان",
  buyerName: "ناوی کڕیار",
  page: "پەڕەی",
  of: "لەكۆی",
  hBd: "ئەنجام دراوە لەلایەن",
  priceOfProperty: "نرخی موڵک",
  statisticTitle: "ئاماری ئەم ڕاپۆرتەی نوێکراوەتەوە",
  numberOfUnits: "ژمارەی یەکەکان",
  numberOfBuyers: "ژمارەی کڕیارەکان",
  amountsRecived: "ئەو بڕەی وەرگیراون",
  remainingAmounts: "ئەو بڕەی کە ماونەتەوە",
  valueOfAllUnits: "بەهای هەموو یەکەکان",
  totalActualArea: "کۆی ڕووبەری ڕاستەقینە",
  totalArea: "ڕووبەری گشتی",
  totalAreaPlus: "ڕووبەری گشتی",
  averageSalePerMeter: "تێکڕای فرۆشتن بۆ هەر مەترێک",
  // End Buy Contract Details

  // Start Payment Process Report
  paymentProcessReport: "ڕاپۆرتی پارەدان",
  paymentProcessesReport: "ڕاپۆرتی پارەدانەکان",
  paymentProcessCount: "ژمارەی پارەدانەکان",
  paymentReport: "ڕاپۆرتی پارەدان",
  paymentsReport: "ڕاپۆرتی پارەدانەکان",
  buyPrice: "نرخی کڕین",
  DeliveryKey: "وەرگرتنی كلیل",
  Cash: "پارەدان",
  Installment: "قیستەکان",
  // End Payment Process Report

  // Start Safe Box Transaction Report
  safeBoxTransactionReport: "جوڵەی قاسە",
  safeBoxTransactionsReport: "جوڵەکانی قاسە",
  safeBoxTransactionCount: "ژمارەی جوڵەکانی قاسە",
  safeBoxTransaction: "جوڵەی قاسە",
  // End Safe Box Transaction Report

  // Start Safe Box Transfers
  transfers: "گواستنەوە",
  transfersList: "لیستی گواستنەوەکان",
  transferList: "لیستی گواستنەوەکان",
  fromSafeBox: "لە قاسە",
  toSafeBox: "بۆ قاسە",
  createTransfer: "زیادکردنی گواستنەوە",
  // end Safe Box Transfers

  // Start User Tasks
  userTasks: "ئەرکەکانی بەکارهێنەر",
  userTasksList: "لیستی ئەرکەکانی بەکارهێنەر",
  userTaskList: "لیستی ئەرکەکانی بەکارهێنەر",
  createUserTask: "دروستکردنی ئەرکی بەکارهێنەر",
  editUserTask: "بژارکردنی ئەرکی بەکارهێنەر",
  time: "کات",
  dayName: "ناوی ڕۆژ",
  taskType: "جۆری ئەرک",
  youHaveTasks: "ئەرکت هەیە",
  tasks: "ئەرکەکان",
  // End User Tasks

  // Start Financial Dashboard
  financialDashboard: "داشبۆردی دارایی",
  moreInfo: "زانیاری زیاتر",
  totalIncomeFromSell: "کۆی گشتی داهات لە فرۆشتن",
  totalDeposits: "کۆی گشتی پارەی دانراو",
  totalWithdraws: "کۆی گشتی ڕاکێشانەکان",
  defaultSafeBox: "قاصەی سەرەکی",
  otherExpenseSafeBox: "قاصەی خەرجی",
  projectExpenses: "خەرجی پڕۆژە",
  bankSafeBox: "قاصەی بانک",
  safeBoxes: "قاصەکان",
  currentMonth: "مانگی ئێستا",
  totalTillNow: "کۆوی گشتی تاکو ئێستا",
  latestDeposits: "کۆتا پارە دانانەکان",
  latestWithdraws: "کۆتا پارە ڕاکێشانەکان",
  latestExpenses: "کۆتا خەرجیەکان",
  latestTransfers: "کۆتا گواستنەوەکان",
  totalOfExpenses: "کۆی گشتی خەرجی",
  paymentsFromAgents: "پارەدانی نووسینگەکان",
  january: "مانگى كانوونى دووەم",
  february: "شوبات",
  march: "ئازار",
  april: "نیسان",
  may: "ئایار",
  june: "حوزەیران",
  july: "تەممووز",
  august: "ئاب",
  september: "ئەیلول",
  october: "تشرینی یەکەم",
  november: "تشرینی دووەم",
  december: "کانوونی یەکەم",
  passedPayments: "پارەدانی تێپەڕیوو",
  totalSells: "کۆی فرۆشتنەکان",
  unpaidContracts: "گرێبەستی پارەنەدراو",
  // End Financial Dashboard

  // Start Investors
  investors: "وەبەرهێنەران",
  investorsList: "لیستی وەبەرهێنەران",
  investorList: "لیستی وەبەرهێنەران",
  createInvestor: "دروستکردنی وەبەرهێنەر",
  editInvestor: "دەستکاری وەبەرهێنەر بکە",
  investor: "وەبەرهێنەر",
  meter: "مەتر",
  needToPay: "پێویستە پارە بدەیت",
  // End Investors

  // Start Agents
  agents: "پارەدانی نووسینگەکان",
  agentsList: "لیستی پارەدانی نووسینگەکان",
  agentList: "لیستی پارەدانی نووسینگەکان",
  createAgent: "زیادکردنی پارەدانی نووسینگە",
  editAgent: "گۆڕینی پارەدانی نووسینگە",
  agent: "پارەدانی نووسینگە",
  // End Agents

  // Start Extras
  totalExpense: "کۆی خەرجیەکان",
  totalDiscount: "کۆی داشکانەکان",
  extraPayments: "تێچووی زیادە",
  totalExpenseCount: "کۆی ژمارەی خەرجیەکان",
  youWantToMakeUnpayPayment: "دڵنیای دەتەوێ گەڕانەوەی پرەدانەکە بکەیت؟",
  unpay: "گەڕانەوەی پارەدان",
  paidDate: "ڕێکەوتی پارەدان",
  forgivePrice: "بڕی لێخۆشبوون",
  createBuyContractAttachment: "زیادکردنی هاوپێچی گرێبەست",
  loadMore: "زیاتر باربکە",
  showContractAttachments: "هاوپێچەکانی گرێبەست",
  status: "بارودۆخ",
  forgivePriceReport: "ڕاپۆرتی لێخۆشبوون",
  totalForgivePrice: "کۆی گشتی لێخۆشبوون",
  currentPayPrice: "بڕی پارە",
  currentForgivePrice: "بڕی لێخۆشبوون",
  payDate: "بەروار",
  returnedAmount: "بڕی گەڕاوە",
  perMonth: "مانگانە",
  ordering: "ڕیزکردن",
  desc: "Z - A",
  asc: "A - Z",
  sorting: "جۆری ڕیزکردن",
  corner: "ڕوکن",
  road20m: "شەقامی 20m",
  facingPark: "بەرامبەر باخچە",
  reserved: "ڕاگیراو",
  available: "بەردەست",
  isSharedCustomer: "کڕیاری هاوبەش",
  additionalShortagePlotArea: "ڕووبەری زیادە / کەم",
  pleaseTypeSecondCustomerInformation: "تکایە زانیاری کڕیاری دووەم بنووسە",
  pleaseSelectAttachment: "تکایە هاوپێچ هەڵبژێرە",
  addAttachment: "زیادکرنی هاوپێچ",
  attachmentAdded: "هاوپێچ زیادکرا",
  passport: "پاسپۆرت",
  is20Percent: "20%",
  paidTo: "درا بە",
  reasonFor: "بۆ مەبەستی",
  propertyExpense: "خەرجی موڵک",
  totalPropertyExpense: "کۆی گشتی خەرجی موڵک",
  propertyExpenseList: "لیستی خەرجی موڵک",
  buyContractsExpenses: "خەرجی گرێبەستی کڕین",
  buyContractExpensesList: "لیستی خەرجی گرێبەستی کڕین",
  // End Extras
  downpayment: "پارەدانی یەکەم",
  otherExpenses: "خەرجیەکانی تر",

  //safeBox transactions
  addWithdraw: "زیادکردنی ڕاکێشان",
  deleteWithdraw: "سڕینەوەی ڕاکێشان",
  safeBoxTransfer: "گواستنەوەی قاصە",
  deleteSafebox: "سڕینەوەی قاصە",
  deletePrpertyExpense: "تێچووی تایبەتمەندی بسڕەوە",
  addInvestor: "زیادکردنی وەبەرهێنەر",
  deleteInvestor: "سڕینەوەی وەبەرهێنەر",
  addExpense: "زیادکردنی تێچوون",
  deleteExpense: "خەرجی بسڕەوە",
  addDeposit: "زیادکردنی پارە دانان",
  deleteDeposit: "پارە دانان بسڕەوە",
  unpayContractPayment: "پارەی گرێبەستی نەدانی پارە",
  contractPayment: "پارەدانی گرێبەست",
  buyContractPayment: "پارەی گرێبەستی کڕین",
  deleteCancleContract: "گرێبەستی هەڵوەشاندنەوە بسڕەوە",
  cancleContract: "پاشگەزبوونەوە لە گرێبەست",
  deleteContractPayment: "پارەی گرێبەست بسڕەوە",
  deleteBuyContractPayment: "پارەی گرێبەستی کڕین بسڕەوە",
  deleteSafeBoxTransferFrom: "بسڕەوە SafeBox Transfer From",
  m: "م",
  totalRemainArea: "ڕووبەری ماوە",
  contractNote: "تێبینی گرێبەست",
  dari: "عقد دارى",
  textMessage: "دەقی نامە",
  smsMessages: "نامەکانی SMS",
  createSmsMessage: "نامەی SMS دروست بکە",
  TotalHoldPrice: " کۆی پارەی ڕاگیراو",
  NotAvailable: "بەردەست نیە",
};
