import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";
import mixin from "@/mixins";

const buyContracts = {
  namespaced: true,
  state: {
    buyContracts: {},
    buyContractNumber: {},
    createdBuyContract: {},
    getOneBuyContract: {},
    updatedBuyContract: {},
  },
  mutations: {
    SET_BUY_CONTRACTS(state, value) {
      state.buyContracts = value;
    },
    SET_BUY_CONTRACT_NUMBER(state, value) {
      state.buyContractNumber = value;
    },
    SET_CREATED_BUY_CONTRACT(state, value) {
      state.createdBuyContract = value;
    },
    SET_ONE_BUY_CONTRACT(state, value) {
      state.getOneBuyContract = value;
    },
    SET_UPDATED_BUY_CONTRACT(state, value) {
      state.updatedBuyContract = value;
    },
  },
  actions: {
    GET_ALL({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/BuyContracts` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_BUY_CONTRACTS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_BUY_CONTRACTS", {});
            reject(error);
          });
      });
    },
    GET_BUY_CONTRACT_NUMBER({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/BuyContracts/GetContractNumber`)
          .then((response) => {
            commit("SET_BUY_CONTRACT_NUMBER", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_BUY_CONTRACT_NUMBER", {});
            reject(error);
          });
      });
    },
    GET_ONE({ commit }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/BuyContracts/${id}`)
          .then((response) => {
            commit("SET_ONE_BUY_CONTRACT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_ONE_BUY_CONTRACT", {});
            reject(error);
          });
      });
    },
    CREATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        delete form.controls;
        HTTP.post(`/BuyContracts`, form)
          .then((response) => {
            commit("SET_CREATED_BUY_CONTRACT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CREATED_BUY_CONTRACT", {});
            reject(error);
          });
      });
    },
    UPDATE({ commit }, { id, form }) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        delete form.controls;
        HTTP.put(`/BuyContracts/${id}`, form)
          .then((response) => {
            commit("SET_UPDATED_BUY_CONTRACT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_BUY_CONTRACT", {});
            reject(error);
          });
      });
    },
    DELETE(commit, ids) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/BuyContracts/${ids}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    ADD_CONTRACT_ATTACHMENT(commit, { contractId, attachment }) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/BuyContracts/UploadAttachment`, {
          id: contractId,
          attachment,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    DELETE_CONTRACT_ATTACHMENT(commit, contractId) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/BuyContracts/DeleteAttachment?id=${contractId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    TRANSFER_PAYMENT_PROCESS(commit, { fromPayment, toPayment, price }) {
      return new Promise((resolve, reject) => {
        HTTP.post(
          `/BuyContracts/TransferPaymentProcessPrice?fromPayment=${fromPayment}&toPayment=${toPayment}&price=${price}`
        )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    UNPAY_PAYMENT_PROCESS(commit, id) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/PaymentProcesses/UnpayPayment?id=${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GET_PAYMENT_INVOICE_NUMBER() {
      return new Promise((resolve, reject) => {
        HTTP.get(`/BuyContracts/GetPaymentNumber`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    EXPENSES({ commit }, {id, serverParams}) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
            `/BuyContracts/GetContractExpenses/${id}` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
            .then((response) => {
              commit("SET_BUY_CONTRACTS", response.data);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_BUY_CONTRACTS", {});
              reject(error);
            });
      });
    },
  },
  getters: {
    buyContracts: (state) => state.buyContracts,
    buyContractNumber: (state) => state.buyContractNumber,
    createdBuyContract: (state) => state.createdBuyContract,
    getOneBuyContract: (state) => state.getOneBuyContract,
    updatedBuyContract: (state) => state.updatedBuyContract,
  },
};

export default buyContracts;
