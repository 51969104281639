import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const customerAttachments = {
    namespaced: true,
    state: {
        customerAttachments: {},
        createdCustomerAttachment: {},
        getOneCustomerAttachment: {},
        updatedCustomerAttachment: {},
    },
    mutations: {
        SET_CUSTOMER_ATTACHMENTS (state, value) { state.customerAttachments = value },
        SET_CREATED_CUSTOMER_ATTACHMENT (state, value) {state.createdCustomerAttachment = value},
        SET_GET_ONE_CUSTOMER_ATTACHMENT (state, value) {state.getOneCustomerAttachment = value},
        SET_UPDATED_CUSTOMER_ATTACHMENT (state, value) {state.updatedCustomerAttachment = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/CustomerAttachments` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_CUSTOMER_ATTACHMENTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CUSTOMER_ATTACHMENTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/CustomerAttachments/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_CUSTOMER_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_CUSTOMER_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/CustomerAttachments`, form)
                    .then((response) => {
                        commit('SET_CREATED_CUSTOMER_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CUSTOMER_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/CustomerAttachments/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_CUSTOMER_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_CUSTOMER_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/CustomerAttachments/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/CustomerAttachments/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        customerAttachments: state => state.customerAttachments,
        createdCustomerAttachment: state => state.createdCustomerAttachment,
        getOneCustomerAttachment: state => state.getOneCustomerAttachment,
        updatedCustomerAttachment: state => state.updatedCustomerAttachment,
    }
}

export default customerAttachments;
