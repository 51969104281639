<template>
  <div v-if="hasPermission(permissions.propertyHoldCreate)">
    <content-header>{{ $t('createPropertyHold') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
                label="name,+finalPrice"
                :name="$t('property')"
                api-link="properties/GET_ALL"
                :api-params="{OnlyAvailable: true}"
                :required="true"
                :return-object="true"
                @itemSelected="propertySelected"
            />
            <boolean-input
                v-if="projectTenant() !== 'MassHills'"
                id="isCustomer"
                :name="$t('isCustomer')"
                :default-value="false"
                :checked-label="$t('yes')"
                :un-checked-label="$t('no')"
                @switched="e => form.isCustomer = e"
            />
            <select-input
                v-if="form.isCustomer"
                label="firstName,middleName,lastName,+phone"
                id="customerId"
                :name="$t('customer')"
                api-link="customers/GET_ALL"
                :required="true"
                @itemSelected="e => form.customerId = e"
            />
            <text-input v-else id="name" :name="$t('name')" :validate="$v" :required="true" :is-loading="isLoading"/>
            <text-input id="phone" :name="$t('phone')" :validate="$v" :is-loading="isLoading"/>

            <select-input
                label="name"
                id="payType"
                :name="$t('payType')"
                :api-link="payTypes"
                :required="true"
                @itemSelected="e => form.payType = e"
            />
            <text-input v-if="form.payType === 2" id="bankName" :name="$t('bankName')" :validate="$v" :required="true" :is-loading="isLoading"/>
            <text-input v-if="form.payType === 2" id="bankSlipNumber" :name="$t('bankSlipNumber')" :validate="$v" :required="true" :is-loading="isLoading"/>
            <date-time-input v-if="form.payType === 2" id="bankSlipDate" :name="$t('bankSlipDate')" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-input v-if="form.payType === 2" id="bankSlipNote" :name="$t('bankSlipNote')" :validate="$v" :required="true" :is-loading="isLoading"/>
            <number-input id="holdPayment" :name="$t('holdPayment')" :show-locale-string="true" :validate="$v" :required="true" :is-loading="isLoading" />
           
            <div class="form-group row">
              <label class="col-sm-2 col-form-label text-right">{{ $t('remain2') }}</label>
              <div class="col-sm-10">
                <p class="lead pt-1">{{ remain && remain.toLocaleString() }}</p>
              </div>
            </div>
            <date-time-input id="date" :name="$t('date')" :validate="$v" :required="true" :is-loading="isLoading" />
            <date-time-input id="deadline" :name="$t('deadline')" default-value="" :validate="$v" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :required="true"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {PropertyHold} from "@/models/PropertyHold";
import SubmitInput from "@/components/Inputs/SubmitInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import SelectInput from "@/components/Inputs/SelectInput";
import NumberInput from "@/components/Inputs/NumberInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import TextInput from "@/components/Inputs/TextInput";
import FileInput from "@/components/Inputs/FileInput";
import BooleanInput from "@/components/Inputs/BooleanInput";
import PayType from "@/enums/PayType";
import {permissions} from "@/plugins/constants";

export default {
  name: "PropertyHoldCreate",
  components: {BooleanInput, FileInput, TextInput, TextAreaInput, NumberInput, SelectInput, DateTimeInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new PropertyHold().fillAbles),
      isLoading: false,
      selectedItem: {},
      payTypes: PayType,
      property: {},
      remain: 0,
      permissions: permissions
    }
  },
  watch: {
    "form.holdPayment"(newVal) {
      console.log(this.property.finalPrice, newVal)
      this.remain = this.property && this.property.finalPrice && (this.property.finalPrice - newVal);
    }
  },
  validations: {
    form: {
      propertyId: {required},
      name: {},
      isCustomer: {},
      customerId: {},
      payType: {},
      bankName: {},
      bankSlipNumber: {},
      bankSlipDate: {},
      bankSlipNote: {},
      holdPayment: {required},
      phone: {},
      date: {required},
      deadline: {},
      note: {}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('propertyHolds/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "propertyHolds"})
      }).catch(() => this.isLoading = false);
    },
    propertySelected(e) {
      this.property = e;
      this.form.propertyId = e.id;
    },
  }
}
</script>
