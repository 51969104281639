import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyTypes = {
    namespaced: true,
    state: {
        propertyTypes: {},
        createdPropertyType: {},
        getOnePropertyType: {},
        updatedPropertyType: {},
    },
    mutations: {
        SET_PROPERTY_TYPES (state, value) { state.propertyTypes = value },
        SET_CREATED_PROPERTY_TYPE (state, value) {state.createdPropertyType = value},
        SET_GET_ONE_PROPERTY_TYPE (state, value) {state.getOnePropertyType = value},
        SET_UPDATED_PROPERTY_TYPE (state, value) {state.updatedPropertyType = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/PropertyTypes` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_TYPES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_TYPES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/PropertyTypes/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_TYPE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/PropertyTypes`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_TYPE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/PropertyTypes/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_TYPE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/PropertyTypes/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyTypes: state => state.propertyTypes,
        createdPropertyType: state => state.createdPropertyType,
        getOnePropertyType: state => state.getOnePropertyType,
        updatedPropertyType: state => state.updatedPropertyType,
    }
}

export default propertyTypes;
