<template>
  <div v-if="hasPermission(permissions.cancelContractsList)">
    <content-header>{{ $t('cancelContracts') }}</content-header>
    <v-content>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="controls" slot-scope="props" v-if="hasPermission(permissions.cancelContractsShow)">
          <control-buttons
              :props="props"
              :show-link="hasPermission(permissions.cancelContractsShow) && 'cancelContracts.show'"
              v-on:successAction="loadData(true)"
          />
        </template>
        <template slot="buyContractId" slot-scope="props">
          <router-link :to="{name: 'buyContracts.show', params: {id: props.row.buyContractId }}">{{ props.row.buyContractId }}</router-link>
        </template>
        <template slot="customerId" slot-scope="props">
          <router-link :to="{name: 'customers.edit', params: {id: props.row.customerId }}">{{ props.row.customerName }}</router-link>
        </template>
        <template slot="propertyId" slot-scope="props">
          <router-link :to="{name: 'properties.edit', params: {id: props.row.propertyId }}">{{ props.row.propertyName }}</router-link>
        </template>
        <template slot="returnedPrice" slot-scope="props">
          {{ parseFloat(props.row.returnedPrice).toLocaleString() }}
        </template>
        <template slot="remainPrice" slot-scope="props">
          {{ parseFloat(props.row.remainPrice).toLocaleString() }}
        </template>
        <template slot="date" slot-scope="props">
          {{ formatDate(props.row.date) }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {CancelContract} from "@/models/CancelContract";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {actions, permissions, serverParams, swalMixin, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "CancelContractList",
  components: {ControlButtons, IsLoading, 'v-content': Content, ContentHeader, BootstrapTable},
  data() {
    return {
      isLoading: false,
      model: new CancelContract(),
      columns: [],
      rows: [],
      actions: tableActions(),
      totalRecords: 0,
      config: tableConfigure(this.$t('cancelContractList')),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      this.isLoading = true;
      this.$store.dispatch('cancelContracts/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin('error', 'noRows')
        return false;
      }
      if (await actions('cancelContracts/DELETE', this.selectedRows, 'delete')) {
        this.loadData();
      }
    },
  }
}
</script>
