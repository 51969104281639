import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const withdrawTypes = {
    namespaced: true,
    state: {
        withdrawTypes: {},
        createdWithdrawType: {},
        getOneWithdrawType: {},
        updatedWithdrawType: {},
    },
    mutations: {
        SET_WITHDRAW_TYPES (state, value) { state.withdrawTypes = value },
        SET_CREATED_WITHDRAW_TYPE (state, value) {state.createdWithdrawType = value},
        SET_GET_ONE_WITHDRAW_TYPE (state, value) {state.getOneWithdrawType = value},
        SET_UPDATED_WITHDRAW_TYPE (state, value) {state.updatedWithdrawType = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/WithdrawTypes` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_WITHDRAW_TYPES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_WITHDRAW_TYPES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/WithdrawTypes/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_WITHDRAW_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_WITHDRAW_TYPE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/WithdrawTypes`, form)
                    .then((response) => {
                        commit('SET_CREATED_WITHDRAW_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_WITHDRAW_TYPE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/WithdrawTypes/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_WITHDRAW_TYPE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_WITHDRAW_TYPE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/WithdrawTypes/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        withdrawTypes: state => state.withdrawTypes,
        createdWithdrawType: state => state.createdWithdrawType,
        getOneWithdrawType: state => state.getOneWithdrawType,
        updatedWithdrawType: state => state.updatedWithdrawType,
    }
}

export default withdrawTypes;
