<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row
        class="text-center"
        style="margin: 0; font-size: 28px; border-bottom: 3px solid #b58151"
      >
        <b-col cols="4">
          <h2 class="text-left text-bold pt-4">مشروع رویال ستی</h2>
          <h2 class="text-left text-bold">مرکز المبیعات</h2>
        </b-col>
        <b-col cols="3" class="text-center">
          <!-- <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 65%"
          ></b-img> -->
          <img
            src="../../assets/RoyalTower/royal.png"
            alt="kjnvf"
            width="200"
            height="150"
            style="width: 65%"
          />
        </b-col>
        <b-col cols="5" class="p-2 pt-1 text-right" style="text-align: end">
          <!-- <h5 class="text-bold">
            <b>law@investincandle.com</b>&nbsp;
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            <b>sales@investincandle.com</b>&nbsp;
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5> -->
          <h5 class="text-bold">
            <b> 2004 440 784 964+</b>&nbsp;
            <i class="fal fa-phone fa-rotate-270" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            &nbsp;
            <b>بغداد - علي الصالح - البيجية</b>
            &nbsp;<i class="fal fa-map-marker" style="color: #9e7f49"></i>&nbsp;
          </h5>
          <h5 class="text-bold">
            &nbsp;
            <b>Baghdad - Ali Saleh District - Al-Beijyiah Subdistrict</b>
            &nbsp;<i class="fal fa-map-marker" style="color: #9e7f49"></i>&nbsp;
          </h5>
        </b-col>
      </b-row>
      <b-row class="m-2" style="margin: 0; font-size: 20px">
        <b-col class="text-left" style="font-size: 25px" cols="4">
          <h5>
            <span class="text-bold"
              >رقم الوصل:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              ><b>{{ voucherNo && voucherNo }}</b></span
            >
          </h5>
          <h5>
            <span class="text-bold">المجموع الكلي:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
            >
              <!-- <b>
                $
              {{
                (
                  parseFloat(totalPaidPrice) + parseFloat(remain)
                ).toLocaleString()
              }}
              </b> -->
              <b> ${{ total && total.toLocaleString() }}</b>
            </span>
          </h5>
          <h5>
            <span class="text-bold">المبلغ المتبقي:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              ><b>${{ remain && remain.toLocaleString() }}</b></span
            >
          </h5>
          <h5>
            <span class="text-bold">نسبة الباقي:&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
            >
              <b
                >%{{
                  ((parseFloat(remain) / parseFloat(total)) * 100).toFixed(2)
                }}</b
              >
            </span>
          </h5>
          <!-- <h5>
            <span class="text-bold">اسم الزبون:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >
              <b>
                {{ paidName && paidName }}
              </b>
              </span
            >
          </h5> -->
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          <strong>سند قبض</strong>
          <p class="lead">
            {{ formatDate(voucherDate && voucherDate, "YYYY-MM-DD - h:m A") }}
            <br />
            <span v-if="isCash">کاش</span>
            <span v-else
              >بنك <br />
              {{ bankSlipNumber ? bankSlipNumber : "-- لا یوجد --" }} <br />
              {{
                bankSlipDate ? formatDate(bankSlipDate) : "-- لا یوجد --"
              }}</span
            >
          </p>
          <h5>
            <span class="text-bold">اسم الزبون: </span>
            <span>
              <b>
                {{ paidName && paidName }}
              </b>
            </span>
          </h5>
        </b-col>
        <b-col class="text-center" style="font-size: 25px" cols="4">
          <p class="h5">
            <span class="text-bold"
              >بناية:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              ><b>{{ property && property.zone }}</b></span
            >
          </p>
          <p class="h5">
            <span class="text-bold"
              >طابق:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              ><b>{{ property && property.floors }}</b></span
            >
          </p>
          <p class="h5">
            <span class="text-bold"
              >شقة:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              ><b>{{ property && property.name }}</b></span
            >
          </p>
          <p class="h5">
            <span class="text-bold">المساحة:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
                direction: ltr !important;
              "
              ><b>
                {{
                  property &&
                  `${property.propertyType.name} ${property.buildUpArea}`
                }}m<sup>2</sup>
              </b></span
            >
          </p>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="2"> المبلغ</b-col>
        <b-col class="text-center" cols="8">
          <strong>${{ amount && numberFormat(amount) }}</strong>
          <h4 style="border-bottom: 2px solid #9e7f49; padding-bottom: 15px">
            {{ amountText }}
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="2">
          Amount
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4">و ذلك عن</b-col>
        <b-col class="text-center" cols="4">
          <strong>{{ note && note }}</strong>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4">
          Purpose of receipt
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center mt-5">
        <b-col cols="6">
          <strong> توقيع المحاسب</strong>
          <!-- <br /> -->
          <!-- {{ accountantName && accountantName }} -->
        </b-col>
        <!-- <b-col cols="4">
          <strong>توقيع المستلم منه</strong>
          <br />
          {{ paidName && paidName }}
        </b-col> -->
        <b-col cols="6">
          <strong>توقيع المدير المالي</strong>
          <br />
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 40px 0" />
  </div>
</template>

<script>
// import mixins from "@/mixins";

export default {
  name: "VoucherReceiptRoyalTower",
  props: {
    voucherNo: {
      type: String,
    },
    voucherDate: {
      type: String,
    },
    paidName: {
      type: String,
    },
    amount: {
      type: String,
    },
    amountText: {},
    purposeOfVoucher: {
      type: String,
    },
    note: {
      type: String,
    },
    accountantName: {
      type: String,
      default: "ناوی ژمێریار",
    },
    property: {
      type: Object,
    },
    isCash: {
      type: Boolean,
      default: true,
    },
    bankSlipNumber: {},
    bankSlipDate: {},
    remain: {},
    totalPaidPrice: {},
    total: {
      type: Number,
    }
  },

  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      datetime: null,
    };
  },
  mounted() {
    // mixins.getCurrentDateTimeServer().then((response) => {
    //   this.datetime = response.datetime;
    // });
    // this.datetime = new Date();
  },
};
</script>
