<template>
  <div class="form-group row">
    <label class="col-md-2 col-form-label"> </label>
    <button class="btn btn-outline-primary col-md-3" type="submit" :disabled="disabled"><i class="fas" :class="icon"></i>
      {{ name }}
    </button>
  </div>
</template>

<script>

export default {
  name: "SubmitInput",
  props: {
    name: {
      default: 'Create'
    },
    icon: {
      type: String,
      default: 'fa-plus'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>
