import { i18n } from "@/plugins/i18n";

export class Customer {
  columns = [];
  id = "id";

  fillAbles = [
    ["firstName", i18n.t("fullName"), true],
    ["middleName", i18n.t("middleName"), true, false],
    ["lastName", i18n.t("lastName"), true, false],
    ["nationality", i18n.t("nationality"), true],
    ["address", i18n.t("address"), true],
    ["phone", i18n.t("phone"), true],
    ["idcard", i18n.t("idCard"), true],
    ["foodCard", i18n.t("foodCard"), true],
    ["informationCard", i18n.t("informationCard"), true],
    ["male", i18n.t("male"), true],
    ["hasAccount", i18n.t("hasAccount"), true],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
