import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";

const users = {
    namespaced: true,
    state: {
        users: {},
        getOneUser: {},
    },
    mutations: {
        SET_USERS (state, value) { state.users = value },
        SET_GET_ONE_USER (state, value) {state.getOneUser = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Users` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_USERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_USERS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Users/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_USER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_USER', {})
                        reject(error)
                    });
            })
        },
        ADD_PROJECT_TO_USER(commit, {userId, projectId}) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Users/PostUserProject?userId=${userId}&projectId=${projectId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        REMOVE_PROJECT_FROM_USER(commit, {userId, projectId}) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Users/DeleteUserProject?userId=${userId}&projectId=${projectId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters: {
        users: state => state.users,
        getOneUser: state => state.getOneUser,
    }
}

export default users;
