<template>
  <div>
    <content-header>{{ $t('createPropertyFeature') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
                label="name"
                id="feature"
                :name="$t('feature')"
                api-link="features/GET_ALL"
                :required="true"
                :return-object="true"
                @itemSelected="featureSelected"
            />
            <number-input id="extraPrice" :name="$t('extraPrice')" :show-locale-string="true" default-value="0" :validate="$v" :required="true" :is-loading="isLoading" />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import {PropertyFeature} from "@/models/PropertyFeature";
import NumberInput from "@/components/Inputs/NumberInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "PropertyFeatureCreate",
  components: {SelectInput, SubmitInput, NumberInput, IsLoading, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new PropertyFeature().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      featureId: {},
      extraPrice: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.propertyId = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('propertyFeatures/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'properties.edit', params: {id: this.$route.params.id}, hash: '#features'})
      }).catch(() => this.isLoading = false);
    },
    featureSelected(feature) {
      this.form.featureId = feature.id;
      this.form.extraPrice = feature.extraPrice;
    }
  }
}
</script>
