import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const propertyAttachments = {
    namespaced: true,
    state: {
        propertyAttachments: {},
        createdPropertyAttachments: {},
        getOnePropertyAttachments: {},
        updatedPropertyAttachments: {},
    },
    mutations: {
        SET_PROPERTY_ATTACHMENTS (state, value) { state.propertyAttachments = value },
        SET_CREATED_PROPERTY_ATTACHMENT (state, value) {state.createdPropertyAttachments = value},
        SET_GET_ONE_PROPERTY_ATTACHMENT (state, value) {state.getOnePropertyAttachments = value},
        SET_UPDATED_PROPERTY_ATTACHMENT (state, value) {state.updatedPropertyAttachments = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/PropertyAttachment` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PROPERTY_ATTACHMENTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PROPERTY_ATTACHMENTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/PropertyAttachment/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PROPERTY_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PROPERTY_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.post(`/PropertyAttachment`, form)
                    .then((response) => {
                        commit('SET_CREATED_PROPERTY_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PROPERTY_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                delete form.controls
                HTTP.put(`/PropertyAttachment/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PROPERTY_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PROPERTY_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/PropertyAttachment/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/PropertyAttachment/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        propertyAttachments: state => state.propertyAttachments,
        createdPropertyAttachments: state => state.createdPropertyAttachments,
        getOnePropertyAttachments: state => state.getOnePropertyAttachments,
        updatedPropertyAttachments: state => state.updatedPropertyAttachments,
    }
}

export default propertyAttachments;
