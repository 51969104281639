<template>
  <router-link class='animationButton btn btn-lg' :class="classes" :to="link">{{ text }}</router-link>
</template>

<script>
export default {
  name: "AnimationButton",
  props: {
    link: {
      default: '#',
      required: true
    },
    text: {
      default: 'Alert',
      required: true
    },
    classes: {
      default: ''
    }
  }
}
</script>

<style scoped>
a {
  animation: alert 1s infinite ease-in-out;
  background-color: #ff0000;
}

@keyframes alert {
  from { background-color: #ff0000; }
  to { background-color: #fdfdfd; }
}
</style>
