<template>
  <div id="transfer-contract-voucher-receipt" class="contract text-right d-none" dir="rtl">
    <h1 class="text-center">بسم الله الرحمن الرحيم</h1>
    <h3 class="text-center">(( عقد تنازل ))</h3>
    <h3 class="text-center">للڤلل السكنية لمشروع (مدينة ديبلومات السفراء)</h3>
    <p class="lead text-right" dir="rtl">الطرف الأول (المتنازل): {{ data.oldCustomer && (`${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}`) }}</p>
    <p class="lead text-right" dir="rtl">الطرف الثاني (المتنازل اليه): {{ data.newCustomer && (`${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}`) }}</p>
    <p class="lead text-right" dir="rtl">إشارة إلى العقد الموقع  بتأريخ ({{ formatDate(data.buyingDate) }}) بين الطرف الأول (المتنازل) و شركة گولوان للاستثمار العقاري/ المحدودة المنجزة للمشروع المذكور أعلاه و عليه تنازل الطرف الأول إلى الطرف الثاني في هذا العقد عن الڤیلا المذكورة أدناه و وفق النقاط التالية:</p>
    <p class="lead text-right" dir="rtl">
      1- تنازل الطرف الأول (المتنازل) السيد ({{ data.oldCustomer && (`${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}`) }}) عن الڤیلا المرقمة ({{ data.property && data.property.name }}) و المصنفة ({{ data.property && data.property.zone }}) بمساحة ({{ data.property && data.property.buildUpArea }}) الى الطرف الثاني (المتنازل اليه) السيد ({{ data.newCustomer && (`${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}`) }}) و المشيدة في مشروع (مدينة ديبلومات السفراء) التابعة للشركة المنجزة للمشروع المذكور أعلاه و بعلم و موافقة الشركة المنجزة للمشروع.
    </p>
    <p class="lead text-right" dir="rtl">
      2- يجب ان توقع الشركة المنجزة على هذا العقد و عند ذاك تعتبر هذه الوثيقة قانونية مستكفية لشروطها الشكلية الكاملة ويحق للطرف الثاني (المتنازل اليه) أن يتمسك بها كوثيقة قانونية معتبرة.
    </p>
    <p class="lead text-right" dir="rtl">
      3- بعد التوقيع على هذا العقد على الشركة المنجزة للمشروع إبرام عقد جديد مع الطرف الثاني (المتنازل اليه) على الڤیلا المذكورة في هذا العقد و يلتزم الطرف الثاني (المتنازل اليه) بأداء الحقوق المادية و القانونية المترتبة في ذمة الطرف الأول (المتنازل) مع الشركة المنجزة للمشروع وفق العقد الأصلي المبرم بین المتنازل و الشركة المنجزة للمشروع عن الڤیلا المذكورة في العقد و يلتزم الطرف الثاني (المتنازل اليه)  بدفع الأقساط المتبقية في وقتها المحدد وفق العقد.
    </p>
    <p class="lead text-right" dir="rtl">
      4- بعد التوقيع على هذا العقد التنازل لا يحق للطرف الأول (المتنازل) الرجوع عن توقيعه و لم يبقى لديه أية حق في ذمة الطرف الثاني (المتنازل اليه) (ولا في ذمة الشركة المنجزة للمشروع ) ، و بناء على طلبه من الشركة المنجزة يتم إبرام عقد جديد مع الطرف الثاني (المتنازل اليه) .
    </p>
    <p class="lead text-right" dir="rtl">
      5- الشركة المنجزة للمشروع ليست طرفا في عقد التنازل هذا من الناحية المالية بين الطرفين و لا يحق لأي من الطرفين مطالبة الشركة المنجزة للمشروع عن أي خلل في التزاماتهما.
    </p>
    <p class="lead text-right" dir="rtl">
      6- يتعهد الطرف الثاني (المتنازل اليه) بتنفيذ جميع التزامات المشتري المذكورة في العقد الأصلي بين الطرفين.
    </p>
    <p class="lead text-right" dir="rtl">
      7- حرر هذا العقد في أربيل بتأريخ  ({{ getCurrentDate() }}) و وقع من قبل الطرفين و ختم بختم الشركة المنجزة للمشروع بعد موافقته على هذا التنازل.
    </p>
    <br />
    <b-row class="text-center mb-4">
      <b-col cols="6">
        <p class="lead">الطرف الأول</p>
        <p class="lead">(المتنازل)</p>
        <p class="lead" dir="rtl">الإسم: {{ data.oldCustomer && (`${data.oldCustomer.firstName} ${data.oldCustomer.middleName} ${data.oldCustomer.lastName}`) }} </p>
        <p class="lead" dir="rtl">رقم الهویة: {{ data.oldCustomer && data.oldCustomer.idcard }} </p>
        <p class="lead" dir="rtl">رقم الموبایل: {{ data.oldCustomer && replacePhone(data.oldCustomer.phone) }} </p>
      </b-col>
      <b-col cols="6">
        <p class="lead">الطرف الثانی</p>
        <p class="lead">(المتنازل اليه)</p>
        <p class="lead" dir="rtl">الإسم: {{ data.newCustomer && (`${data.newCustomer.firstName} ${data.newCustomer.middleName} ${data.newCustomer.lastName}`) }} </p>
        <p class="lead" dir="rtl">رقم الهویة: {{ data.newCustomer && data.newCustomer.idcard }} </p>
        <p class="lead" dir="rtl">رقم الموبایل: {{ data.newCustomer && replacePhone(data.newCustomer.phone) }} </p>
      </b-col>
    </b-row>
    <p class="lead text-center">موافقة المدير المفوض لشركة گولوان للأستثمار العقاري/ المحدودة اضافة الى وظيفته على هذا التنازل</p>
    <p class="lead text-center">المستشار القانوني للشركة</p>
    <p class="lead text-center">احمد عثمان الأطرقچی</p>
  </div>
</template>

<script>

export default {
  name: "CancelContractDesignDiplomatiSafiran",
  props: {
    data: {}
  }
}
</script>
