<template>
  <transition name="fade">
    <div key="1" v-if="showMap">
      <b-row>
        <b-col v-for="map in maps" v-bind:key="map.id" xl="3" lg="6" md="6" sm="12">
          <b-card class="text-center mb-2 map p-0" @click="mapClicked(map.name)">
            <b-card-header>
              {{ map.name }}
              <b-progress :value="calculatePercentage(map.totalProperties, map.soldProperties)" max="100" show-progress variant="primary" height="2rem"></b-progress>
            </b-card-header>
            <b-card-text>{{ $t('total') }}: {{ map.totalProperties }}</b-card-text>
            <b-row>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-success">{{ $t('remain') }} <br /> {{ map.remainProperties }}</b-card-text>
              </b-col>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-blue">{{ $t('sold') }} <br /> {{ map.soldProperties }}</b-card-text>
              </b-col>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-secondary">{{ $t('hold') }} <br /> {{ map.holdProperties }}</b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div key="2" v-else class="houses">
      <b-row class="mb-2">
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-primary" @click="backToMaps">{{ $t('back') }}</button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-info" @click="filterMaps(1)">{{ $t('soldProperties') }} <span class="badge badge-light">{{ sold }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-success" @click="filterMaps(2)">{{ $t('remainedProperties') }} <span class="badge badge-light">{{ remained }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-secondary" @click="filterMaps(3)">{{ $t('onHoldProperties') }} <span class="badge badge-light">{{ onHold }}</span><span class="sr-only"> </span></button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button type="button" class="btn btn-danger" @click="filterMaps(0)">{{ $t('reset') }}</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="mapHouse in searchMap" v-bind:key="mapHouse.name" cols="3">
          <b-card
              style="max-width: 20rem;min-height: 125px;padding: 5px;"
              class="my-2 text-white"
              :style="{'background-color': mapHouse.isSold ? '#17A2B8' : mapHouse.isHold ? '#5a6268' : '#28A745'}"
          >
            <div class="container">
              <h4 @click="$router.push({name: 'properties.edit', params: { id: mapHouse.id }})" style="cursor: pointer;">
                {{ mapHouse.name }}
              </h4>
              <p v-if="mapHouse.isSold" class="d-inline-block m-1 mr-2">
                <router-link style="font-size: 22px !important;" class="text-white" :to="{name: 'buyContracts.show', params: { id: mapHouse.contractId }}">
                  <i class="fas fa-file-contract"></i>
                </router-link>
              </p>
              <p v-if="mapHouse.isSold" class="d-inline-block m-1">
                <router-link style="font-size: 22px !important;" class="text-white" :to="{name: 'customers.edit', params: { id: mapHouse.cutomerId }}">
                  <i class="fas fa-user"></i>
                </router-link>
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </transition>
</template>

<script>
import lodash from 'lodash';

export default {
  name: "CantalMap",
  props: {},
  data() {
    return {
      maps: [],
      showMap: true,
      sold: 0,
      remained: 0,
      onHold: 0,
      mapHouses: [],
      searchMap: []
    }
  },
  mounted() {
    this.loadMapInfo();
  },
  methods: {
    loadMapInfo() {
      this.$store.dispatch('dashboard/GET_MAP_INFO').then((response) => {
          this.maps = response.data;
      });
    },
    mapClicked(zone) {
      this.$store.dispatch('dashboard/GET_PROPERTY_INFO_BY_ZONE', zone).then((response) => {
        this.mapHouses = response.data;
        this.searchMap = response.data;
        this.sold = lodash.filter(response.data, (x) => x.isSold).length;
        this.remained = lodash.filter(response.data, (x) => !x.isSold && !x.isHold).length;
        this.onHold = lodash.filter(response.data, (x) => x.isHold).length;
        this.showMap = false;
      });
    },
    filterMaps(type) {
      if (type === 1) {
        this.searchMap = lodash.filter(this.mapHouses, (x) => x.isSold);
      } else if (type === 2) {
        this.searchMap = lodash.filter(this.mapHouses, (x) => !x.isSold && !x.isHold);
      } else if (type === 3) {
        this.searchMap = lodash.filter(this.mapHouses, (x) => x.isHold);
      } else {
        this.searchMap = this.mapHouses;
      }
    },
    backToMaps() {
      this.mapHouses = [];
      this.showMap = true;
    },
    calculatePercentage(total, value) {
      return (value / total) * 100;
    }
  }
}
</script>
<style scoped>
.map {
  transition: 0.3s;
}

.map:hover {
  background-color: #236398;
  transform: scale(1.05);
  color: #FFFFFF;
  cursor: pointer;
}

.mapElement {
  border-radius: 5px;
  padding: 4px;
  box-shadow: 1px 1px 1px 1px #000000;
  color: white;
}

.fade-enter-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
