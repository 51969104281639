import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const deposits = {
    namespaced: true,
    state: {
        deposits: {},
        createdDeposit: {},
        getOneDeposit: {},
        updatedDeposit: {},
    },
    mutations: {
        SET_DEPOSITS (state, value) { state.deposits = value },
        SET_CREATED_DEPOSIT (state, value) {state.createdDeposit = value},
        SET_GET_ONE_DEPOSIT (state, value) {state.getOneDeposit = value},
        SET_UPDATED_DEPOSIT (state, value) {state.updatedDeposit = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Deposits` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_DEPOSITS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_DEPOSITS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Deposits/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_DEPOSIT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_DEPOSIT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Deposits`, form)
                    .then((response) => {
                        commit('SET_CREATED_DEPOSIT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_DEPOSIT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Deposits/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_DEPOSIT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_DEPOSIT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Deposits/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        deposits: state => state.deposits,
        createdDeposit: state => state.createdDeposit,
        getOneDeposit: state => state.getOneDeposit,
        updatedDeposit: state => state.updatedDeposit,
    }
}

export default deposits;
