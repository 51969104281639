<template>
  <div v-if="hasPermission(permissions.propertyHoldList)">
    <content-header>{{ $t("propertyHolds") }}</content-header>
    <v-content>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @on-create="$router.push({ name: 'propertyHolds.create' })"
        @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template
          slot="controls"
          slot-scope="props"
          v-if="
            hasPermission(permissions.propertyHoldDelete) ||
            hasPermission(permissions.propertyHoldShow) ||
            hasPermission(permissions.propertyHoldEdit)
          "
        >
          <control-buttons
            :props="props"
            :edit-link="
              hasPermission(permissions.propertyHoldEdit) &&
              'propertyHolds.edit'
            "
            :delete-link="
              hasPermission(permissions.propertyHoldDelete) &&
              'propertyHolds/DELETE'
            "
            :show-link="
              hasPermission(permissions.propertyHoldShow) &&
              'propertyHolds.show'
            "
            v-on:successAction="loadData(true)"
          />
        </template>
        <template slot="holdPayment" slot-scope="props">
          {{
            projectCurrencySymbol() +
            props.row.holdPayment.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format("YYYY-MM-DD") }}
        </template>
        <template slot="deadline" slot-scope="props">
          {{
            props.row.deadline &&
            moment(props.row.deadline).format("YYYY-MM-DD")
          }}
        </template>
        <template slot="note" slot-scope="props">
          <template v-if="props.row.note">{{ props.row.note }}</template>
          <template v-else>-</template>
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment
            :attachment="props.row.attachment"
            :title="props.row.name"
          />
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { PropertyHold } from "@/models/PropertyHold";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  actions,
  permissions,
  serverParams,
  swalMixin,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";

export default {
  name: "PropertyHoldList",
  components: {
    ShowAttachment,
    ControlButtons,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
  },
  data() {
    return {
      isLoading: false,
      model: new PropertyHold(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.propertyHoldCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t("propertyHoldsList")),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions,
    };
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store
        .dispatch("propertyHolds/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin("error", "noRows");
        return false;
      }
      if (await actions("propertyHolds/DELETE", this.selectedRows, "delete")) {
        this.loadData();
      }
    },
  },
};
</script>
