<template>
  <div id="property-hold-voucher-2" class="contract text-right d-none" dir="rtl">
    <div>
      <b-row class="text-center m-3">
        <b-col cols="4">
          <img :src="royalLogo" alt="Logo" width="100" height="100">
        </b-col>
        <b-col cols="4">
          <h3>بطاقة حجز مؤقتة</h3>
        </b-col>
        <b-col cols="4">
          <img :src="gelLogo" alt="Logo" width="100" height="100">
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="8">
          <p style="font-size: 19px;text-align: start">الإسم: <span class="selectedSpan">{{ data.name ? data.name : `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}` }}</span></p>
        </b-col>
        <b-col cols="4">
          <p style="font-size: 19px;text-align: start">رقم التلفون: <span class="selectedSpan">{{ data.customer && `${replacePhone(data.customer.phone)}` }}</span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">إسم الحساب: <span class="selectedSpan">مجمع رویال ستی السکنی</span></p>
        </b-col>
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">رقم الحساب: <span class="selectedSpan">IQ67TRIQ99300<span class="text-danger">6010234001</span></span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">المبغ: <span class="selectedSpan">{{ data && data.holdPayment && data.holdPayment.toLocaleString() }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">عمارة: <span class="selectedSpan">{{ data && data.property && data.property.zone }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">الطابق: <span class="selectedSpan">{{ data && data.property && data.property.floors }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">شقە: <span class="selectedSpan">{{ data && data.property && data.property.name }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ: <span class="selectedSpan">{{ formatDate(data.date) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ الإنتهاء: <span class="selectedSpan">{{ formatDate(data.deadline) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p style="font-size: 19px;text-align: center">ملاحظة/ یلغی الحجز بعد مرور ٤٨ ساعة من تأریخ الحجز عدا ایام العطل</p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: center">توقیع المخول</p>
        </b-col>
        <hr style="width: 85%;margin: 10px auto" />
        <b-col cols="4">
          <p class="text-bold">
            <span>
               بغداد - المنصور - مقاطعة البیجیة - بجانب مطار مثنی
              <br />
              Baghdad - Al-Mansour - Albejiah - Beside Al-Muthanna Airport
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07844402001
              <br />
              07844402002
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07740422222
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row class="text-center m-3">
        <b-col cols="4">
          <img :src="royalLogo" alt="Logo" width="100" height="100">
        </b-col>
        <b-col cols="4">
          <h3>بطاقة حجز مؤقتة</h3>
        </b-col>
        <b-col cols="4">
          <img :src="gelLogo" alt="Logo" width="100" height="100">
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="8">
          <p style="font-size: 19px;text-align: start">الإسم: <span class="selectedSpan">{{ data.name ? data.name : `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}` }}</span></p>
        </b-col>
        <b-col cols="4">
          <p style="font-size: 19px;text-align: start">رقم التلفون: <span class="selectedSpan">{{ data.customer && `${replacePhone(data.customer.phone)}` }}</span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">إسم الحساب: <span class="selectedSpan">مجمع رویال ستی السکنی</span></p>
        </b-col>
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">رقم الحساب: <span class="selectedSpan">IQ67TRIQ99300<span class="text-danger">6010234001</span></span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">المبغ: <span class="selectedSpan">{{ data && data.holdPayment && data.holdPayment.toLocaleString() }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">عمارة: <span class="selectedSpan">{{ data && data.property && data.property.zone }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">الطابق: <span class="selectedSpan">{{ data && data.property && data.property.floors }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">شقە: <span class="selectedSpan">{{ data && data.property && data.property.name }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ: <span class="selectedSpan">{{ formatDate(data.date) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ الإنتهاء: <span class="selectedSpan">{{ formatDate(data.deadline) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p style="font-size: 19px;text-align: center">ملاحظة/ یلغی الحجز بعد مرور ٤٨ ساعة من تأریخ الحجز عدا ایام العطل</p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: center">توقیع المخول</p>
        </b-col>
        <hr style="width: 85%;margin: 10px auto" />
        <b-col cols="4">
          <p class="text-bold">
            <span>
               بغداد - المنصور - مقاطعة البیجیة - بجانب مطار مثنی
              <br />
              Baghdad - Al-Mansour - Albejiah - Beside Al-Muthanna Airport
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07844402001
              <br />
              07844402002
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07740422222
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row class="text-center m-3">
        <b-col cols="4">
          <img :src="royalLogo" alt="Logo" width="100" height="100">
        </b-col>
        <b-col cols="4">
          <h3>بطاقة حجز مؤقتة</h3>
        </b-col>
        <b-col cols="4">
          <img :src="gelLogo" alt="Logo" width="100" height="100">
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="8">
          <p style="font-size: 19px;text-align: start">الإسم: <span class="selectedSpan">{{ data.name ? data.name : data.customer && `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}` }}</span></p>
        </b-col>
        <b-col cols="4">
          <p style="font-size: 19px;text-align: start">رقم التلفون: <span class="selectedSpan">{{ data.customer && `${replacePhone(data.customer.phone)}` }}</span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">إسم الحساب: <span class="selectedSpan">مجمع رویال ستی السکنی</span></p>
        </b-col>
        <b-col cols="12" class="my-2">
          <p style="font-size: 19px;text-align: start">رقم الحساب: <span class="selectedSpan">IQ67TRIQ99300<span class="text-danger">6010234001</span></span></p>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">المبغ: <span class="selectedSpan">{{ data && data.holdPayment && data.holdPayment.toLocaleString() }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">عمارة: <span class="selectedSpan">{{ data && data.property && data.property.zone }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">الطابق: <span class="selectedSpan">{{ data && data.property && data.property.floors }}</span></p>
        </b-col>
        <b-col cols="3">
          <p style="font-size: 19px;text-align: start">شقە: <span class="selectedSpan">{{ data && data.property && data.property.name }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ: <span class="selectedSpan">{{ formatDate(data.date) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: start">التاریخ الإنتهاء: <span class="selectedSpan">{{ formatDate(data.deadline) }}</span></p>
        </b-col>
        <b-col cols="6">
          <p style="font-size: 19px;text-align: center">ملاحظة/ یلغی الحجز بعد مرور ٤٨ ساعة من تأریخ الحجز عدا ایام العطل</p>
        </b-col>
        <b-col cols="6">
          <p class="lead my-1" style="text-align: center">توقیع المخول</p>
        </b-col>
        <hr style="width: 85%;margin: 10px auto" />
        <b-col cols="4">
          <p class="text-bold">
            <span>
               بغداد - المنصور - مقاطعة البیجیة - بجانب مطار مثنی
              <br />
              Baghdad - Al-Mansour - Albejiah - Beside Al-Muthanna Airport
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07844402001
              <br />
              07844402002
            </span>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="text-bold">
            <span>
              07740422222
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import royal from '@/assets/RoyalTower/royal.png';
import gel from '@/assets/RoyalTower/gel.png';

export default {
  name: "PropertyHoldDesignRoyalTower2",
  props: {
    data: {}
  },
  data() {
    return {
      royalLogo: royal,
      gelLogo: gel
    }
  }
}
</script>

<style scoped>
.lead {
  font-size: 1.25rem;
  font-weight: 300;
  width: 100%;
}

.selectedSpan {
  font-weight: bold;
  background-color: #8696b2;
  margin: 10px;
  padding: 5px;
  width: 100%;
}
</style>