<template>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>{{ $t("contractNumber") }}</th>
        <th>{{ $t("customerName") }}</th>
        <th>{{ $t("buyingPrice") }}</th>
        <th>{{ $t("buyingDate") }}</th>
        <th>{{ $t("transferred") }}</th>
        <th>{{ $t("cancelled") }}</th>
        <th>{{ $t("note") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="contract in allContracts"
        :key="contract.id"
        :style="
          contract.isTransfered
            ? { 'background-color': '#ffc1c1' }
            : contract.isCancelled
            ? { 'background-color': '#ff8e8e' }
            : { 'background-color': '#ffffff' }
        "
      >
        <td>
          <router-link
            :to="{ name: 'buyContracts.show', params: { id: contract.id } }"
          >
            {{ contract.contractNumber }}
          </router-link>
        </td>
        <td>
          <router-link
            :to="{
              name: 'customers.edit',
              params: { id: contract.customerId },
            }"
          >
            {{
              `${contract.customer.firstName} ${contract.customer.middleName} ${contract.customer.lastName}`
            }}
          </router-link>
        </td>
        <td>
          {{
            projectCurrencySymbol() +
            contract.buyingPrice.toLocaleString() +
            projectCurrencySymbol(true)
          }}
        </td>
        <td>{{ formatDate(contract.buyingDate, "YYYY-MM-DD") }}</td>
        <td>{{ contract.isTransfered ? $t("yes") : $t("no") }}</td>
        <td>{{ contract.isCancelled ? $t("yes") : $t("no") }}</td>
        <td>{{ contract.note }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "PropertyContractsList",
  props: {
    contracts: {
      type: Array,
    },
  },
  watch: {
    contracts: function (value) {
      this.allContracts = value;
    },
  },
  data() {
    return {
      allContracts: this.contracts,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false,
    };
  },
};
</script>
