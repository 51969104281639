import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";
import mixin from "@/mixins";

const propertyHolds = {
  namespaced: true,
  state: {
    propertyHolds: {},
    createdPropertyHold: {},
    getOnePropertyHold: {},
    updatedPropertyHold: {},
  },
  mutations: {
    SET_PROPERTY_HOLDS(state, value) {
      state.propertyHolds = value;
    },
    SET_CREATED_PROPERTY_HOLD(state, value) {
      state.createdPropertyHold = value;
    },
    SET_GET_ONE_PROPERTY_HOLD(state, value) {
      state.getOnePropertyHold = value;
    },
    SET_UPDATED_PROPERTY_HOLD(state, value) {
      state.updatedPropertyHold = value;
    },
  },
  actions: {
    GET_ALL({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/PropertyHold` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_PROPERTY_HOLDS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_PROPERTY_HOLDS", {});
            reject(error);
          });
      });
    },
    GET_ONE({ commit }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/PropertyHold/${id}`)
          .then((response) => {
            commit("SET_GET_ONE_PROPERTY_HOLD", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_GET_ONE_PROPERTY_HOLD", {});
            reject(error);
          });
      });
    },
    CREATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.post(`/PropertyHold`, form)
          .then((response) => {
            commit("SET_CREATED_PROPERTY_HOLD", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CREATED_PROPERTY_HOLD", {});
            reject(error);
          });
      });
    },
    UPDATE({ commit }, { id, form }) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.put(`/PropertyHold/${id}`, form)
          .then((response) => {
            commit("SET_UPDATED_PROPERTY_HOLD", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_PROPERTY_HOLD", {});
            reject(error);
          });
      });
    },
    DELETE(commit, ids) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/PropertyHold/${ids}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    UPDATE_ATTACHMENT(commit, form) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/PropertyHold/UploadAttachment`, form)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    propertyHolds: (state) => state.propertyHolds,
    createdPropertyHold: (state) => state.createdPropertyHold,
    getOnePropertyHold: (state) => state.getOnePropertyHold,
    updatedPropertyHold: (state) => state.updatedPropertyHold,
  },
};

export default propertyHolds;
