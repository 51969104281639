import {HTTP} from "@/plugins/axios";

const financialDashboard = {
    namespaced: true,
    state: {
        accountantDashboard: {},
        expenseChart: {},
    },
    mutations: {
        SET_ACCOUNTANT_DASHBOARD(state, value) { state.accountantDashboard = value },
        SET_EXPENSE_CHART(state, value) { state.expenseChart = value },
    },
    actions: {
        GET_ACCOUNTANT_DASHBOARD({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard/GetAccountantDashboard`)
                    .then((response) => {
                        commit('SET_ACCOUNTANT_DASHBOARD', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ACCOUNTANT_DASHBOARD', {})
                        reject(error)
                    });
            })
        },
        GET_EXPENSE_CHART({commit}, {year, expenseType}) {
            return new Promise((resolve, reject) => {
                let expenseTypeOb = '';
                if (expenseType) {
                    expenseType.map((type) => {
                        expenseTypeOb += `&id=${type}`
                    })
                }
                HTTP.get(`/Reports/GetExpenseChart?startDate=${year}-01-01${expenseTypeOb}`)
                    .then((response) => {
                        commit('SET_EXPENSE_CHART', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_EXPENSE_CHART', {})
                        reject(error)
                    });
            })
        },
    },
    getters : {
        accountantDashboard: state => state.accountantDashboard,
        expenseChart: state => state.expenseChart,
    }
}

export default financialDashboard;
