import { HTTP } from "@/plugins/axios";
import mixin from "@/mixins";

const paymentProcesses = {
  namespaced: true,
  state: {
    updatedPaymentProcesses: {},
  },
  mutations: {
    SET_UPDATED_PAYMENT_PROCESS(state, value) {
      state.updatedPaymentProcesses = value;
    },
  },
  actions: {
    UPDATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.post(`/PaymentProcesses`, form)
          .then((response) => {
            commit("SET_UPDATED_PAYMENT_PROCESS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_PAYMENT_PROCESS", {});
            reject(error);
          });
      });
    },
    PARTIAL_PAY_INSTALLMENT({ commit }, form) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/PaymentProcesses/PostPartialPaymentProcesses`, form)
          .then((response) => {
            commit("SET_UPDATED_PAYMENT_PROCESS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_PAYMENT_PROCESS", {});
            reject(error);
          });
      });
    },
  },
  getters: {
    updatedPaymentProcesses: (state) => state.updatedPaymentProcesses,
  },
};

export default paymentProcesses;
