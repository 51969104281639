<template>
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <img :src="image403" class="img-fluid mb-2" alt="403" style="max-width: 350px">
          <h1 class="font-weight-bold mb-22 mt-2 tx-80 text-muted">403</h1>
          <h4 class="mb-2">{{ $t('notAuthorized') }}</h4>
          <h6 class="text-muted mb-3 text-center">{{ $t('oopsYouAreNotAllowedToSeeThisPage') }}</h6>
          <router-link :to="{name: 'profile'}" class="btn btn-primary">{{ $t('back') }}</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import image403 from './../../assets/images/403.svg';
export default {
  name: "NotAuthorized",
  data: () => {
    return {
      image403
    }
  }
}
</script>
