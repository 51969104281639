<template>
  <div id="transfer-contract-voucher-receipt-zaiton-v2-kurdish" class="d-none" >
    <div style="line-height: 35px;">


<div >
    <h3  style="text-align: center;
    font-weight: bold;" >
بۆ/ كۆمپانیاى شجره‌ الزیتون

    <br> <br>
    ب/ تنازل كردن
</h3>
</div>  

<div  >
  <br> 
<p style=" text-align: justify;"> 


  من ( {{ data.oldCustomer && data.oldCustomer.fullName }} )
   كڕیارى یه‌كه‌ى 
   نیشته‌جێبوونى ژماره‌ ( {{ data.property && data.property.name }} ) 
   له‌ زۆنى ( {{ data.property && data.property.zone }} )  
   <span v-if="data.property && data.property.zone !== 'L'" >
     جاده‌ى ({{ data.property && data.property.streetnumber }}) بلۆكى ({{ data.property && data.property.block }})
    </span>

    له‌ كۆمپانیاكه‌تان به‌ پێ ى گرێبه‌ستى واژوو كراوى ژماره‌ ( {{ data.contractNumber }} )
     له‌ ڕێككه‌وتى   {{ moment(data.buyingDate).format('YYYY-MM-DD')  }} ، من به‌ ڕه‌زامه‌ندى خۆم له‌ ڕێككه‌وتى
     {{moment(data.transferDate).format('YYYY-MM-DD')}} 
      تنازل له‌ یه‌كه‌ى نیشته‌جێبوونى ئاماژه‌ پێ كراوى سه‌ره‌وه‌ ده‌كه‌م بۆ به‌ڕێز
       ( {{ data.newCustomer && data.newCustomer.fullName }} ) 
       ، وه‌ گشت مافه‌كانى خۆم لێ ى وه‌رگرتووه‌ و هیچ مافێكم له‌ سه‌ر كۆمپانیاكه‌تان 
       ( كۆمپانیاى شجره‌ الزیتون ) نه‌ماوه‌و به‌ هیچ شێوه‌یه‌ك مافى گه‌ڕانه‌وه‌م نیه‌ بۆ كۆمپانیاكه‌تان .<br/>
بۆ ئه‌م مه‌به‌سته‌ به‌
 ڕه‌زامه‌ندى خۆم داوا له‌ به‌ڕێزتان ده‌كه‌م ڕِِه‌زامه‌ندى بفه‌رموون
  له‌ سه‌ر گرێبه‌ستى تنازل كردن له‌ نێوان خودى خۆم وه‌كو لایه‌نى یه‌كه‌م
   وه‌ به‌ڕێز ( {{  data.newCustomer && data.newCustomer.fullName  }})  
   وه‌كو لایه‌نى دووه‌م له‌ گرێبه‌ستى تنازل كردنى نێوانمان .
   <br/>
بۆیه‌ ڕه‌زامه‌ندم له‌ سه‌ر ئه‌نجامدان و واژوو
 كردنى گرێبه‌ستێكى نوێ له‌ گه‌ڵ لایه‌نى
  دووه‌م به‌ڕێز (  {{ data.newCustomer && data.newCustomer.fullName  }}) وه‌ له‌ كۆتایى دا 
  به‌ناو كردنى یه‌كه‌ى نیشته‌جێبوون به‌ ناوى خۆى ( لایه‌نى دووه‌م ) ،
   وه‌ خودى خۆم وه‌كو لایه‌نى یه‌كه‌م هیچ مافێكم نه‌ماوه‌ نه‌ له‌ سه‌ر
    یه‌كه‌ى نیشته‌جێبوونى ئاماژه‌ بۆ كراوى سه‌ره‌وه‌ ،
      وه‌ نه‌ له‌ سه‌ر كۆمپانیاى ( شجره‌ الزیتون ) بۆ بازرگانى گشتى و وه‌به‌رهێنانى خانووبه‌ره‌و پیشه‌سازى .

</p>
<div style="text-align: center;  margin-top: 250px;">
  .....
  لەگەڵ رێزدا
</div>
<br/>
<div >
    <p style=" text-align: center;" class="d-inline-flex">
      داواكار ( المتنازل )
              <br>
        {{ data.oldCustomer && data.oldCustomer.fullName }} <br>
        {{ moment(data.transferDate).format('YYYY-MM-DD')  }}<br>
    </p>
</div>

</div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ZaitonTransferContractDesignV2Kurdish",
  props: {
    data: {},
  },
  mounted() {
  },
};
</script>

<style scoped>
p {
  font-size: 19px !important;
  direction: rtl !important;
  text-align: start;
  font-family: Noto, Speda, sans-serif !important;
}

@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
