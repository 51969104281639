import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";

const reports = {
  namespaced: true,
  state: {
    transactions: {},
    propertyReport: {},
    buyContractReport: {},
    paymentProcessReport: {},
    upcommingPaymentReport: {},
    GetSafeBoxTransaction: {},
    buyContractDetails: {},
    forgivePrice: {},
    partialPaymentReport: {},
  },
  mutations: {
    SET_TRANSACTIONS(state, value) {
      state.transactions = value;
    },
    SET_PROPERTY_REPORT(state, value) {
      state.propertyReport = value;
    },
    SET_BUY_CONTRACT_REPORT(state, value) {
      state.buyContractReport = value;
    },
    SET_PAYMENT_PROCESS_REPORT(state, value) {
      state.paymentProcessReport = value;
    },
    SET_UPCOMMING_PAYMENT_REPORT(state, value) {
      state.upcommingPaymentReport = value;
    },
    SET_SAFE_BOX_TRANSACTION(state, value) {
      state.GetSafeBoxTransaction = value;
    },
    SET_BUY_CONTRACT_DETAILS(state, value) {
      state.buyContractDetails = value;
    },
    SET_FORGIVE_PRICE_REPORT(state, value) {
      state.forgivePrice = value;
    },
    SET_PARTIAL_PAYMENT_REPORT(state, value) {
      state.partialPaymentReport = value;
    },
  },
  actions: {
    GET_TRANSACTIONS({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/Transactions` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `&startDate=${serverParams.fromDate}` +
            `&endDate=${serverParams.toDate}` +
            `&type=${serverParams.type}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_TRANSACTIONS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_TRANSACTIONS", {});
            reject(error);
          });
      });
    },
    GET_PROPERTY_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/GetPropertiesReport` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_PROPERTY_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_PROPERTY_REPORT", {});
            reject(error);
          });
      });
    },
    GET_BUY_CONTRACT_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/BuyContractReport` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_BUY_CONTRACT_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_BUY_CONTRACT_REPORT", {});
            reject(error);
          });
      });
    },


    GET_PAYMENT_PROCESS_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/PaymentProcessReport` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_PAYMENT_PROCESS_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_PAYMENT_PROCESS_REPORT", {});
            reject(error);
          });
      });
    },

    
    GET_UPCOMING_PAYMENT_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Dashboard/GetAllUpcomingPayments` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_UPCOMMING_PAYMENT_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPCOMMING_PAYMENT_REPORT", {});
            reject(error);
          });
      });
    },



    GET_SAFE_BOX_TRANSACTION({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/GetSafeboxTransaction` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_SAFE_BOX_TRANSACTION", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_SAFE_BOX_TRANSACTION", {});
            reject(error);
          });
      });
    },
    GET_BUY_CONTRACT_DETAILS({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/GetDetailBuyContracts` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_BUY_CONTRACT_DETAILS", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_BUY_CONTRACT_DETAILS", {});
            reject(error);
          });
      });
    },
    GET_FORGIVE_PRICE_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/GetForgivePaymentReport` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_FORGIVE_PRICE_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_FORGIVE_PRICE_REPORT", {});
            reject(error);
          });
      });
    },
    GET_PARTIAL_PAYMENT_REPORT({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Reports/GetPartialPaymentReport` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}`
        )
          .then((response) => {
            commit("SET_PARTIAL_PAYMENT_REPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_PARTIAL_PAYMENT_REPORT", {});
            reject(error);
          });
      });
    },
  },
  getters: {
    transactions: (state) => state.transactions,
    propertyReport: (state) => state.propertyReport,
    buyContractReport: (state) => state.buyContractReport,
    paymentProcessReport: (state) => state.paymentProcessReport,
    upcommingPaymentReport: (state) => state.upcommingPaymentReport,
    GetSafeBoxTransaction: (state) => state.GetSafeBoxTransaction,
    buyContractDetails: (state) => state.buyContractDetails,
    forgiveReport: (state) => state.forgiveReport,
    partialPaymentReport: (state) => state.partialPaymentReport,
  },
};

export default reports;
